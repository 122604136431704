function SyncService() {};

SyncService.prototype.setTimer = function(){
    var THIS = this;
    try{
        //run timer to download all pending events
//        setTimeout(function() {
//            var pendingdata = Platform.getPendingEvent();
//            if(pendingdata != '' && pendingdata != CURRENT_PENDING_DATA)
//            {
//                CURRENT_PENDING_DATA = pendingdata;
//                pendingdata = $.parseJSON(pendingdata);
//                if (pendingdata['name'].indexOf("objectsmodified") >= 0) {
//                    if (pendingdata['name'] != "objectsmodified-" + OBJECT_USER_ID) {
//                        Platform.removePendingEvent(pendingdata['id']);
//                        THIS.setTimer();
//                        return "";
//                    }
//                }
//                THIS.doSetTimeout(pendingdata);
//            }
//            else if(pendingdata == ""){
//                CURRENT_PENDING_DATA = "";
//            }
//        }, 500);
    }
    catch(e){
        console.log( "function : setTimer"
            + "\n message:" + e.message);
    }
};

SyncService.prototype.doSetTimeout = function(pendingdata){
    try{
        var sync = this;
        setTimeout(function(){
        var pendingobjectdata = pendingdata["event_data"];
        var pendingdataname = pendingdata["name"];
        pendingdataname = pendingdataname.split("-");
        switch(pendingdataname[0])
        {
            case OBJECT_CATEGORY :
            {
                //download pending category data
                sync.downloadPendingCategories(pendingobjectdata, pendingdata);
                break;
            }

            case OBJECT_COURSE :
            {
                //download pending course data
                sync.downloadPendingCoursesforCategory(pendingobjectdata, pendingdata);
                break;
            }

            case OBJECT_COURSE+"order" :
            {
                //download pending course data with order
                sync.downloadPendingCoursesforCategoryWithOrder(pendingobjectdata, pendingdata);
                break;
            }

            case OBJECT_CHAPTER+"order" :
            {
                //download pending chapter data with order
                sync.downloadPendingChaptersforCourseWithOrder(pendingobjectdata, pendingdata);
                break;
            }
            
            case "videoindex" :
            {
                sync.downloadPendingVideoIndex(pendingobjectdata, pendingdata);
                break;
            }

            case OBJECT_CHAPTER :
            {
                //download pending chapter data
                sync.downloadPendingChaptersforCourse(pendingobjectdata, pendingdata);
                break;
            }

            case OBJECT_VIDEO :
            {
                //download pending resource data
                sync.downloadPendingResourcesforChapters(pendingobjectdata, pendingdata);
                break;
            }

            case "product" :
            {
                //download pending resource data
                sync.downloadPendingUserProducts(pendingobjectdata, pendingdata);
                break;
            }

            case "book" :
            {
                //download pending ebooks data
                sync.downloadPendingBooksforCourse(pendingobjectdata, pendingdata);
                break;
            }

            case "booksforcourse" :
            {
                var courseid = pendingdataname[1];
                //download pending ebooks data
                sync.downloadPendingBooksforCourse(pendingobjectdata, pendingdata,courseid);
                break;
            }

            case "supportdoc" :
            {
                //download pending supporting docs data
                sync.downloadPendingSupportDocsforChapter(pendingobjectdata, pendingdata);
                break;
            }

            case "supportdocsforchapter" :
            {
                var chapterid = pendingdataname[1];
                //download pending supporting docs data
                sync.downloadPendingSupportDocsforChapter(pendingobjectdata, pendingdata, chapterid);
                break;
            } 

            case "resourcesforchapter" :
            {
                var chapterid = pendingdataname[1];
                //download pending supporting docs data
                sync.downloadPendingResourcesforChapters(pendingobjectdata, pendingdata, chapterid);
                break;
            }

            case "quizofcourse" :
            {
                var courseid = pendingdataname[1];
                //download pending supporting docs data
                sync.downloadPendingQuizOfCourse(pendingobjectdata, pendingdata, courseid);
                break;
            }

            case "content" :
            {
                //download pending resource data
                sync.downloadPendingResourcesforChapters(pendingobjectdata, pendingdata);
                break;
            }

            case "resourcecontent" :
            {
                //download pending resource content data
                sync.downloadPendingResourceContentMeta(pendingobjectdata, pendingdata);
                break;
            }

            case "faq" :
            {
                //download pending resource content data
                sync.downloadPendingFAQ(pendingobjectdata, pendingdata);
                break;
            }
            case "quiz" :
            {
                sync.downloadPendingquiz(pendingobjectdata,pendingdata);
                break;
            }
            case "objectsmodified" :
            {
                //download pending resource content data
                sync.downloadModifiedObjects(pendingobjectdata, pendingdata);
                break;
            }
            case "notifications" : 
            {
                //download all notifications
                sync.downloadNotifications(pendingobjectdata, pendingdata);
                break;
            }
            case "folder":
            {
                sync.downloadModifiedFolder(pendingobjectdata, pendingdata);
                sync.deleteModifiedFolder(pendingobjectdata[0]);
                this.removePendingEvent(pendingdata["id"]);
                break;
            }
        } 
        },2000);
    }
    catch(e){
        console.log( "function : doSetTimeout"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.removePendingEvent = function(id){
    Platform.removePendingEvent(id);
    this.setTimer();
}

SyncService.prototype.downloadModifiedObjects = function(pendingobjectdata, pendingdata){
    try{
        var sync = this;
        for(i = 0; i < pendingobjectdata.length; i++){
            (function(i){
                setTimeout(function(){
                    sync.objectsModifiedTimeOut(pendingdata,pendingobjectdata,i);
                }, 2000 * i);
            }(i));
        } 
        if(pendingobjectdata.length == 0){
            var objectsmodified_id = pendingdata["id"];
            this.removePendingEvent(pendingdata["id"]);
        }
    }
    catch(e){
        console.log( "function : downloadModifiedObjects"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.objectsModifiedTimeOut = function(pendingdata,pendingobjectdata,i){
    var sync = new SyncService();
    var quizService = CONTROLLER.getService("Quiz");
    var settings = CONTROLLER.getService("Settings");
        switch (pendingobjectdata[i].type) {
            case OBJECT_CATEGORY_TYPE :
                {
                    //download modified category data
                    if ((pendingobjectdata[i].operation == 'modified' || pendingobjectdata[i].operation == 'created')) {
                        sync.downloadSingleCategory(pendingobjectdata[i].nid, pendingobjectdata[i].changed);
                    } else if (pendingobjectdata[i].operation == 'delete') {
                        Platform.removeObject(OBJECT_CATEGORY, pendingobjectdata[i].nid);
                        Platform.deleteVideoViewHistoryByType(pendingobjectdata[i].nid, "subject");
                    }
                    break;
                }

            case OBJECT_COURSE :
                {
                    //download modified course data
                    if ((pendingobjectdata[i].operation == 'modified' || pendingobjectdata[i].operation == 'created') && pendingobjectdata[i].offline != '0') {
                        if (typeof CHAPTER_ORDER_REQUIRED != "undefined" && CHAPTER_ORDER_REQUIRED != "" && CHAPTER_ORDER_REQUIRED == true) {
                            if (pendingobjectdata[i].operation == 'created') {
                                sync.downloadSingleCourse(pendingobjectdata[i].nid, pendingobjectdata[i].changed, true);
                            }
                            else if (pendingobjectdata[i].operation == 'modified') {
                                sync.downloadSingleCourse(pendingobjectdata[i].nid, pendingobjectdata[i].changed);
                            }
                        }
                        else {
                            sync.downloadSingleCourse(pendingobjectdata[i].nid, pendingobjectdata[i].changed);
                        }
                    } else if (pendingobjectdata[i].offline == '0' || pendingobjectdata[i].operation == 'delete') {
                        if (typeof CHAPTER_ORDER_REQUIRED != "undefined" && CHAPTER_ORDER_REQUIRED != "" && CHAPTER_ORDER_REQUIRED == true) {
                            var course = sync.getObjectProperty(OBJECT_COURSE, pendingobjectdata[i].nid, "content");
                            if (course != "") {
                                for (var k = 0; k < course[OBJECT_GROUP].length; k++) {
                                    for (var j = 0; j < course[OBJECT_CATEGORY].length; j++) {
                                        sync.downloadCoursesForCategoryAsync(course[OBJECT_CATEGORY][j], course[OBJECT_GROUP][k], pendingobjectdata[i].changed);
                                    }
                                }
                                Platform.removeObject(OBJECT_COURSE, pendingobjectdata[i].nid);
                              }
                        }
                        Platform.deleteVideoViewHistoryByType(pendingobjectdata[i].nid, "course");
                    }
                    break;
                }

            case OBJECT_CHAPTER :
                {
                    //download pending chapter data
                    if ((pendingobjectdata[i].operation == 'modified' || pendingobjectdata[i].operation == 'created') && pendingobjectdata[i].offline != '0') {
                        sync.downloadSingleChapter(pendingobjectdata[i].nid, pendingobjectdata[i].changed);
                    } else if (pendingobjectdata[i].offline == '0' || pendingobjectdata[i].operation == 'delete') {
                        sync.removeObject(OBJECT_CHAPTER, pendingobjectdata[i].nid);
                        Platform.deleteVideoViewHistoryByType(pendingobjectdata[i].nid, "chapter");
                    }
                    break;
                }

            case OBJECT_VIDEO :
                {
                    //download modified resource data
                    var content_type = "";
                    if ((pendingobjectdata[i].operation == 'modified' || pendingobjectdata[i].operation == 'created') && pendingobjectdata[i].offline != '0') {
                        if (pendingobjectdata[i].content_type && pendingobjectdata[i].content_type.indexOf("supporting doc") > 0) {
                            content_type = OBJECT_SUPPORT_DOCS;
                        }
                        else if (pendingobjectdata[i].content_type && pendingobjectdata[i].content_type.indexOf("ebook") > 0) {
                            content_type = OBJECT_BOOK;
                        }
                        else {
                            content_type = OBJECT_VIDEO;
                        }
                        sync.downloadSingleResourceMeta(pendingobjectdata[i].nid, pendingobjectdata[i].changed, content_type);
                    } else if (pendingobjectdata[i].offline == '0' || pendingobjectdata[i].operation == 'delete') {
                        var objtype = Platform.getObjectType(pendingobjectdata[i].nid);
                        if (objtype != "") {
                            if (objtype == OBJECT_SUPPORT_DOCS) {
                                sync.removeObject(OBJECT_SUPPORT_DOCS, pendingobjectdata[i].nid);
                                Platform.deleteVideoViewHistoryByType(pendingobjectdata[i].nid, "resource");
                            }
                            else if (objtype == OBJECT_BOOK) {
                                sync.removeObject(OBJECT_BOOK, pendingobjectdata[i].nid);
                            }
                            else {
                                sync.removeObject(OBJECT_VIDEO, pendingobjectdata[i].nid);
                                Platform.deleteVideoViewHistoryByType(pendingobjectdata[i].nid, "resource");
                                sync.deleteResource(pendingobjectdata[i].nid, OBJECT_VIDEO);
                            }
                        }
                    }
                    break;
                }

            case "faq" :
                {
                    //download modified faq
                    if ((pendingobjectdata[i].operation == 'modified' || pendingobjectdata[i].operation == 'created') && pendingobjectdata[i].offline != '0') {
                        sync.downloadSingleFAQ(pendingobjectdata[i].nid, pendingobjectdata[i].changed);
                    } else if ((pendingobjectdata[i].operation == 'modified' && pendingobjectdata[i].offline == '0') || pendingobjectdata[i].operation == 'delete') {
                        sync.removeObject("faq", pendingobjectdata[i].nid);
                    }
                    break;
                }
                case "quiz" :
                {
                    if ((pendingobjectdata[i].operation == 'modified' || pendingobjectdata[i].operation == 'created') && pendingobjectdata[i].offline != '0') {
                        sync.downloadQuiz(pendingobjectdata[i].nid, pendingobjectdata[i].changed,pendingdata.id);
                    } else if ((pendingobjectdata[i].operation == 'modified' && pendingobjectdata[i].offline == '0') || pendingobjectdata[i].operation == 'delete') {
                        sync.removeObject("quiz", pendingobjectdata[i].nid);
                    }
                    break;
                }
            case "product" :
            {
                var userproducts = settings.getUserProducts(OBJECT_USER_ID);
                if(typeof userproducts != "undefined" && userproducts != ""){
                    for(var j=0;j<userproducts.length;j++){
                        if(userproducts[j].nid == pendingobjectdata[i].nid){
                            if (pendingobjectdata[i].operation == 'modified' || pendingobjectdata[i].operation == 'created') {
                                sync.downloadUserProducts();
                            }
                            break;
                        }
                    }
                }
                break;
            }
            case "folder":
                {
                    //download modified folder
                    if ((pendingobjectdata[i].operation == 'modified' || pendingobjectdata[i].operation == 'created')) {
                        sync.downloadSingleFolder(pendingobjectdata[i].nid, pendingobjectdata[i].changed);
                        if (pendingobjectdata[i].operation == 'modified') {
                            if (!$.isEmptyObject(pendingobjectdata[i])) {
                                var folder = sync.getObjectProperty("folder", pendingobjectdata[i].nid, "content");
                                if (!$.isEmptyObject(folder)) {
                                    sync.deleteModifiedFolder(folder);
                                }
                            }
                        }
                    } else if (pendingobjectdata[i].operation == 'delete') {
                        if (!$.isEmptyObject(pendingobjectdata[i])) {
                            var folder = sync.getObjectProperty("folder", pendingobjectdata[i].nid, "content");
                            sync.removeObject("folder", pendingobjectdata[i].nid);
                            if (!$.isEmptyObject(folder)) {
                                sync.deleteModifiedFolder(folder);
                            }
                        }
                    }
                    break;
                }
            case 'command' :
            {
                //download modified folder
                if (pendingobjectdata[i].operation == 'delete') {
                    if(pendingobjectdata[i].command == 'video_view_history_delete'){
                        Platform.deleteVideoViewHistoryByType(pendingobjectdata[i].param1, OBJECT_COURSE);
                        //remove the video viewed history of all the course contents
                        var object_testcollection = Platform.getRelatedObjects("course",pendingobjectdata[i].param1, OBJECT_TEST_COLLECTION);
                        var object_testcollection = $.parseJSON(object_testcollection);
                        for (var j = 0; j < object_testcollection['test_collection'].length; j++) {
                            var result = quizService.getTestCollectionQuizResult(object_testcollection['test_collection'][j]);
                            for (var k = 0; k < result['quiz_result'].length; k++) {
                                var id = result['quiz_result'][k]['result_id'];
                                Platform.removeObject("quiz_result", id); //remove all relations made from testcollection to quiz_result
                            }
                        }
                        Platform.removeRelationOfType(OBJECT_COURSE, pendingobjectdata[i].param1, OBJECT_TEST_COLLECTION); //remove all relations made from course to testcollection
                        Platform.removeObject(OBJECT_TEST_COLLECTION, object_testcollection[j]); // remove all saved objects of test collection
                    }
                }
                break;
            }
        }
        if (i == (pendingobjectdata.length - 1)) {
            sync.removeObjectsModifiedPendingEvent(pendingdata, pendingobjectdata);
        }
}

SyncService.prototype.removeObjectsModifiedPendingEvent = function(pendingdata,pendingobjectdata){

    var objectsmodified_id = pendingdata["id"];
    this.removePendingEvent(pendingdata["id"]);
    var timestamp = "";
    if (pendingobjectdata.length > 0) {
        var cronjobs = Platform.getAllCronJobs();
        cronjobs = $.parseJSON(cronjobs);
        if (!$.isEmptyObject(cronjobs)) {
            for (j = 0; j < cronjobs.length; j++) {
                if(cronjobs[j].id.indexOf("objectsmodified") >= 0){
                    Platform.unregisterCronJob(cronjobs[j].id);
                }
            }
        }
        timestamp = pendingobjectdata[pendingobjectdata.length - 1].changed;
        var cronid = "objectsmodified-" + OBJECT_USER_ID;
        Platform.registerCronJob(OBJECT_SERVER_DOMAIN + '/api/getobjectsmodified?timestamp=' + timestamp + '&uid=' + OBJECT_USER_ID + "&version=" + API_VERSION, 1, cronid);
    }
}

SyncService.prototype.getObjectProperty = function(objtype, objid, objname){
    try{
        if(objid !== null){
            var property = Platform.getObjectProperty(objtype, objid, objname);
            if(property !== ""){
                property = $.parseJSON(property);
            }
        }
        else
        {
            property = "";
        }
        return property;
    }
    catch(e){
        console.log( "function : getObjectProperty"
            + "\n message:" + e.message);
    }
};

SyncService.prototype.removeObject = function(objtype, objid){
    try{
        Platform.removeObject(objtype, objid);
    }
    catch(e){
        console.log( "function : removeObject"
            + "\n message:" + e.message);
    }
};

SyncService.prototype.downloadModifiedFolder = function(pendingobjectdata, pendingdata){
    for(var i=0;i<pendingobjectdata.length;i++){
        try{
            if(!$.isEmptyObject(pendingobjectdata[i])){
                Platform.removeObject("folder",pendingobjectdata[i].nid);
                Platform.saveObject("folder", pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));
            }
        }
        catch(e){
            var error = "FOR LOOP : method - downloadModifiedFolder"
            + "\n data = " + pendingobjectdata[i]
            console.log(error);
        }
    }
}

SyncService.prototype.deleteModifiedFolder = function(obj){
    try{
        if(typeof obj.path != "undefined" && obj.path != null && obj.path != ""){
            var docpath = obj.path;
            var filexists = Platform.isFilePresentInDocRoot(docpath);
            if (filexists) {
                Platform.deleteFileFromDocRoot(docpath);
            }
        }
    }
    catch(e){
        console.log( "function : deleteModifiedFolder"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.downloadPendingCategories = function(pendingobjectdata, pendingdata){
    try{
        for(var i=0;i<pendingobjectdata.length;i++){
            try{
                var category = this.getObjectProperty(OBJECT_CATEGORY,pendingobjectdata[i].nid,"content");
                 var groups = pendingobjectdata[i].group;
                if(!$.isEmptyObject(category)){
                    if(category.thumbnail_id != pendingobjectdata[i].thumbnail_id){
                        this.deleteMetaImage(OBJECT_CATEGORY, category.nid);
                    }
                }
                if(!$.isEmptyObject(pendingobjectdata[i])){
                    if(!$.isEmptyObject(category)){
                        this.removeManyToOneRelation(OBJECT_GROUP, groups, OBJECT_CATEGORY , category.nid);
                    }
                    Platform.saveObject(OBJECT_CATEGORY, pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));
                    if(typeof OBJECT_GROUP != "undefined" && OBJECT_GROUP != ""){
                        this.addManyToOneRelation(OBJECT_GROUP, groups, OBJECT_CATEGORY , pendingobjectdata[i].nid);
                    }
                    this.downloadImage(pendingobjectdata[i].nid, pendingobjectdata[i].thumbnail_filename, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC);
                    for(var j=0;j<groups.length;j++){
                        var timestamp = new Date().getTime();
                        this.downloadCoursesForCategoryAsyncWithOrder(pendingobjectdata[i].nid,groups[j],timestamp);
                    }
                    this.downloadImage(pendingobjectdata[i].nid, pendingobjectdata[i].thumbnail_filename, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC);
                }
            }
            catch(e){
                var error = "FOR LOOP : method - downloadPendingCategories"
                + "\n data = " + JSON.stringify(pendingobjectdata[i])
                + "\n message:" + e.message
                console.log(error);
            }
                
        }
        this.removePendingEvent(pendingdata["id"]);
    }
    catch(e){
        console.log( "function : downloadPendingCategories"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.downloadPendingCoursesforCategory = function(pendingobjectdata, pendingdata){
    try{
        var THIS = this;
        for(i = 0; i < pendingobjectdata.length; i++){
            (function(i){
                setTimeout(function(){
                    THIS.downloadPendingCoursesforCategoryTimeOut(pendingdata,pendingobjectdata,i);
                }, 1500 * i);
            }(i));
        }
        if(pendingobjectdata.length == 0){
            var objectsmodified_id = pendingdata["id"];
            this.removePendingEvent(pendingdata["id"]);
        }
    }
    catch(e){
        console.log( "function : downloadPendingCoursesforCategory"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.downloadPendingCoursesforCategoryTimeOut = function(pendingdata,pendingobjectdata,i){
    try{
        var course = this.getObjectProperty(OBJECT_COURSE,pendingobjectdata[i].nid,"content");
        if(!$.isEmptyObject(course)){
            if(course.thumbnail_id != pendingobjectdata[i].thumbnail_id){
                this.deleteMetaImage(OBJECT_COURSE, course.nid);
            }
        }
        if(!$.isEmptyObject(pendingobjectdata[i])){
//            if(!$.isEmptyObject(course)){
//                this.removeManyToOneRelation(OBJECT_CATEGORY, course[OBJECT_CATEGORY], OBJECT_COURSE , course.nid);
//                this.removeManyToOneRelation(OBJECT_GROUP, course[OBJECT_GROUP], OBJECT_COURSE , course.nid);
//                this.removeOneToManyRelation(OBJECT_COURSE, course.nid, OBJECT_CHAPTER , course[OBJECT_CHAPTER]);
//            }
//            Platform.saveObject(OBJECT_COURSE, pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));
//            this.addManyToOneRelation(OBJECT_CATEGORY, pendingobjectdata[i][OBJECT_CATEGORY], OBJECT_COURSE , pendingobjectdata[i].nid);
            if(typeof OBJECT_GROUP != "undefined" && OBJECT_GROUP != ""){
                if(typeof CHAPTER_ORDER_REQUIRED != "undefined" && CHAPTER_ORDER_REQUIRED != "" && CHAPTER_ORDER_REQUIRED == true){
//                    this.addManyToOneRelation(OBJECT_GROUP, pendingobjectdata[i][OBJECT_GROUP], OBJECT_COURSE , pendingobjectdata[i].nid, true, (i+1));
                    var pendingdataname = pendingdata["name"];
                    pendingdataname = pendingdataname.split("-");
                    if(pendingdataname[1]){
                       var groups = OBJECT_USER_DATA.groups;
                        for(var k=0; k < groups.length; k++){
                            for(var j=0; j<pendingobjectdata[i][OBJECT_CATEGORY].length; j++){
                                var date = new Date();
                                var ts = Math.floor(date.getTime() / 1000);
                                this.downloadCoursesForCategoryAsyncWithOrder(pendingobjectdata[i][OBJECT_CATEGORY][j],groups[k],ts);
                            }
                        }
                    }
                }
                else
                {
//                    this.addManyToOneRelation(OBJECT_GROUP, pendingobjectdata[i][OBJECT_GROUP], OBJECT_COURSE , pendingobjectdata[i].nid, false);
                }
            }
            this.downloadBooksforCourseAsync(pendingobjectdata[i].nid);
            this.downloadQuizOfCourseAsync(pendingobjectdata[i].nid);
//            this.addOneToManyRelation(OBJECT_COURSE, pendingobjectdata[i].nid, OBJECT_CHAPTER , pendingobjectdata[i][OBJECT_CHAPTER],true);
            this.downloadImage(pendingobjectdata[i].nid, pendingobjectdata[i].thumbnail_filename, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC);
        }
    }
    catch(e){
        var error = "FOR LOOP : method - downloadPendingCoursesforCategory"
        + "\n data = " + JSON.stringify(pendingobjectdata[i])
        + "\n message:" + e.message
        console.log(error);
    }
    if(i == (pendingobjectdata.length - 1)){
        this.removePendingEvent(pendingdata["id"]);    
    }
}

SyncService.prototype.downloadPendingCoursesforCategoryWithOrder = function(pendingobjectdata, pendingdata){
    var THIS = this;
    for(i = 0; i < pendingobjectdata.length; i++){
        (function(i){
            setTimeout(function(){
                THIS.downloadPendingCoursesforCategoryWithOrderTimeOut(pendingdata,pendingobjectdata,i);
            }, 1500 * i);
        }(i));
    }
    if(pendingobjectdata.length == 0){
        var objectsmodified_id = pendingdata["id"];
        this.removePendingEvent(pendingdata["id"]);
    }
}

SyncService.prototype.downloadPendingCoursesforCategoryWithOrderTimeOut = function(pendingdata,pendingobjectdata,i){
    try{
        try{
            var course = $.parseJSON(Platform.getObjectProperty(OBJECT_COURSE,pendingobjectdata[i].nid,"content"));
            if(!$.isEmptyObject(course)){
                if(course.thumbnail_id != pendingobjectdata[i].thumbnail_id){
                    this.deleteMetaImage(OBJECT_COURSE, course.nid);
                }
            }
            var groups = pendingobjectdata[i].group;
            if(!$.isEmptyObject(pendingobjectdata[i])){
                Platform.saveObject(OBJECT_COURSE, pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));

                if(!$.isEmptyObject(course)){
                    this.removeManyToOneRelation(OBJECT_CATEGORY, course[OBJECT_CATEGORY], OBJECT_COURSE , course.nid);
                    this.removeManyToOneRelation(OBJECT_GROUP, course[OBJECT_GROUP], OBJECT_COURSE , course.nid);
                    this.removeOneToManyRelation(OBJECT_COURSE, course.nid, OBJECT_CHAPTER , course[OBJECT_CHAPTER]);
                }

                this.addManyToOneRelation(OBJECT_CATEGORY, pendingobjectdata[i][OBJECT_CATEGORY], OBJECT_COURSE , pendingobjectdata[i].nid);
                if(typeof OBJECT_GROUP != "undefined" && OBJECT_GROUP != ""){                        
                    if(typeof CHAPTER_ORDER_REQUIRED != "undefined" && CHAPTER_ORDER_REQUIRED != "" && CHAPTER_ORDER_REQUIRED == true){
                        this.addManyToOneRelation(OBJECT_GROUP, groups, OBJECT_COURSE , pendingobjectdata[i].nid, true, (i+1));
                    }
                    else
                    {
                        this.addManyToOneRelation(OBJECT_GROUP, groups, OBJECT_COURSE , pendingobjectdata[i].nid, false);
                    }
                } 
                this.addOneToManyRelation(OBJECT_COURSE, pendingobjectdata[i].nid, OBJECT_CHAPTER , pendingobjectdata[i][OBJECT_CHAPTER],true);
                this.downloadImage(pendingobjectdata[i].nid, pendingobjectdata[i].thumbnail_filename, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC);
            }
        }
        catch(e){
            var error = "FOR LOOP : method - downloadPendingCoursesforCategory"
            + "\n data = " + JSON.stringify(pendingobjectdata[i])
            + "\n message:" + e.message
            console.log(error);
        }
        if(i == (pendingobjectdata.length - 1)){
            this.removePendingEvent(pendingdata["id"]);    
        }
    }
    catch(e){
        console.log( "function : downloadPendingCoursesforCategory"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.downloadPendingChaptersforCourse = function(pendingobjectdata, pendingdata){
    try{
        for(var i=0;i<pendingobjectdata.length;i++){
            try{
                var chapter = this.getObjectProperty(OBJECT_CHAPTER,pendingobjectdata[i].nid,"content");
                if(!$.isEmptyObject(pendingobjectdata[i])){
//                    Platform.saveObject(OBJECT_CHAPTER, pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));
                    
                    //remove existing relations for chapter with course and video 
                    //and course to category relationship and add it back
                    if(!$.isEmptyObject(chapter)){
//                        this.removeManyToOneRelation(OBJECT_COURSE, chapter[OBJECT_COURSE], OBJECT_CHAPTER , chapter.nid);
//                        this.removeManyToManyRelation(OBJECT_CATEGORY, chapter[OBJECT_CATEGORY], OBJECT_COURSE , chapter[OBJECT_COURSE]);
//                        this.removeOneToManyRelation(OBJECT_CHAPTER, chapter.nid, OBJECT_VIDEO ,chapter[OBJECT_VIDEO]);
                        if(chapter && chapter.thumbnail_id != pendingobjectdata[i].thumbnail_id){
                            this.deleteMetaImage(OBJECT_CHAPTER, chapter.nid);
                        } 
                    }
                    
                    for(var j=0;j<pendingobjectdata[i][OBJECT_COURSE].length;j++){
                        this.downloadChaptersForCourseWithOrderAsync(pendingobjectdata[i][OBJECT_COURSE][j]);
                    }
                    
//                    this.addManyToOneRelation(OBJECT_COURSE, pendingobjectdata[i][OBJECT_COURSE], OBJECT_CHAPTER , pendingobjectdata[i].nid, false);
//                    this.addManyToManyRelation(OBJECT_CATEGORY, pendingobjectdata[i][OBJECT_CATEGORY], OBJECT_COURSE , pendingobjectdata[i][OBJECT_COURSE]);
//                    this.addOneToManyRelation(OBJECT_CHAPTER, pendingobjectdata[i].nid, OBJECT_VIDEO ,pendingobjectdata[i][OBJECT_VIDEO]);
                    
                    //download related supporting docs and other resources
                    this.downloadSupportingDocsforChapterAsync(pendingobjectdata[i].nid);
                    this.downloadResourcesForChapterAsync(pendingobjectdata[i].nid);
                    
                    //download chapter image
                    this.downloadImage(pendingobjectdata[i].nid, pendingobjectdata[i].thumbnail_filename, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC);
                }
            }
            catch(e){
                var error = "FOR LOOP : method - downloadPendingChaptersforCourse"
                + "\n data = " + JSON.stringify(pendingobjectdata[i])
                + "\n message:" + e.message
                console.log(error);
            }
        }
        this.removePendingEvent(pendingdata["id"]);
    }
    catch(e){
        console.log( "function : downloadPendingChaptersforCourse"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.downloadPendingVideoIndex = function(pendingobjectdata, pendingdata){
    try{
        try{
            if (typeof pendingobjectdata.nid != "undefined" && pendingobjectdata.nid != null) {
                if(typeof LIMIT_BOOTSTRAP != "undefined" && LIMIT_BOOTSTRAP){
                    delete pendingobjectdata.description;
                }
                Platform.saveObject(OBJECT_VIDEO_INDEX, pendingobjectdata.nid, "content", JSON.stringify(pendingobjectdata));
                Platform.addRelation(OBJECT_VIDEO, pendingobjectdata.clipping_nid, OBJECT_VIDEO_INDEX, pendingobjectdata.nid, "");

            }
        }
        catch(e){
            var error = "FOR LOOP : method - downloadPendingVideoIndex"
            + "\n data = " + JSON.stringify(pendingobjectdata)
            + "\n message:" + e.message
            console.log(error);
        }
        this.removePendingEvent(pendingdata["id"]);
    }
    catch(e){
        console.log( "function : downloadPendingVideoIndex"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.downloadPendingChaptersforCourseWithOrder = function(pendingobjectdata, pendingdata){
    try{
        for(var i=0;i<pendingobjectdata.length;i++){
            try{
                var chapter = this.getObjectProperty(OBJECT_CHAPTER,pendingobjectdata[i].nid,"content");
                if(!$.isEmptyObject(pendingobjectdata[i])){
                    Platform.saveObject(OBJECT_CHAPTER, pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));
                    
                    //remove existing relations for chapter with course and video 
                    //and course to category relationship and add it back
                    if(!$.isEmptyObject(chapter) && chapter != ""){
                        this.removeManyToOneRelation(OBJECT_COURSE, chapter[OBJECT_COURSE], OBJECT_CHAPTER , chapter.nid);
                        this.removeManyToManyRelation(OBJECT_CATEGORY, chapter[OBJECT_CATEGORY], OBJECT_COURSE , chapter[OBJECT_COURSE]);
                        this.removeOneToManyRelation(OBJECT_CHAPTER, chapter.nid, OBJECT_VIDEO ,chapter[OBJECT_VIDEO]);
                        if(chapter.thumbnail_id != pendingobjectdata[i].thumbnail_id){
                            this.deleteMetaImage(OBJECT_CHAPTER, chapter.nid);
                        } 
                    }
                    
                    this.addManyToOneRelation(OBJECT_COURSE, pendingobjectdata[i][OBJECT_COURSE], OBJECT_CHAPTER , pendingobjectdata[i].nid, true, (i+1));
                    this.addManyToManyRelation(OBJECT_CATEGORY, pendingobjectdata[i][OBJECT_CATEGORY], OBJECT_COURSE , pendingobjectdata[i][OBJECT_COURSE]);
                    this.addOneToManyRelation(OBJECT_CHAPTER, pendingobjectdata[i].nid, OBJECT_VIDEO ,pendingobjectdata[i][OBJECT_VIDEO]);
                    
//                    //download related supporting docs and other resources
//                    this.downloadSupportingDocsforChapterAsync(pendingobjectdata[i].nid);
//                    this.downloadResourcesForChapterAsync(pendingobjectdata[i].nid);
                    
                    //download chapter image
                    this.downloadImage(pendingobjectdata[i].nid, pendingobjectdata[i].thumbnail_filename, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC);
                }
            }
            catch(e){
                var error = "FOR LOOP : method - downloadPendingChaptersforCourse"
                + "\n data = " + JSON.stringify(pendingobjectdata[i])
                + "\n message:" + e.message
                console.log(error);
            }
        }
        this.removePendingEvent(pendingdata["id"]);
    }
    catch(e){
        console.log( "function : downloadPendingChaptersforCourse"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.downloadPendingBooksforCourse = function(pendingobjectdata, pendingdata, courseid){
    try{
        var course = [];
        if(courseid){
            course[0] = courseid;
            Platform.removeRelationOfType(OBJECT_COURSE, courseid, OBJECT_BOOK);
        }
        for(var i=0;i<pendingobjectdata.length;i++){
            try{
                var resource = this.getObjectProperty(OBJECT_BOOK,pendingobjectdata[i].nid,"content");
                if(!$.isEmptyObject(pendingobjectdata[i])){
                    if(pendingobjectdata[i].filetype != "html"){
                        if(pendingobjectdata[i].url.indexOf("sites/default") > -1){
                            pendingobjectdata[i].url = this.getResourcePathFromUrl(pendingobjectdata[i].nid,pendingobjectdata[i].filetype);
                        }
                    }
                    if(!$.isEmptyObject(resource)){
                        if(resource.thumbnail_id != pendingobjectdata[i].thumbnail_id){
                            this.deleteMetaImage(OBJECT_BOOK, resource.nid);
                        }
                       var product_type = this.getUserProductType();
                       var contentAvailable = $.parseJSON(Platform.isContentAvailable(resource.nid,resource.youtube_id,RESOURCE_FORMAT,product_type,"ebook"));
                       var contentAdded = Platform.isFileAddedForDownload(resource.nid);
                        if( contentAvailable.filestatus == "Available" && contentAdded == false ){
                            var paramsJson = {'objectid':resource.nid,'objecttype':'resource',
                            'serverurl':OBJECT_DATA_FOLDER,'localurl':OBJECT_DATA_FOLDER, 'message':"", 
                            'sync':FILE_DOWNLOAD_ASYNC, 'expiryduration':"", 
                            'onComplete':"", 'youtubeid':resource.youtube_id,'format':RESOURCE_FORMAT,'type':'content','remove_file_entry':"true"};
                            Platform.downloadFile(JSON.stringify(paramsJson));
                        }
                    }
                    Platform.saveObject(OBJECT_BOOK, pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));
                    if(courseid){
                        course[0] = courseid;
                        this.addManyToOneRelation(OBJECT_COURSE, course, OBJECT_BOOK , pendingobjectdata[i].nid);
                    }                    
                    this.downloadResourcePosterImage(pendingobjectdata[i].nid, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC, pendingobjectdata[i].thumbnail_id , pendingobjectdata[i].thumbnail_filename, pendingobjectdata[i].filetype);
                }
            }
            catch(e){
                var error = "FOR LOOP : method - downloadPendingBooksforCourse"
                + "\n data = " + JSON.stringify(pendingobjectdata[i])
                + "\n message:" + e.message
                console.log(error);
            }
        }
        this.removePendingEvent(pendingdata["id"]);
    }
    catch(e){
        console.log( "function : downloadPendingBooksforCourse"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.downloadPendingUserProducts = function(pendingobjectdata, pendingdata, chapterid){
    try{
        if (!$.isEmptyObject(pendingobjectdata))
        {
            var userproducts = pendingobjectdata;
            if(typeof userproducts[0].expiry != "undefined" && userproducts[0].expiry != ""){
                Platform.addOrUpdateProductExpiry(userproducts[0].expiry);
            }
            if(typeof userproducts[0].type != "undefined" && userproducts[0].type == "internal"){
                if(typeof userproducts[0].promo_duration != "undefined" && userproducts[0].promo_duration > 0){
                    BUYNOW_POPUP_DURATION = userproducts[0].promo_duration / 60;
                }
            }
            if(typeof userproducts[0].hours != "undefined" && userproducts[0].hours != null){
                if(userproducts[0].hours > 0){
                    CONTENT_EXPIRY_HOURS = userproducts[0].hours;
                }
                else{
                    CONTENT_EXPIRY_HOURS = "";
                }
            }
            if(typeof userproducts[0].watch_hours != "undefined" && userproducts[0].watch_hours != null && userproducts[0].watch_hours > 0){
                if(userproducts[0].watch_hours > 0){
                    CONTENT_WATCH_HOURS = userproducts[0].watch_hours
                }
                else{
                    CONTENT_WATCH_HOURS = "";
                }
            }
            Platform.saveObject("userproducts",OBJECT_USER_ID,"content",JSON.stringify(userproducts));
        }
        this.removePendingEvent(pendingdata["id"]);
    }
    catch(e){
        this.removePendingEvent(pendingdata["id"]);
        console.log( "function : downloadPendingUserProducts"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.downloadPendingSupportDocsforChapter = function(pendingobjectdata, pendingdata, chapterid){
    try{
        var chapter = [];
        if(chapterid){
            chapter[0] = chapterid;
            Platform.removeRelationOfType(OBJECT_CHAPTER, chapterid, OBJECT_SUPPORT_DOCS);
        }
        var downloadType;
        for(var i=0;i<pendingobjectdata.length;i++){
            try{
                var resource = this.getObjectProperty(OBJECT_SUPPORT_DOCS,pendingobjectdata[i].nid,"content");
                if(!$.isEmptyObject(pendingobjectdata[i])){
                    if(pendingobjectdata[i].filetype != "html"){
                        if(pendingobjectdata[i].url.indexOf("sites/default") > -1){
                            pendingobjectdata[i].url = this.getResourcePathFromUrl(pendingobjectdata[i].nid,pendingobjectdata[i].filetype);
                        }
                    }
                    if(!$.isEmptyObject(resource)){
                        if(resource.thumbnail_id != pendingobjectdata[i].thumbnail_id){
                            this.deleteMetaImage(OBJECT_BOOK, resource.nid);
                        }
                        if(resource.type == "Document"){
                            downloadType = "ebook";
                        }
                        else{
                            downloadType = "video";
                        }
                        var product_type = this.getUserProductType();
                        var contentAvailable = $.parseJSON(Platform.isContentAvailable(resource.nid,resource.youtube_id,RESOURCE_FORMAT,product_type,downloadType));
                        var contentAdded = Platform.isFileAddedForDownload(resource.nid);
                        if( contentAvailable.filestatus == "Available" && contentAdded == false ){
                            var paramsJson = {'objectid':resource.nid,'objecttype':'resource',
                            'serverurl':OBJECT_DATA_FOLDER,'localurl':OBJECT_DATA_FOLDER, 'message':"", 
                            'sync':FILE_DOWNLOAD_ASYNC, 'expiryduration':"", 
                            'onComplete':"", 'youtubeid':resource.youtube_id,'format':RESOURCE_FORMAT,'type':'content','remove_file_entry':"true"};
                            Platform.downloadFile(JSON.stringify(paramsJson));
                        }
                    }
                    Platform.saveObject(OBJECT_SUPPORT_DOCS, pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));
                    if(chapterid){
                        chapter[0] = chapterid;
                        this.addManyToOneRelation(OBJECT_CHAPTER, chapter, OBJECT_SUPPORT_DOCS , pendingobjectdata[i].nid);
                    }
                    this.downloadResourcePosterImage(pendingobjectdata[i].nid, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC, pendingobjectdata[i].thumbnail_id , pendingobjectdata[i].thumbnail_filename, pendingobjectdata[i].filetype);
                }
            }
            catch(e){
                var error = "FOR LOOP : method - downloadPendingSupportDocsforChapter"
                + "\n data = " + JSON.stringify(pendingobjectdata[i])
                + "\n message:" + e.message
                console.log(error);
            }
        }
        this.removePendingEvent(pendingdata["id"]);
    }
    catch(e){
        console.log( "function : downloadPendingSupportDocsforChapter"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.downloadPendingResourcesforChapters = function(pendingobjectdata, pendingdata, chapterid){
    try{
        var chapter = [];
        for(var i=0;i<pendingobjectdata.length;i++){
            try{
                var resource = this.getObjectProperty(OBJECT_VIDEO,pendingobjectdata[i].nid,"content");
                if(!$.isEmptyObject(resource)){
                    if(resource.thumbnail_id != pendingobjectdata[i].thumbnail_id){
                        this.deleteMetaImage(OBJECT_VIDEO, resource.nid);
                    }
                    var product_type = this.getUserProductType();
                    var contentAvailable = $.parseJSON(Platform.isContentAvailable(resource.nid,resource.youtube_id,RESOURCE_FORMAT,product_type,"video"));
                    var contentAdded = Platform.isFileAddedForDownload(resource.nid);
                        if( contentAvailable.filestatus == "Available" && contentAdded == false ){
                        var paramsJson = {'objectid':resource.nid,'objecttype':'resource',
                        'serverurl':OBJECT_DATA_FOLDER,'localurl':OBJECT_DATA_FOLDER, 'message':"", 
                        'sync':FILE_DOWNLOAD_ASYNC, 'expiryduration':"", 
                        'onComplete':"", 'youtubeid':resource.youtube_id,'format':RESOURCE_FORMAT,'type':'content','remove_file_entry':"true"};
                        Platform.downloadFile(JSON.stringify(paramsJson));
                    }
                }
                if(!$.isEmptyObject(pendingobjectdata[i])){
                    if(pendingobjectdata[i].filetype != "html"){
                        if(pendingobjectdata[i].url.indexOf("sites/default") > -1){
                            pendingobjectdata[i].url = this.getResourcePathFromUrl(pendingobjectdata[i].nid,pendingobjectdata[i].filetype);
                        }
                    }
                    Platform.saveObject(OBJECT_VIDEO, pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));
                    if(chapterid){
                        chapter[0] = chapterid;
                        this.addManyToOneRelation(OBJECT_CHAPTER, chapter, OBJECT_VIDEO , pendingobjectdata[i].nid);
                    }
                    this.downloadResourcePosterImage(pendingobjectdata[i].nid, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC, pendingobjectdata[i].thumbnail_id , pendingobjectdata[i].thumbnail_filename, pendingobjectdata[i].filetype);
                }
            }
            catch(e){
                var error = "FOR LOOP : method - downloadPendingResourcesforChapters"
                + "\n data = " + JSON.stringify(pendingobjectdata[i])
                + "\n message:" + e.message
                console.log(error);
            }
        }
        this.removePendingEvent(pendingdata["id"]);
    }
    catch(e){
        console.log( "function : downloadPendingResourcesforChapters"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.downloadPendingResourceContentMeta = function(pendingobjectdata, pendingdata){
    try{
        for(var i=0;i<pendingobjectdata.length;i++){
            try{
                var resource = this.getObjectProperty(OBJECT_VIDEO,pendingobjectdata[i].nid,"content");
                if(!$.isEmptyObject(resource)){
                    if(resource.thumbnail_id != pendingobjectdata[i].thumbnail_id){
                        this.deleteMetaImage(OBJECT_VIDEO, resource.nid);
                    }
                    var product_type = this.getUserProductType();
                    var contentAvailable = $.parseJSON(Platform.isContentAvailable(resource.nid,resource.youtube_id,RESOURCE_FORMAT,product_type,"video"));
                     var contentAdded = Platform.isFileAddedForDownload(resource.nid);
                        if( contentAvailable.filestatus == "Available" && contentAdded == false ){
                            var paramsJson = {'objectid':resource.nid,'objecttype':'resource',
                            'serverurl':OBJECT_DATA_FOLDER,'localurl':OBJECT_DATA_FOLDER, 'message':"", 
                            'sync':FILE_DOWNLOAD_ASYNC, 'expiryduration':"", 
                            'onComplete':"", 'youtubeid':resource.youtube_id,'format':RESOURCE_FORMAT,'type':'content','remove_file_entry':"true"};
                            Platform.downloadFile(JSON.stringify(paramsJson));
                        }
                }
                if(!$.isEmptyObject(pendingobjectdata[i])){
                    if(pendingobjectdata[i].filetype != "html"){
                        if(pendingobjectdata[i].url.indexOf("sites/default") > -1){
                            pendingobjectdata[i].url = this.getResourcePathFromUrl(pendingobjectdata[i].nid,pendingobjectdata[i].filetype);
                        }
                        
                    }
                    Platform.saveObject(OBJECT_VIDEO, pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));

                    if(typeof DOWNLOAD_CONTENT_THROUGH_SYNC != "undefined" && DOWNLOAD_CONTENT_THROUGH_SYNC != "" && DOWNLOAD_CONTENT_THROUGH_SYNC == "true"){
                        if(pendingobjectdata[i].filetype != "html"){
                            if($.isEmptyObject(resource)){
                                this.downloadResourceAsync(pendingobjectdata[i].nid, pendingobjectdata[i].youtube_id, 'created', pendingobjectdata[i].title + " downloaded successfully");
                            } else if(!$.isEmptyObject(resource)){
                                var filephypath = getResourceLocalPath(resource);
                                var resourcefileAvailable = Platform.isFilePresentInDocRoot(filephypath);
                                if(resourcefileAvailable == true){
                                    this.downloadResourceAsync(pendingobjectdata[i].nid, resource.youtube_id, 'modified', pendingobjectdata[i].title + " downloaded successfully");
                                }
                                else{
                                    this.downloadResourceAsync(pendingobjectdata[i].nid, pendingobjectdata[i].youtube_id, 'created', pendingobjectdata[i].title + " downloaded successfully");
                                }
                            }
                        }
                    }
                    this.downloadResourcePosterImage(pendingobjectdata[i].nid, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC, pendingobjectdata[i].thumbnail_id , pendingobjectdata[i].thumbnail_filename, pendingobjectdata[i].filetype);
                }
                if (typeof DELETE_DOC_THROUGH_SYNC != "undefined" && DELETE_DOC_THROUGH_SYNC == "true" && !$.isEmptyObject(resource) && typeof resource.type != "undefined" && resource.type == 'Document' && resource.source == "Upload") {
                    if(typeof resource.url != "undefined" && resource.url != "" ){
                        Platform.deleteFileFromInternalMemory(resource.nid);
                    }
                    else{
                        var error = "empty url for delete - downloadPendingResourceContentMeta"
                        + "\n data = " + JSON.stringify(pendingobjectdata[i])
                        console.log(error);
                    }
                }
            }
            catch(e){
                var error = "FOR LOOP : method - downloadPendingResourceContentMeta"
                + "\n data = " + JSON.stringify(pendingobjectdata[i])
                + "\n message:" + e.message
                console.log(error);
            }
        }
        this.removePendingEvent(pendingdata["id"]);
    }
    catch(e){
        console.log( "function : downloadPendingResourceContentMeta"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.downloadResourceAsync = function(resourceid, youtube_id, operation, downloadmessage, downloadExpiry, download_complete_url,courseid,type)
{
    /*  get resource type
    *  get sdcard path
    * build download path
    * downLoadFile(URL,path);
    */
   
    try{
        if(type){
            var resource = Platform.getObjectProperty(type,resourceid,"content");
        }
        else{
            resource = Platform.getObjectProperty(OBJECT_VIDEO,resourceid,"content");
        }
        resource = $.parseJSON(resource);
        var filepath = getResourceLocalPath(resource);
        var format = "";
        var courseparam = "";
        var downloadapi = "";
        if(!$.isEmptyObject(resource)){
            if(resource.filetype == 'pdf'){
                format = '&format=original';
            }
            else{
                format = '&format=' + RESOURCE_FORMAT;
            }
            if(resource.source != 'Link' && resource.filetype != "html"){
                if(resource.filetype == 'pdf' && DOCUMENT_TRANSCODE == true){
                    downloadapi = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO + "/zip";
                }
                else{
                    downloadapi = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO;
                }
                //online redirect api call for type video
                if(courseid && courseid != ""){
                    courseparam = "&courseid="+courseid;
                }
                else{
                    courseparam = "";
                }
                if(operation == 'modified'){
                    var downloadurl = downloadapi + '?nid=' + resourceid + '&redirect=true'+ format +'&youtubeid=' + youtube_id + '&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED + '&download=true' + courseparam;
                } else {
                    downloadurl = downloadapi + '?nid=' + resourceid + '&redirect=true'+ format + '&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED + '&download=true' + courseparam;
                }
                var downloadmessage = resource.title + " downloaded successfully"
                var paramsJson = {'objectid':resourceid,'objecttype':'resource',
                'serverurl':downloadurl,'localurl':filepath, 'message':downloadmessage, 
                'sync':FILE_DOWNLOAD_ASYNC, 'expiryduration':downloadExpiry, 
                'onComplete':download_complete_url, 'youtubeid':youtube_id,'format':RESOURCE_FORMAT,'type':'content'};
                Platform.downloadFile(JSON.stringify(paramsJson));
            }
        }
    }
    catch(e){
        console.log( "function : downloadResourceAsync"
            + "\n url:" + downloadurl
            + "\n message" + e.message);
    }
};

SyncService.prototype.downloadPendingFAQ = function(pendingobjectdata, pendingdata){
    try{
        for(var i=0;i<pendingobjectdata.length;i++){
            try{
                if(!$.isEmptyObject(pendingobjectdata[i])){
                    Platform.saveObject("faq", pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));
                }
            }
            catch(e){
                var error = "FOR LOOP : method - downloadPendingFAQ"
                + "\n nid - " + pendingobjectdata[i].nid + " data = " + pendingobjectdata[i]
                console.log(error);
            }
        }
        this.removePendingEvent(pendingdata["id"]);
    }
    catch(e){
        console.log( "function : downloadPendingFAQ"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.addManyToOneRelation = function(objtype, objid, relobjtype, relobjid, addoffset, index){
    try{
        if (objid != null && objid != "" && objid != "null" && relobjid != null && relobjid != "" && relobjid != "null") {
            for (var i = 0; i < objid.length; i++) {
                if (objid[i] != null && objid[i] != "" && objid[i] != "null" && relobjid != null && relobjid != "" && relobjid != "null") {
                    try {
                        if (addoffset == true) {
                            Platform.addRelation(objtype, objid[i], relobjtype, relobjid, index);
                        }
                        else {
                            Platform.addRelation(objtype, objid[i], relobjtype, relobjid, "");
                        }
                    }
                    catch (e) {
                        var error = "FOR LOOP : method - addManyToOneRelation"
                                + "\n nid - " + objid[i].nid + " data = " + objid[i]
                        console.log(error);
                    }
                }
            }
        }
    }
    catch(e){
        console.log( "function : addManyToOneRelation"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.addOneToManyRelation = function(objtype, objid, relobjtype, relobjid, addoffset){
    try{
        if(relobjid != null && relobjid != "" && relobjid != "null" && objid != null && objid != "" && objid != "null"){
            for(var i = 0; i < relobjid.length; i++) {
                if (relobjid[i] != null && relobjid[i] != "" && relobjid[i] != "null" && objid != null && objid != "" && objid != "null") {
                    try {
                        if (addoffset) {
                            Platform.addRelation(objtype, objid, relobjtype, relobjid[i], (i + 1));
                        }
                        else {
                            Platform.addRelation(objtype, objid, relobjtype, relobjid[i]);
                        }
                    }
                    catch (e) {
                        var error = "FOR LOOP : method - addOneToManyRelation"
                                + "\n nid - " + relobjid[i].nid + " data = " + relobjid[i]
                        console.log(error);
                    }
                }
            }
        }
    }
    catch(e){
        console.log( "function : addOneToManyRelation"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.removeManyToOneRelation = function(objtype, objid, relobjtype, relobjid){
    try{
        if (objid != null && objid != "" && objid != "null" && relobjid != null && relobjid != "" && relobjid != "null") {
            for (var i = 0; i < objid.length; i++) {
                if (objid[i] != null && objid[i] != "" && objid[i] != "null" && relobjid != null && relobjid != "" && relobjid != "null") {
                    try {
                        Platform.removeRelation(objtype, objid[i], relobjtype, relobjid);
                    }
                    catch (e) {
                        var error = "FOR LOOP : method - removeManyToOneRelation"
                                + "\n nid - " + objid[i].nid + " data = " + objid[i]
                        console.log(e.message);
                    }
                }
            }
        }
    }
    catch(e){
        console.log( "function : addManyToOneRelation"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.removeOneToManyRelation = function(objtype, objid, relobjtype, relobjid){
    try{
        if(relobjid != null && relobjid != "" && relobjid != "null" && objid != null && objid != "" && objid != "null"){
            for(var i = 0; i < relobjid.length; i++) {
                if (relobjid[i] != null && relobjid[i] != "" && relobjid[i] != "null" && objid != null && objid != "" && objid != "null") {
                    try {
                        Platform.removeRelation(objtype, objid, relobjtype, relobjid[i]);
                    }
                    catch (e) {
                        var error = "FOR LOOP : method - removeOneToManyRelation"
                                + "\n nid - " + relobjid[i].nid + " data = " + relobjid[i]
                        console.log(e.message);
                    }
                }
            }
        }
    }
    catch(e){
        console.log( "function : addOneToManyRelation"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.removeManyToManyRelation = function(objtype, objid, relobjtype, relobjid){
    try{
        for(var i=0; i<objid.length; i++){
            for(var j=0; j<relobjid.length; j++){
                try{
                    Platform.removeRelation(objtype, objid[i], relobjtype, relobjid[j]);
                }
                catch(e){
                    var error = "FOR LOOP : method - removeManyToManyRelation"
                    + "\n data = " + relobjid[i]
                    console.log(e.message);
                }
            }
        }
    }
    catch(e){
        console.log( "function : addOneToManyRelation"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.addManyToManyRelation = function(objtype, objid, relobjtype, relobjid){
    try{
        for(var i=0; i<objid.length; i++){
            for(var j=0; j<relobjid.length; j++){
                try{
                    Platform.addRelation(objtype, objid[i], relobjtype, relobjid[j]);
                }
                catch(e){
                    var error = "FOR LOOP : method - addManyToManyRelation"
                    + "\n data = " + relobjid[i]
                    console.log(e.message);
                }
            }
        }
    }
    catch(e){
        console.log( "function : addOneToManyRelation"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.getAllNotifications = function(){
    try{
        var notifications = Platform.getObjectsOfType("notifications");
        if(notifications != ""){
            return $.parseJSON(notifications); 
        }
        else
        {
            return "";
        }
    }
    catch(e){
        console.log( "function : getAllNotifications"
            + "\n message:" + e.message);
    }
};

SyncService.prototype.getAllUnreadNotifications = function(){
    try{
        var notifications = Platform.getObjectsOfType("unreadnotifications");
        if(notifications != ""){
            return $.parseJSON(notifications); 
        }
        else
        {
            return "";
        }
    }
    catch(e){
        console.log( "function : getAllUnreadNotifications"
            + "\n message:" + e.message);
    }
};

SyncService.prototype.downloadNotifications = function(pendingobjectdata, pendingdata){
    if(pendingobjectdata.length > 0 ){
        for(var i=0;i<pendingobjectdata.length;i++){
            try{
                if(!$.isEmptyObject(pendingobjectdata[i])){
                    Platform.removeObject("notifications",pendingobjectdata[i].nid);
                    Platform.saveObject("notifications", pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));
                    Platform.saveObject("unreadnotifications", pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));
                    this.downloadImage(pendingobjectdata[i].nid, pendingobjectdata[i].thumbnail_filename, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC);
                    if(typeof NOTIF_COUNT_DISPLAY_ELEMENT != "undefined" && NOTIF_COUNT_DISPLAY_ELEMENT != ""){
                        var unreadnotif = this.getAllUnreadNotifications();
                        if(unreadnotif != ""){
                            var unreadcount = unreadnotif["unreadnotifications"].length;
                            $(NOTIF_COUNT_DISPLAY_ELEMENT).html(unreadcount);
                        }
                    }
                }
            }
            catch(e){
                var error = "FOR LOOP : method - downloadNotifications"
                + "\n nid - " + pendingobjectdata[i].nid + " data = " + pendingobjectdata[i]
                console.log(error);
            }
        }
        var timestamp = pendingobjectdata[pendingobjectdata.length - 1].created;
        Platform.unregisterCronJob("notifications");
        Platform.registerCronJob(OBJECT_SERVER_DOMAIN + '/api/getallnotifications?timestamp=' + timestamp + "&type=general", 1, "notifications");
    }
    this.removePendingEvent(pendingdata["id"]);
}

////////////////////////////////////////////////////////////////////////////////
//                          SINGLE META DOWNLOAD METHODS                      //
////////////////////////////////////////////////////////////////////////////////

SyncService.prototype.downloadSingleFolder = function(nid,changed){
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/getfolderfromid?nid=' + nid + '&uid=' + OBJECT_USER_ID + '&changed=' + changed + "&version=" + API_VERSION, "folder-"+nid);
    }
    catch(e){
        console.log("function : downloadSingleFolder"
            + " message" + e.message);
    }
};

SyncService.prototype.downloadSingleCategory = function(catid,changed){
    try{
        
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CATEGORY + 'fromid?nid=' + catid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&changed=' + changed + "&version=" + API_VERSION, OBJECT_CATEGORY+"-"+catid);
    }
    catch(e){
        console.log("function : downloadSingleCategory"
            + " message" + e.message);
    }
};

SyncService.prototype.downloadSingleCourse = function(courseid,changed,created){
    try{
        if(created){
            var id = OBJECT_COURSE+"-"+courseid+"-created";
        }
        else{
            id = OBJECT_COURSE+"-"+courseid;
        }
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_COURSE + 'fromid?nid=' + courseid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&changed=' + changed + "&version=" + API_VERSION, id);
    }
    catch(e){
        console.log("function : downloadSingleCourse"
            + " message" + e.message);
    }
};


SyncService.prototype.downloadSingleChapter = function(chapterid,changed){
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'fromid?nid=' + chapterid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&changed=' + changed + "&version=" + API_VERSION, OBJECT_CHAPTER+"-"+chapterid);
    }
    catch(e){
        console.log("function : downloadSingleChapter"
            + " message" + e.message);
    }
};

SyncService.prototype.downloadUserProducts = function(){
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/getuserproducts?uid=' + OBJECT_USER_ID + "&auth=" + AUTH_ENABLED, "product-"+OBJECT_USER_ID);
    }
    catch(e){
        console.log("function : downloadUserProducts"
            + " message" + e.message);
    }
};
SyncService.prototype.downloadQuiz = function(nid,changed){
    try{
        var ts = new Date().getTime();
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUIZ+'fromid?nid='+nid+'&t='+ts,"quiz-"+nid);
    }
    catch(e){
        console.log("function : syncdownloadQuiz"
            + " message" + e.message);
    }
}
SyncService.prototype.downloadPendingQuizOfCourse = function(pendingobjectdata, pendingdata, courseid){
    try{
        for (i = 0; i < pendingobjectdata.length; i++) {
            var resource = this.getObjectProperty(OBJECT_QUIZ,pendingobjectdata[i].nid,"content");
            var product_type =this.getUserProductType();
            var contentAvailable = $.parseJSON(Platform.isContentAvailable(resource.nid, resource.changed, RESOURCE_FORMAT, product_type, OBJECT_QUIZ));
            var contentAdded = Platform.isFileAddedForDownload(resource.nid);
            if (contentAvailable.filestatus == "Available" && contentAdded == false) {
                var paramsJson = {'objectid': resource.nid, 'objecttype': 'resource',
                    'serverurl': OBJECT_DATA_FOLDER, 'localurl': OBJECT_DATA_FOLDER, 'message': "",
                    'sync': FILE_DOWNLOAD_ASYNC, 'expiryduration': "",
                    'onComplete': "", 'youtubeid': resource.changed, 'format': RESOURCE_FORMAT, 'type': 'content', 'remove_file_entry': "true"};
                Platform.downloadFile(JSON.stringify(paramsJson));
            }
            Platform.saveObject(OBJECT_QUIZ, pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));
            Platform.addRelation(OBJECT_COURSE, courseid, OBJECT_QUIZ, pendingobjectdata[i].nid, (i+1));
        }
        this.removePendingEvent(pendingdata["id"]);
    }
    catch(e){
        console.log("function : syncdownloadQuiz"
            + " message" + e.message);
    }
}
SyncService.prototype.downloadPendingquiz = function(pendingobjectdata, pendingdata){
    try{
        for (i = 0; i < pendingobjectdata.length; i++) {
            var resource = this.getObjectProperty(OBJECT_QUIZ,pendingobjectdata[i].nid,"content");
            var product_type = this.getUserProductType();
            var contentAvailable = $.parseJSON(Platform.isContentAvailable(resource.nid, resource.changed, RESOURCE_FORMAT, product_type, OBJECT_QUIZ));
            var contentAdded = Platform.isFileAddedForDownload(resource.nid);
            if (contentAvailable.filestatus == "Available" && contentAdded == false) {
                var paramsJson = {'objectid': resource.nid, 'objecttype': 'resource',
                    'serverurl': OBJECT_DATA_FOLDER, 'localurl': OBJECT_DATA_FOLDER, 'message': "",
                    'sync': FILE_DOWNLOAD_ASYNC, 'expiryduration': "",
                    'onComplete': "", 'youtubeid': resource.changed, 'format': RESOURCE_FORMAT, 'type': 'content', 'remove_file_entry': "true"};
                Platform.downloadFile(JSON.stringify(paramsJson));
            }
            Platform.saveObject(OBJECT_QUIZ, pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));
        }
        this.removePendingEvent(pendingdata["id"]);
    }
    catch(e){
        console.log("function : syncdownloadQuiz"
            + " message" + e.message);
    }
}
SyncService.prototype.downloadSingleResourceMeta = function(resourceid,changed,type){
    try{
        var ts = new Date().getTime();
        var cronid = "";
        if(type == OBJECT_VIDEO){
            cronid = "resourcecontent-"+resourceid;
        }
        else if(type == OBJECT_BOOK){
            cronid = "book-"+resourceid;
        }
        else if(type == OBJECT_SUPPORT_DOCS){
            cronid = "supportdoc-"+resourceid;
        }
        if(type == OBJECT_VIDEO && typeof VIDEO_INDEX_ENABLED != "undefined" && VIDEO_INDEX_ENABLED == "true"){
            Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get'+OBJECT_VIDEO_INDEX+'?nid='+resourceid+'&ts='+ts, "videoindex-"+resourceid);
        }  
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'fromid?nid=' + resourceid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&changed=' + changed + "&version=" + API_VERSION + "&format=" + RESOURCE_FORMAT, cronid);
    }
    catch(e){
        console.log("function : downloadSingleResourceMeta"
            + " message" + e.message);
    }
};

SyncService.prototype.deleteResource = function(resourceid,resourcetype){
    try{
        var resource = "";
        if(resourcetype == OBJECT_VIDEO){
            resource = Platform.getObjectProperty(OBJECT_VIDEO,resourceid,"content");
        }
        else if(resourcetype == OBJECT_BOOK){
            resource = Platform.getObjectProperty(OBJECT_BOOK,resourceid,"content");
        }
        else{
            resource = Platform.getObjectProperty(OBJECT_SUPPORT_DOCS,resourceid,"content");
        }
        if(resource != ""){
            resource = $.parseJSON(resource);
        }
        if(!$.isEmptyObject(resource)){
            Platform.deleteFileFromInternalMemory(resourceid);
        }
    }
    catch(e){
        console.log("function : deleteResource"
            + " message" + e.message);
    }
};

/*
 * function to delete images when existing meta image gets modified or changes
 */
SyncService.prototype.deleteMetaImage = function(type, nid){
    try{
        var obj = this.getObjectProperty(type,nid,"content");
        if(!$.isEmptyObject(obj)){
            if (obj.thumbnail_filename && obj.thumbnail_filename) {
                var folderpath = obj.thumbnail_filename.split("/").slice(3).join("/");
                var filepath = folderpath.substring(0, folderpath.indexOf('?'));
                var fileAvailable = Platform.isFilePresentInDocRoot(filepath);
                if(fileAvailable == true && filepath != OBJECT_DATA_FOLDER && filepath != OBJECT_RESOURCE_FOLDER){
                    Platform.deleteFileFromDocRoot(filepath);
                }
            }
        }
    }
    catch(e){
        console.log("function : deleteMetaImage"
            + " message" + e.message);
    }
};

SyncService.prototype.downloadAllCategoriesAsync = function(){
    try{
        var url = OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET;
        Platform.downloadForm(url, OBJECT_CATEGORY);
    }
    catch(e){
        console.log("function : downloadAllCategoriesAsync"
            + " message" + e.message);
    }
};

SyncService.prototype.downloadCoursesForCategoryAsync = function(catid,groupid,ts){
    try{
        if (typeof groupid != "undefined" && groupid != "") {
            var group = this.getObjectProperty("group", groupid, "content");
            var grouptitle = group.title;
        }
        else if (typeof CLIENT_GROUP != "undefined")
        {
            grouptitle = CLIENT_GROUP;
        }
        else {
            grouptitle = "";
        }
        var category = this.getObjectProperty(OBJECT_CATEGORY, catid, "content");
        if(category != "" && typeof grouptitle != "undefined" && grouptitle != ""){
            var url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_COURSE + 'sfor' + OBJECT_CATEGORY + '?nid=' + category.tid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle) + '&t=' + ts;
            var pendingdataid = OBJECT_COURSE+"-"+catid;
            Platform.downloadForm(url, pendingdataid);
        }
    }
    catch(e){
        console.log("function : downloadCoursesForCategoryAsync"
            + " message" + e.message);
    }
};

SyncService.prototype.downloadCoursesForCategoryAsyncWithOrder = function(catid,groupid,ts){
    try{
        if (typeof groupid != "undefined" && groupid != "") {
            var group = this.getObjectProperty("group", groupid, "content");
            var grouptitle = group.title;
        }
        else if (typeof CLIENT_GROUP != "undefined")
        {
            grouptitle = CLIENT_GROUP;
        }
        else {
            grouptitle = "";
        }
        var category = this.getObjectProperty(OBJECT_CATEGORY, catid, "content");
        if(category != "" && typeof grouptitle != "undefined" && grouptitle != ""){
            var url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_COURSE + 'sfor' + OBJECT_CATEGORY + '?nid=' + category.tid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle) + '&t=' + ts; 
            var pendingdataid = OBJECT_COURSE+"order-"+catid+"-"+grouptitle;
            Platform.downloadForm(url, pendingdataid);
        }
    }
    catch(e){
        console.log("function : downloadCoursesForCategoryAsyncWithOrder"
            + " message" + e.message);
    }
};


SyncService.prototype.downloadChaptersForCourseAsync = function(courseid){
    try{
        var ts = new Date().getTime();
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'sfor' + OBJECT_COURSE + '?nid=' + courseid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&ts=' + ts, OBJECT_CHAPTER+"-"+courseid);
    }
    catch(e){
        console.log("function : downloadChaptersForCourseAsync"
            + " message" + e.message);
    }
};

SyncService.prototype.downloadChaptersForCourseWithOrderAsync = function(courseid){
    try{
        var ts = new Date().getTime();
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'sfor' + OBJECT_COURSE + '?nid=' + courseid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&ts=' + ts, OBJECT_CHAPTER+"order-"+courseid);
    }
    catch(e){
        console.log("function : downloadChaptersForCourseWithOrderAsync"
            + " message" + e.message);
    }
};

SyncService.prototype.downloadResourcesForChapterAsync = function(chapterid){
    try{
        var ts = new Date().getTime();
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'sfor' + OBJECT_CHAPTER + '?nid=' + chapterid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&format=' + RESOURCE_FORMAT + '&ts=' + ts, "resourcesforchapter-"+chapterid);
    }
    catch(e){
        console.log("function : downloadResourcesForChapterAsync"
            + " message" + e.message);
    }
};

SyncService.prototype.downloadBooksforCourseAsync = function(courseid){
    try{
        var timestamp = new Date().getTime();
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_BOOK + 'sof' + OBJECT_COURSE + '?nid=' + courseid + '&uid=' + OBJECT_USER_ID + '&t=' + timestamp, "booksforcourse-"+courseid);
    }
    catch(e){
        console.log("function : downloadBooksforCourseAsync"
            + " message" + e.message);
    }
};

SyncService.prototype.downloadQuizOfCourseAsync = function(courseid){
    try{
        var timestamp = new Date().getTime();
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUIZ + 'of' + OBJECT_COURSE + '?nid=' + courseid + '&uid=' + OBJECT_USER_ID + '&t=' + timestamp, "quizofcourse-"+courseid);
    }
    catch(e){
        console.log("function : downloadQuizOfCourseAsync"
            + " message" + e.message);
    }
};

SyncService.prototype.downloadSupportingDocsforChapterAsync = function(chapterid){
    try{
        var timestamp = new Date().getTime();
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_SUPPORT_DOCS + '?nid=' + chapterid + '&uid=' + OBJECT_USER_ID + '&t=' + timestamp, "supportdocsforchapter-"+chapterid);
    }
    catch(e){
        console.log("function : downloadSupportingDocsforChapterAsync"
            + " message" + e.message);
    }
};


SyncService.prototype.downloadImage = function(nid, filename, preset, filedownloadsync )
{
    /*
* get sdcard path
* build download path
* downLoadFile(URL,path);
*/
    //api/downloadfile
    
    if (filename && filename.length) {
        if(typeof CATEGORY_PRESET != "undefined" && CATEGORY_PRESET != ""){
            var preset = CATEGORY_PRESET;
        }
        else{
            preset = OBJECT_PRESET;
        }
        var imagedownloadurl = OBJECT_SERVER_DOMAIN + '/api/downloadposter?nid=' + nid + '&preset=' + preset + '&uid=' + OBJECT_USER_ID;
        var folderpath = filename.split("/").slice(3).join("/");
        folderpath = folderpath.substring(0, folderpath.indexOf('?'));
        var filenamesplit = folderpath.split("/");
        var laststr = filenamesplit[filenamesplit.length - 1];
        var decodedfilename = decodeURI(laststr);
        folderpath = folderpath.replace(laststr,decodedfilename);
        var filephypath = "/" + folderpath;
        var imagepath = OBJECT_DATA_FOLDER + filephypath;
        try{
            var imagefileAvailable = Platform.isFilePresentInDocRoot(filephypath);
            if(imagefileAvailable != true){
                var paramsJson = {'objectid':nid,'objecttype':'content','serverurl':imagedownloadurl,
                    'localurl':imagepath, 'message':'', 'sync':FILE_DOWNLOAD_ASYNC,
                    'youtubeid':'','type':'none','format':''};
                Platform.downloadFile(JSON.stringify(paramsJson));
            }
        }
        catch(e){
            console.log("function : downloadImage"
                + "url" + imagedownloadurl + " message" + e.message);
        }
    }
};

SyncService.prototype.getResourcePathFromUrl = function(nid,type){
    try {
        if(type == 'pdf'){
            var format = '&format=original';
        }
        else{
            format = '&format=' + RESOURCE_FORMAT;
        }
        var resource = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO + '?nid=' + nid + format + '&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED);
        resource = $.parseJSON(resource);
        var status = resource['status'];
        if (status == 200)
        {
            var resourcedata = resource['data'];
            resourcedata = $.parseJSON(resourcedata);
            var resourceurl = resourcedata[0].videourl;
            if(type == 'pdf'){
                resourceurl = resourceurl["original"];
            }
            else{
                resourceurl = resourceurl[RESOURCE_FORMAT];
            }
            resourceurl = resourceurl.split('?');
            resourceurl = resourceurl[0];
            resourceurl = resourceurl.split("/").slice(3).join("/");
            return resourceurl;
            
        }
    }
    catch (e)
    {
        var error = "url :" + getResourcePathFromUrl
        console.log(error);
    };
};

function getResourceLocalPath(resource){
    try
    {
        var filepath = "/video";
        if(typeof ENCRYPTION_ENABLED != "undefined" && ENCRYPTION_ENABLED != "" && ENCRYPTION_ENABLED == "true"){
            var videotype = '.emp4';
        }
        else{
            videotype = '.mp4';
        }
        var resourceurl = resource.resourceurl;
        resourceurl = resourceurl.split('?');
        resourceurl = resourceurl[0];
        resourceurl = resourceurl.split("/").slice(3).join("/");
        if (resource.filetype == 'mp3' || resource.type == 'Video') {
            filepath = filepath + "/" + resourceurl;
            if(videotype == '.emp4'){
                generateEncryptedPath(filepath);
            }
        } else if (resource.filetype == 'pdf') {
            filepath = filepath + "/" + resourceurl;
        }
        return filepath;
    }
    catch(e){
        console.log( "function : getResourceLocalPath"
            + "\n message" + e.message);
    }
};

function generateEncryptedPath(filepath){
    filepath = filepath.replace('.mp4','.emp4');
    return filepath;
}

function getResourceImagePath(resourceid){
    try
    {
        var resourceimagepath = "/video/" + resourceid + "/" + POSTER_IMAGE_NAME + ".jpg";
        return resourceimagepath;
    }
    catch(e){
        console.log( "function : getResourceImagePath"
            + "\n message:" + e.message);
    }
}

SyncService.prototype.downloadResourcePosterImage = function(resourceid, preset, filedownloadsync, thumbnail_id, filename, filetype)
{
    /*
* get sdcard path
* build download path
* downLoadFile(URL,path);
*/
    //api/downloadthumbnail
    try{
        var imagedownloadurl = OBJECT_SERVER_DOMAIN + '/api/downloadposter?nid=' + resourceid + '&preset=' + OBJECT_PRESET + '&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED;
        if(thumbnail_id == null  || thumbnail_id == "null"){
            var filephypath = "/video/" + resourceid + "/" + POSTER_IMAGE_NAME + ".jpg"
            var imagepath = OBJECT_RESOURCE_FOLDER + filephypath;
        }
        else
        {
            filephypath = "/video/" + resourceid + "/" + filename
            imagepath = OBJECT_RESOURCE_FOLDER + filephypath;
        }
        var resourceImagefileAvailable = Platform.isFilePresentInDocRoot(filephypath);
        if(!resourceImagefileAvailable){
            if(filetype != 'pdf'){
                var paramsJson = {'objectid':resourceid,'objecttype':'resource',
                    'serverurl':imagedownloadurl,'localurl':imagepath, 'message':'', 
                    'sync':filedownloadsync,'youtubeid':'','type':'poster','format':''};
                Platform.downloadFile(JSON.stringify(paramsJson));
            }
        }
    }
    catch(e){
        console.log( "function : downloadResourcePosterImage"
            + "\n message:" + e.message);
    }

};

SyncService.prototype.getUserGroups = function() {
    try {
        var groups = [];
        if (typeof UNIQUE_GROUPS != 'undefined' && UNIQUE_GROUPS == 'true') {
            groups[0] = OBJECT_USER_DATA.groups[0];
        } else {
            for (var m = 0; m < OBJECT_USER_DATA.groups.length; m++) {
                groups[m] = OBJECT_USER_DATA.groups[m];
            }
        }
        return groups;
    }
    catch (e) {
        console.log("function : getUserGroups"
                + "\n message:" + e.message);
    }
}

SyncService.prototype.getUserProductType = function() {
    try {
        if (typeof CHECK_SDCARD_CONTENT != "undefined" && CHECK_SDCARD_CONTENT == "offline") {
            var content_access_type = CHECK_SDCARD_CONTENT;
        } else {
            var content_access_type = USER_PRODUCT_TYPE;
        }
        return content_access_type;
    }
    catch (e) {
        console.log("function : getUserGroups"
                + "\n message:" + e.message);
    }
}

OBJECT_RESOURCE_FOLDER = Platform.getResourceFolder();
OBJECT_DATA_FOLDER = Platform.getDataFolder();
OBJECT_APPLICATION_FOLDER = Platform.getApplicationFolder();

function ContentUtilsService(){};


ContentUtilsService.prototype.downloadImage = function(nid, filename, preset, filedownloadsync )
{
    /*
* get sdcard path
* build download path
* downLoadFile(URL,path);
*/
    //api/downloadfile

    if (filename && filename.length) {
        if(typeof CATEGORY_PRESET != "undefined" && CATEGORY_PRESET != ""){
            var preset = CATEGORY_PRESET;
        }
        else if(typeof preset == "undefined"){
            preset = OBJECT_PRESET;
        }
        var imagedownloadurl = OBJECT_SERVER_DOMAIN + '/api/downloadposter?nid=' + nid + '&preset=' + preset + '&uid=' + OBJECT_USER_ID;
        var folderpath = filename.split("/").slice(3).join("/");
        folderpath = folderpath.substring(0, folderpath.indexOf('?'));
        var filenamesplit = folderpath.split("/");
        var laststr = filenamesplit[filenamesplit.length - 1];
        var decodedfilename = decodeURI(laststr);
        folderpath = folderpath.replace(laststr,decodedfilename);
        var filephypath = "/" + folderpath;
        var imagepath = OBJECT_DATA_FOLDER + filephypath;
        try{
            var imagefileAvailable = Platform.isFilePresentInDocRoot(filephypath);
            if(imagefileAvailable != true){
                var paramsJson = {'objectid':nid,'objecttype':'content',
                'serverurl':imagedownloadurl,'localurl':imagepath, 'message':'',
                'sync':FILE_DOWNLOAD_ASYNC,'youtubeid':'','type':'none','format':''};
                Platform.downloadFile(JSON.stringify(paramsJson));
            }
        }
        catch(e){
            console.log("function : downloadImage"
                + "url" + imagedownloadurl + " message" + e.message);
        }
    }
};

ContentUtilsService.prototype.deleteMetaImage = function(type, nid){
    try{
        var obj = DataManager.getObjectProperty(type,nid,"content");
        if(!$.isEmptyObject(obj)){
            if (obj.thumbnail_filename && obj.thumbnail_filename) {
                var folderpath = obj.thumbnail_filename.split("/").slice(3).join("/");
                var filepath = folderpath.substring(0, folderpath.indexOf('?'));
                var fileAvailable = Platform.isFilePresentInDocRoot(filepath);
                if(fileAvailable == true && filepath != OBJECT_DATA_FOLDER && filepath != OBJECT_RESOURCE_FOLDER){
                    Platform.deleteFileFromDocRoot(filepath);
                }
            }
        }
    }
    catch(e){
        console.log("function : deleteMetaImage"
            + " message" + e.message);
    }
};

ContentUtilsService.prototype.getPosterPath = function(nid, filename, preset)
{
    try{
        if(typeof preset == "undefined"){
            preset = OBJECT_PRESET;
        }
        if(EXEC_MODE == "test"){
            var theUrl = OBJECT_SERVER_DOMAIN + '/api/downloadposter?nid=' + nid + '&preset=' + preset + '&uid=' + OBJECT_USER_ID;
            return theUrl;
        }
        else
        {
            var folderpath = filename.split("/").slice(3).join("/");
            folderpath = folderpath.substring(0, folderpath.indexOf('?'));
            var filenamesplit = folderpath.split("/");
            var laststr = filenamesplit[filenamesplit.length - 1];
            var decodedfilename = decodeURI(laststr);
            folderpath = folderpath.replace(laststr,decodedfilename);
            var filephypath = "/" + folderpath;
            var filepath = Platform.getLocalServerPath() + filephypath;
            var fileAvailable = Platform.isFilePresentInDocRoot(filephypath);


            if( fileAvailable == false)
            {

                try{
                    this.downloadImage(nid, filename, preset, BOOTSTRAP_IMAGES_SYNC);
                }
                catch(e){
                    console.log(" message" + e.message);
                }
                if(BOOTSTRAP_IMAGES_SYNC == 'true' || (BOOTSTRAP_IMAGES_SYNC == 'false' && Platform.getNetworkState() == false)){
                    filepath = "platform/media/noimageavailable.jpg";
                }else if(typeof CATEGORY_PRESET != "undefined" && CATEGORY_PRESET != ""){
                    filepath = OBJECT_SERVER_DOMAIN + '/api/downloadposter?nid=' + nid + '&preset=' + preset + '&uid=' + OBJECT_USER_ID;
                }
                else if(BOOTSTRAP_IMAGES_SYNC == 'false' && Platform.getNetworkState() == true)
                {
                    filepath = OBJECT_SERVER_DOMAIN + '/api/downloadposter?nid=' + nid + '&preset=' + preset + '&uid=' + OBJECT_USER_ID;
                }
            }
        }

        return filepath;
    }
    catch(e){
        console.log( "function : getPosterPath"
            + "\n message:" + e.message);
    }

};

ContentUtilsService.prototype.downloadResourcePosterImage = function(resourceid, preset, filedownloadsync, thumbnail_id, filename, filetype)
{
    /*
* get sdcard path
* build download path
* downLoadFile(URL,path);
*/
    //api/downloadthumbnail
    try{
        var resource = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO, resourceid, "content"));
        if (resource == null) {
            return;
        }
        var thumbnail_id = resource.thumbnail_id;
        var filename = resource.thumbnail_filename;
        var imagedownloadurl = OBJECT_SERVER_DOMAIN + '/api/downloadposter?nid=' + resourceid + '&preset=' + RESOURCE_PRESET + '&uid=' + OBJECT_USER_ID;
        if(thumbnail_id == null  || thumbnail_id == "null"){
            var filephypath = "/video/" + resourceid + "/" + POSTER_IMAGE_NAME + ".jpg"
            var imagepath = OBJECT_RESOURCE_FOLDER + filephypath;
        }
        else
        {
            filephypath = "/video/" + resourceid + "/" + filename
            imagepath = OBJECT_RESOURCE_FOLDER + filephypath;
        }
        var resourceImagefileAvailable = Platform.isFilePresentInDocRoot(filephypath);
        if(!resourceImagefileAvailable){
            if(typeof filetype == "undefined" || filetype == "" || filetype.toLowerCase() != 'pdf'){
                var paramsJson = {'objectid':resourceid,'objecttype':'resource',
                'serverurl':imagedownloadurl,'localurl':imagepath, 'message':'',
                'sync':filedownloadsync, 'youtubeid':'','format':'','type':'poster'};
                Platform.downloadFile(JSON.stringify(paramsJson));
            }
        }
    }
    catch(e){
        console.log( "function : downloadResourcePosterImage"
            + "\n message:" + e.message);
    }

};

ContentUtilsService.prototype.fileDownload = function(resourcedata,preference,download_complete_url,downloadmessage,courseid,type,categoryid){
    //function to download resource
    try{
       var courseleveldownload = CONTROLLER.getVariable("courseleveldownload");
       var resource = CONTROLLER.getService("Resource");
       var filephypath = getResourceLocalPath(resourcedata);
       var audioPrepView = CONTROLLER.getVariable("audioPrep");
       var downloadType = "";
       var contentType = "";
       var posterimageid=resourcedata.nid;
       var  resourcedata_youtube_id = resourcedata.youtube_id;
        if (resourcedata.type == PRACTICE || resourcedata.type == CERTIFICATE || resourcedata.type == OBJECT_QUIZ || resourcedata.type == RESOURCE_TYPE_ASSESSMENT) {
            downloadType = OBJECT_QUIZ;
            contentType = OBJECT_QUIZ;
            resourcedata_youtube_id = resourcedata.changed;
        }
      else if(resourcedata.type == "Video"){
           downloadType = "video";
           if(type == OBJECT_SUPPORT_DOCS){
               contentType = OBJECT_SUPPORT_DOCS;
           }
           else{
                contentType = OBJECT_VIDEO;
           }
       }
       else if(resourcedata.type == "Document"){
           downloadType = "ebook";
           if(type == OBJECT_SUPPORT_DOCS){
               contentType = OBJECT_SUPPORT_DOCS;
           }
           else if(type == OBJECT_VIDEO){
               contentType = OBJECT_VIDEO;
           }else{
               contentType = OBJECT_BOOK;
           }
       }else if(resourcedata.type == RESOURCE_TYPE_HTML || resourcedata.type == RESOURCE_TYPE_ASSIGNMENT || resourcedata.type.toLowerCase().indexOf('scorm') >= 0 || resourcedata.type.toLowerCase() == "html"){
           downloadType = ISAVAILABLE_HTML_TYPE;
       }
        var isAvail = this.isFileAvailable(resourcedata.nid, resourcedata_youtube_id,courseid,downloadType);
        var downloadMessage = "";
        if(isAvail.filestatus != "In Progress"){

            if(downloadType == "ebook"){
                downloadMessage = Download_InProgress;
            }
            else if(downloadType == OBJECT_QUIZ){
                posterimageid=resourcedata.contentid
                downloadMessage = Quiz_Download_InProgress;
            }
            else {
                if(typeof audioPrepView != 'undefined' && audioPrepView == true){
                    downloadMessage = AUDIO_DOWNLOAD_INPROGRESS;
                }else{
                    downloadMessage = VIDEO_DOWNLOAD_INPROGRESS;
                }
            }
            if(typeof TRIAL_DOWNLOAD_MESSAGE != "undefined" && TRIAL_USER == "true"){
                downloadMessage = downloadMessage + ". " + TRIAL_DOWNLOAD_MESSAGE;
            }
            if(typeof courseleveldownload =='undefined' || courseleveldownload == '' ||(typeof courseleveldownload !='undefined' && courseleveldownload != 'true')){
                Platform.showMessage(downloadMessage);
            }
            resource.downloadResourceAsync(resourcedata.nid, resourcedata_youtube_id, "created", downloadmessage, DOWNLOAD_EXPIRY, download_complete_url, courseid, contentType, categoryid);
            this.downloadResourcePosterImage(posterimageid, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC, resourcedata.thumbnail_id , resourcedata.thumbnail_filename,DOWNLOAD_EXPIRY);
//            this.addToDownloadsList(resourcedata);
            this.showProgressBar(resourcedata.nid);
            this.showInProgressMessage(resourcedata);
        }
    }
    catch(e){
       console.log( "function : fileDownload"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.isFileAvailable = function(nid,youtubeid,courseid,type,catid){
    //function to download resource
    try{
        var contentInToc = "";
        var contentAvailable = "";
        var tocService = CONTROLLER.getService("Toc");
        if(USER_PRODUCT_TYPE == "online" || EXEC_MODE == "online"){
            return {"filestatus":"Not Available"};
        }
        else if(USER_PRODUCT_TYPE == "hybrid"){
            return this.contentAvailable(nid,youtubeid,type,USER_PRODUCT_TYPE);
        }
        else if (USER_PRODUCT_TYPE == "offline") {
            if(CONTENT_IN_MULTIPLE_SDCARDS == true){
                var isOfflineTestCollection = (typeof TEST_COLL_ID != 'undefined' && TEST_COLL_ID['available_offline'] == '1' && typeof OFFLINE_TEST_SERIES != 'undefined' && OFFLINE_TEST_SERIES == "true");
                if (typeof isOfflineTestCollection != "undefined" && !isOfflineTestCollection) {
                    return this.contentAvailable(nid,youtubeid,type,USER_PRODUCT_TYPE);
                }

                contentInToc = this.checkContentAvailableInToc(courseid, catid, isOfflineTestCollection);
                var sdcard = tocService.getTocSdCardInfo(courseid);
                if(contentInToc == true || sdcard === false){
                    return this.contentAvailable(nid,youtubeid,type,USER_PRODUCT_TYPE);
                }
                else{
                    return {"filestatus":"Not Available"};
                }
            }
            else{
                return this.contentAvailable(nid,youtubeid,type,USER_PRODUCT_TYPE);
            }
        }
    }
    catch(e){
       console.log( "function : isFileAvailable"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.contentAvailable = function(nid,youtubeid,type,USER_PRODUCT_TYPE){
    try{
        if (typeof CHECK_SDCARD_CONTENT != "undefined" && CHECK_SDCARD_CONTENT == "offline") {
            var content_access_type = CHECK_SDCARD_CONTENT;
        } else {
            var content_access_type = USER_PRODUCT_TYPE;
        }
        if (type == OBJECT_QUIZ || type == "quiz") {
            var contentAvailable = Platform.isContentAvailable(nid, youtubeid, RESOURCE_FORMAT, content_access_type, OBJECT_QUIZ);
            contentAvailable = $.parseJSON(contentAvailable);
            if(contentAvailable.filestatus == "Not Available"){
               var contentAvailable = Platform.isContentAvailable(nid, '', RESOURCE_FORMAT, content_access_type, OBJECT_QUIZ);
                contentAvailable = $.parseJSON(contentAvailable);
                 return contentAvailable;
            }else{
               return contentAvailable;
            }

        } else {
            var contentAvailable = Platform.isContentAvailable(nid, youtubeid, RESOURCE_FORMAT, content_access_type, type);
            contentAvailable = $.parseJSON(contentAvailable);
            return contentAvailable;
        }
    }
    catch(e){
       console.log( "function : contentAvailable"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.getFilePauseState = function(nid){
    try{
        var pause_state = this.getFilePauseState(nid);
        return pause_state;
    }
    catch(e){
       console.log( "function : getFilePauseState"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.getResourcePosterPath = function(resourceid,thumbnail_id,thumbnail_filename,filetype)
{
    try{
        var fileAvailable = OBJECT_RESOURCE_FOLDER;
        var resource = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO,resourceid,"content"));
        var thumbnail_id = resource.thumbnail_id;
        var thumbnail_filename = resource.thumbnail_filename;
        var contentUtilsService = CONTROLLER.getService("ContentUtils");
        if(thumbnail_id == null || thumbnail_id == "null"){
            var filephypath = "video/" + resourceid + "/" + POSTER_IMAGE_NAME + ".jpg";
            var filepath = Platform.getLocalServerPath() + "/" + filephypath
        } else {
            filephypath = "video/" + resourceid + "/" + encodeURI(thumbnail_filename);
            filepath = Platform.getLocalServerPath() + "/" + filephypath;
        }
        var fileAvailable = Platform.isFilePresentInDocRoot(filephypath);
        if( fileAvailable == false)
        {
            contentUtilsService.downloadResourcePosterImage(resourceid, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC, thumbnail_id, thumbnail_filename, filetype);

            if(typeof filetype != "undefined" && filetype.toLowerCase() == 'pdf'){
                filepath = "platform/media/document.png";
            } else {
                if(BOOTSTRAP_IMAGES_SYNC == 'true' || (BOOTSTRAP_IMAGES_SYNC == 'false' && Platform.getNetworkState() == false)){
                    filepath = "platform/media/noimageavailable.jpg";
                }
                else if(BOOTSTRAP_IMAGES_SYNC == 'false' && Platform.getNetworkState() == true)
                {
                    filepath = OBJECT_SERVER_DOMAIN + '/api/downloadposter?nid=' + resourceid + '&preset=' + RESOURCE_PRESET + '&uid=' + OBJECT_USER_ID;
                }
            }
        }
        return filepath;
    }
    catch(e){
        console.log( "function : getResourcePosterPath"
            + "\n message:" + e.message);
    }
};

ContentUtilsService.prototype.checkContentAvailableInToc = function(courseid, catid, isOfflineTestCollection)
{
    try{
	    var chaptersubject = "";
	    var key = {};
	    var tocService = CONTROLLER.getService("Toc");
	    var sdcard = tocService.getTocSdCardInfo(courseid);
	    if(sdcard === false){
	       return true;
	    }
	    if (typeof isOfflineTestCollection != 'undefined' && isOfflineTestCollection) {
	        key.title = 'test_collection';
	    } else {
	        if(!catid){
	            catid = CONTROLLER.getVariable("activecatid");
	        }
	        key = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content"));
	    }
	    var chaptersofsdcard = tocService.getChaptersOfSdCard();
	    if (chaptersofsdcard != "" && key && key.title) {
	        for (j = 0; j < chaptersofsdcard[key.title].length; j++) {
	            if (chaptersofsdcard[key.title][j].nid == courseid) {
	                chaptersubject = true;
	            }
	        }
	    }
	    if (chaptersubject == false) {
	        return false;
	    }
	    else{
	        return true;
	    }
    }
    catch (e) {
        Client.closeProgressBar();
        console.log("function : checkContentAvailableInToc"
                + "\n message:" + e.message);
    }
};


//ContentUtilsService.prototype.addToDownloadsList = function(content)
//{
//    try{
//        Platform.saveObject("chapter", DOWNLOADS_OBJECT_ID, "content", "");
//        Platform.saveObject("resource", content.nid, "content", JSON.stringify(content));
//        Platform.addRelation("chapter", DOWNLOADS_OBJECT_ID, "resource", content.nid);
//    }
//    catch(e){
//        console.log( "function : addToDownloadsList"
//            + "\n message:" + e.message);
//    }
//};

ContentUtilsService.prototype.getResourcePathFromUrl = function(nid,type,courseid){
    try {
        if(type.toLowerCase() == 'pdf' || type == 'Document'){
            var format = '&format=original';
        }
        else{
            format = '&format=' + RESOURCE_FORMAT;
        }
        var resource = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO + '?nid=' + nid + format + '&courseid='+courseid+'&uid=' + OBJECT_USER_ID + "&auth=" + AUTH_ENABLED);
        resource = $.parseJSON(resource);
        var status = resource['status'];
        if (status == 200)
        {
            var resourcedata = resource['data'];
            resourcedata = $.parseJSON(resourcedata);
            var resourceurl = resourcedata[0].videourl;
            if(type.toLowerCase() == 'pdf' || type == 'Document'){
                resourceurl = resourceurl["original"];
            }
            else{
                resourceurl = resourceurl[RESOURCE_FORMAT];
            }
            return resourceurl;

        }
    }
    catch (e)
    {
        var error = "function : getResourcePathFromUrl\n\
                    url :" + OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO +
                    '?nid=' + nid + format + '&uid=' + OBJECT_USER_ID + "&auth=" + AUTH_ENABLED
        console.log(error);
    };
};

ContentUtilsService.prototype.getResourceStatus = function(nid,type,courseid,download,cat_tid){
    try {
        var format = "";
        var downloadparam = "";
        var showpopup_for_unsubscribed_video = CONTROLLER.getVariable("showpopup_for_unsubscribed_video");
        if(typeof type != 'undefined' &&  (type.toLowerCase() == 'pdf' || type == 'Document')){
            format = '&format=original';
            stream = "";
        }
        else{
            format = '&format=' + RESOURCE_ONLINE_FORMAT;
            stream = '&stream=' + STREAM_TYPE;
        }
        if(typeof download != "undefined" && download == "true"){
            downloadparam = "&download=true";
        }
        else{
            downloadparam = "";
        }

        var resource = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO + '?nid=' + nid + format+stream+downloadparam+'&uid=' + OBJECT_USER_ID + "&courseid=" + courseid + "&cat_id=" + cat_tid);
        resource = $.parseJSON(resource);
        var status = resource['status'];
        if(status == 200){
            var data = $.parseJSON(resource['data']);
            var resourceurl = data[0]['videourl'];
            CONTROLLER.setVariable("activeresourcepath",resourceurl);
            if(typeof showpopup_for_unsubscribed_video != 'undefined' && showpopup_for_unsubscribed_video == 'true'){
                //don't show buynow popup for subscribed video
                CONTROLLER.setVariable("showpopup_for_unsubscribed_video",'');
            }
        }
        return status;
    }
    catch (e)
    {
        var error = "function : getResourceStatus\n\
                    url :" + OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO +
                    '?nid=' + nid + format + '&uid=' + OBJECT_USER_ID + "&auth=" + AUTH_ENABLED
        console.log(error);
    };
};
ContentUtilsService.prototype.getUnsubscribedResourceurl = function(nid,type,courseid,download,cat_tid){
    try {
        var format = "";
        var downloadparam = "";
        if(typeof type != 'undefined' &&  (type.toLowerCase() == 'pdf' || type == 'Document')){
            format = '&format=original';
            stream = "";
        }
        else{
            format = '&format=' + RESOURCE_ONLINE_FORMAT;
            stream = '&stream=' + STREAM_TYPE;
        }
        if(typeof download != "undefined" && download == "true"){
            downloadparam = "&download=true";
        }
        else{
            downloadparam = "";
        }
        var resource = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get/'+OBJECT_VIDEO+'/publicurl'+ '?nid=' + nid +format+stream+downloadparam +"&auth=" + AUTH_ENABLED );
        resource = $.parseJSON(resource);
        var status = resource['status'];
        if(status == 200){
            var data = $.parseJSON(resource['data']);
            var resourceurl = data['videourl'];
            CONTROLLER.setVariable("activeresourcepath",resourceurl);
            CONTROLLER.setVariable("showpopup_for_unsubscribed_video",'true');
        }
        return status;
    }
    catch (e)
    {
        var error = "function : getUnsubscribedResourceurl\n\
                    url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO +
                    '?nid=' + nid + "&auth=" + AUTH_ENABLED
        console.log(error);
    };
};

ContentUtilsService.prototype.sortByRank = function(json){
    try{
        json.sort(function(a, b) {
            return ((a.rank < b.rank) ? -1 : ((a.rank > b.rank) ? 1 : 0));
        });
    }
    catch(e){
        console.log( "function : sortByRank"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.checkDownloadStatus = function(resource,type,courseid)
{
    //function to check download status
    try{
        var contentType = type;
        var listallquizzesview = CONTROLLER.getVariable("listallquizzes");
        var quizService = CONTROLLER.getService("Quiz");
        var resourcesjsonarray = [];
        var resourcesjson = "";
        for(var i=0;i<resource.length;i++){
           var youtubeid = "";
           var nid = "";
           var quiz = "";
           var filetype = resource[i].filetype;
            if ((resource[i].source != RESOURCE_TYPE_LINK && (resource[i].type != "Document" || filetype == "pdf")) || (resource[i].source == RESOURCE_TYPE_LINK && ((typeof RESOURCE_TYPE_HTML != "undefined" && resource[i].type == RESOURCE_TYPE_HTML && ((resource[i].url).indexOf(OBJECT_SERVER_DOMAIN) >= 0)) || resource[i].type == RESOURCE_TYPE_ASSESSMENT))) {
                if(resource[i].type == RESOURCE_TYPE_ASSESSMENT){
                    quiz = quizService.getQuiz(resource[i].url);
                    if(typeof quiz == "undefined" || quiz == "" || quiz == INTERNET_ERROR){
                        continue;
                    }
                    nid = quiz.nid;
                    type = OBJECT_QUIZ;
                    youtubeid = quiz.changed;
                } else if ((typeof listallquizzesview !== 'undefined' && listallquizzesview === "true")
                    || (resource[i].type == "certificate" || resource[i].type == "practice")) {
                    quiz = resource[i];
                    if(typeof quiz == "undefined" || quiz == ""){
                        continue;
                    }
                    nid = quiz.nid;
                    type = OBJECT_QUIZ;
                    youtubeid = quiz.changed;
                }
                else{
                    youtubeid = resource[i].youtube_id;
                    type = resource[i].type;
                    nid = resource[i].nid;
                }
                resourcesjson = {"nid":nid,"type":type,"youtubeid":youtubeid,"resource_id":resource[i].nid};
                resourcesjsonarray.push(resourcesjson);
            }
        }
        var THIS = this;
        this.checkMultipleResourceDownloadStatus(resourcesjsonarray, function(status){
            for(var i=0;i<status.length;i++){
                var index = findIndexByKeyValue(resourcesjsonarray,'nid',status[i].nid);
                if(status[i]["status"] == 0)
                {
                    THIS.showDownloadMessage(resourcesjsonarray[index].resource_id);
                }
                else if(status[i]["status"] == 1)
                {
                    THIS.showRemoveMessage(resourcesjsonarray[index].resource_id);
                }
                else if(status[i]["status"] == 2)
                {
                    var resource_index = findIndexByKeyValue(resource,'nid',resourcesjsonarray[index].resource_id);
                    THIS.showInProgressMessage(resource[resource_index]);
                }
                else if(status[i]["status"] == 3){
                    THIS.showNewFileAvailable(resourcesjsonarray[index].resource_id);
	            }
				else {
	                THIS.hideDownloadMessage(resourcesjsonarray[index].resource_id);
	            }
			}
        });
    }
    catch(e){
       console.log( "function : checkDownloadStatus"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.checkResourceDownloadStatus = function(nid,type,courseid,youtubeid) {
    if(type==RESOURCE_TYPE_ASSESSMENT || type == OBJECT_QUIZ) {
        youtubeid = youtubeid;
    } else {
        var resource = $.parseJSON(Platform.getObjectProperty(type, nid, "content"));
        var youtubeid = resource.youtube_id;

        if (resource.type == "Document") {
            type = "ebook";
        }
        else if (resource.type == RESOURCE_TYPE_ASSESSMENT) {
            type = 'quiz';
        }else if(resource.type == RESOURCE_TYPE_HTML || resource.type == RESOURCE_TYPE_ASSIGNMENT || resource.type.toLowerCase().indexOf('scorm') >= 0 || resource.type.toLowerCase() == "html"){
           type = ISAVAILABLE_HTML_TYPE;
        }
        else {
            type = "video";
        }
    }

    var downloadStatus = this.contentAvailable(nid,youtubeid,type,USER_PRODUCT_TYPE);

    if(downloadStatus.filestatus == "In Progress"){
        return 2; //Download in progress and part file exists
    }
    else if(downloadStatus.filestatus == "Available" && downloadStatus.version == "new")
    {
        return 1; //Download complete and file exists or not put for download
    }
    else if(downloadStatus.filestatus == "Not Available"){
        return 0; //File is not available and not put for download
    }
    else if(downloadStatus.filestatus == "Available" && downloadStatus.version == "old"){
        return 3; //old file exists and new file has not been put for download
    }
};

ContentUtilsService.prototype.checkMultipleResourceDownloadStatus = function(jsonarr, callback) {
    var type = "";
    var nid = "";
    var multiplejsonarray = [];
    var statusjson = {};
    for(var i in jsonarr){
        type = jsonarr[i].type;
        nid = jsonarr[i].nid;
        var youtubeid = jsonarr[i].youtubeid;

        if (jsonarr[i].type == "Document") {
            type = "ebook";
        }
        else if (type == RESOURCE_TYPE_ASSESSMENT || type == OBJECT_QUIZ) {
            type = OBJECT_QUIZ;
        }
        else if(jsonarr[i].type == RESOURCE_TYPE_HTML || jsonarr[i].type == RESOURCE_TYPE_ASSIGNMENT || jsonarr[i].type.toLowerCase().indexOf('scorm') >= 0 || jsonarr[i].type.toLowerCase() == "html"){
            type = ISAVAILABLE_HTML_TYPE;
        }
        else {
            type = "video";
        }
        if (typeof CHECK_SDCARD_CONTENT != "undefined" && CHECK_SDCARD_CONTENT == "offline") {
            var content_access_type = CHECK_SDCARD_CONTENT;
        } else {
            var content_access_type = USER_PRODUCT_TYPE;
        }
        // if(type == "video"){
        //     if(DOWNLOAD_STREAM == "m3u8"){
        //         type = "m3u8";
        //     }
        // }
        statusjson = {"nid":nid,"youtubeid":youtubeid,"format":RESOURCE_FORMAT.toString(),"usertype":content_access_type,"filetype":type};
        multiplejsonarray.push(statusjson);
    }

    checkMultipleResourceDownloadStatusComplete = function(downloadStatus) {
        for(var i in downloadStatus){
            if(downloadStatus[i].filestatus == "In Progress"){
                downloadStatus[i]["status"] = 2; //Download in progress and part file exists
            }
            else if(downloadStatus[i].filestatus == "Available" && downloadStatus[i].version == "new")
            {
                downloadStatus[i]["status"] = 1; //Download complete and file exists or not put for download
            }
            else if(downloadStatus[i].filestatus == "Not Available"){
                downloadStatus[i]["status"] = 0; //File is not available and not put for download
            }
            else if(downloadStatus[i].filestatus == "Available" && downloadStatus[i].version == "old"){
                downloadStatus[i]["status"] = 3; //old file exists and new file has not been put for download
            }
        }
        return callback(downloadStatus);
    }
    var content = JSON.stringify([multiplejsonarray]);
    var str = JSON.stringify(content);
    str = str.substring(1, str.length-1);
    makeAsyncTaskCall("", str, 'isMultipleContentAvailable', 'internalapi', "false", 'checkMultipleResourceDownloadStatusComplete', 'Platform');
};

ContentUtilsService.prototype.showDownloadMessage = function(nid)
{
    try{
        var index = findIndexByKeyValue(DOWNLOAD_FILES_IN_PROGRESS,'nid',nid);
        if(index != null){
           DOWNLOAD_FILES_IN_PROGRESS.splice(index,1);
        }
        if ($('.download_button[nid='+nid+']').hasClass("delete")) {
            $('.download_button[nid='+nid+']').removeClass("delete");
            if (PROJECT_TITLE == "iTutor")
            {
                $('.progress_img[nid=' + nid + ']' + ',' + '.down_arrow_img[nid=' + nid + ']').addClass("hidden");
            }
        }
        $('.download_button[nid='+nid+']').removeAttr("style");
        $('.download_button[nid='+nid+']').addClass('download');
//        if (PROJECT_TITLE == "iTutor")
//        {
//            if ($('.download_button[nid=' + nid + ']').hasClass("download_button_ebook")) {
//                $('.download_button[nid=' + nid + ']').css({'background-image': "url('images/download_ebook.f8428bb6.png')"});
//            }
//        }
    }
    catch(e){
       console.log( "function : showDownloadMessage"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.showRemoveMessage = function(nid)
{
    try{
        if (PROJECT_TITLE == "iTutor")
        {
            $('.progress_img[nid=' + nid + ']' + ',' + '.down_arrow_img[nid=' + nid + ']').addClass("hidden");
        }
        if ($('.download_button[nid='+nid+']').hasClass("download")) {
            $('.download_button[nid='+nid+']').removeClass("download");
//            if (PROJECT_TITLE == "iTutor")
//            {
//                if ($('.download_button[nid=' + nid + ']').hasClass("download_button_ebook")) {
//                    $('.download_button[nid=' + nid + ']').css({'background-image': "url('images/download_ebook.f8428bb6.png')"});
//                }
//            }
        }
        if ($('.download_button[nid='+nid+']').hasClass("inprogress")) {
            $('.download_button[nid='+nid+']').removeClass("inprogress");
        }
        $('.download_button[nid='+nid+']').addClass("delete");

        if (USER_PRODUCT_TYPE == "online" || USER_PRODUCT_TYPE == "hybrid" ) {
            if (PROJECT_TITLE == "iTutor")
            {
                $('.download_button[nid=' + nid + ']').css({'background': 'url(images/thumb-dl.47881697.png)', "background-repeat": "no-repeat"});
            } else {
                $('.download_button[nid=' + nid + ']').css({'background': 'url(images/thumb-dl.47881697.png)'});
            }
        }
        else{
            $('.download_button[nid='+nid+']').parent().addClass('hidden');
        }

    }
    catch(e){
       console.log( "function : showRemoveMessage"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.showNewFileAvailable = function(nid)
{
    try{
        if ($('.download_button[nid='+nid+']').hasClass("delete")) {
            $('.download_button[nid='+nid+']').removeClass("delete");
        }
        if ($('.download_button[nid='+nid+']').hasClass("inprogress")) {
            $('.download_button[nid='+nid+']').removeClass("inprogress");
        }
        $('.download_button[nid='+nid+']').addClass('download');
        $('.new_version_text[nid='+nid+']').removeClass("hidden");
    }
    catch(e){
       console.log( "function : showRemoveMessage"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.showInProgressMessage = function(resource)
{
    try{
        if ($('.download_button[nid='+resource.nid+']').hasClass("delete")) {
            if (PROJECT_TITLE == "iTutor")
            {
                $('.download_button[nid='+resource.nid+'] ').css({"background-image": "url('')"});
            }
            $('.download_button[nid='+resource.nid+']').removeClass("delete");
        }
        if ($('.download_button[nid='+resource.nid+']').hasClass("download")) {
            $('.download_button[nid='+resource.nid+']').removeClass("download");
            if (PROJECT_TITLE == "iTutor")
            {
                 if ($('.download_button[nid='+resource.nid+']').hasClass("download_button_ebook")){
                    $('.download_button[nid=' + resource.nid + ']').css({'background-image': "url('')"});
                 }
            }
        }
        if(!$('.new_version_text[nid='+resource.nid+']').hasClass("hidden")){
            $('.new_version_text[nid='+resource.nid+']').addClass("hidden");
            $('.new_version_text[nid='+resource.nid+']').addClass("new_version_inprogress");
        }
        $('.download_button[nid='+resource.nid+']').addClass("inprogress");
        var pause_state = this.getFilePauseState(resource.nid);
        if (PROJECT_TITLE == "iTutor")
        {
            $('.progress_img[nid=' + resource.nid + ']' + ',' + '.down_arrow_img[nid=' + resource.nid + ']').removeClass('hidden');
        }
        if(pause_state == false){
            if (PROJECT_TITLE == "iTutor") {
                $('.progress_img[nid=' + resource.nid + ']' + ' .ko-progress-circle').attr('data-progress', 0);
            } else {
                $('.download_button[nid=' + resource.nid + ']').css({'background-image': 'url(images/thumb-dl-duel.86c57123.png)'});
            }

        }else{
            if (PROJECT_TITLE == "iTutor") {
                var current_status = 0;
                current_status = $('.download_wrapper[nid=' + resource.nid + ']').find('.download_status').html();
                current_status = current_status.split('%');
                $('.progress_img[nid=' + resource.nid + ']' + ' .ko-progress-circle').attr('data-progress', current_status);
            } else {
                $('.download_button[nid=' + resource.nid + ']').css({'background-image': 'url(images/thumb-dl-blue.2c845142.png)'});
            }
        }
        this.displayProgress(resource);
        var index = findIndexByKeyValue(DOWNLOAD_FILES_IN_PROGRESS,'nid',resource.nid);
        if(index == null){
               DOWNLOAD_FILES_IN_PROGRESS.push(resource);
        }
    }
    catch(e){
       console.log( "function : showInProgressMessage"
            + "\n message:" + e.message);
    }
}
ContentUtilsService.prototype.hideDownloadMessage = function(nid)
{
    try{
        $('.download_wrapper[nid='+nid+']').addClass('hidden');
    }
    catch(e){
       console.log( "function : hideDownloadMessage"
            + "\n message:" + e.message);
    }
}
ContentUtilsService.prototype.getFilePauseState = function(nid){
    try{
       var pause_state = Platform.getFilePauseState(nid);
       return pause_state;
    }
    catch(e){
       console.log( "function : getFilePauseState"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.filedownloadprogresstimer = function() {
    var THIS = this;
    setInterval(function () {
        if (DOWNLOAD_FILES_IN_PROGRESS.length > 0) {
            for (var i = 0; i < DOWNLOAD_FILES_IN_PROGRESS.length; i++) {
                THIS.displayProgress(DOWNLOAD_FILES_IN_PROGRESS[i]);
            }
        }
        if (typeof DOWNLOAD_FILES_UNDER_CATEGORY_IN_PROGRESS != 'undefined') {
            THIS.filedownloadProgressTimerForCategory();
        }
        if (typeof DOWNLOAD_FILES_UNDER_COURSE_IN_PROGRESS != 'undefined') {
            THIS.filedownloadProgressTimerForCourse();
        }
    }, 10000);
}

ContentUtilsService.prototype.displayProgress = function(resource){
    try{
        if($('.download_wrapper[nid='+resource.nid+']').length >= 1){
            var download_status = 0;
            var filepath;
            var download_progress = 0;
            var current_status = 0;
            var nid = "";
            var youtube_id = "";
             var quizService = CONTROLLER.getService("Quiz");
            var resource_nid=resource.nid;
            if(resource.type=="practice" || resource.type=="certificate" || resource.type == RESOURCE_TYPE_ASSESSMENT){
                if(resource.type == RESOURCE_TYPE_ASSESSMENT){
					nid = resource.url;
					var quiz = quizService.getQuiz(nid);
					filepath = "/video/"+quiz.nid +"/"+quiz.nid+'_'+quiz.changed+".zip";
					youtube_id = quiz.changed;
					resource_nid = quiz.nid;
                }
                else{
					nid = resource.nid;
					filepath = "/video/"+resource.nid+"/"+resource.nid+'_'+resource.changed+".zip";
					youtube_id=resource.changed;
                }
            }else{
               nid = resource.nid;
               youtube_id = resource.youtube_id;
               filepath = getResourceLocalPath(resource);
            }
            if(filepath){
                download_status = Platform.getDownloadFileProgress(filepath,resource_nid,youtube_id);
                download_status = download_status.split("-");
                if(download_status[0] == 0){
                    download_progress = 0;
                }else{
                    download_progress = Math.round((download_status[0] / download_status[1]) * 100);
                }
                if(download_progress > 100){
                    download_progress = 99;
                }
                current_status = $('.download_wrapper[nid='+resource.nid+']').find('.download_status').html();
                current_status = current_status.split('%');
                if(current_status[0] < 90 || (current_status[0] >= 1 && download_progress != 0)){
                    $('.download_wrapper[nid='+resource.nid+']').find('.download_status').html(download_progress + '%');
                    if (PROJECT_TITLE == "iTutor")
                    {
                        $('.progress_img[nid=' + resource.nid + ']' + ' .ko-progress-circle').attr('data-progress', download_progress);
                    } else {

                    }
                }
                else if((current_status[0] >= 1 && download_progress == 0)){
                    $('.download_wrapper[nid='+resource.nid+']').find('.download_status').html('99%');
                    if (PROJECT_TITLE == "iTutor")
                    {
                        $('.progress_img[nid='+resource.nid+']'+' .ko-progress-circle' ).attr('data-progress', 99);
                    }
                }
                this.showProgressBar(resource.nid);
                if (download_progress == 100) {
                    this.showRemoveMessage(resource.nid);
                     $('.download_wrapper[nid='+resource.nid+']').find('.download_status').html('0' + '%');
                    $('.download_wrapper[nid='+resource.nid+']').find('.download_status_wrapper').addClass("hidden");
                    if($('.new_version_text[nid='+resource.nid+']').hasClass("new_version_inprogress")){
                        $('.new_version_text[nid='+resource.nid+']').removeClass("new_version_inprogress");
                        $('.new_version_text[nid='+resource.nid+']').addClass("hidden");
                    }
                    var index = findIndexByKeyValue(DOWNLOAD_FILES_IN_PROGRESS,'nid',resource.nid);
                    if(index != null){
                       DOWNLOAD_FILES_IN_PROGRESS.splice(index,1);
                    }
                }
            }
        }
    }
    catch(e){
       console.log( "function : displayProgress"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.showProgressBar = function(nid){
    if($('.download_wrapper[nid='+nid+']').find('.download_status_wrapper').hasClass("hidden")){
        $('.download_wrapper[nid='+nid+']').find('.download_status_wrapper').removeClass("hidden");
    }
}

ContentUtilsService.prototype.uploadFiletoServer= function(qsn_data){
   try{
        if (Platform.getNetworkState() == true) {
            var user = Platform.makeAsyncTaskCall(qsn_data);
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch(e){
       console.log( "function : uploadFiletoServer"
            + "\n message:" + e.message);
    }
}

ContentUtilsService.prototype.showDownloadProgressForCategory = function() {
    if ($('.cat_downloaded_content_status').length > 0) {
        var cat_content_status = Platform.getAllDownloadStatusByCategory();
        if (cat_content_status) {
            cat_content_status = $.parseJSON(cat_content_status);
            DOWNLOAD_FILES_UNDER_CATEGORY_IN_PROGRESS = cat_content_status;
            for (var i = 0; i < cat_content_status.length; i++) {
                var catid = cat_content_status[i]['categoryid'];
                if (catid) {
                    var tot_content_added = Platform.getTotalFileAddedForDownloadByCategory(CONTROLLER.getVariable("activegroupid"), catid);
                    var downloaded_content_count = Platform.getCategoryDownloadedFileCount(CONTROLLER.getVariable("activegroupid"), catid);
                    if(CONTROLLER.getVariable("activegroupid") == cat_content_status[i]['groupid']){
                          $('#content_status_' + catid).html(downloaded_content_count + '/' + tot_content_added);
                    }
                }
            }
        }
    }
};

ContentUtilsService.prototype.filedownloadProgressTimerForCategory = function() {
    var THIS = this;
    for (var i = 0; i < DOWNLOAD_FILES_UNDER_CATEGORY_IN_PROGRESS.length; i++) {
        if (DOWNLOAD_FILES_UNDER_CATEGORY_IN_PROGRESS[i]['totalfiledownloaded'] != DOWNLOAD_FILES_UNDER_CATEGORY_IN_PROGRESS[i]['totalfileAdded']) {
            THIS.showDownloadProgressForCategory();
        }
    }
}

ContentUtilsService.prototype.showDownloadProgressForCourse = function() {
    if ($('.downloaded_content_status').length > 0) {
        var course_content_status = Platform.getAllDownloadStatusByCourse();
        if (course_content_status) {
            course_content_status = $.parseJSON(course_content_status);
            DOWNLOAD_FILES_UNDER_COURSE_IN_PROGRESS = course_content_status;
            for (var i = 0; i < course_content_status.length; i++) {
                var courseid = course_content_status[i]['courseid'];
                if (courseid) {
                    var tot_course_contents_added = Platform.getTotalFileAddedForDownloadByCourse(courseid);
                    var courselevel_downloaded_contents = Platform.getCourseDownloadedFileCount(courseid);
                    $('#content_status_' + courseid).html(courselevel_downloaded_contents + '/' + tot_course_contents_added);
                }
            }
        }
    }
};

ContentUtilsService.prototype.filedownloadProgressTimerForCourse = function() {
    var THIS = this;
    for (var i = 0; i < DOWNLOAD_FILES_UNDER_COURSE_IN_PROGRESS.length; i++) {
        if (DOWNLOAD_FILES_UNDER_COURSE_IN_PROGRESS[i]['totalfiledownloaded'] != DOWNLOAD_FILES_UNDER_COURSE_IN_PROGRESS[i]['totalfileAdded']) {
            THIS.showDownloadProgressForCourse();
        }
    }
}

ContentUtilsService.prototype.checkContentAvailableInSdcard = function(courseid, catid) {
    try{
    var tocService = CONTROLLER.getService("Toc");
    var chaptersubject = false;
    var filedata = Array();
    var sdcard = tocService.getTocSdCardInfo(courseid);
    if (sdcard !== false) {
        var chaptersofsdcard = tocService.getChaptersOfSdCard();
        var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content"));
        if (typeof chaptersofsdcard[category.title] == "undefined" || chaptersofsdcard[category.title] == "") {
            chaptersofsdcard = "";
        }
        if (chaptersofsdcard != "") {
            for (j = 0; j < chaptersofsdcard[category.title].length; j++) {
                if (chaptersofsdcard[category.title][j].nid == courseid) {
                    chaptersubject = true;
                }
            }
        }
        if (chaptersubject == false) {
            var sdcard = tocService.getTocSdCardInfo(courseid);
            filedata[0] = 'error';
            if (typeof sdcard == "undefined" || sdcard == "") {
                filedata[1] = "Please insert SD card and try again";
            }
            else {
                filedata[1] = "Please insert SD card " + sdcard + " to view this.";
            }
            return filedata;
        }
    }
    }catch(e){
        Client.closeProgressBar();
        console.log("function checkContentAvailableInSdcard := " + e.message)
    }
}

function SettingsService() {
    this.setLastLoggedinUser();
};

SettingsService.prototype.getLastLoggedinUser = function(){
    try{
        if(OBJECT_USER_DATA != ""){
            return OBJECT_USER_DATA;
        }
        else{
            var user = Platform.getObjectProperty("currentuser", 1, "content");
            if(user == ""){
                return "";
            }
            else{
                return $.parseJSON(user);
            }
        }
    }
    catch(e){
        console.log("function : getLastLoggedinUser"
            + " message" + e.message);
    }
};

SettingsService.prototype.isFunctionalityExist = function(object)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/isfunctionalityexist?object='+object;
            var fexists = Platform.makeHttpGetCall(url);
            fexists = $.parseJSON(fexists);
            var status = fexists['status'];
            if (status == 200) {
                var data = $.parseJSON(fexists["data"]);
                if(data["status"] == "true"){
                    return "true";
                }
                else{
                    return data["message"];
                }
            }
            else {
                return ERROR_MESSAGE;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : getForumTopics"
                + "\n message:" + e.message);
    }
};

SettingsService.prototype.setLastLoggedinUser = function(){

    /* Sets the last logged in user automatically whenever the app is killed and
    re-opened */

    try{
        var user = Platform.getObjectProperty("currentuser", 1, "content");
        if(user != ''){
            user = $.parseJSON(user);
            OBJECT_USER_ID = user.uid;
            OBJECT_USER_NAME = user.username;
            OBJECT_USER_PASSWORD = user.password;
            OBJECT_USER_DATA = user;
            OBJECT_USER_GROUPS = user.groups;
            OBJECT_USER_GROUPS_JSON = [];
        }
    }
    catch(e){
        console.log("function : setLastLoggedinUser"
            + " message" + e.message);
    }
};

SettingsService.prototype.getUserProducts = function(userid){
    try{
        if(typeof OBJECT_USER_PRODUCTS != "undefined"){
            return OBJECT_USER_PRODUCTS;
        }
        var products = Platform.getObjectProperty("userproducts",userid,"content");
        if (products == "") {
            var userService = CONTROLLER.getService("User");
            userService.downloadUserProducts();
            return OBJECT_USER_PRODUCTS;
        }
        else{
            return $.parseJSON(products);
        }
    }
    catch(e){
        console.log("function : getUserProducts"
            + " message" + e.message);
    }
};

SettingsService.prototype.getUserSubscriptions = function(userid,catid,courseid,groupid,sku_id){
    try{
        var data = "{";
        var temp = "";
        temp = ' "uid" : "' + userid + '", ';
        if (typeof sku_id == 'undefined' || sku_id == "") {
            temp = temp + ' "category_id" : "' + catid + '", ';
            temp = temp + ' "collection_id" :"' + courseid + '"';
        } else {
            temp = temp + ' "sku_id" :"' + sku_id + '"';
        }
        data = data + temp + "}";
        var subscription = Platform.getUserSubscription(data);
        if(subscription == "" || subscription == "{}" || subscription == '[]'){
            return "";
        }
        else if(subscription == ACCESS_DENIED){
            return ACCESS_DENIED;
        }
        else{
            return $.parseJSON(subscription);
        }
    }
    catch(e){
        console.log("function : getUserSubscriptions"
            + " message" + e.message);
    }
};

SettingsService.prototype.getAllLanguages= function(){
   try {
       var languages = Platform.getObjectsOfType("lang");
       return $.parseJSON(languages);
    }
    catch (e) {
        console.log("function : getAllLanguages"
                + "\n message:" + e.message);
    }
};

SettingsService.prototype.isFunctionalityExist = function(object)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/isfunctionalityexist?object='+object;
            var fexists = Platform.makeHttpGetCall(url);
            fexists = $.parseJSON(fexists);
            var status = fexists['status'];
            if (status == 200) {
                var data = $.parseJSON(fexists["data"]);
                if(data["status"] == "true"){
                    return "true";
                }
                else{
                    return data["message"];
                }
            }
            else {
                return ERROR_MESSAGE;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : getForumTopics"
                + "\n message:" + e.message);
    }
};

SettingsService.prototype.getLeaderboardData = function(){
    try{
        if (Platform.getNetworkState() == true) {
           var url = OBJECT_SERVER_DOMAIN + '/api/getleaderboarddata?uid='+OBJECT_USER_ID+
                   '&limit='+LEADERBOARD_LIMIT+'&auth=' + AUTH_ENABLED;
           var leaderData = Platform.makeHttpGetCall(url);
            leaderData = $.parseJSON(leaderData);
            var status = leaderData['status'];
            if (status == 200) {
                var data = $.parseJSON(leaderData["data"]);
                return data;
            }else if(status == 403){
                onAccessDenied();
            }
            else {
                return ERROR_MESSAGE;
            }
        }else{
            return INTERNET_ERROR;
        }
    } catch(e){

    }
}

function MobileUserService(){};

MobileUserService.prototype.createUser = function(userjson,externalid)
{
    try{
        if(Platform.getNetworkState() == true){
            userjson = $.parseJSON(userjson);
            if(EMAIL_VERIFIED && EMAIL_VERIFIED == true){
                userjson.email_verified = true;
            }
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/createuser?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            if (status == 201 || status == 200){
                var userdata = user['data'];
                if(userdata.trim() != ""){
                    userdata = $.parseJSON(userdata);
                    userdata = userdata[0];
                    this.saveUserData(userjson,userdata,externalid);
                }
                return "true";
            }
            else if(status == 409 || status == 205){
                return USER_ALREADY_REGISTERED;
            }
            else if(status == 406 ){
                return COUPON_CODE_ERROR;
            }
            else if(status == 405 ){
                return MOBILE_NO_ALREADY_EXIST_ERROR_MESSAGE;
            }
            else{
                return USER_CREATION_FAILURE;
            }
        }
        else{
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : createUser"
            + "\n message:" + e.message);
    }
};
MobileUserService.prototype.changeClassStream = function(userjson)
{
    try{
        if(Platform.getNetworkState() == true){         
            userjson = $.parseJSON(userjson);
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/change/class_stream?uid='+ OBJECT_USER_ID +'&auth='+ AUTH_ENABLED;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            if (status == 201 || status == 200){
                return "true";
            }
            else if(status == "401"){
                return CHANGE_CLASS_SUBSCRIPTION_ERROR;
            }
            else{
                return OPERATION_ERROR;
            }
        }
        else{
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : changeClassStream"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.verifyUser = function(userjson){
    try{
        var temp = $.parseJSON(userjson);
        if(Platform.getNetworkState() == true){
            var url = OBJECT_SERVER_DOMAIN + '/api/verify/user';
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200 || status == 201){
                return "true";
            }
            else if(status == 401){
                return LOGIN_FAILURE;
            }
            else if(status == 404){
                return USER_NOT_REGISTERED;
            }
            else{
                return USER_LOGIN_FAILURE;
            }
        }
        else{
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : verifyUser"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.retagUser = function(userjson)
{
    try{
        if(Platform.getNetworkState() == true){
            userjson = $.parseJSON(userjson);
            if(EMAIL_VERIFIED && EMAIL_VERIFIED == true){
                userjson.email_verified = true;
            }
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/retag/usergroups?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            if (status == 201 || status == 200){
                var userdata = user['data'];
                if(userdata.trim() != ""){
                    userdata = $.parseJSON(userdata);
                    userdata = userdata[0];
                    this.saveUserData(userjson,userdata,externalid);
                }
                return "true";
            }
            else if(status == "401"){
                return CHANGE_CLASS_SUBSCRIPTION_ERROR;
            }
            else{
                return OPERATION_ERROR;
            }
        }
        else{
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : createUser"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.loginUserAsync = function(){
    var loginsource = Platform.getPreference("loginsource");
    var backfrombuynow = CONTROLLER.getVariable("backfrombuynow");
    var login_history_update ='true';
    if (typeof loginsource == "undefined" || loginsource == "") {
        loginsource = "";
    }
    if (typeof backfrombuynow != "undefined" && backfrombuynow != "" && backfrombuynow == 'true') {
        login_history_update = 'false';
        CONTROLLER.setVariable("backfrombuynow", '');
    }
    var loginurl = OBJECT_SERVER_DOMAIN + "/api/login/redirect?uid=" + OBJECT_USER_ID +'&login_history_update='+login_history_update+'&login_source='+ loginsource+"&auth=true&useractive=true&path=/";
    var data = "{";
    var temp = "";
    temp = temp + ' "message" : "",';
    temp = temp + ' "data" : "",';
    temp = temp + ' "url" : "'+loginurl+'",';
    temp = temp + ' "method" : "get",';
    temp = temp + ' "showdialog" : "false",';
    temp = temp + ' "callback" : "MobileUserService.prototype.onAsyncLoginComplete"';
    data = data + temp + "}";
    Platform.makeAsyncTaskCall(data);
}

MobileUserService.prototype.onAsyncLoginComplete = function(result){
    var THIS = this;
    if(typeof result["status"] != "undefined" && (result["status"] != "200" && result["status"] != "302")){
        setTimeout(function(){
            THIS.loginUserAsync();
        },5000);
        ASYNC_LOGIN_REQUIRED = "true";
    }
    else{
        ASYNC_LOGIN_REQUIRED = "false";
    }
}

MobileUserService.prototype.loginUserSync = function(){
    var loginurl = OBJECT_SERVER_DOMAIN + "/api/login/redirect?uid=" + OBJECT_USER_ID + "&auth=true&useractive=true&path=";

    var result = Platform.makeHttpPostCall(loginurl);
    if (typeof result == "string") {
        result = JSON.parse(result);
    }
    if(typeof result["status"] != "undefined" && result["status"] != "200" && result["status"] != "302"){
        return false;
    }
    return true;
}

MobileUserService.prototype.saveUserData = function(userjson,userdata,externalid){
    try{
        userjson.uid = userdata.uid;
        Platform.setUserID(userjson.uid);
        userjson.groups = userdata.groups;
        if(typeof OBJECT_USER_PASSWORD != "undefined" && OBJECT_USER_PASSWORD != "" && OBJECT_USER_PASSWORD == "true"){
            userjson.password = OBJECT_USER_PASSWORD;
        }
        else if(typeof userjson.password != "undefined" && typeof USE_CRYPTOJS != 'undefined' && USE_CRYPTOJS != 'false'){
            userjson.password = CryptoJS.MD5(userjson.password).toString();
        }
        userjson = JSON.stringify(userjson);
        Platform.saveObject("currentuser", 1, "content", $.trim(userjson));
        Platform.saveObject("user", userdata.uid, "content", $.trim(userjson));
        if(externalid){
            var mappingJson = {"nid": userdata.uid, "externalid": externalid};
            Platform.saveObject("usermapping", externalid, "content", JSON.stringify(mappingJson));
        }
        OBJECT_USER_DATA = $.parseJSON(userjson);
        OBJECT_USER_ID = OBJECT_USER_DATA.uid;
        OBJECT_USER_NAME = OBJECT_USER_DATA.username;
    }
    catch(e){
        console.log( "function : saveUserData"
            + "\n message:" + e.message);
    }
}

MobileUserService.prototype.saveUser = function(userjson)
{
    Platform.saveObject("user",userjson.uid,"content",$.trim(JSON.stringify(userjson)));
    Platform.saveObject("currentuser",1,"content",$.trim(JSON.stringify(userjson)));
}

MobileUserService.prototype.updateUser = function(userjson)
{
    try{
        if(Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/edituser?uid=' + OBJECT_USER_ID;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200) {
                var userdata = user['data'];
                userdata = $.parseJSON(userdata);
                Platform.saveObject("currentuser", 1, "content", $.trim(userjson));
                Platform.saveObject("user", userdata[0].uid, "content", $.trim(userjson));
                return "true";
            }
            else {
                return EDIT_USER_FAILURE;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : updateUser"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getPassword = function(userjson,uid)
{
    try{
        if(Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/get/password?auth=true';
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200) {
                var userdata = user['data'];
                userdata = $.parseJSON(userdata);
                var currentuserjson = DataManager.getObjectProperty("user", uid, "content");
                currentuserjson.password = userdata[0].password;
                var json = JSON.stringify(currentuserjson);
                Platform.saveObject("user", uid, "content", $.trim(json));
                return "true";
            }
            else if (status == 404){
                return USER_NOT_EXISTS;
            }
            else {
                return EDIT_USER_FAILURE;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : getPassword"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getUserInfo = function(username,email,callback,uid)
{
    var params = "";
    try{
        if(Platform.getNetworkState() == true) {
            if(typeof email !== "undefined" && email != ""){
                params = '&email=' + email;
            }
            else if(typeof username !== "undefined" && username != ""){
                params = '&username='+username;
            } else if (typeof uid != "undefined" && uid != "") {
                params = '&uid='+uid;
            }
            var url = OBJECT_SERVER_DOMAIN + '/api/getuserinfo?auth=true'+params;
            if(typeof callback != 'undefined' && typeof callback == 'function' ){
                onAsyncGetuserinfoComplete = function (result)
                {
                    var status = result['status'];
                    if (status == 200) {
                        var userdata = result['data'];
                        Platform.saveObject("userProfile", OBJECT_USER_ID, "content", $.trim(userdata));
                        userdata = $.parseJSON(userdata);
                        return callback(userdata);
                    } else if (status == 403) {
                        return onAccessDenied();
                    } else {
                        return callback(GET_USER_INFO_FAILURE);
                    }
                }
                makeAsyncTaskCall("","",url,"get","false","onAsyncGetuserinfoComplete");
            }else{
                var postdata = "";
                var user = Platform.makeHttpPostCall(url, postdata);
                user = $.parseJSON(user);
                var status = user['status'];
                if (status == 200) {
                    var userdata = user['data'];
                    Platform.saveObject("userProfile", OBJECT_USER_ID, "content", $.trim(userdata));
                    userdata = $.parseJSON(userdata);
                    return userdata;
                } else if (status == 403) {
                    return onAccessDenied();
                } else {
                    return GET_USER_INFO_FAILURE;
                }
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : getUserInfo"
                + "\n message:" + e.message);
    }
};
MobileUserService.prototype.getUserBadge = function()
{
    var params = "";
    try{
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getuser'+USER_BADGE+'?auth=true';
            var postdata = "";
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200) {
                var userdata = user['data'];
                userdata = $.parseJSON(userdata);
                return userdata;
            }
            else {
                return GET_USER_INFO_FAILURE;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : getUserBadge"
            + "\n message:" + e.message);
    }
};
MobileUserService.prototype.getGroupsForUser = function(uid)
{
    if (typeof OBJECT_USER_GROUPS_JSON != 'undefined' && OBJECT_USER_GROUPS_JSON != null && OBJECT_USER_GROUPS_JSON != "") {
        return OBJECT_USER_GROUPS_JSON;
    }
    var user_groups = [];
    try{
        if(Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getgroupsforuser?uid='+uid;
            var postdata = "";
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200) {
                var userdata = user['data'];
                userdata = $.parseJSON(userdata);
                OBJECT_USER_GROUPS_JSON = userdata;
                for (var i = 0; i < userdata.length; i++) {
                    user_groups.push(userdata[i].nid);
                }
                OBJECT_USER_DATA.groups = user_groups;
                OBJECT_USER_GROUPS = user_groups;
                return userdata;
            }
            else {
                return GET_USER_GROUPS_FAILURE;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : getUserInfo"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getAllgroups = function()
{
    var params = "";
    try{
        if(Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getallgroups';
            var postdata = "";
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200) {
                var userdata = user['data'];
                userdata = $.parseJSON(userdata);
                return userdata;
            }
            else {
                return GET_USER_GROUPS_FAILURE;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : getUserInfo"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getAllgroupsFromDatabase = function()
{
    try{
        if (EXEC_MODE == "online") {
            var data = {};
            if (typeof OBJECT_USER_GROUPS_JSON != 'undefined' && OBJECT_USER_GROUPS_JSON != null && OBJECT_USER_GROUPS_JSON != "") {
                data[OBJECT_GROUP] = OBJECT_USER_GROUPS_JSON;
            } else {
                data[OBJECT_GROUP] = this.getGroupsForUser(OBJECT_USER_ID);
            }
            return data;
        }
        var groups = Platform.getObjectsOfType("group");
        return $.parseJSON(groups);
    }
    catch(e){
        console.log( "function : getAllgroupsFromDatabase"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.downloadUserImage = function(username,imagedownloadurl)
{
    var imagepath = OBJECT_DATA_FOLDER + "/sites/default/files/users/" + username + ".png";
    var filepath_to_check = "/sites/default/files/users/" + username + ".png";
    try{
        var imagefileAvailable = Platform.isFilePresentInDocRoot(filepath_to_check);
        if(imagefileAvailable != true){
            var paramsJson = {'objectid':"",'objecttype':'content',
            'serverurl':imagedownloadurl,'localurl':imagepath,
            'message':'', 'sync':FILE_DOWNLOAD_ASYNC};
            Platform.downloadFile(JSON.stringify(paramsJson));
        }
    }
    catch(e){
        console.log("function : downloadUserImage"
            + "url" + imagedownloadurl + " message" + e.message);
    }
};

MobileUserService.prototype.getAllUsers = function()
{
    try{
        var users = Platform.getObjectsOfType("user");
        if(users != ""){
            return $.parseJSON(users);
        }
        else
        {
            return "";
        }
    }
    catch(e){
        console.log( "function : getAllUsers"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getUseridFromExternalId = function(externalid)
{
    try{
        //call  getObjects(OBJECT_CATEGORY)
        //api/getallcategories
        var mapping = Platform.getObjectProperty("usermapping", externalid, "content");
        if(mapping != "" && mapping != null){
            mapping = $.parseJSON(mapping);
            if(!$.isEmptyObject(mapping)){
                return mapping.nid;
            }
            else{
                return "";
            }
        }
        else
        {
            return "";
        }
    }
    catch(e){
        console.log( "function : getUseridFromExternalId"
            + "\n message:" + e.message);
    }

};

MobileUserService.prototype.doesUserExist = function(userjson)
{
    try{
        var url = OBJECT_SERVER_DOMAIN + '/api/isuserexist?auth=' + AUTH_ENABLED;
        var postdata = encodeURIComponent(userjson);
        var user = Platform.makeHttpPostCall(url, postdata);
        user = $.parseJSON(user);
        var status = user['status'];
        if (status == 200){
            var data = user['data'];
            data = $.parseJSON(data);
            var userstatus = data[0]["status"];
            OBJECT_USER_ID = data[0]["uid"];
            var status_code = data[0]["status_code"];
            if(userstatus == true){
                if(status_code == 409 || status_code == 405){
                    return status_code; // status_code for mobile,email exist in Aakash
                }else{
                    return "true";
                }
            }
            else if(userstatus == false){
                return "false";
            }
        }
        else {
            return USER_EXIST_CHECK_ERROR;
        }
    }
    catch(e){
        console.log( "function : doesUserExist"
            + "\n message:" + e.message);
    }
};
MobileUserService.prototype.isUserLoggedin = function()
{
    try{
        var url = OBJECT_SERVER_DOMAIN + '/api/user/isloggedin';
        var user = Platform.makeHttpPostCall(url,'');
        user = $.parseJSON(user);
        var status = user['status'];
        if (status == 200){
            var data = user['data'];
            data = $.parseJSON(data);
            var userstatus = data[0]["status"];
            if(userstatus == 200){
                return data;
            }
        }else if (status == 401 || status == 403) {
            return 'false';
        }
    }
    catch(e){
        console.log( "function : isUserLoggedin"
            + "\n message:" + e.message);
    }
};
MobileUserService.prototype.changePassword = function(currentpassword,newpassword){
   try{
        var url = OBJECT_SERVER_DOMAIN + '/api/chgpwd';
        var data = "{";
        var temp ="";
        temp = ' "uid" : "' + OBJECT_USER_ID + '", ';
        temp = temp + ' "currentpassword" : "' + currentpassword + '", ';
        temp = temp + ' "newpassword" : "' + newpassword + '" ';
        data = data + temp + "}";
        var postdata = encodeURIComponent(data);
        var user = Platform.makeHttpPostCall(url, data);
        user = $.parseJSON(user);
        var status = user['status'];
        if (status == 200){
            return "true";
        }
        else if(status == 422){
            return WRONG_CURRENT_PASSWORD;
        }
        else{
            return CHANGE_PASSWORD_FAILURE;
        }
    }
    catch(e){
        console.log( "function : changePassword"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.logout = function (){
   try{
       var url = OBJECT_SERVER_DOMAIN + '/api/logout';
        var logoutdata = Platform.makeHttpPostCall(url, '');
        logoutdata = $.parseJSON(logoutdata);
        var status = logoutdata['status'];
        if (status == 200){
            return "true";
        }
        else{
            return LOGOUT_FAILURE;
        }
   }catch(e){
       console.log("function : logout"
            + " message" + e.message);
   }
}

MobileUserService.prototype.isLoggedin = function (){
    try{
         var userdata = OBJECT_USER_DATA;
         if(!$.isEmptyObject(userdata)){
            if(userdata[0].hasOwnProperty('isloggedin')){
                var loggedin = userdata[0].isloggedin;
                return loggedin;

            }else{
                return false;
            }
         }else{
            return false;
         }
    } catch (e){

    }
}

MobileUserService.prototype.getLastViewedVideoOfSubject = function(userid, groupid, catid){
    try{
        if(typeof PLATFORM_VERSION != "undefined" && PLATFORM_VERSION == 4.0){
            if (typeof VIDEOVIEW_HISTORY_VERSION != "undefined" && VIDEOVIEW_HISTORY_VERSION == "1.0") {
                var videosViewed = Platform.getVideoViewHistoryByType(OBJECT_USER_ID, groupid, catid, "subject", OBJECT_VIDEO);
            }
            else {
            var videosViewed = Platform.getVideoViewHistoryByType(userid, groupid, catid, "subject");
        }
        }
        else{
            videosViewed = Platform.getVideoViewHistory(userid, groupid, catid, "subject");
        }

        videosViewed = $.parseJSON(videosViewed);
        var videos_length = videosViewed["videoviewed"].length;
        if(videos_length > 0){
            return videosViewed["videoviewed"][videos_length - 1];
        }
        else
        {
            return "";
        }
    }
    catch(e){
        console.log("function : getLastViewedVideoOfSubject"
            + " message" + e.message);
    }
};

MobileUserService.prototype.getVideoViewHistoryCountByType = function(uid,groupid,objid,objtype){
    try{
        groupid = "";
        return Platform.getVideoViewHistoryCountByType(uid,groupid,objid,objtype);
    }
    catch(e){
        console.log( "function : getVideoViewHistoryCountByType"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.getVideoViewHistoryCount = function(uid,groupid,objid,objtype){
    try{
        groupid = "";
        if (typeof PLATFORM_VERSION != "undefined" && PLATFORM_VERSION == 4.0) {
            return Platform.getVideoViewHistoryCountByType(OBJECT_USER_ID, groupid, objid, objtype);
        }
        else {
            return Platform.getVideoViewHistoryCount(uid, groupid, objid, objtype);
        }
    }
    catch(e){
        console.log( "function : getVideoViewHistoryCount"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.downloadUserProducts = function(){
    var highest_product_expiry = "";
    var userProductsUrl = OBJECT_SERVER_DOMAIN + '/api/getuserproducts?uid=' + OBJECT_USER_ID + "&auth=" + AUTH_ENABLED + "&api_version=" + API_VERSION;
    var products = Platform.makeHttpGetCall(userProductsUrl);
    try
    {
        if (!$.isEmptyObject(products))
        {
            products = $.parseJSON(products);
            var status = products['status'];
            if (status == 200)
            {
                var data = products['data'];
                if(data != '' && data.trim() != "null"){
                    var userproducts = $.parseJSON(products['data']);
                    if(typeof userproducts[0]["server_time"] != "undefined"){
                        var curTime = new Date().getTime()/1000;
                        timeDiff = (curTime - userproducts[0]["server_time"])/3600;
                        if(timeDiff >= 0.5 || timeDiff <= -0.5){
                            CONTROLLER.loadView("Error","invaliddate"); 
                            return false;
                        }
                    }
                    OBJECT_USER_PRODUCTS = userproducts;
                    for(var i = 0; i < userproducts.length; i++){
                        var product_expiry = userproducts[i].expiry;
                        if (highest_product_expiry == "") {
                            highest_product_expiry = product_expiry;
                        }
                        else if (highest_product_expiry < product_expiry) {
                            highest_product_expiry = product_expiry;
                        }
                    }

                    if(DATABASE_DOWNLOADED == true){
                        if(typeof highest_product_expiry == "undefined" || highest_product_expiry == ""){
                            var curDate = new Date();
                            var dd = curDate.getDate();
                            var mm = curDate.getMonth();
                            var y = curDate.getFullYear() + 2;
                            var twoyrsdate = y + '-' + ('0' + mm).slice(-2) + '-' + ('0' + dd).slice(-2);
                            Platform.addOrUpdateProductExpiry(twoyrsdate);
                            userproducts[0].expiry = twoyrsdate;
                            Platform.addOrUpdateProductExpiry(twoyrsdate);
                        }
                        else{
                            Platform.addOrUpdateProductExpiry(highest_product_expiry);
                        }
                        Platform.saveObject("userproducts",OBJECT_USER_ID,"content",JSON.stringify(userproducts));
                    }
                    return userproducts;
                }
                else
                {
                    OBJECT_USER_PRODUCTS = "";
                    return '';
                }
            }
            else if (status == 403){
                return 'access forbidden';
                console.log("error status = " + status + "function = " + downloadUserProducts);
            }
            else if (status == 401){
               Platform.addOrUpdateProductExpiry("");
               return 'subscription error';
               console.log("error status = " + status + "function = " + downloadUserProducts);
            }
            else
            {
                return 'error';
                console.log("error status = " + status + "function = " + downloadUserProducts);
            }
        } else {
            console.log("Empty JSON data");
        }
    }
    catch(e){
        console.log("function : downloadUserProducts"
            + " message" + e.message);
    }
};


MobileUserService.prototype.loginUser = function(userjson){
    try{
        var temp = $.parseJSON(userjson);
        if(Platform.getNetworkState() == true){
            var url = "";
            url = OBJECT_SERVER_DOMAIN + '/api/login/user?useractive=true';
            var postdata = userjson;
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200 || status == 201){
                var userdata = user['data'];
                Platform.setPreference("loginRequired", "false");
                userdata = $.parseJSON(userdata);
                userdata[0].isloggedin = true;
                if(typeof PASSWORD_ENCRYPT != "undefined" && PASSWORD_ENCRYPT != "" && PASSWORD_ENCRYPT == "true"
                        && (typeof(temp.hash) == "undefined" || (typeof(temp.hash) != "undefined" && temp.hash == false)) ){
                    userdata[0].password = Platform.getMD5String(OBJECT_USER_PASSWORD);
                }
                else{
                    userdata[0].password = OBJECT_USER_PASSWORD;
                }
                var uid = userdata[0].uid;
                userdata[0].loginRequiredOnline = false;
                userdata = JSON.stringify(userdata[0]);
                if(typeof DOWNLOAD_DATABASE == "undefined" || DOWNLOAD_DATABASE != true){
                    Platform.saveObject("currentuser", 1, "content", $.trim(userdata));
                    Platform.saveObject("user", uid, "content", $.trim(userdata));
                }
                OBJECT_USER_DATA = $.parseJSON(userdata);
                OBJECT_USER_ID = OBJECT_USER_DATA.uid;
                OBJECT_USER_NAME = OBJECT_USER_DATA.username;
                Platform.setUserID(OBJECT_USER_ID);
                return "true";
            }
            else if(status == 405){
                return INVALID_MINIPC_PRODUCT_ERROR;
            }
            else if(status == 406){
                return UNAUTHORIZED_DEVICE_ERROR;
            }
            else if(status == 401){
                return LOGIN_FAILURE;
            }
            else if(status == 404){
                return USER_NOT_REGISTERED;
            }
            else{
                return USER_LOGIN_FAILURE;
            }
        }
        else{
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : loginUser"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.loginUserWithEncryptedPassword = function(userjson){
    try{
        userjson = $.parseJSON(userjson);
        userjson.hash = true;
        userjson = JSON.stringify(userjson);
        if(Platform.getNetworkState() == true){
            var url = OBJECT_SERVER_DOMAIN + '/api/login/user';
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200 || status == 201){
                var userdata = user['data'];
                userdata = $.parseJSON(userdata);
                userdata[0].isloggedin = true;
                if(typeof PASSWORD_ENCRYPT != "undefined" && PASSWORD_ENCRYPT != "" && PASSWORD_ENCRYPT == "true"){
                    userdata[0].password = Platform.getMD5String(OBJECT_USER_PASSWORD);
                }
                else{
                    userdata[0].password = OBJECT_USER_PASSWORD;
                }
                var uid = userdata[0].uid;
                userdata = JSON.stringify(userdata[0]);
                if(typeof DOWNLOAD_DATABASE == "undefined" || DOWNLOAD_DATABASE != true){
                    Platform.saveObject("currentuser", 1, "content", $.trim(userdata));
                    Platform.saveObject("user", uid, "content", $.trim(userdata));
                }
                OBJECT_USER_DATA = $.parseJSON(userdata);
                OBJECT_USER_ID = OBJECT_USER_DATA.uid;
                OBJECT_USER_NAME = OBJECT_USER_DATA.username;
                Platform.setUserID(OBJECT_USER_ID);
                return "true";
            }
            else if(status == 401){
                return LOGIN_FAILURE;
            }
            else if(status == 404){
                return USER_NOT_REGISTERED;
            }
            else{
                return USER_LOGIN_FAILURE;
            }
        }
        else{
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : loginUserWithEncryptedPassword"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.resetPassword = function(userjson){
    try{
        if(Platform.getNetworkState() == true){
            var url = OBJECT_SERVER_DOMAIN + '/api/forgot/password?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200 || status == 201){
                var data = $.parseJSON(user['data']);
                if(data[0].password){
                    OBJECT_USER_DATA.password = data[0].password;
                    OBJECT_USER_DATA.loginRequiredOffline = true;
                }
                Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
                Platform.saveObject("user", OBJECT_USER_DATA.uid, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
                return "true";
            }
            else if(status == 404){
                return USER_NOT_REGISTERED;
            }
            else{
                return FORGOT_PASSWORD_ERROR;
            }
        }
        else{
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : forgotPassword"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.loginUserOffline = function(password,userjson){
    try{
        if(Platform.getMD5String(password) != userjson.password){
           return LOGIN_FAILURE;
        }
        else
        {
            userjson.loginRequiredOffline = false;
            var userjsonstring = JSON.stringify(userjson);
            Platform.saveObject("currentuser", 1, "content", $.trim(userjsonstring));
            Platform.saveObject("user", userjsonstring.uid, "content", $.trim(userjsonstring));
            OBJECT_USER_DATA = userjson;
            OBJECT_USER_ID = OBJECT_USER_DATA.uid;
            OBJECT_USER_NAME = OBJECT_USER_DATA.username;
            return "true";
        }
    }
    catch(e){
        console.log( "function : loginUserOffline"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.loginThroughBrowser = function(userjson) {
    try{
        if(Platform.getNetworkState() == true){
            if (USE_PORTAL_SERVER_DOMAIN && USE_PORTAL_SERVER_DOMAIN == true) {
                SERVER_DOMAIN = PORTAL_SERVER_DOMAIN;
            }
            else {
                SERVER_DOMAIN = OBJECT_SERVER_DOMAIN;
            }
            var url = SERVER_DOMAIN + '/api/login/user';
            var loginStatus = "";
            $.ajax({
                type: "POST",
                url: url,
                data: userjson,
                xhrFields: {
                    withCredentials: true
                },
                async:false,
                crossDomain: true,
                success: function(data,status) {
                    loginStatus = "true";
                },
                error: function() {
                    loginStatus = USER_LOGIN_FAILURE;
                }
            });
        }
        else{
            loginStatus = INTERNET_ERROR;
        }
        return loginStatus;
    }
    catch(e){
        console.log( "function : loginThroughBrowser"
            + "\n message:" + e.message);
        if(e.message.toLowerCase().indexOf("a network error") >= 0){
            return "true";
        }
    }
};

MobileUserService.prototype.onAccesDenied = function() {
    try{
        if(Platform.getNetworkState() == true){
            Client.showProgressBar("Loading");
            setTimeout(function() {
            Client.closeProgressBar();
             $('#wrapper').remove();
             $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
            <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
                OBJECT_USER_DATA.loginRequiredOffline = true;
                Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
                Platform.saveObject("user", OBJECT_USER_DATA.uid, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
                CONTROLLER.loadView("Signin");
            }, 400);
        }
    }
    catch(e){
        console.log( "function : onAccesDenied"
            + "\n message:" + e.message);
        if(e.message.toLowerCase().indexOf("a network error") >= 0){
            return "true";
        }
    }
};

MobileUserService.prototype.setUserAttributes = function(json) {
    try{
        if(Platform.getNetworkState() == true){
            Client.showProgressBar("Loading");
            var url = OBJECT_SERVER_DOMAIN + '/api/setuser/attributes?auth=' + AUTH_ENABLED;
            var postdata = encodeURIComponent(json);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            Client.closeProgressBar();
            if (status == 200){
                var newEmail = $.parseJSON(json);
                newEmail = newEmail.mail;
                OBJECT_USER_DATA.email = newEmail
                userjson = JSON.stringify(OBJECT_USER_DATA);
                Platform.saveObject("currentuser", 1, "content", $.trim(userjson));
                Platform.saveObject("user", OBJECT_USER_DATA.uid, "content", $.trim(userjson));
                return true;
            }
            else if(status == 403){
                return UNAUTHORIZED_DEVICE_ERROR;
            }
            else if(status == 401){
                return USER_NOT_REGISTERED;
            }
            else if(status == 205){
                return USER_ALREADY_EXIST;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : setUserAttributes"
            + "\n message:" + e.message);

    }
};

MobileUserService.prototype.loginRedirect = function(email, path, username) {
    try {
         var loginsource =Platform.getPreference("loginsource")
        if (Platform.getNetworkState() == true) {
            var emailparam = "",usernameparam = "";
            if (typeof path == "undefined" || path == "") {
                path = "";
            }
            if(typeof email != "undefined" && email != ""){
                emailparam = '&email=' + email;
            }
            if(typeof username != "undefined" && username != ""){
                usernameparam = '&username=' + username;
            }
            if(typeof loginsource == "undefined" || loginsource == "" ){
                loginsource = "";
            }
            var url = OBJECT_SERVER_DOMAIN + '/api/login/redirect?uid='+OBJECT_USER_ID+ emailparam + usernameparam + '&path=' + path +'&login_source='+ loginsource +'&useractive=true&auth=' + AUTH_ENABLED;
            var user = Platform.makeHttpGetCall(url);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200) {
                return true;
            } else if(status == 403){
                return status;
            }
            else {
                return false;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : loginRedirect"
                + "\n message:" + e.message);

    }
};

MobileUserService.prototype.getOffers = function() {
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/get/promo?auth=' + AUTH_ENABLED;
            var useroffers = Platform.makeHttpGetCall(url);
            useroffers = $.parseJSON(useroffers);
            var status = useroffers['status'];
            var offeres = {};
            if (status == 200) {
                offeres['offers'] = $.parseJSON(useroffers['data']);
                return offeres;
            }
            else {
                return false;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : getOffers"
                + "\n message:" + e.message);

    }
};

MobileUserService.prototype.loginOnline = function() {
    try {
         Client.closeProgressBar();
        if (Platform.getNetworkState() == true) {
            OBJECT_USER_DATA.loginRequiredOnline = true;
            Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
            Platform.saveObject("user", OBJECT_USER_DATA.uid, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
            Platform.showMessage(USER_LOGOUT_ERROR);
            $('#wrapper').remove();
            $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
             <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
            if($(window).width() > $(window).height()){
                Platform.setDeviceOrientation("portrait");
                CONTROLLER.loadView("Signin");
            }
            else{
                CONTROLLER.loadView("Signin");
            }
            return false;
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : loginOnline"
                + "\n message:" + e.message);

    }
};



MobileUserService.prototype.registerProduct = function(userjson){
    try{
        var temp = $.parseJSON(userjson);
        if(Platform.getNetworkState() == true){
            var url = "";
            url = OBJECT_SERVER_DOMAIN + '/api/coupon/subscription';
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            var status = user['status'];
            if (status == 200 || status == 201){
                return "true";
            }
             else if (status == 406) {
                return LICENCE_KEY_LIMIT_ERROR_MESSAGE;
            }else{
              return ERROR_MESSAGE;
            }
        }
        else{
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : showCouponCodeDialog"
            + "\n message:" + e.message);
    }
};

MobileUserService.prototype.downloadUserGroups = function(check_group) {
    var settingService = CONTROLLER.getService("Settings");
    var currentuser = settingService.getLastLoggedinUser();
    var user_groups = [];
    ADD_RELATION_ARRAY = [];
    var categoryService = CONTROLLER.getService("Category");
    var groups = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getgroupsforuser?uid=' + OBJECT_USER_ID);
    try {
        groups = $.parseJSON(groups);
        var status = groups['status'];
        if (status == 200)
        {
            var data = $.parseJSON(groups['data']);
            for (var i = 0; i < data.length; i++) {
                user_groups.push(data[i].nid);
                if (typeof LIMIT_BOOTSTRAP != "undefined" && LIMIT_BOOTSTRAP) {
                    delete data[i].description;
                    delete data[i].parent;
                }
                var group = Platform.getObjectProperty("group", data[i].nid, "content");
                 Platform.saveObject("group", data[i].nid, "content", JSON.stringify(data[i]));
                if (group == "" || (check_group && check_group == "false") && data[i].type == "user") {
                    categoryService.addCategoriestoUserGroups(data[i].nid);
                }
            }
            if (ADD_RELATION_ARRAY.length > 0) {
                var json = JSON.stringify(ADD_RELATION_ARRAY);
                Platform.addMultipleRelation(json);
                ADD_RELATION_ARRAY = [];
            }
            if (isArray(OBJECT_USER_DATA.groups)) {
                var userGroups = OBJECT_USER_DATA.groups;
            }else{
                var userGroups = [];
                userGroups.push(OBJECT_USER_DATA.groups);
            }
                OBJECT_USER_DATA.groups = user_groups;
                OBJECT_USER_GROUPS = user_groups;
                if (currentuser != "") {
                    currentuser.groups = user_groups;
                    Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(currentuser)));
                    Platform.saveObject("user", currentuser.uid, "content", $.trim(JSON.stringify(currentuser)));
                }
                this.getUserGroupsData();
        }
    }
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getgroupsforuser?uid=' + OBJECT_USER_ID
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    };
};

MobileUserService.prototype.getUserGroupsData = function() {
    try {
        var userService = CONTROLLER.getService("User");
        var batches = {}; batches['batches'] = []; var usergroups = [];
        var userbatches = []; var allUserBatches = {}; var contentGroups = [];
        var parentGroups = []; allUserBatches['batches'] = [];
          for (var i = 0; i < OBJECT_USER_GROUPS.length; i++) {
            var usergroups = $.parseJSON(Platform.getObjectProperty(OBJECT_GROUP, OBJECT_USER_GROUPS[i], "content"));
            if(usergroups.type != "content" && usergroups.status != '0'){
                 batches['batches'].push(usergroups);
                 userbatches.push(usergroups.nid);
                 parentGroups.push(usergroups.parent);
            }
            else if(usergroups.type == "content"){
                contentGroups.push(usergroups);
            }
        }
          for (var i = 0; i < batches['batches'].length; i++) {
            if ($.inArray(batches['batches'][i].nid, parentGroups) < 0) {
                allUserBatches['batches'].push(batches['batches'][i]);
            }
        }
        CONTROLLER.setVariable("activebatches", allUserBatches);
        CONTROLLER.setVariable("contentGroups", contentGroups);
        return allUserBatches;
    }
    catch (e) {
        console.log("function :- getUserGroupsData " + e.message);
    }
}
MobileUserService.prototype.getBannersInfo = function (url,callback)
{
    try {
        if (Platform.getNetworkState() == true) {
            onAsyncGetBannersInfoComplete = function (result)
            {
                if (typeof result["status"] != 'undefined' && result["status"] == '200') {
                    var userdata = result['data'];
                    userdata = $.parseJSON(userdata);
                    return callback(userdata);
                }
                else {
                    return callback(ERROR_MESSAGE);
                }
            }
            makeAsyncTaskCall("", "", url, "get", "false", "onAsyncGetBannersInfoComplete");
        } else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : getBannersInfo"
                + "\n message:" + e.message);
    }
};
MobileUserService.prototype.getAiatsSolutionInfo = function (url)
{
    try {
        if (Platform.getNetworkState() == true) {
            var result = Platform.makeHttpGetCall(url);
            result = $.parseJSON(result);
            var status = result['status'];
            if (status == 200 || status == 201){
                result = $.parseJSON(result['data']);
                return result;
            }else{
              return ERROR_MESSAGE;
            }

        }else{
           return INTERNET_ERROR; 
        }
    }
    catch (e) {
        console.log("function : getAiatsSolutionInfo"
                + "\n message:" + e.message);
    }
};
MobileUserService.prototype.generateOtp = function (userjson)
{
    try {
        var response = {};
        if (Platform.getNetworkState() == true) {
            userjson = $.parseJSON(userjson);
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/generate/otp?uid=' + OBJECT_USER_ID;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            response['status'] = status;
            if (status == 200) {
                var userdata = user['data'];
                userdata = $.parseJSON(userdata);
                response['data'] = userdata;
                return response;
            } else if (status == 405) {
                response['data'] = MOBILE_NO_NOT_VERIFIED_MESSAGE;
                return response;
            } else if (status == 403) {
                response['data'] = USER_HAS_MORE_THEN_ONE_EMAIL_ID_MESSAGE;
                return response;
            } else if (status == 401) {
                response['data'] = USER_HAS_BLOCKED_MESSAGE;
                return response;
            } else if (status == 404) {
                response['data'] = EMAIL_ID_DOES_NOT_EXIST_MESSAGE;
                return response;
            } else if (status == 411) {
                response['data'] = INVALID_MOBILE_NUMBER_MESSAGE;
                return response;
            } else if (status == 409) {
                response['data'] = MOBILE_NO_ASSOCIATED_WITH_MULTIPLE_ACC_MESSAGE;
                return response;
            } else if (status == 406) {
                response['data'] = MOBILE_NO_ALREADY_EXIST;
                return response;
            } else {
                response['data'] = ERROR_MESSAGE;
                return response;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : generateOtp"
                + "\n message:" + e.message);
    }
};

MobileUserService.prototype.validateOtp = function (userjson)
{
    try {
        var response = {};
        if (Platform.getNetworkState() == true) {
            userjson = $.parseJSON(userjson);
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/validate/otp?uid=' + OBJECT_USER_ID;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            response['status'] = status;
            if (status == 200) {
                return response;
            }
            else if (status == 403) {
                response['data'] = OTP_EXPIRED_MESSAGE;
                return response;
            } else if (status == 401) {
                response['data'] = OTP_DOES_NOT_MATCH_MESSAGE;
                return response;
            } else if (status == 406) {
                response['data'] = MOBILE_NO_ALREADY_EXIST;
                return response;
            } else if (status == 409) {
                response['data'] = MOBILE_NO_ASSOCIATED_WITH_MULTIPLE_ACC_MESSAGE;
                return response;
            } else {
                response['data'] = ERROR_MESSAGE;
                return response;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : validateOtp"
                + "\n message:" + e.message);
    }
};
MobileUserService.prototype.forgotPassword = function (userjson)
{
    try {
        var response = {};
        if (Platform.getNetworkState() == true) {
            userjson = $.parseJSON(userjson);
            userjson = JSON.stringify(userjson);
            var url = OBJECT_SERVER_DOMAIN + '/api/forgot/password?uid=' + OBJECT_USER_ID;
            var postdata = encodeURIComponent(userjson);
            var user = Platform.makeHttpPostCall(url, postdata);
            user = $.parseJSON(user);
            userjson = $.parseJSON(userjson);
            var status = user['status'];
            response['status'] = status;
            if (status == 200) {
                return response;
            }
            else if (status == 404) {
                response['data'] = USER_NOT_REGISTERED;
                return response;
            } else if (status == 401) {
                response['data'] = USER_HAS_BLOCKED_MESSAGE;
                return response;
            } else {
                response['data'] = ERROR_MESSAGE;
                return response;
            }
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : forgotPassword"
                + "\n message:" + e.message);
    }
};

MobileUserService.prototype.sendMail = function(to ,subject, mailcontent){
    // if(Platform.getNetworkState()){
    //     var data = "{";
    //     var temp = "";
    //     temp = ' "to" : "' + to + '", ';
    //     temp = temp + ' "subject" : "' + subject + '", ';
    //     temp = temp + ' "body" : "' + mailcontent + '" ';
    //     data = data + temp + "}";

    //     var url = OBJECT_SERVER_DOMAIN + '/api/send/custom/mail?auth='+ AUTH_ENABLED;
    //     var postdata = encodeURIComponent(data);
    //     var status = Platform.makeHttpPostCall(url, postdata);
    //     status = $.parseJSON(status);
    //     if (status.status == 200) {
            return true;
    //     }
    //     else if (status.status == 400) {
    //         return "error while sending mail";
    //     }
    //     else if (status.status == 403) {
    //         return "Not Authorized to access callback.";
    //     }
    //     else {
    //         return INTERNET_ERROR;
    //     }
    // }else{
    //     return INTERNET_ERROR;
    // }
}
MobileUserService.prototype.getBlockUserDetails = function (callback) {
    var url = BLOCK_USER_JSON_URL;
    try {
        onGetBlockUserDetails = function (response) {
            var result = '';
            var status = response['status'];
            if (status == 200) {
                result = $.parseJSON(response["data"]);
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onGetBlockUserDetails");
        } else {
            var response = Platform.makeHttpGetCall(url);
            return onGetBlockUserDetails($.parseJSON(response));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};
////////////////////////////////////////////////////////////////////////////////
//                             BOOTSTRAP METHODS                              //
////////////////////////////////////////////////////////////////////////////////


function BootstrapService() {};

BootstrapService.prototype.downloadAllGroups = function(){

    var groups = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_GROUPS + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET);
    try {
        groups = $.parseJSON(groups);
        var status = groups['status'];
        if (status == 200) {
            var data = $.parseJSON(groups['data']);
            for (var i = 0; i < data.length; i++) {
                if(typeof LIMIT_BOOTSTRAP != "undefined" && LIMIT_BOOTSTRAP){
                    delete data[i].description;
                    delete data[i].parent;
                }
                Platform.saveObject(OBJECT_GROUP, data[i].nid, "content", JSON.stringify(data[i]));
                //Platform.logMessage("Downloading group = " + " " + data[i].nid + " " + data[i].question);
            }
        }
    }
    catch (e) {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_GROUPS + '?uid=' + OBJECT_USER_ID
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    };
};

BootstrapService.prototype.downloadAllLanguages = function(){

    var groups = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getalllanguages?uid=' + OBJECT_USER_ID);
    try {
        groups = $.parseJSON(groups);
        var status = groups['status'];
        if (status == 200) {
            var data = $.parseJSON(groups['data']);
            for (var i = 0; i < data.length; i++) {
                Platform.saveObject("lang", data[i].tid, "content", JSON.stringify(data[i]));
            }
        }
    }
    catch (e) {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getalllanguages?uid=' + OBJECT_USER_ID
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    };
};

BootstrapService.prototype.downloadAllCategories = function (groupid) {

    var contentUtilsService = CONTROLLER.getService("ContentUtils");

    Client.showMessage("Initializing content");

    if(typeof groupid != "undefined" && groupid != ""){
        var group = Platform.getObjectProperty(OBJECT_GROUP,groupid,"content");
        group = $.parseJSON(group);
        var grouptitle = group.title;
    }
    else if (typeof CLIENT_GROUP != "undefined") {
        grouptitle = CLIENT_GROUP;
    }
    else{
        grouptitle = "";
    }
    if(Platform.getNetworkState() == true){

        var ts = new Date().getTime();


        //Make syncronous call to download the category meta data
        var url = OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle) + '&t=' + ts;
        var categories = Platform.makeHttpGetCall(url);
        try {
            if (!$.isEmptyObject(categories)) {
                categories = $.parseJSON(categories);
                var status = categories['status'];
                if(status == 103){
                    categories = this.retry(url);
                }
                if (status == 200 || categories != false) {
                    var data = $.parseJSON(categories['data']);
                    //download each cateogry
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].nid != null) {
                            //Platform.logMessage("Downloading " + OBJECT_CATEGORY + " " + data[i].nid + " " + data[i].title);
                            if(typeof LIMIT_BOOTSTRAP != "undefined" && LIMIT_BOOTSTRAP){
                                delete data[i].description;
                                delete data[i].external_id;
                            }
                            Platform.saveObject(OBJECT_CATEGORY, data[i].nid, "content", JSON.stringify(data[i]));
                            if(typeof groupid != "undefined" && groupid != ""){
                                Platform.addRelation(OBJECT_GROUP, groupid, OBJECT_CATEGORY, data[i].nid, "");
                            }
                            if(typeof BS_DOWNLOAD_IMAGES == "undefined" || (typeof BS_DOWNLOAD_IMAGES != "undefined" && BS_DOWNLOAD_IMAGES != "true") ){
                                //contentUtilsService.downloadImage(data[i].nid, data[i].thumbnail_filename, OBJECT_PRESET, BOOTSTRAP_IMAGES_SYNC);
                            }

                            //download all respective courses for the category
                            this.downloadCoursesForCategory(data[i].nid,group);
                        }
                    }
                }
                else {
                    console.log("error status" + status);
                    this.setBootstrapStatus("failed",group,status);
                }
            } else {
                console.log("Empty JSON data");
                status = "Empty JSON data url :- " + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle) + '&t=' + ts;
                this.setBootstrapStatus("failed",group,status);
            }
        }
        catch (e) {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle)
            + "\n error_code :" + status
            + "\n response_data :" + data
            + "\n message :" + e.message;
            console.log(error);
            this.setBootstrapStatus("failed",group,status);
        };
    }
    else {
        this.setBootstrapStatus("failed",group,status);
    }
};

BootstrapService.prototype.retry = function(url){
    try{
        var data = "";
        var THIS = this;
        console.log("retrying for url - " + url);
        for(var i=0; i<=100; i++){
            sleep(5000);
            data = Platform.makeHttpGetCall(url);
            if (!$.isEmptyObject(data)) {
                data = $.parseJSON(data);
                var status = data['status'];
            }
            console.log(status);
            if(status == 200){
                return data;
            }
            if(i == 100){
                if(confirm('Unable to continue after multiple attempts to connect, do you want to retry again?') == true){
                    i = 0;
                }
            }
        }
        return false;
    }
    catch(e){
        console.log("retry failed for url - " + url);
    }
}

BootstrapService.prototype.downloadCoursesForCategory = function (catid, group) {

    var contentUtilsService = CONTROLLER.getService("ContentUtils");

    var category = Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content");
    category = $.parseJSON(category);
    if (typeof group != "undefined" && group != "") {
        var grouptitle = group.title;
    }
    else if (typeof CLIENT_GROUP != "undefined") {
        grouptitle = CLIENT_GROUP;
    }
    else {
        grouptitle = "";
    }
    if(Platform.getNetworkState() == true){

        var ts = new Date().getTime();
        var url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_COURSE + 'sfor' + OBJECT_CATEGORY + '?nid=' + category.tid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle) + '&t=' + ts;
        var coursesforcategory = Platform.makeHttpGetCall(url);
        try {
            if (!$.isEmptyObject(coursesforcategory)) {
                coursesforcategory = $.parseJSON(coursesforcategory);
                var status = coursesforcategory['status'];
                if(status == 103){
                    coursesforcategory = this.retry(url);
                }
                if (status == 200 || coursesforcategory != false) {
                    var data = $.parseJSON(coursesforcategory['data']);

                    //download each course
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].nid != null) {
                            if(typeof LIMIT_BOOTSTRAP != "undefined" && LIMIT_BOOTSTRAP){
                                delete data[i].description;
                                delete data[i].summary;
                                delete data[i].expiry;
                                delete data[i].year;
                                delete data[i].month;
                                delete data[i].day;
                                delete data[i].external_id;
                                delete data[i].external_name;
                            }
                            Platform.saveObject(OBJECT_COURSE, data[i].nid, "content", JSON.stringify(data[i]));
                            Platform.addRelation(OBJECT_CATEGORY, catid, OBJECT_COURSE, data[i].nid, "");
                            if (typeof group != "undefined" && group != "") {
                                Platform.addRelation(OBJECT_GROUP, group.nid, OBJECT_COURSE, data[i].nid,(i+1));
                            }
//
                            if(typeof BS_DOWNLOAD_IMAGES == "undefined" || (typeof BS_DOWNLOAD_IMAGES != "undefined" && BS_DOWNLOAD_IMAGES != "true") ){
                                //contentUtilsService.downloadImage(data[i].nid, data[i].thumbnail_filename, OBJECT_PRESET, BOOTSTRAP_IMAGES_SYNC);
                            }

                            //download all respective chapters for the course
                            this.downloadChaptersForCourse(data[i].nid,group);
                            this.downloadEbooksForCourse(data[i].nid);
                                if(typeof BS_QUIZ_DISABLE == "undefined" || (typeof BS_QUIZ_DISABLE != "undefined" && BS_QUIZ_DISABLE != "true") ){
                                    this.downloadQuizForCourse(data[i],group,category);
                                }
                            }
                        }
                    }
                else {
                    console.log("url = " + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_COURSE + 'sfor' + OBJECT_CATEGORY + '?nid=' + category.tid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle) + "error status" + status);
                    this.setBootstrapStatus("failed",group,status);
                }
            } else {
                console.log("url = " + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_COURSE + 'sfor' + OBJECT_CATEGORY + '?nid=' + category.tid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle) + " Empty JSON data");
                status = "Empty JSON data url = " + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_COURSE + 'sfor' + OBJECT_CATEGORY + '?nid=' + category.tid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle) + " Empty JSON data"
                this.setBootstrapStatus("failed",group,status);
            }
        }
        catch (e) {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_COURSE + 'sfor' + OBJECT_CATEGORY + '?nid=' + catid + '&uid=' + OBJECT_USER_ID
                    + "\n error_code :" + status
                    + "\n response_data :" + data
                    + "\n message :" + e.message;
            console.log(error);
            this.setBootstrapStatus("failed",group,status);
        };
    }
    else {
        this.setBootstrapStatus("failed",group,status);
    }

};

BootstrapService.prototype.downloadEbooksForCourse = function (courseid) {

    var contentUtilsService = CONTROLLER.getService("ContentUtils");

    if(Platform.getNetworkState() == true){

        //Platform.logMessage("Downloading " + OBJECT_BOOK);

        var ts = new Date().getTime();

        //Make syncronous call to download the chapter meta data
        var url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_BOOK + 'sof' + OBJECT_COURSE + '?nid=' + courseid + '&uid=' + OBJECT_USER_ID + '&t=' + ts;
        var booksforcourse = Platform.makeHttpGetCall(url);
        try {
            if (!$.isEmptyObject(booksforcourse)) {
                booksforcourse = $.parseJSON(booksforcourse);
                var status = booksforcourse['status'];
                if(status == 103){
                    booksforcourse = this.retry(url);
                }
                if (status == 200 || booksforcourse != false) {

                    var data = $.parseJSON(booksforcourse['data']);

                    for (var i = 0; i < data.length; i++) {
                        if (data[i].nid != null) {
                            //Platform.logMessage("Downloading " + OBJECT_BOOK + " " + data[i].nid + " " + data[i].title);
                            if(typeof LIMIT_BOOTSTRAP != "undefined" && LIMIT_BOOTSTRAP){
                                delete data[i].expiry;
                                delete data[i].description;
                                delete data[i].viewcount;
                                delete data[i].image_small;
                                delete data[i].image_medium;
                                delete data[i].image_large;
                                delete data[i].external_id;
                                delete data[i].external_name;
                                delete data[i].language;
                                delete data[i].rank;
                            }
                            Platform.saveObject(OBJECT_BOOK, data[i].nid, "content", JSON.stringify(data[i]));
                            Platform.addRelation(OBJECT_COURSE, courseid, OBJECT_BOOK, data[i].nid,(i+1));
                            if(typeof data[i].group != "undefined" && data[i].group != ""){
                                for(var k=0;k<data[i].group.length;k++){
                                    Platform.addRelation(OBJECT_GROUP, data[i].group[k], OBJECT_BOOK, data[i].nid, "");
                                }
                            }
                            if(typeof BS_DOWNLOAD_IMAGES == "undefined" || (typeof BS_DOWNLOAD_IMAGES != "undefined" && BS_DOWNLOAD_IMAGES != "true") ){
                                //contentUtilsService.downloadImage(data[i].nid, data[i].thumbnail_filename, OBJECT_PRESET, BOOTSTRAP_IMAGES_SYNC);
                            }

                        }
                    }
                }
                else {
                    console.log("error status" + status);
                    this.setBootstrapStatus("failed","",status);
                }
            } else {
                console.log("Empty JSON "+OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_BOOK + 'sfor' + OBJECT_COURSE + '?nid=' + courseid + '&uid=' + OBJECT_USER_ID + '&t=' + ts + "Empty JSON data");
                this.setBootstrapStatus("failed","",status);
            }
        }
        catch (e) {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_BOOK + 'sfor' + OBJECT_COURSE + '?nid=' + courseid + '&uid=' + OBJECT_USER_ID + '&t=' + ts
            + "\n error_code :" + status
            + "\n response_data :" + data
            + "\n message :" + e.message;
            console.log(error);
            this.setBootstrapStatus("failed","",status);
        };
    }
    else {
        this.setBootstrapStatus("failed","",status);
    }

};

//Bootstrap method

BootstrapService.prototype.downloadChaptersForCourse = function (courseid, group) {


    var contentUtilsService = CONTROLLER.getService("ContentUtils");

    if(Platform.getNetworkState() == true){


        var ts = new Date().getTime();

        //Make syncronous call to download the chapter meta data
        var url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'sfor' + OBJECT_COURSE + '?nid=' + courseid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&t=' + ts;
        var chaptersforcourse = Platform.makeHttpGetCall(url);
        try {
            if (!$.isEmptyObject(chaptersforcourse)) {
                chaptersforcourse = $.parseJSON(chaptersforcourse);
                var status = chaptersforcourse['status'];
                if(status == 103){
                    chaptersforcourse = this.retry(url);
                }
                if (status == 200 || chaptersforcourse != false) {

                    var data = $.parseJSON(chaptersforcourse['data']);

                    //download each chapters
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].nid != null) {
                            if(typeof LIMIT_BOOTSTRAP != "undefined" && LIMIT_BOOTSTRAP){
                                delete data[i].description;
                                delete data[i].summary;
                                delete data[i].expiry;
                                delete data[i].year;
                                delete data[i].month;
                                delete data[i].day;
                                delete data[i].external_id;
                                delete data[i].external_name;
                            }
                            Platform.saveObject(OBJECT_CHAPTER, data[i].nid, "content", JSON.stringify(data[i]));
                            Platform.addRelation(OBJECT_COURSE, courseid, OBJECT_CHAPTER, data[i].nid,(i+1));
                            if(typeof data[i].group != "undefined" && data[i].group != ""){
                                for(var k=0;k<data[i].group.length;k++){
                                    Platform.addRelation(OBJECT_GROUP, data[i].group[k], OBJECT_CHAPTER, data[i].nid, "");
                                }
                            }
                            if(typeof BS_DOWNLOAD_IMAGES == "undefined" || (typeof BS_DOWNLOAD_IMAGES != "undefined" && BS_DOWNLOAD_IMAGES != "true") ){
                                //contentUtilsService.downloadImage(data[i].nid, data[i].thumbnail_filename, OBJECT_PRESET, BOOTSTRAP_IMAGES_SYNC);
                            }

                            //download all respective resources for the chapter
                            this.downloadResourcesForChapter(data[i].nid,courseid,group);
                            this.downloadSupportDocsForChapter(data[i].nid,courseid,group);
                        }
                    }
                }
                else {
                    console.log("error status" + status);
                    this.setBootstrapStatus("failed",group,status);
                }
            } else {
                console.log("Empty JSON "+OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'sfor' + OBJECT_COURSE + '?nid=' + courseid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&t=' + ts + "Empty JSON data");
                this.setBootstrapStatus("failed",group,status);
            }
        }
        catch (e) {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'sfor' + OBJECT_COURSE + '?nid=' + courseid + '&uid=' + OBJECT_USER_ID
            + "\n error_code :" + status
            + "\n response_data :" + data
            + "\n message :" + e.message;
            console.log(error);
            this.setBootstrapStatus("failed",group,status);
        };
    }
    else {
        this.setBootstrapStatus("failed",group,status);
    }

};

BootstrapService.prototype.downloadSupportDocsForChapter = function (chapterid, courseid, group) {

    var contentUtilsService = CONTROLLER.getService("ContentUtils");

    if (Platform.getNetworkState() == true) {


        var ts = new Date().getTime();

        //Make syncronous call to download the resource meta data
        var url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_SUPPORT_DOCS + '?nid=' + chapterid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&t=' + ts;
        var supportdocsforchapter = Platform.makeHttpGetCall(url);
        try {
            if (!$.isEmptyObject(supportdocsforchapter)) {
                supportdocsforchapter = $.parseJSON(supportdocsforchapter);
                var status = supportdocsforchapter['status'];
                if(status == 103){
                    supportdocsforchapter = this.retry(url);
                }
                if (status == 200 || supportdocsforchapter != false) {

                    var data = $.parseJSON(supportdocsforchapter['data']);
                    //download each resources
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].nid != null) {
                            if(typeof LIMIT_BOOTSTRAP != "undefined" && LIMIT_BOOTSTRAP){
                                delete data[i].description;
                                delete data[i].expiry;
                                delete data[i].image_small;
                                delete data[i].image_medium;
                                delete data[i].image_large;
                                delete data[i].external_id;
                                delete data[i].external_name;
                            }
                            //Platform.logMessage("Downloading " + OBJECT_SUPPORT_DOCS + " " + data[i].nid + " " + data[i].title);
                            Platform.saveObject(OBJECT_SUPPORT_DOCS, data[i].nid, "content", JSON.stringify(data[i]));
                            Platform.addRelation(OBJECT_CHAPTER, chapterid, OBJECT_SUPPORT_DOCS, data[i].nid, "");
                            if(typeof data[i].group != "undefined" && data[i].group != ""){
                                for(var k=0;k<data[i].group.length;k++){
                                    Platform.addRelation(OBJECT_GROUP, data[i].group[k], OBJECT_SUPPORT_DOCS, data[i].nid, "");
                                }
                            }
                             if (data[i].captions) {
                                var subtitles = data[i].captions;
                                for (var j = 0; j < subtitles.length; j++) {
                                    Platform.saveObject(CONTENT_SUBTITLE, subtitles[j].nid, "content", JSON.stringify(subtitles[j]));
                                    Platform.addRelation(OBJECT_VIDEO, data[i].nid, CONTENT_SUBTITLE, subtitles[j].nid, (j + 1));
                                }
                            }
                        }
                    }
                }
                else {
                    console.log("error status" + status);
                    this.setBootstrapStatus("failed",group,status);
                }
            }
        } catch (e) {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'sfor' + OBJECT_CHAPTER + '?nid=' + chapterid + '&uid=' + OBJECT_USER_ID
                    + "\n error_code :" + status
                    + "\n response_data :" + data
                    + "\n message :" + e.message;
            console.log(error);
            this.setBootstrapStatus("failed",group,status);
        };
    }
    else {
        this.setBootstrapStatus("failed",group,status);
    }

};

BootstrapService.prototype.downloadResourcesForChapter = function (chapterid, courseid, group) {

    var contentUtilsService = CONTROLLER.getService("ContentUtils");

    if (Platform.getNetworkState() == true) {


        var ts = new Date().getTime();

        //Make syncronous call to download the resource meta data
        var url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'sfor' + OBJECT_CHAPTER + '?nid=' + chapterid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&format='+RESOURCE_FORMAT+'&t=' + ts;
        var resourcesforchapter = Platform.makeHttpGetCall(url);
        try {
            if (!$.isEmptyObject(resourcesforchapter)) {
                resourcesforchapter = $.parseJSON(resourcesforchapter);
                var status = resourcesforchapter['status'];
                if(status == 103){
                    resourcesforchapter = this.retry(url);
                }
                if (status == 200 || resourcesforchapter != false) {

                    var data = $.parseJSON(resourcesforchapter['data']);
                    //download each resources
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].nid != null) {
                            if(typeof LIMIT_BOOTSTRAP != "undefined" && LIMIT_BOOTSTRAP){
                                delete data[i].description;
                                delete data[i].expiry;
                                delete data[i]["format-type"];
                                delete data[i]["stream-type"];
                                delete data[i].tags;
                                delete data[i].viewcount;
                                delete data[i].language;
                                delete data[i].image_small;
                                delete data[i].image_medium;
                                delete data[i].image_large;
                                delete data[i].external_id;
                                delete data[i].external_name;
                            }
                            //Platform.logMessage("Downloading " + OBJECT_VIDEO + " " + data[i].nid + " " + data[i].title);
                            //data[i].resourceurl = contentUtilsService.getResourcePathFromUrl(data[i].nid, data[i].filetype,courseid);
                            Platform.saveObject(OBJECT_VIDEO, data[i].nid, "content", JSON.stringify(data[i]));
                            //contentUtilsService.downloadResourcePosterImage(data[i].nid, OBJECT_PRESET, BOOTSTRAP_IMAGES_SYNC, data[i].thumbnail_id, data[i].thumbnail_filename, data[i].filetype);

                            Platform.addRelation(OBJECT_CHAPTER, chapterid, OBJECT_VIDEO, data[i].nid, "");
                            if(typeof VIDEO_INDEX_ENABLED != "undefined" && VIDEO_INDEX_ENABLED == "true"){
                                this.downloadIndexForResource(data[i].nid);
                            }
                            if(typeof data[i].group != "undefined" && data[i].group != ""){
                                for(var k=0;k<data[i].group.length;k++){
                                    Platform.addRelation(OBJECT_GROUP, data[i].group[k], OBJECT_VIDEO, data[i].nid, "");
                                }
                            }
                            if (data[i].captions) {
                                var subtitles = data[i].captions;
                                for (var j = 0; j < subtitles.length; j++) {
                                    Platform.saveObject(CONTENT_SUBTITLE, subtitles[j].nid, "content", JSON.stringify(subtitles[j]));
                                    Platform.addRelation(OBJECT_VIDEO, data[i].nid, CONTENT_SUBTITLE, subtitles[j].nid, (j + 1));
                                }
                            }
                        }
                    }
                }
                else {
                    console.log("error status" + status);
                    this.setBootstrapStatus("failed",group,status);
                }
            }
        } catch (e) {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'sfor' + OBJECT_CHAPTER + '?nid=' + chapterid + '&uid=' + OBJECT_USER_ID
                    + "\n error_code :" + status
                    + "\n response_data :" + data
                    + "\n message :" + e.message;
            console.log(error);
            this.setBootstrapStatus("failed",group,status);
        };
    }
    else {
        this.setBootstrapStatus("failed",group,status);
    }

};

BootstrapService.prototype.downloadIndexForResource = function (resourceid) {
    var contentUtilsService = CONTROLLER.getService("ContentUtils");

    if (Platform.getNetworkState() == true) {


        var ts = new Date().getTime();

        var url = OBJECT_SERVER_DOMAIN + '/api/get'+OBJECT_VIDEO_INDEX+'?nid='+resourceid+'&ts='+ts;
        var indexofresource = Platform.makeHttpGetCall(url);
        try {
            if (!$.isEmptyObject(indexofresource)) {
                indexofresource = $.parseJSON(indexofresource);
                var status = indexofresource['status'];
                if(status == 103) {
                    indexofresource = this.retry(url);
                }
                if (status == 200 || indexofresource != false)
                {

                    var data = $.parseJSON(indexofresource['data']);
                    //download each resources
                        if (data.length > 0) {
                            data.forEach(function(videoindex,i){
                                if(typeof LIMIT_BOOTSTRAP != "undefined" && LIMIT_BOOTSTRAP){
                                    delete videoindex.description;
                                }
                                Platform.saveObject(OBJECT_VIDEO_INDEX, videoindex.nid, "content", JSON.stringify(videoindex));
                                Platform.addRelation(OBJECT_VIDEO, resourceid, OBJECT_VIDEO_INDEX, videoindex.nid, "");
                            });

                        }
                }
                else
                {
                    console.log("error status" + status);
//                    this.setBootstrapStatus("failed",group,status);
                }
            }
        } catch (e)
        {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'sfor' + OBJECT_CHAPTER + '?nid=' + chapterid + '&uid=' + OBJECT_USER_ID
                    + "\n error_code :" + status
                    + "\n response_data :" + data
                    + "\n message :" + e.message;
            console.log(error);
            this.setBootstrapStatus("failed",group,status);
        };
    }
    else
    {
        this.setBootstrapStatus("failed",group,status);
    }

};

BootstrapService.prototype.setBootstrapStatus = function(status,group,statusmessage){
    try{
        Platform.setBootstrapStatus(status);
        if (typeof group != "undefined" && group != "" && !$.isEmptyObject(group)) {
            group.bootstrapStatus = status;
            Platform.saveObject(OBJECT_GROUP, group.nid, "content", JSON.stringify(group));
        }
    }
    catch(e){
        var error = "method = setBootstrapStatus"
                    + "\n message :" + e.message;
        console.log(error);
    }
}



BootstrapService.prototype.downloadAllQuiz = function(groups){
    var contentUtilsService = CONTROLLER.getService("ContentUtils");
    var url = OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_QUIZ + "?uid=" + OBJECT_USER_ID;
    var quiz = Platform.makeHttpGetCall(url);
    try {
        quiz = $.parseJSON(quiz);
        var status = quiz['status'];
        if(status == 103) {
            quiz = this.retry(url);
        }
        if (status == 200 || quiz != false) {
            var data = $.parseJSON(quiz['data']);
            for (var i = 0; i < data.length; i++) {
                Platform.saveObject(OBJECT_QUIZ, data[i].nid, "content", JSON.stringify(data[i]));
            }
        }
    }
    catch (e) {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_QUIZ + "?uid=" + OBJECT_USER_ID;
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    };
};




BootstrapService.prototype.downloadQuizForCourse = function (course, group, category) {
    var contentUtilsService = CONTROLLER.getService("ContentUtils");
    if(Platform.getNetworkState() == true){


        var ts = new Date().getTime();

        //Make syncronous call to download the chapter meta data
        var url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUIZ + 'of' + OBJECT_COURSE + '?nid=' + course.nid + '&t=' + ts;
        var quizforcourse = Platform.makeHttpGetCall(url);
        try {
            if (!$.isEmptyObject(quizforcourse)) {
                quizforcourse = $.parseJSON(quizforcourse);
                var status = quizforcourse['status'];
                    if(status == 103) {
                        quizforcourse = this.retry(url);
                    }
                if (status == 200 || quizforcourse != false) {

                   var data = $.parseJSON(quizforcourse['data']);
                   console.log(JSON.stringify(data))
                    //download each chapters
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].nid != null) {
                            if(typeof LIMIT_BOOTSTRAP != "undefined" && LIMIT_BOOTSTRAP){
                                delete data[i].description;
                                delete data[i].external_id;
                                delete data[i].external_name;
                            }

                            Platform.saveObject(OBJECT_QUIZ, data[i].nid, "content", JSON.stringify(data[i]));
                            Platform.addRelation(OBJECT_COURSE, course.nid, OBJECT_QUIZ, data[i].nid,(i+1));
                            if(typeof data[i].groups != "undefined" && data[i].groups != ""){
                                for(var k=0;k<data[i].groups.length;k++){
                                    Platform.addRelation(OBJECT_GROUP, data[i].groups[k], OBJECT_QUIZ, data[i].nid, "");
                                }
                            }
                            //Platform.logMessage(OBJECT_QUIZ+','+data[i].nid+','+data[i].title);

                            if(typeof BS_DOWNLOAD_IMAGES == "undefined" || (typeof BS_DOWNLOAD_IMAGES != "undefined" && BS_DOWNLOAD_IMAGES != "true") ){
                                //contentUtilsService.downloadImage(data[i].nid, data[i].thumbnail_filename, OBJECT_PRESET, BOOTSTRAP_IMAGES_SYNC);
                            }
                               this.downloadgetQuizQuestion(data[i].nid,course,group,category);

                        }
                    }
                }
                else {
                    console.log("error status" + status);
                    this.setBootstrapStatus("failed",group,status);
                }
            } else {
                console.log("Empty JSON data");
                this.setBootstrapStatus("failed",group,status);
            }
        }
        catch (e) {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'sfor' + OBJECT_COURSE + '?nid=' + course.nid + '&uid=' + OBJECT_USER_ID
            + "\n error_code :" + status
            + "\n response_data :" + data
            + "\n message :" + e.message;
            console.log(error);
            this.setBootstrapStatus("failed",group,status);
        };
    }
    else {
        this.setBootstrapStatus("failed",group,status);
    }

};

BootstrapService.prototype.downloadgetQuizQuestion = function (quiz_nid) {

    var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUESTION+'sfor'+OBJECT_QUIZ+'?nid='+quiz_nid);
    try {
        quiz = $.parseJSON(quiz);
        var status = quiz['status'];
        if (status == 200) {
            Platform.saveDataToFileSystem("video/"+quiz_nid+"/"+quiz_nid+".quiz",quiz['data']);
        }
    }
    catch (e) {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUESTION+'for'+OBJECT_QUIZ+'?nid='+nid
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    };

}

BootstrapService.prototype.getQuestionsForQuiz = function (quiz_nid) {
    //api/getchaptersforcourse
    try{
        var questions = Platform.getRelatedObjects(OBJECT_QUIZ, quiz_nid, OBJECT_QUESTION);
        questions = $.parseJSON(questions);
        return questions;
    }
    catch(e){
        console.log( "function : getQuestionsForQuiz"
            + "\n message:" + e.message);
    }
};

BootstrapService.prototype.downloadAllTestCollections = function (groupid) {
    try {
        var url = OBJECT_SERVER_DOMAIN + '/api/getalltest_collection?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID + '&groups=' + groupid;
        var test_collection = Platform.makeHttpGetCall(url);
        test_collection = $.parseJSON(test_collection);
        var status = test_collection['status'];
        if (status == 200) {
            if (test_collection["data"] != "") {
                var data = $.parseJSON(test_collection["data"]);
                for (var i = 0; i < data.length; i++) {
                    if (typeof LIMIT_BOOTSTRAP != "undefined" && LIMIT_BOOTSTRAP) {
                        delete data[i].body;
                    }
                    if (typeof data[i].available_offline != 'undefined' && data[i].available_offline == '1') {
                        Platform.saveObject(OBJECT_TEST_COLLECTION, data[i].nid, "content", JSON.stringify(data[i]));
                        Platform.addRelation(OBJECT_GROUP, groupid, OBJECT_TEST_COLLECTION, data[i].nid, "");
                        this.downloadAllTestCollectionQuizes(data[i].nid, groupid);
                    }
                }
            }
        } else {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getalltest_collection?uid=' + OBJECT_USER_ID + '&groups=' + groupid
                + "\n error_code :" + status
                + "\n message :" + e.message;
            console.log(error);
        }
    } catch (e) {
        console.log("function : downloadAllTestCollections"
            + "\n message:" + e.message);
    }
}

BootstrapService.prototype.downloadAllTestCollectionQuizes = function (tc_id, groupid) {
    this.downloadQuizzesOfState('active', tc_id, groupid);
    this.downloadQuizzesOfState('upcoming', tc_id, groupid);
    this.downloadQuizzesOfState('attempted', tc_id, groupid);
    this.downloadQuizzesOfState('missed', tc_id, groupid);
};

BootstrapService.prototype.downloadQuizzesOfState = function (state, tc_id, groupid) {
    try {
        var limit = TESTSERIES_QUIZZES_LIMIT;
        if (typeof limit != "undefined") {
            limit = "&limit=" + limit;
        } else {
            limit = "";
        }
        var lastData = "";
        var quizDownloadComplete = true;
        var pageNumber = 0;
        while (quizDownloadComplete) {
            var page = "&page=" + pageNumber;
            var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/' + state + OBJECT_QUIZ + '?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID + "&nid=" + tc_id + "&groups=" + groupid + page + limit;

            var quizData = Platform.makeHttpGetCall(url);
            quizData = $.parseJSON(quizData);
            var status = quizData['status'];
            if (status == 200) {
                var newQuizData = $.parseJSON(quizData["data"]);
                if (typeof newQuizData != 'undefined' && newQuizData != null && newQuizData.length == 0) {
                    quizDownloadComplete = false;
                } else {
                    if (newQuizData.length > 0 && lastData != newQuizData[newQuizData.length - 1].nid) {
                        for (var i = 0; i < newQuizData.length; i++) {
                            if (newQuizData[i].nid != null) {
                                if (typeof LIMIT_BOOTSTRAP != "undefined" && LIMIT_BOOTSTRAP) {
                                    delete newQuizData[i].external_id;
                                    delete newQuizData[i].external_name;
                                }
                                Platform.saveObject(OBJECT_QUIZ, newQuizData[i].nid, "content", JSON.stringify(newQuizData[i]));
                                Platform.addRelation(OBJECT_GROUP, groupid, OBJECT_QUIZ, newQuizData[i].nid, "");
                                Platform.addOrUpdateTestSeries(JSON.stringify(newQuizData[i]), tc_id);
                            }
                        }
                        lastData = newQuizData[newQuizData.length - 1].nid;
                        pageNumber = pageNumber + 1;
                    } else {
                        quizDownloadComplete = false;
                    }
                }
            } else {
                quizDownloadComplete = false;
                var error = "url :" + url
                + "\n error_code :" + status
                + "\n message :" + e.message;
                console.log(error);
            }
        }
    } catch (e) {
        var error = "downloadQuizesOfState - state :" + state
            + "\n message :" + e.message;
        console.log(error);
    }
};
function UtilsService(){};

UtilsService.prototype.getDataFromExternalFileSystem = function(url,callback){
//    var data = Platform.makeHttpGetCall(url);
    var result = "";
    try {
        $.ajax({
            url: url,
            dataType : 'text',
            type:'GET',
            async: true,
            crossDomain: true,
            contentType: 'text/plain',
            success: callback,
            error: function(xhr, status, error) {
                var err = eval("(" + xhr.responseText + ")");
                console.log(err.Message);
            }
        });
    }
    catch (e)
    {
        var error = "url :" + url
        + "\n message :" + e.message;
        console.log(error);
    };
}

function replaceAll(find, replace, str) {
  return str.replace(new RegExp(find, 'g'), replace);
}
function MobileResourceService(){};

MobileResourceService.prototype.getResourceById = function(resourceid)
{
    try{
        var resource = Platform.getObjectProperty(OBJECT_VIDEO, resourceid, "content");
        resource = $.parseJSON(resource);
        return resource;
    }
    catch(e){
        console.log( "function : getResourceById"
            + "\n message:" + e.message);
    }
};

MobileResourceService.prototype.downloadResourceAsync = function(resourceid, youtube_id, operation, downloadmessage, downloadExpiry, download_complete_url,courseid,type,categoryid)
{
    /*  get resource type
    *  get sdcard path
    * build download path
    * downLoadFile(URL,path);
    */

    try{
        if(type){
            var resource = Platform.getObjectProperty(type,resourceid,"content");
        }
        else{
            resource = Platform.getObjectProperty(OBJECT_VIDEO,resourceid,"content");
        }
        resource = $.parseJSON(resource);
        var filepath = getResourceLocalPath(resource);

        var format = "";
        var categoryparam = "";
        var courseparam = "";
        var downloadapi = "",downloadstreamtype = "";
        var lang=Platform.getPreference("lang");
        if(!$.isEmptyObject(resource)){
            if (typeof ENABLE_LANG_SUPPORT != "undefined" && ENABLE_LANG_SUPPORT == "true") {
                    if (!$.isEmptyObject(resource.language)) {
                        if (jQuery.inArray(lang, resource.language) >= 0) {
                        } else {
                            return false;
                        }
                    }
            }
            if( typeof resource.filetype != 'undefined' &&  resource.filetype != null && (resource.filetype).toLowerCase() == 'pdf'){
                format = '&format=' + DOCUMENT_FORMAT;
            }
            else{
                format = '&format=' + RESOURCE_FORMAT;
            }
            if(DOCUMENT_FORMAT == "original"){
            if( typeof resource.filetype != 'undefined' && resource.filetype != null &&(resource.filetype).toLowerCase() == 'pdf' && DOCUMENT_TRANSCODE == true){
                downloadapi = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO + "/zip";
            }
            else{
                downloadapi = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO;
            }
            }else{
                    downloadapi = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO;
            }
            //online redirect api call for type video
            if(typeof courseid != 'undefined' && courseid != ""){
                courseparam = "&courseid="+courseid;
            }
            else{
                courseparam = "";
            }
            if (typeof categoryid != 'undefined' && categoryid != ""){
                var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, categoryid, "content"));
                categoryparam = "&cat_id="+category.tid;
            } else {
                categoryparam = "";
            }
            if(typeof DOWNLOAD_VIDEO_TYPE != "undefined" && resource.type == "Video"){
                downloadstreamtype = "&stream="+DOWNLOAD_VIDEO_TYPE;
            }
			else if(typeof DOWNLOAD_STREAM != "undefined" && resource.type == "Video") {
                downloadstreamtype = "&stream="+DOWNLOAD_STREAM;
            }
            else if(resource.type == "Video"){
                downloadstreamtype = "&stream=mp4";
            }
            else{
                downloadstreamtype = "";
            }
            if(operation == 'modified'){
                var downloadurl = downloadapi + '?nid=' + resourceid + '&redirect=true'+ format +'&youtubeid=' + youtube_id + '&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED + '&download=true' + courseparam + categoryparam + downloadstreamtype;
            } else {
                downloadurl = downloadapi + '?nid=' + resourceid + '&redirect=true'+ format + '&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED + '&download=true' + courseparam + categoryparam + downloadstreamtype;
            }
            if(typeof CONTENT_EXPIRY_HOURS != "undefined" && CONTENT_EXPIRY_HOURS != null && CONTENT_EXPIRY_HOURS != ""){
                downloadExpiry = CONTENT_EXPIRY_HOURS;
            }
            if (resource.type == PRACTICE || resource.type == CERTIFICATE || resource.type == OBJECT_QUIZ || resource.type == RESOURCE_TYPE_ASSESSMENT) {
                format = "";
                downloadExpiry="";
                downloadurl = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_QUIZ + '?quizid=' + resourceid + "&auth=" + AUTH_ENABLED;
            }
            if(typeof DOWNLOAD_VIDEO_TYPE != "undefined" && resource.type == "Video"){
                filepath = filepath.substring(0, filepath.length-3);
                filepath = filepath + DOWNLOAD_VIDEO_TYPE;
            }

            if (downloadmessage != "") {
                downloadmessage = resource.title + " downloaded successfully";
            }

            var paramsJson = {'objectid':resourceid,'objecttype':'resource',
            'serverurl':downloadurl,'localurl':filepath, 'message':downloadmessage,
            'sync':FILE_DOWNLOAD_ASYNC, 'expiryduration':downloadExpiry, 'courseid' : courseid, 'categoryid' : categoryid, 'groupid' : CONTROLLER.getVariable("activegroupid"),
            'onComplete':download_complete_url, 'youtubeid':youtube_id,'format':RESOURCE_FORMAT,'type':'content'};
            Platform.downloadFile(JSON.stringify(paramsJson));
        }
    }
    catch(e){
        console.log( "function : downloadResourceAsync"
            + "\n url:" + downloadurl
            + "\n message" + e.message);
    }
};

MobileResourceService.prototype.getResourceUrlFromResourceData = function(nid,type,chapterid,courseid,categoryid)
{
    var cat_tid = '';
    var categoryparam = "";
    var courseparam = "";
    var chapterparam = "";
    if(typeof type != 'undefined' && type != null && type.toLowerCase() == 'pdf'){
        var format = '&format=original';
    }else if(typeof RESOURCE_TYPE_TEST_COLLECTION != "undefined" && type == RESOURCE_TYPE_TEST_COLLECTION){
        type=RESOURCE_TYPE_TEST_COLLECTION
        format = "";
    }
    else{
        format = '&format=' + RESOURCE_FORMAT;
    }
    if(typeof chapterid != 'undefined' && chapterid != ''){
        chapterparam = "&topicid="+chapterid;
    } else {
        chapterparam = '';
    }
    if(typeof courseid != 'undefined' && courseid != ""){
        courseparam = "&courseid="+courseid;
    }
    else{
        courseparam = "";
    }
    if (typeof categoryid != 'undefined' && categoryid != ""){
        var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, categoryid, "content"));
        categoryparam = "&cat_id="+category.tid;
    } else {
        categoryparam = "";
    }
    var resource = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO + '?nid=' + nid + format + chapterparam + courseparam + categoryparam +'&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED);
    resource = $.parseJSON(resource);
    var status = resource['status'];
    if (status == 200)
    {
        var resourcedata = resource['data'];
        resourcedata = $.parseJSON(resourcedata);
        var resourceurl = resourcedata[0].videourl;
        if(typeof type != 'undefined' && type != null && type.toLowerCase() == 'pdf'){
            resourceurl = resourceurl["original"];
        }else if(typeof RESOURCE_TYPE_TEST_COLLECTION != "undefined" && type == RESOURCE_TYPE_TEST_COLLECTION){
            type=RESOURCE_TYPE_TEST_COLLECTION
            resourceurl = resourcedata[0];
        }
        else{
            resourceurl = resourceurl[RESOURCE_FORMAT];
        }
        return resourceurl;

    }
    else {
        return "error";
    }
}

MobileResourceService.prototype.getBreaksDataForResource = function(resourceid)
{
    if(Platform.getNetworkState() == true){
        var breaks = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getclippingbreaks?clip_id=' + resourceid + '&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED);
        try {
            breaks = $.parseJSON(breaks);
            var status = breaks['status'];
            if (status == 200)
            {
                var data = $.parseJSON(breaks['data']);
                return data;
            }
            else{
                return "error";
            }
        }
        catch (e)
        {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_GROUPS + '?uid=' + OBJECT_USER_ID
            + "\n error_code :" + status
            + "\n response_data :" + data
            + "\n message :" + e.message;
            console.log(error);
        };
    }
    else{
        return "error";
    }
};

function getResourceLocalPath(resource){
    try
    {
        var filepath = "/video";

        if (resource.type == PRACTICE || resource.type == CERTIFICATE || resource.type == OBJECT_QUIZ || resource.type == RESOURCE_TYPE_ASSESSMENT) {
            filepath = "video/" + resource.nid + "/" + resource.nid + '_' + resource.changed + ".zip";
            return filepath;
        }

        if(resource.type == RESOURCE_TYPE_HTML || resource.type.toLowerCase().indexOf('scorm') >= 0){
            filepath = "video/" + resource.nid + "/content.zip";
            return filepath;
        }

        if(resource.resourceurl && resource.filetype != "html" && resource.url.indexOf("sites/default") > -1){
            var resourceurl = resource.resourceurl;
            resourceurl = resourceurl.split('?');
            resourceurl = resourceurl[0];
            resourceurl = resourceurl.split("/").slice(3).join("/");
        }
        else{
            if(resource.url.indexOf("sites/default") > -1){
                if (resource.url.indexOf("sites/default/files") > -1) {
                    resourceurl = resource.url.split("sites/default/files/");
                    resourceurl = resourceurl[1];
                    if (resource.filetype == 'mp3' || resource.type == 'Video') {
                        resourceurl = resourceurl + ".mp4";
                    }
                    else if (resource.filetype.toLowerCase() == 'pdf') {
                        resourceurl = resourceurl + ".zip";
                    }
                }else  if(resource.url.indexOf("sites/default/videos") > -1){
                     resourceurl =  resource.url.split("sites/default/videos/");
                    resourceurl= resourceurl[1];
                     if (resource.filetype == 'mp3' || resource.type == 'Video') {
                        resourceurl = resourceurl + ".mp4";
                    }
                    else if (resource.filetype.toLowerCase() == 'pdf') {
                        resourceurl = resourceurl + ".zip";
                    }
                }
            }
            else if(resource.url.indexOf("sites") > -1){
                resourceurl = resource.url.split("/").slice(3).join("/");
                if (resource.filetype == 'mp3' || resource.type == 'Video') {
                    resourceurl = resourceurl + ".mp4";
                }
                else if (resource.filetype.toLowerCase() == 'pdf') {
                    resourceurl = resourceurl + ".zip";
                }
            } else {
                resourceurl = resource.url;
            }
        }
        if (resource.filetype == 'mp3' || resource.type == 'Video') {
            filepath = filepath + "/" + resourceurl;
            if(DOWNLOAD_STREAM == "m3u8"){
                filepath = filepath.replace(".mp4",".m3u8");
            }

            //Replace format of download from settings
            var temp = filepath.split(".");
            var newString = temp[0].substring(0, temp[0].length-3);
            newString = newString + RESOURCE_FORMAT;
            filepath = newString + "." + temp[1];
            if(typeof ENCRYPTION_ENABLED != "undefined" && ENCRYPTION_ENABLED != "" && ENCRYPTION_ENABLED == "true"){
                generateEncryptedPath(filepath);
            }
        } else if (resource.filetype.toLowerCase() == 'pdf') {
             if(DOCUMENT_FORMAT == "native"){
                filepath = filepath + "/" + resource.nid + "/" + resource.youtube_id + ".pdf";
            }
            else{
            resourceurl = resourceurl.replace("pdf","zip");
            resourceurl = resourceurl.replace("html","zip");
            filepath = filepath + "/" + resourceurl;
        }
        }
        return filepath;
    }
    catch(e){
        console.log( "function : getResourceLocalPath"
            + "\n message" + e.message);
    }
};

function generateEncryptedPath(filepath){
    if(DOWNLOAD_STREAM == "m3u8"){
        filepath = filepath.replace('.m3u8','.em3u8');
    }
    else{
        filepath = filepath.replace('.mp4','.emp4');
    }
    return filepath;
}

function getResourceImagePath(resourceid){
    try
    {
        var resourceimagepath = "/video/" + resourceid + "/" + POSTER_IMAGE_NAME + ".jpg";
        return resourceimagepath;
    }
    catch(e){
        console.log( "function : getResourceImagePath"
            + "\n message:" + e.message);
    }
}

MobileResourceService.prototype.getIndexOfresource = function(resourceid){
    try{
        var index = Platform.getRelatedObjects(OBJECT_VIDEO, resourceid, OBJECT_VIDEO_INDEX);
        index = $.parseJSON(index);
        return index;
    }
    catch(e){
        console.log("function : getIndexOfresource, message: " + e.message);
    }

}


MobileResourceService.prototype.downloadSingleResourceMeta = function(resourceid,changed){
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'fromid?nid=' + resourceid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&changed=' + changed + "&version=" + API_VERSION, "resourcecontent-"+resourceid);
    }
    catch(e){
        console.log("function : downloadSingleResourceMeta"
            + " message" + e.message);
    }
};

MobileResourceService.prototype.deleteResource = function(resourceid){
    try{
        var resource = Platform.getObjectProperty(OBJECT_VIDEO,resourceid,"content");
        if(resource != ""){
            resource = $.parseJSON(resource);
        }
        if(!$.isEmptyObject(resource)){
            var resourcefilepath = getResourceLocalPath(resource);
            var resourcefileAvailable = Platform.isContentAvailableInFilesTable(resourceid,resource.youtube_id,RESOURCE_FORMAT);
            if(resourcefileAvailable == true && resourcefilepath != OBJECT_DATA_FOLDER && resourcefilepath != OBJECT_RESOURCE_FOLDER){
                Platform.deleteFileFromDocRoot(resourcefilepath);
            }
            var resourceimagepath = getResourceImagePath(resourceid);
            var resourceimagefileAvailable = Platform.isContentPosterAvailableInFilesTable(resourceid,"","");
            if(resourceimagefileAvailable == true && resourceimagepath != OBJECT_DATA_FOLDER && resourcefilepath != OBJECT_RESOURCE_FOLDER){
                Platform.deleteFileFromDocRoot(resourceimagepath);
            }
        }
    }
    catch(e){
        console.log("function : deleteResource"
            + " message" + e.message);
    }
};

MobileResourceService.prototype.downloadResourcesForChapterAsync = function(chapterid){
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'sfor' + OBJECT_CHAPTER + '?nid=' + chapterid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET, OBJECT_VIDEO+"-"+chapterid);
    }
    catch(e){
        console.log("function : downloadResourcesForChapterAsync"
            + " message" + e.message);
    }
};

MobileResourceService.prototype.getAllResource = function()
{
    try{
        //call  getObjects(OBJECT_CATEGORY)
        //api/getallcategories
        var categories = Platform.getObjectsOfType(OBJECT_VIDEO);
        return $.parseJSON(categories);
    }
    catch(e){
        console.log( "function : getAllResource"
            + "\n message:" + e.message);
    }

};

MobileResourceService.prototype.getResourceIdFromExternalId = function(externalid)
{
    try{
        //call  getObjects(OBJECT_CATEGORY)
        //api/getallcategories
        var mapping = Platform.getObjectProperty("resourcemapping", externalid, "content");
        if(mapping != "" && mapping != null){
            mapping = $.parseJSON(mapping);
            if(!$.isEmptyObject(mapping)){
                return mapping.nid;
            }
            else{
                return "";
            }
        }
        else
        {
            return "";
        }
    }
    catch(e){
        console.log( "function : getResourceIdFromExternalId"
            + "\n message:" + e.message);
    }

};

MobileResourceService.prototype.getResourcePath = function(resourceid,courseid,catid,type, resource)
{
    /*
*  getObjectProperty(OBJECT_VIDEO,resourceid,"content");
*  From JSON find the type
*   getSdcardFilePath();
*   FilePath + "resourceID/" + "resourceid_240.mp4" ,  resourceid.pdf
*
*/
    try{
        var filedata = Array();
        var chaptersubject = false;
        var contentUtilsService = CONTROLLER.getService("ContentUtils");
        var tocService = CONTROLLER.getService("Toc");
        var resource = "";
        var filepath = "";
        var folderpath = "";
        var filephypath = "";
        var courseparam = "";
        var categoryparam = "";
        var fileAvailable = "";
        if(typeof CONTENT_IN_MULTIPLE_SDCARDS != "undefined" && CONTENT_IN_MULTIPLE_SDCARDS == true && typeof USER_PRODUCT_TYPE != "undefined" && USER_PRODUCT_TYPE == "offline"){

            var sdcard = tocService.getTocSdCardInfo(courseid);
            if(sdcard !== false){
                var chaptersofsdcard = tocService.getChaptersOfSdCard();
                var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content"));
                if(typeof chaptersofsdcard[category.title] == "undefined" || chaptersofsdcard[category.title] == ""){
                    chaptersofsdcard = "";
                }
                if(chaptersofsdcard != ""){
                    for(j=0;j<chaptersofsdcard[category.title].length;j++){
                        if(chaptersofsdcard[category.title][j].nid == courseid){
                            chaptersubject = true;
                        }
                    }
                }
                if(chaptersubject == false){
                    var sdcard = tocService.getTocSdCardInfo(courseid);
                    filedata[0] = 'error';
                    if(typeof sdcard == "undefined" || sdcard == ""){
                        filedata[1] = "Please insert SD card and try again";
                    }
                    else{
                        filedata[1] = "Please insert SD card " + sdcard + " to view this.";
                    }
                    return filedata;
                }
            }
        }

        if(typeof type == "undefined" || type == ""){
            type = OBJECT_VIDEO;
        }
        if (!resource) {
            resource = Platform.getObjectProperty(type, resourceid, "content");
            resource = $.parseJSON(resource);
        }

        filepath = "video";

        if(resource.resourceurl && resource.filetype != "html" && resource.url.indexOf("sites/default") > -1){
            var resourceurl = resource.resourceurl;
            resourceurl = resourceurl.split('?');
            resourceurl = resourceurl[0];
            resourceurl = resourceurl.split("/").slice(3).join("/");
        }
        else{
            resourceurl = resource.url;
        }

        if (resource.filetype == 'mp3' || resource.type == 'Video') {
            filepath = filepath + "/" + resourceurl;
            if(DOWNLOAD_STREAM == "m3u8"){
               filepath = filepath.replace(".mp4",".m3u8");
            }
            if(typeof ENCRYPTION_ENABLED != "undefined" && ENCRYPTION_ENABLED != "" && ENCRYPTION_ENABLED == "true"){
                filepath = generateEncryptedPath(filepath);
            }
        } else if(resource.filetype != null && resource.filetype.toLowerCase() == 'pdf'){
            filepath = filepath + "/" + resourceurl;
        }
        else if( resource.filetype == 'html'){
            filepath = "/" + resource.url;
            params = "?content=true&local=" + timestamp;
        }

        fileAvailable = contentUtilsService.isFileAvailable(resource.nid,resource.youtube_id,courseid,"video",catid);

        if( (fileAvailable.filestatus == "Available") ||
                (fileAvailable.filestatus == "In Progress") ){
                filepath = filepath.replace(resource.youtube_id,fileAvailable.youtubeid);
        }

        if( fileAvailable.filestatus == "Not Available" || (fileAvailable.filestatus == "In Progress" && fileAvailable.version != "old") || USER_PRODUCT_TYPE == "online")
        {
            if(Platform.getNetworkState() == true && resource.filetype == 'html' && USER_PRODUCT_TYPE != "offline"){
                filedata[0] = 'online';
                filedata[1] = Platform.getLocalServerPath() + "/" + resource.url + params;
            }
            else if(Platform.getNetworkState() == true && USER_PRODUCT_TYPE != "offline")
            {
                //online redirect api call for type video
                if(courseid){
                    courseparam = "&courseid="+courseid;
                }
                else{
                    courseparam = "";
                }
                if(catid){
                    var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content"));
                    if (category) {
                        categoryparam = "&cat_id="+category.tid;
                    }
                }
                filepath = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_VIDEO + '?nid=' + resourceid + '&redirect=true&format=' + RESOURCE_ONLINE_FORMAT + '&uid=' + OBJECT_USER_ID + '&auth=' + VIDEO_AUTH_ENABLED + courseparam + categoryparam + '&stream=' + STREAM_TYPE;
                if(VIDEO_AUTH_ENABLED == true) {
                    filepath = Platform.getAuthenticatedURL(filepath, 'GET', 'cloud');
                }
                filedata[0] = 'online';
                filedata[1] = filepath;

            }
            else
            {
                filedata[0] = 'error';
                if(resource.filetype && resource.filetype.toLowerCase() == 'pdf'){
                    filedata[1] = FILE_NOT_AVAILABLE;
                }else if((resource.source && resource.source == 'link') || USER_PRODUCT_TYPE == "online"){
                    filedata[1] = 'Internet connectivity is required to view this content';
                }else{
                    filedata[1] = NO_INTERNET_AND_FILE_NOT_AVAILABLE;
                }
            }
        }
        else if (resource.type == 'Document' && resource.source == "Upload") {
            var timestamp = new Date().getTime();
            if(resource.url.indexOf("?") > -1){
                var params = "&content=true&local=" + timestamp;
            }
            else{
                params = "?content=true&local=" + timestamp;
            }
            filedata[0] = 'offline';
            if(resource.filetype == 'html'){
                resourceurl = resource.url  + params;
                filedata[1] = Platform.getLocalServerPath() + "/" + resourceurl;
            }
            else if(resource.filetype.toLowerCase() == 'pdf'){
                resourceurl = filepath;
                filedata[1] = OBJECT_RESOURCE_FOLDER + "/" + resourceurl;
            }
        }
        else
        {
            if(fileAvailable.filestatus == false){
                if(fileAvailable.youtubeid != resource.youtube_id){
                    //play old video
                    filepath.replace(fileAvailable.youtubeid,resource.youtube_id);
                }
            }
            filedata[0] = 'offline';
            if(resource.filetype.toLowerCase() == 'pdf'){
                filedata[1] = OBJECT_RESOURCE_FOLDER + filepath;
            }else{
                //Replace format of download from settings
                var temp = filepath.split(".");
                var newString = temp[0].substring(0, temp[0].length-3);
                newString = newString + RESOURCE_FORMAT;
                filepath = newString + "." + temp[1];
                if(typeof DOWNLOAD_VIDEO_TYPE != "undefined" && resource.type == "Video" && ((fileAvailable.filestatus == "Available") ||
                (fileAvailable.filestatus == "In Progress" && fileAvailable.version == "old") ) ){
                    filepath = filepath.substring(0, filepath.length-3);
                    filepath = filepath + fileAvailable.type;
                }
                filedata[1] = Platform.getLocalServerPath() + "/" + filepath;
            }
        }
        CONTROLLER.setVariable("resourcemode",filedata[0]);
        return filedata;
    }
    catch(e){
        console.log( "function : getResourcePath"
            + "\n message:" + e.message);
    }
};

MobileResourceService.prototype.getUnsubscribedResourcePath = function(resourceid,youtube_id)
{
    //added api_version to redirect to app buy now
    if (typeof UNSUBSCRIBED_RES_PATH != 'undefined') {
        var path = PORTAL_SERVER_DOMAIN + UNSUBSCRIBED_RES_PATH + "/public_video.php?nid=" + resourceid + "&format=" + UNSUBSCRIBED_VIDEO_FORMAT + "&uid=" + OBJECT_USER_ID + "&app=true"+'&api_version='+API_VERSION+'&youtube_id='+youtube_id;
    } else {
        var path = PORTAL_SERVER_DOMAIN + "/sites/all/includes/public_video.php?nid=" + resourceid + "&format=" + UNSUBSCRIBED_VIDEO_FORMAT + "&uid=" + OBJECT_USER_ID + "&app=true"+'&api_version='+API_VERSION+'&youtube_id='+youtube_id;
    }
    return path;
};

MobileResourceService.prototype.getResourcePosterPath = function(resourceid,thumbnail_id,thumbnail_filename,filetype)
{
    try{
        var fileAvailable = OBJECT_RESOURCE_FOLDER;
        var contentUtilsService = CONTROLLER.getService("ContentUtils");
        if(thumbnail_id == null || thumbnail_id == "null"){
            var filephypath = "video/" + resourceid + "/" + POSTER_IMAGE_NAME + ".jpg";
            var filepath = Platform.getLocalServerPath() + "/" + filephypath
        } else {
            filephypath = "video/" + resourceid + "/" + encodeURI(thumbnail_filename);
            filepath = Platform.getLocalServerPath() + "/" + filephypath;
        }
        var fileAvailable = Platform.isFilePresentInDocRoot(filephypath);
        if( fileAvailable == false)
        {
            contentUtilsService.downloadResourcePosterImage(resourceid, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC, thumbnail_id, thumbnail_filename, filetype);

            if(filetype.toLowerCase() == 'pdf'){
                filepath = "platform/media/document.png";
            } else {
                if(BOOTSTRAP_IMAGES_SYNC == 'true' || (BOOTSTRAP_IMAGES_SYNC == 'false' && Platform.getNetworkState() == false)){
                    filepath = "platform/media/noimageavailable.jpg";
                }
                else if(BOOTSTRAP_IMAGES_SYNC == 'false' && Platform.getNetworkState() == true)
                {
                    filepath = OBJECT_SERVER_DOMAIN + '/api/downloadposter?nid=' + resourceid + '&preset=' + OBJECT_PRESET + '&uid=' + OBJECT_USER_ID;
                }
            }
        }
        return filepath;
    }
    catch(e){
        console.log( "function : getResourcePosterPath"
            + "\n message:" + e.message);
    }
};

MobileResourceService.prototype.downloadAllResources = function(){
   try{
        var resources = this.getObjectsOfType(OBJECT_VIDEO);
        for(var i=0;i<resources[OBJECT_VIDEO].length;i++){
           var filephypath = getResourceLocalPath(resources[OBJECT_VIDEO][i]);
           var resourcefileAvailable = Platform.isFilePresentInDocRoot(filephypath);
            if(resourcefileAvailable != true && filephypath != OBJECT_DATA_FOLDER && filephypath != OBJECT_RESOURCE_FOLDER){
                this.downloadResourcePosterImage(resources[OBJECT_VIDEO][i].nid, OBJECT_PRESET, BOOTSTRAP_IMAGES_SYNC, resources[OBJECT_VIDEO][i].thumbnail_id, resources[OBJECT_VIDEO][i].thumbnail_filename, resources[OBJECT_VIDEO][i].filetype);
                this.downloadResourceAsync(resources[OBJECT_VIDEO][i].nid,resources[OBJECT_VIDEO][i].youtube_id, resources[OBJECT_VIDEO][i].title + " downloaded successfully");
            }
        }
    }
    catch(e){
        console.log( "function : downloadAllResources"
            + "\n message:" + e.message);
    }
};

MobileResourceService.prototype.getDownloadFileProgress = function(filepath){
    var status = Platform.getDownloadFileProgress(filepath);
    return status;
}

//MobileResourceService.prototype.addToDownloadsList = function(content)
//{
//    try{
//        Platform.saveObject(OBJECT_CHAPTER, DOWNLOADS_OBJECT_ID, "content", "");
//        content.resourceurl = this.getResourcePathFromUrl(content.nid,content.filetype);
//        Platform.saveObject(OBJECT_VIDEO, content.nid, "content", JSON.stringify(content));
//        Platform.addRelation(OBJECT_CHAPTER, DOWNLOADS_OBJECT_ID, OBJECT_VIDEO, content.nid);
//    }
//    catch(e){
//        console.log( "function : addToDownloadsList"
//            + "\n message:" + e.message);
//    }
//};

MobileResourceService.prototype.checkDownloadStatus = function(nid) {
    var resource = DataManager.getObjectProperty(OBJECT_VIDEO,nid,"content");
    var filephypath = getResourceLocalPath(resource);

    var status = Platform.getFileDownloadStatus(filephypath);


    if(status == 0 || status == 1){
        return 2; //Download in progress and part file exists
    }
    else if(status == 2)
    {
        return 1; //Download complete and file exists
    }
    else if(status == -1){
        var isAvail  = DataManager.isFileExist(filephypath);
        if(isAvail){
            return 1; //File exists already
        }else{
            return 0; //File is not available and not put for download
        }
    }
};

MobileResourceService.prototype.showDownloadMessage = function(msg) {
    try{

        Platform.showMessage(msg);
    }
    catch(e){
        console.log( "function : MobileResourceService showMessage"
            + "\n message:" + e.message);
    }
};
MobileResourceService.prototype.getVideoViewedCount = function(resourceid, callback)
{
    if (VIDEO_VIEWED_COUNT.hasOwnProperty(resourceid)) {
        return callback(VIDEO_VIEWED_COUNT[resourceid]);
    }
    if(Platform.getNetworkState() == true){
        var url = OBJECT_SERVER_DOMAIN + '/api/videoview/count?nid=' + resourceid + '&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED;
        videoViewedCountCallBack = function(videoviewcount){
            try {
                var status = videoviewcount['status'];
                if (status == 200)
                {
                    var data = $.parseJSON(videoviewcount['data']);
                    VIDEO_VIEWED_COUNT[resourceid] = data[resourceid];
                    return callback(data[resourceid]);
                }
                else if(status == 400){
                    return callback("error");
                }
            }
            catch (e)
            {
                var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/videoview/count?nid=' + resourceid + '&uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED
                + "\n error_code :" + status
                + "\n response_data :" + data
                + "\n message :" + e.message;
                console.log(error);
                return callback("error");
            }
        };
        makeAsyncTaskCall("", "", url, "get", "false", "videoViewedCountCallBack");
    }
    else{
        return callback("error");
    }
};
MobileResourceService.prototype.getVideoDurationFromSeconds = function(resourceDuration){
    var sec_num = parseInt(resourceDuration, 10);
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}

    if (hours > 0) {
        resourceDuration =hours + ':' + minutes + ':' + seconds;
    } else {
        resourceDuration = minutes + ':' + seconds;
    }
    return resourceDuration;
}

function downloadModifiedResource(pendingobjectdata){
    //download modified resource data
    if((pendingobjectdata.operation == 'modified' || pendingobjectdata.operation == 'created') && pendingobjectdata.offline != '0'){
        DataManager.downloadSingleResourceMeta(pendingobjectdata.nid,pendingobjectdata.changed);
    } else if(pendingobjectdata.offline == '0' || pendingobjectdata.operation == 'delete'){
        DataManager.deleteResource(pendingobjectdata.nid);
        DataManager.removeObject(OBJECT_VIDEO,pendingobjectdata.nid);
        Platform.deleteVideoViewHistoryByType(pendingobjectdata.nid,"resource");
    }
}

MobileResourceService.prototype.getScormData = function(resourceid){
    try {
        var scormData = Platform.getObjectProperty('scorm_result', resourceid, "content");
        if(scormData !== "" && scormData !== null){
            scormData = $.parseJSON(scormData);
            return scormData;
        } else {
            scormData = {};
            return scormData;
        }

    } catch(e) {
        var error = "function : getScormData"
        + "\n message :" + e.message;
        console.log(error);
    }
}

MobileResourceService.prototype.setScormData = function(jsonData){
    try {
        var result_id = 'temp_scorm_' + jsonData.nid + '_' + new Date().getTime();
        Platform.saveObject('scorm_result', result_id, 'content', JSON.stringify(jsonData));
    } catch (e) {
        var error = "function : setScormData"
        + "\n message :" + e.message;
        console.log(error);
    };
}
MobileResourceService.prototype.getResourceInfoById = function(resourceid){
    if (Platform.getNetworkState() == true) {
        var data = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_VIDEO + 'fromid?nid=' + resourceid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + "&version=" + API_VERSION);
        try {
            data = $.parseJSON(data);
            var status = data['status'];
            if (status == 200)
            {
                var data = $.parseJSON(data['data']);
                return data;
            }
            else {
                return "error";
            }
        }
        catch (e)
        {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_VIDEO + 'fromid?uid=' + OBJECT_USER_ID
                    + "\n error_code :" + status
                    + "\n response_data :" + data
                    + "\n message :" + e.message;
            console.log(error);
        }
        ;
    }
    else {
        return "error";
    }
};

// get video settings to show watermark

MobileResourceService.prototype.getVideoSettings = function(){
    var url = OBJECT_SERVER_DOMAIN + '/api/get/videosettings' + '&auth=' + AUTH_ENABLED;
    try{
        if (Platform.getNetworkState() == true) {
            onGetVideoSettingsCallback = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200)
                {
                    var data = events['data'];
                    data =  $.parseJSON(data);
                    result = data;
                }
                else{
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "get", "false", "onGetVideoSettingsCallback");
            } else {
                var user = Platform.makeHttpGetCall(url);
                return onGetVideoSettingsCallback($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get/videosettings'
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}

MobileResourceService.prototype.videoFeedbackSave = function (result_id, vid, feedbackjson)
{
    try {
        Platform.saveObject("videofeedback", result_id, "content",encodeURIComponent(feedbackjson));
        Platform.addRelation(OBJECT_VIDEO, vid, "videofeedback", result_id);
    }
    catch (e) {
        console.log("function : videoFeedbackSave"
                + "\n message:" + e.message);
    }
};

MobileResourceService.prototype.getVideoFeedback = function (vid)
{

    try {
        var result = Platform.getRelatedObjects(OBJECT_VIDEO, vid, "videofeedback");
        if (result) {
            result = decodeURIComponent(result);
            result = $.parseJSON(result);
            return result.videofeedback;
        }
        return false;
    }
    catch (e) {
        console.log("function : getVideoFeedback"
                + "\n message:" + e.message);
    }
};
function MobileQuizService() {
}
;

//Sync methods

MobileQuizService.prototype.getAllQuiz = function() {

    try {
        var quiz = Platform.getObjectsOfType('quiz');
        return  $.parseJSON(quiz);
    }
    catch (e) {
        console.log("function : getAllQuiz"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.downloadQuiz = function (quizdata, download_complete_url, downloadmessage) {

    try {
        var contentUtils = CONTROLLER.getService("ContentUtils");
        var nid = quizdata.nid;
        var downloadurl = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_QUIZ + '?quizid=' + nid + "&auth=" + AUTH_ENABLED;
        var filepath = "video/" + nid + "/" + nid + '_' + quizdata.changed + ".zip";
        var paramsJson = {'objectid': nid, 'objecttype': 'resource',
            'serverurl': downloadurl, 'localurl': filepath, 'message': downloadmessage,
            'sync': FILE_DOWNLOAD_ASYNC, 'expiryduration': "",
            'onComplete': "", 'youtubeid': quizdata.changed, 'format': "", 'type': 'content'};
        Platform.downloadFile(JSON.stringify(paramsJson));
        contentUtils.showProgressBar(nid);
        contentUtils.showInProgressMessage(quizdata);
    }
    catch (e) {
        console.log(e.message);
    }
};

MobileQuizService.prototype.getAllModuleQuizzes = function () {

    try {
        var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_COURSE + OBJECT_QUIZ + "?uid=" + OBJECT_USER_ID);
        quiz = $.parseJSON(quiz);
                    var status = quiz['status'];
                    if (status == 200)
                    {
                        var data = '{"quiz":'+quiz['data'].trim()+'}';
                        quiz = $.parseJSON(data);
                    }
                    else{
                        quiz = "";
                    }
                    return quiz;
    }
    catch (e) {
        console.log(e.message);
    }
};

MobileQuizService.prototype.getAllQuizzes = function () {

    try {
        var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_QUIZ + "?uid=" + OBJECT_USER_ID);
        quiz = $.parseJSON(quiz);
        var status = quiz['status'];
        if (status == 200)
        {
            var data = '{"quiz":'+quiz['data'].trim()+'}';
            quiz = $.parseJSON(data);
        }
        else{
            quiz = "";
        }
        return quiz;
    }
    catch (e) {
        console.log(e.message);
    }
};

MobileQuizService.prototype.getQuestionsForQuiz = function(quiz_nid, quiz_changed, callback)
{
    var questions = "";

    var contentUtils = CONTROLLER.getService("ContentUtils");
    try {
            var isFileAvailable = contentUtils.isFileAvailable(quiz_nid, quiz_changed, "", "quiz");
            $("#quiz_nid" + quiz_nid).css("opacity", "1");
            if ((isFileAvailable.filestatus == "Available") && (isFileAvailable.version == "new" || isFileAvailable.version == "old"))
            {
                if (typeof PLATFORM_VERSION != "undefined" && PLATFORM_VERSION == 4.0) {
                    quiz_changed = isFileAvailable.youtubeid;
                    if (isFileAvailable.youtubeid == "") {
                        var filepath = "/video/" + quiz_nid + "/" + quiz_nid + ".quiz";
                    }
                    else{
                        var filepath = "/video/" + quiz_nid + "/" + quiz_nid + '_' + quiz_changed + ".quiz";
                    }
                    if (typeof GET_DATA_FROMFILESYSTEM != 'undefined' && GET_DATA_FROMFILESYSTEM == 'true') {
                        questions = Platform.getDataFromFileSystem(filepath);
                    } else {
                        questions = Platform.getDataFromInternalFileSystem(filepath);
                    }

                    if (questions == "")
                    {
                        Client.closeProgressBar();
                        alert("Something went wrong");
	                }
	                else{
                        questions = $.parseJSON(questions);
                    }

	            }
	            else {
                    if (isFileAvailable.youtubeid == "") {
                        var filepath = "video/" + quiz_nid + "/" + quiz_nid + ".quiz";
                    } else {
                        if (isFileAvailable.version == "old") {
                            quiz_changed = isFileAvailable.youtubeid;
                        }
                        var filepath = "video/" + quiz_nid + "/" + quiz_nid + '_' + quiz_changed + ".quiz";
                    }
                    questions = Platform.getDataFromFileSystem(filepath);
                }
        }
        else if(questions == ""){
            if (Platform.getNetworkState() == true) {
                if (typeof QUESTIONS_FOR_QUIZ[quiz_nid] != "undefined") {
                    if (typeof callback != 'undefined' && typeof callback == 'function') {
                        return callback(QUESTIONS_FOR_QUIZ[quiz_nid]);
                    } else {
                        return QUESTIONS_FOR_QUIZ[quiz_nid];
                    }
                }
                var url = OBJECT_SERVER_DOMAIN + "/api/get" + OBJECT_QUESTION + "sfor" + OBJECT_QUIZ + "?nid=" + quiz_nid + "&noredirect=true";
                try {
                    var THIS = this;
                    onGetQuestionsForQuizComplete = function (questions) {
                        if (typeof questions == 'string') {
                            questions = $.parseJSON(questions);
                        }
                        var status = questions['status'];
                        if (status == 200) {
                            var data = '{"question":'+questions['data'].trim()+'}';
                            questions = THIS.updateQuestionLocale($.parseJSON(data));
                            QUESTIONS_FOR_QUIZ[quiz_nid] = questions;
                        } else {
                            questions = "";
                        }
                    Platform.saveDataToFileSystem("video/"+quiz_nid+"/"+quiz_nid+".quiz",questions['data']);
                        CONTROLLER.setVariable("resourcevvhmode", "1");

                        if (typeof callback != 'undefined' && typeof callback == 'function') {
                            return callback(questions);
                        } else {
                            return questions;
                        }
                    };
                    if (typeof callback != 'undefined' && typeof callback == 'function') {
                        makeAsyncTaskCall("", "", url, "get", "false", "onGetQuestionsForQuizComplete", "" ,"true")
                    } else {
                        var result = $.parseJSON(Platform.makeHttpGetCall(url));
                        var status = result['status'];
                        if (status == 200) {
                            var data = $.parseJSON(result['data'].trim());
                            result = $.parseJSON(Platform.makeCrossDomainCall(data.url));
                        }
                        return onGetQuestionsForQuizComplete(result);
                    }
                } catch (e) {
                    var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUESTION+'sfor'+OBJECT_QUIZ+'?nid='+quiz_nid
                    + "\n error_code :" + status
                    + "\n response_data :" + questions['data']
                    + "\n message :" + e.message;
                    console.log(error);
                };
            }
            else{
                alert("Internet is required.");
            }
        }
        if(questions == ""){
            return "";
        }
        if (isFileAvailable.filestatus == "Available") {
            if (typeof questions == 'string') {
	            questions = $.parseJSON(questions);
	            if (typeof questions == 'string') {
	                questions = $.parseJSON(questions);
	            }
            }
            questions = JSON.stringify(questions);
            var filepath = Platform.getLocalServerPath() +"/video/"+quiz_nid+"/" ;
            questions=replaceAll(STATIC_SERVER+"/",filepath,questions);

            var staticPath = STATIC_SERVER.substr(STATIC_SERVER.indexOf('//'));
            var localFilePath = filepath.substr(filepath.indexOf('//'));
            questions = replaceAll(staticPath+"/", localFilePath, questions);

            if(typeof OLD_STATIC_SERVER != "undefined"){
                if (OLD_STATIC_SERVER instanceof Array) {
                    for (var i = 0; i < OLD_STATIC_SERVER.length; i++) {
                        var oldStaticPath = OLD_STATIC_SERVER[i].substr(OLD_STATIC_SERVER[i].indexOf('//'));
                        questions = replaceAll(oldStaticPath+"/", localFilePath, questions);
                    }
                }
                else{
                    var oldStaticPath = OLD_STATIC_SERVER.substr(OLD_STATIC_SERVER.indexOf('//'));
                    questions = replaceAll(oldStaticPath+"/", localFilePath, questions);
                }
            }
            questions = replaceAll('https:', '', questions);
        }

        questions = '{"question":' + $.trim(questions) + '}';
        questions = this.updateQuestionLocale($.parseJSON(questions));
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            return callback(questions);
        } else {
            return questions;
        }
    } catch (e) {
        console.log("function : getQuestionsForQuiz"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.updateQuestionLocale = function (questions) {
    for (var i in questions['question']) {
        var currentQuestion = $.extend(true,{},questions['question'][i]);
        var updatedLocale = {};
        if(typeof currentQuestion.language == "undefined"){
            currentQuestion.language = [];
            currentQuestion.language[0] = ENGLISH_LANGID;
        }
        updatedLocale[currentQuestion.language[0]] = $.extend(true, {}, currentQuestion);
        updatedLocale[currentQuestion.language[0]].locale = {};
        updatedLocale[currentQuestion.language[0]].localeQuestionId = currentQuestion.nid;

		if(typeof currentQuestion.locale != 'undefined'){
			for (var j in currentQuestion.locale) {
                if (j) {
                    updatedLocale[j] = $.extend(true, {}, currentQuestion);

                    var currentQuestionLocale = currentQuestion.locale[j];
                    updatedLocale[j].title = currentQuestionLocale.title;
                    updatedLocale[j].body = currentQuestionLocale.body;
                    updatedLocale[j].hints = currentQuestionLocale.hints;
                    updatedLocale[j].solution = currentQuestionLocale.solution;
                    updatedLocale[j].localeQuestionId = currentQuestionLocale.nid;

                    if (currentQuestion.type == "matching") {
                        for (var k in currentQuestion.match) {
                            updatedLocale[j].match[k].question = currentQuestionLocale.match[k].question;
                            updatedLocale[j].match[k].answer = currentQuestionLocale.match[k].answer;
                            updatedLocale[j].match[k].feedback = currentQuestionLocale.match[k].feedback;
                        }
                    } else if (currentQuestion.type == "short_answer") {
                        if (currentQuestion.correct_answer_evaluation == '5') {
                            updatedLocale[j].answer_list = currentQuestionLocale.answer_list;
                            updatedLocale[j].correct_answer = currentQuestionLocale.correct_answer;
                        }
                    } else if (currentQuestion.type == "multichoice") {
                        for (var k in currentQuestion.alternatives) {
                            updatedLocale[j].alternatives[k].answer = currentQuestionLocale.alternatives[k].answer;
                        }
                    }
                    updatedLocale[j].locale = {};
                }
	        }
		}
        questions['question'][i]['updatedLocale'] = updatedLocale;
    }
    return questions;
};

MobileQuizService.prototype.getQuestionLocaleInfo = function (language, activeLang) {
    if (typeof ALL_LANGUAGES != 'undefined' && ALL_LANGUAGES.length > 0) {
        var allLanguages = ALL_LANGUAGES;
    } else {
        if (EXEC_MODE == "online") {
            var allLanguages = Platform.getAllLanguages();
        } else {
            var settingService = CONTROLLER.getService('Settings');
            var allLanguages = settingService.getAllLanguages();
            allLanguages = allLanguages['lang'];
        }
        ALL_LANGUAGES = allLanguages;
    }
    var langInfo = [];
    for (var i in allLanguages) {
        for (var j in language) {
            if (allLanguages[i].tid === language[j]) {
                var selected = '';
                if (activeLang === language[j])
                    selected = 'selected';
                langInfo.push({ 'tid': language[j], 'name': allLanguages[i].name, 'selected': selected });
                break;
            }
        }
    }
    return langInfo;
};

MobileQuizService.prototype.getActiveQuizzes = function (nid, groups, isOffline, page, limit, callback) {
    try {
        if (isOffline) {
            var result = {};
            if (typeof groups != 'undefined') {
                groups = [groups];
            }
            //To support multiple columns in the future
            var column = ['start_time','quiz_id'];
            var sort = ['DESC','ASC'];
            var order = {
                'columns': column,
                'order': sort
            };
            var quizzes = Platform.getActiveQuizzes(OBJECT_USER_ID, nid, JSON.stringify(groups), limit, page, JSON.stringify(order));
            result['quiz'] = $.parseJSON(quizzes);
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        var quizjson = {};
        if(typeof page != "undefined"){
            page = "&page=" + page;
        }
        else{
            page = "";
        }
        if(typeof limit != "undefined"){
            limit = "&limit=" + limit;
        }
        else{
            limit = "";
        }
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/active' + OBJECT_QUIZ + '?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID + "&nid=" + nid + "&groups=" + groups + page + limit + '&api_version=' + API_VERSION;

        onAsyncGetActiveQuizzesComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetActiveQuizzesComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetActiveQuizzesComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "getActiveQuizzes"
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getTestSeriesCount = function (nid, groups, isOffline) {
    if (isOffline) {
        if (typeof groups != 'undefined') {
            groups = [groups];
        }
        var testSeriesCount = Platform.getCountOfQuizzesInTestCollection(OBJECT_USER_ID, nid, JSON.stringify(groups));
        testSeriesCount = $.parseJSON(testSeriesCount);
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            return callback(testSeriesCount);
        } else {
            return testSeriesCount;
        }
    }
    var quizjson = {};
    var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/test_collection/getcount?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID + "&nid=" + nid + '&groups=' + groups);
    try {
        quiz = $.parseJSON(quiz);
        var status = quiz['status'];
        if (status == 200) {
            return $.parseJSON(quiz["data"]);
        } else if (status == 401 || status == 403) {
            return status;
        } else {
            return ERROR_MESSAGE;
        }
    } catch (e) {
        var error = "getTestSeriesCount"
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getTestCollection = function (groups, callback) {
    try {
        if(Platform.getNetworkState() == false && typeof OFFLINE_TEST_SERIES != 'undefined' && OFFLINE_TEST_SERIES == "true") {
            if (typeof groups != 'undefined') {
                groups = [groups];
            }
            var testCollection = Platform.getAllTestCollections(OBJECT_USER_ID, JSON.stringify(groups));
            testCollection = $.parseJSON(testCollection);
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(testCollection);
            } else {
                return testCollection;
            }
        }
    	var url = OBJECT_SERVER_DOMAIN + '/api/getalltest_collection?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID + '&groups=' + groups;

        onAsyncGetTestCollectionComplete = function (test_collection) {
            var result = '';
            var status = test_collection['status'];
            if (status == 200) {
                var testCollection = $.parseJSON(test_collection["data"]);
                result = testCollection;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetTestCollectionComplete");
        } else {
            var test_collection = Platform.makeHttpGetCall(url);
            return onAsyncGetTestCollectionComplete($.parseJSON(test_collection));
        }
    } catch (e) {
        var error = "getTestCollection"
        + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getUpcomingQuizzes = function (nid, groups, isOffline, page, limit, callback) {
    try {
        if (isOffline) {
            var result = {};
            if (typeof groups != 'undefined') {
                groups = [groups];
            }
            //To support multiple columns in the future
            var column = ['start_time', 'quiz_id'];
            var sort = ['ASC', 'ASC'];
            var order = {
                'columns': column,
                'order': sort
            };
            var quizzes = Platform.getUpcomingQuizzes(OBJECT_USER_ID, nid, JSON.stringify(groups), limit, page, JSON.stringify(order));
            result['quiz'] = $.parseJSON(quizzes);
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }

        var quizjson = {};
        if(typeof page != "undefined"){
            page = "&page=" + page;
        }
        else{
            page = "";
        }
        if(typeof limit != "undefined"){
            limit = "&limit=" + limit;
        }
        else{
            limit = "";
        }
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/upcoming' + OBJECT_QUIZ + '?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID + "&nid=" + nid + "&groups=" + groups + "&order=asc" + page + limit;

        onAsyncGetUpcomingQuizzesComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetUpcomingQuizzesComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetUpcomingQuizzesComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "getMissedQuizzes"
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getMissedQuizzes = function (nid, groups, isOffline, page, limit, callback) {
    try {
        if (isOffline) {
            var result = {};
            if (typeof groups != 'undefined') {
                groups = [groups];
            }
            //To support multiple columns in the future
            var column = ['start_time', 'quiz_id'];
            var sort = ['DESC', 'ASC'];
            var order = {
                'columns': column,
                'order': sort
            };
            var quizzes = Platform.getMissedQuizzes(OBJECT_USER_ID, nid, JSON.stringify(groups), limit, page, JSON.stringify(order));
            result['quiz'] = $.parseJSON(quizzes);
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        var quizjson = {};
        if(typeof page != "undefined"){
            page = "&page=" + page;
        }
        else{
            page = "";
        }
        if(typeof limit != "undefined"){
            limit = "&limit=" + limit;
        }
        else{
            limit = "";
        }
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/missed' + OBJECT_QUIZ + '?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID + "&nid=" + nid + "&groups=" + groups + "&order=desc"+ page + limit;

        onAsyncGetMissedQuizzesComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetMissedQuizzesComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetMissedQuizzesComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "getMissedQuizzes"
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getAttemptedQuizzes = function (nid, groups, isOffline, page, limit, callback) {
    try {
        if (isOffline) {
            var result = {};
            if (typeof groups != 'undefined') {
                groups = [groups];
            }
            //To support multiple columns in the future
            var column = ['attempt_time'];
            var sort = ['DESC'];
            var order = {
                'columns': column,
                'order': sort
            };
            var quizzes = Platform.getAttemptedQuizzes(OBJECT_USER_ID, nid, JSON.stringify(groups), limit, page, JSON.stringify(order));
            result['quiz'] = $.parseJSON(quizzes);
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        var quizjson = {};
        if(typeof page != "undefined"){
            page = "&page=" + page;
        }
        else{
            page = "";
        }
        if(typeof limit != "undefined"){
            limit = "&limit=" + limit;
        }
        else{
            limit = "";
        }
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/attempted' + OBJECT_QUIZ + '?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID + "&nid=" + nid + "&groups=" + groups + "&order=desc" + page + limit;

        onAsyncGetAttemptedQuizzesComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetAttemptedQuizzesComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetAttemptedQuizzesComplete($.parseJSON(quiz));
        }
    } catch (e)
    {
        var error = "getAttemptedQuizzes"
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getAttemptedQuizzesScorecard = function (quiz_nid, resultid) {
    var quizjson = {};
    if(resultid != '')
        var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/test_collection/result/scorecard?quiznid=' + quiz_nid +'&resultid=' + resultid);
    else
        var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/test_collection/result/scorecard?quiznid=' + quiz_nid);
    try {
        quiz = $.parseJSON(quiz);
        var status = quiz['status'];
        if (status == 200) {
            quizjson["quiz"] = $.parseJSON(quiz["data"]);
            return quizjson;
        } else if(status == 201 ){
            return quiz;
        }else if (status == 401 || status == 403) {
            return onAccessDenied();
        } else {
            return ERROR_MESSAGE;
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getQuizzesComparisonChart = function (quiz_nid, resultid, callback) {
    var quizjson = {};
    if(resultid != '')
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/comparisonchart?quiznid=' + quiz_nid + '&resultid=' + resultid;
    else
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/comparisonchart?quiznid=' + quiz_nid ;
    try {
        onAsyncGetQuizzesComparisonChartComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            }else if(status == 201){
                result = quiz;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetQuizzesComparisonChartComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetQuizzesComparisonChartComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getTopicWiseReport = function (quiz_nid, resultid, callback) {
    var quizjson = {};
    if(resultid != '')
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/topicwisereport?quiznid=' + quiz_nid + '&resultid=' + resultid;
    else
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/topicwisereport?quiznid=' + quiz_nid ;
    try {
        onAsyncGetTopicWiseReportComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            } else if(status == 201){
                result = quiz;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetTopicWiseReportComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetTopicWiseReportComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getLevelWiseReport = function (quiz_nid, resultid, callback) {
    var quizjson = {};
    if(resultid != '')
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/levelwisereport?quiznid=' + quiz_nid + '&resultid=' + resultid;
    else
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/levelwisereport?quiznid=' + quiz_nid ;
    try {
        onAsyncGetLevelWiseReportComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            }else if(status == 201){
                result = quiz;
            }else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetLevelWiseReportComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetLevelWiseReportComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getQuizzesAccuracyManagementChart = function (quiz_nid, resultid, callback) {
    var quizjson = {};
    if(resultid != '')
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/accuracymanagement?quiznid=' + quiz_nid + '&resultid=' + resultid;
    else
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/accuracymanagement?quiznid=' + quiz_nid ;
    try {
        onAsyncGetQuizzesAccuracyManagementChartComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            } else if(status == 201){
                result = quiz;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetQuizzesAccuracyManagementChartComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetQuizzesAccuracyManagementChartComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getQuizzesTimeManagementChart = function (quiz_nid, resultid, callback) {
    var quizjson = {};
    if(resultid != '')
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/timemanagement?quiznid=' + quiz_nid + '&resultid=' + resultid;
    else
        var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/timemanagement?quiznid=' + quiz_nid ;
    try {
        onAsyncGetQuizzesTimeManagementChartComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            } else if(status == 201){
                result = quiz;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetQuizzesTimeManagementChartComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetQuizzesTimeManagementChartComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.downloadQuestionsOfQuiz = function(quiz_nids){
    var quiz_nid = "";
    for(i=0;i<quiz_nids.length;i++){
        quiz_nid = quiz_nids[i];
        var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUESTION+'sfor'+OBJECT_QUIZ+'?nid='+quiz_nid);
        try {
            quiz = $.parseJSON(quiz);
            var status = quiz['status'];
            if (status == 200)
            {
                Platform.saveDataToFileSystem("video1/"+quiz_nid+"/"+quiz_nid+".quiz",quiz['data']);
            }
        }
        catch (e)
        {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUESTION+'for'+OBJECT_QUIZ+'?nid='+nid
            + "\n error_code :" + status
            + "\n response_data :" + quiz['data']
            + "\n message :" + e.message;
            console.log(error);
        };
    }
}

MobileQuizService.prototype.getQuiz = function(quiz_nid, newquiz)
{

    try {
        var quiz = "";
        if(GET_QUIZ_DETAILS.hasOwnProperty(quiz_nid)){
            var quiz_data = GET_QUIZ_DETAILS[quiz_nid];
            return quiz_data;
        }
        if (typeof newquiz == 'undefined' || newquiz == false) {
            quiz = Platform.getObjectProperty(OBJECT_QUIZ, quiz_nid, 'content');
            quiz = $.parseJSON(quiz);
        }
        if (quiz == null || quiz == "" || typeof quiz == "undefined") {
            if (Platform.getNetworkState() == true) {
                var quizoftheday = CONTROLLER.getVariable("quizoftheday", "true");
                if (quizoftheday != 'undefined' && quizoftheday == 'true') {
                    var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUIZ + 'ofday?auth=' + AUTH_ENABLED + '&uid=' + OBJECT_USER_ID);
                } else {
                    var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUIZ + 'fromid?nid=' + quiz_nid);
                }
                try {
                    quiz = $.parseJSON(quiz);
                    var status = quiz['status'];
                    if (status == 200) {
                        quiz = quiz['data'];
                        quiz = $.parseJSON(quiz);
                        if (quiz.length != 0 && (quizoftheday != 'undefined' && quizoftheday != 'true')) {
                            Platform.saveObject(OBJECT_QUIZ, quiz_nid, 'content', JSON.stringify(quiz[0]));
                        }
                    } else {
                        return "error"
					}
                } catch (e) {
                    var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUIZ + 'fromid?nid=' + quiz_nid
                            + "\n error_code :" + status
                            + "\n response_data :" + quiz['data']
                            + "\n message :" + e.message;
                    console.log(error);
                }
                ;
            } else {
                return INTERNET_ERROR;
            }
        }
        if (isArray(quiz)) {
            quiz = quiz[0];
        }
        var quiz_changed = quiz.changed;
        var contentUtils = CONTROLLER.getService("ContentUtils");
        var isFileAvailable = contentUtils.isFileAvailable(quiz_nid, quiz_changed, "", "quiz");
        if (isFileAvailable.filestatus == "Available") {
            quiz = JSON.stringify(quiz);
            var filepath = Platform.getLocalServerPath() + "/video/" + quiz_nid + "/";
            quiz = replaceAll(STATIC_SERVER + "/", filepath, quiz);

            var staticPath = STATIC_SERVER.substr(STATIC_SERVER.indexOf('//'));
            var localFilePath = filepath.substr(filepath.indexOf('//'));
            quiz = replaceAll(staticPath + "/", localFilePath, quiz);

            if (typeof OLD_STATIC_SERVER != "undefined") {
                if (OLD_STATIC_SERVER instanceof Array) {
                    for (var i = 0; i < OLD_STATIC_SERVER.length; i++) {
                        var oldStaticPath = OLD_STATIC_SERVER[i].substr(OLD_STATIC_SERVER[i].indexOf('//'));
                        quiz = replaceAll(oldStaticPath + "/", localFilePath, quiz);
                    }
                } else {
                    var oldStaticPath = OLD_STATIC_SERVER.substr(OLD_STATIC_SERVER.indexOf('//'));
                    quiz = replaceAll(oldStaticPath + "/", localFilePath, quiz);
                }
            }
            quiz = replaceAll('https:', '', quiz);
            quiz = $.parseJSON(quiz);
        }
        quiz = this.updateQuizLocale(quiz);
        GET_QUIZ_DETAILS[quiz_nid] = quiz;
        return quiz;
    } catch (e) {
        console.log("function : getQuizbyNid"
                + "\n message:" + e.message);
    }
};


MobileQuizService.prototype.getQuizAsync = function (quiz_nid, callback) {
    try {
        if (Platform.getNetworkState() == true) {
            var THIS = this;
            var url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUIZ + 'fromid?nid=' + quiz_nid;
            onGetQuizAsyncComplete = function (quiz) {
                var status = quiz['status'];
                if (status == 200) {
                    quiz = quiz['data'];
                    quiz = $.parseJSON(quiz);
                    if (isArray(quiz)) {
                        quiz = quiz[0];
                        OBJECT_FROM_ID[OBJECT_QUIZ] = {};
                        OBJECT_FROM_ID[OBJECT_QUIZ][quiz_nid] = JSON.stringify(quiz);
                    }
                    quiz = THIS.updateQuizLocale(quiz);
                } else if (status == 403) {
                    result = USER_LOGOUT_ERROR;
                } else {
                    quiz = ERROR_MESSAGE;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(quiz);
                } else {
                    return quiz;
                }
            };
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "get", "false", "onGetQuizAsyncComplete");
            } else {
                var result = Platform.makeHttpGetCall(url);
                return onGetQuizAsyncComplete($.parseJSON(result));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    } catch (e) {
        console.log("function : getQuizAsync"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.updateQuizLocale = function (quiz) {
    if (isArray(quiz)) {
        quiz = quiz[0];
    }
    if (typeof quiz.locale != 'undefined' && quiz.locale != '') {
        var quiz_instruction = quiz.quiz_instruction;
        var quiz_section = $.extend(true, {}, quiz.sections);

        var updatedLocale = {};
        updatedLocale.quiz_instruction = {};
        updatedLocale.quiz_section = {};
        updatedLocale.quiz_instruction[quiz.language[0]] = quiz_instruction;
        updatedLocale.quiz_section[quiz.language[0]] = quiz_section;

        for (var j in quiz.locale.quiz_instruction) {
            updatedLocale.quiz_instruction[j] = quiz.locale.quiz_instruction[j];
        }
        for (var j in quiz.locale.quiz_section) {
            updatedLocale.quiz_section[j] = $.extend(true, {}, quiz.locale.quiz_section[j]);
        }
        quiz['updatedLocale'] = updatedLocale;
    }
    return quiz;
};

MobileQuizService.prototype.getQuizOfTheDay = function ()
{

    try {
        if (Platform.getNetworkState() == true) {
            var quiz = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_QUIZ + 'ofday?auth=' + AUTH_ENABLED + '&uid=' + OBJECT_USER_ID);
            try {
                quiz = $.parseJSON(quiz);
                var status = quiz['status'];
                if (status == 200)
                {
                    quiz = quiz['data'];
                    quiz = $.parseJSON(quiz);
                    if(quiz.length != 0){
                        return quiz[0];
                    }
                    else{
                        return NO_QOTD_ERROR;
                    }
                }
                else if(status == 403){
                     return status;
                }
                else {
                    return ERROR_MESSAGE;
                }
            }
            catch (e)
            {
                var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getgetQuizOfTheDay'
                + "\n error_code :" + status
                + "\n response_data :" + quiz['data']
                + "\n message :" + e.message;
                console.log(error);
            };
        }
        else{
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : getQuizOfTheDay"
                + "\n message:" + e.message);
    }
};


MobileQuizService.prototype.getQuizResult = function (quiz_id)
{

    try {
        var quiz_result = Platform.getRelatedObjects(OBJECT_QUIZ, quiz_id, "quiz_result");
        var quiz_result = $.parseJSON(quiz_result);
        if (Platform.getNetworkState() == true && (quiz_result == null || quiz_result['quiz_result'].length == 0)) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getquizresults?quiz_id=' + quiz_id + '&uid=' + OBJECT_USER_ID;
            if(GET_QUIZ_RESULTS.hasOwnProperty(quiz_id)){
                var result_data = GET_QUIZ_RESULTS[quiz_id];
                return result_data;
            }
            var response = Platform.makeHttpGetCall(url);
            response = $.parseJSON(response);
            var result = '';
            var status = response['status'];
            if (status == 200) {
                var data = response['data'];
                data = $.parseJSON(data);
                if(data.length > 0){
                    result = {};
                    result['quiz_result'] = data;
                    if (EXEC_MODE != "online") {
                        for(var i in result['quiz_result']){
                            var curr_result = result['quiz_result'][i];
                            if(curr_result["is_evaluated"] > 0) {
                                this.quizResultSave(curr_result.result_id, curr_result.nid, JSON.stringify(curr_result));
                            }
                        }
                    }
                }
                quiz_result = result;
                GET_QUIZ_RESULTS[quiz_id] = quiz_result;
            } else if (status == 403) {
                onAccessDenied();
            }
        }
        return quiz_result;
    }
    catch (e) {
        console.log("function : GetQuizResult"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getQuizResultAsync = function (quiz_id, callback)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getquizresults?quiz_id=' + quiz_id + '&uid=' + OBJECT_USER_ID;
            onGetQuizResultAsyncComplete = function (response) {
                var result = '';
                var status = response['status'];
                if (status == 200) {
                    var data = response['data'];
                    data = $.parseJSON(data);
                    if (data.length > 0) {
                        result = {};
                        result['quiz_result'] = data;
                    }
                } else if (status == 403) {
                    result = onAccessDenied();
                } else {
                    result = {};
                    result['quiz_result'] = [];
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            };
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "get", "false", "onGetQuizResultAsyncComplete");
            } else {
                var quiz = Platform.makeHttpGetCall(url);
                return onGetQuizResultAsyncComplete($.parseJSON(quiz));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback.apply();
            } else {
                return;
            }
        }
    }
    catch (e) {
        console.log("function : GetQuizResult"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getTestCollectionQuizResult = function (test_collection_id)
{
    try {
        var quiz_result = Platform.getRelatedObjects("test_collection", test_collection_id, "quiz_result");
        var quiz_result = $.parseJSON(quiz_result);
        return quiz_result;
    }
    catch (e) {
        console.log("function : getTestCollectionQuizResult"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.quizResultSave = function (result_id, quiz_id, quiz_result, testCollectionId)
{
    try {
        Platform.saveObject("quiz_result", result_id, "content", quiz_result);
        Platform.addRelation(OBJECT_QUIZ, quiz_id, "quiz_result", result_id);
        if (testCollectionId) {
            Platform.addRelation(OBJECT_COURSE, CONTROLLER.getVariable("activecourseid"), OBJECT_TEST_COLLECTION, testCollectionId);
            Platform.addRelation(OBJECT_TEST_COLLECTION, testCollectionId, "quiz_result", result_id);
        }
    }
    catch (e) {
        console.log("function : quizResultSave"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getQuizAttempts = function (nid)
{
    try {
      if (Platform.getNetworkState() == true) {

            var json = "{";
            var temp = "";
            temp = ' "uid" : "' + OBJECT_USER_ID + '",';
            temp = temp + ' "quizid" : "' + nid + '"';
            json = json + temp + "}";

        var url = OBJECT_SERVER_DOMAIN + '/api/getquizattempts?auth=' + AUTH_ENABLED;
        var postdata = encodeURIComponent(json);
        if(GET_QUIZ_ATTEMPTS.hasOwnProperty(nid)){
            var attempts_data = GET_QUIZ_ATTEMPTS[nid];
            return attempts_data;
        }
        var attempts = Platform.makeHttpPostCall(url, postdata);
        attempts = $.parseJSON(attempts);
        var status = attempts['status'];
            var attempts_json = {};
            if (status == 200) {
                var tot_attempts = attempts['data'];
                tot_attempts = $.parseJSON(tot_attempts);
                attempts_json["attempts"] = tot_attempts;
                GET_QUIZ_ATTEMPTS[nid] = attempts_json;
                return attempts_json;
            }
        }
        else {
            return ERROR_MESSAGE;
        }
    }
    catch (e) {
        console.log("function : getQuizAttempts"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getQuestionFromId = function(nid)
{
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getQuestionFromId?nid=' + nid;
            var postdata = encodeURIComponent("");
            var questionData = Platform.makeHttpPostCall(url, postdata);
            questionData = $.parseJSON(questionData);
            var status = questionData['status'];
            var question_json = {};
            if (status == 200) {
                var qsnData = questionData['data'];
                qsnData = $.parseJSON(qsnData);
                question_json["questiondata"] = qsnData;
                return question_json;
            }
            else {
                return ERROR_MESSAGE;
            }
        }
        else {
             return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : getQuestionFromId"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getQuizAttemptsinOffline = function(nid) {
    var quiz_result = this.getQuizResult(nid);
    var attemptsCount =0;
    if (quiz_result != '' && quiz_result != null && quiz_result['quiz_result'].length != 0){
        for(var i=0;i<quiz_result['quiz_result'].length;i++){
           if(quiz_result['quiz_result'][i]["is_evaluated"] == "1"){
               attemptsCount++;
           }
        }
    }
    return attemptsCount;
};

MobileQuizService.prototype.getQuestionsResultData = function(nid, resultid, group) {
    try{
    if (Platform.getNetworkState() == true) {
        if(resultid != '')
            var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/questionslist?quiznid=' + nid + '&resultid=' + resultid + '&group=' + group;
        else
            var url = OBJECT_SERVER_DOMAIN + '/api/test_collection/result/questionslist?quiznid=' + nid + '&group=' + group;
        var postdata = encodeURIComponent("");
        var resultData = Platform.makeHttpPostCall(url, postdata);
        resultData = $.parseJSON(resultData);
        var status = resultData['status'];
            var result_json = {};
            if (status == 200) {
                var qsnData = resultData['data'];
                qsnData = $.parseJSON(qsnData);
                result_json["result_data"] = qsnData;
                return result_json;
            } else if(status == 201){
                return resultData;
            }else if(status == 403){
                onAccessDenied();
            }
            else {
                return ERROR_MESSAGE;
            }
        }
        else {
             return INTERNET_ERROR;
        }
    }catch (e) {
        console.log("function : getQuestionsResultData"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getQuizResultFromResultId = function(quiz_result_id) {
    try{
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getquizresultfromid?resultid=' + quiz_result_id + '&uid=' + OBJECT_USER_ID;
            var response = Platform.makeHttpGetCall(url);
            response = $.parseJSON(response);
            var result = '';
            var status = response['status'];
            if (status == 200) {
                var data = response['data'];
                data = $.parseJSON(data);
                result = data;
            } else if (status == 403) {
                return onAccessDenied();
            } else {
                result = ERROR_MESSAGE;
                console.log("function : getQuizResultFromResultId - /api/getquizresultfromid " +
                + "\n message:" + status + " result :" + result);
            }
            return result;
        }
    } catch (e) {
        console.log("function : getQuizResultFromResultId"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.createResultId = function(nid, temp_result_id, resume, attemptLog, callback) {
    try{
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/'+OBJECT_QUIZ+'/start?quizid=' + nid + '&resume=' + resume + '&uid=' + OBJECT_USER_ID;
            var postdata = attemptLog ? encodeURIComponent(attemptLog) : "";
            onCreateResultIdComplete = function (response) {
                var result = '', resultid = '';
                var status = response['status'];
                if (status == 200) {
                    var data = response['data'];
                    data = $.parseJSON(data);
                    resultid = data['resultid'];
                    result = resultid;
                }else if(status == 401){
                    result = QUIZ_ATTEMPTS_EXCEEDED;
                }else if (status == 403) {
                    result = onAccessDenied();
                } else {
                    result = temp_result_id;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            };
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onCreateResultIdComplete");
            } else {
                var result = Platform.makeHttpPostCall(url, postdata);
                return onCreateResultIdComplete($.parseJSON(result));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(temp_result_id);
            } else {
                return temp_result_id;
            }
        }
    } catch (e) {
        console.log("function : createResultId"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.saveQuestionResult = function(result_id, quiz_id, questResult) {
    try {
        if (Platform.getNetworkState() == true && result_id.indexOf("temp_") < 0) {
            var url = OBJECT_SERVER_DOMAIN + '/api/' + OBJECT_QUIZ + '/updateresult?quizid=' + quiz_id + '&resultid='+ result_id +'&uid=' + OBJECT_USER_ID;
            var postdata = encodeURIComponent(questResult);
            onQuizResultSaveComplete = function (response) {
                var result = '';
                var status = response['status'];
                if (status == 200) {
                    var data = response['data'];
                    data = $.parseJSON(data);
                    result = data;
                } else if (status == 403) {
                    onAccessDenied();
                } else {
                    result = ERROR_MESSAGE;
                    console.log("function : saveQuestionResult - /api/" + OBJECT_QUIZ + "/updateresult " +
                    + "\n message:" + status + " result :" + result);
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback.apply();
                } else {
                    return;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onQuizResultSaveComplete");
            } else {
                var quiz = Platform.makeHttpPostCall(url,postdata);
                return onQuizResultSaveComplete($.parseJSON(quiz));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback.apply();
            } else {
                return;
            }
        }
    }
    catch (e) {
        console.log("function : saveQuestionResult"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.finishQuiz = function(quiz_id, result_id, attemptLog) {
    try {
        if (Platform.getNetworkState() == true && result_id.indexOf("temp_") < 0) {
            var url = OBJECT_SERVER_DOMAIN + '/api/' + OBJECT_QUIZ + '/finish?quizid=' + quiz_id + '&resultid=' + result_id + '&uid=' + OBJECT_USER_ID;
            var postdata = encodeURIComponent(attemptLog);
            var response = Platform.makeHttpPostCall(url, postdata);
            response = $.parseJSON(response);
            var result = '';
            var status = response['status'];
            if (status == 200) {
                var data = response['data'];
                data = $.parseJSON(data);
                return data;
            } else if (status == 403) {
                return onAccessDenied();
            } else {
                result = ERROR_MESSAGE;
                console.log("function : finishQuiz - /api/" + OBJECT_QUIZ + "/finish " +
                + "\n message:" + status + " result :" + result);
                return false;
            }
        }
    }
    catch (e) {
        console.log("function : finishQuiz"
                + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getServerClientOffset = function(callback) {
    try{
        var url = OBJECT_SERVER_DOMAIN + '/api/gettime';
        if (Platform.getNetworkState() == true) {
            var clientTimestamp = (new Date()).valueOf();
            onGetServerClientOffsetComplete = function (time) {
                var result = '';
            var status = time['status'];
            if (status != 200) {
                    result = ERROR_MESSAGE;
            } else {
                var data = time['data'];
                data = $.parseJSON(data);

                var serverTimestamp = data[0].time * 1000;

                var t0 = clientTimestamp,
                    t1 = serverTimestamp,
                    t2 = serverTimestamp,
                    t3 = (new Date()).valueOf();

                var c = ntp(t0, t1, t2, t3);
                var offset = Math.round(c.offset);
                //var syncedServerTime = (new Date(t3 + offset)).valueOf();
                    result = offset;
            }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
        } else {
                    return result;
                }
            };
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetServerClientOffsetComplete");
            } else {
                var time = Platform.makeHttpGetCall(url);
                return onGetServerClientOffsetComplete($.parseJSON(time));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
            return INTERNET_ERROR;
        }
        }
    }catch (e) {
        console.log("function : getServerClientOffset"
                + "\n message:" + e.message);
    }
}

MobileQuizService.prototype.getActiveAttemptDetails = function(nid, callback) {
    var starttime = GAME_START_TIME;
    var url = OBJECT_SERVER_DOMAIN + '/api/' + OBJECT_QUIZ + '/getactiveattemptdetails?nid=' + nid + '&start_time='+ starttime +'&uid=' + OBJECT_USER_ID;
    try{
        if(Platform.getNetworkState() == true){
            onGetActiveAttemptDetailsComplete = function (attemptDetails) {
                var result = '';
                var status = attemptDetails['status'];
                if (status != 200) {
                    result = ERROR_MESSAGE;
                } else {
                    var data = attemptDetails['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetActiveAttemptDetailsComplete");
            } else {
                var quiz = Platform.makeHttpGetCall(url);
                return onGetActiveAttemptDetailsComplete($.parseJSON(quiz));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }catch (e) {
        console.log("function : getActiveAttemptDetails"
                + "\n message:" + e.message);
    }
}

MobileQuizService.prototype.getFastestAttempts = function(nid, limit, start_time, callback) {
    var url = OBJECT_SERVER_DOMAIN + '/api/' + OBJECT_QUIZ + '/getfastestattempts?nid='+ nid +'&limit='+ limit +'&start_time='+ start_time +'&uid=' + OBJECT_USER_ID;
    try{
        if(Platform.getNetworkState() == true){
            onGetFastestAttemptsComplete = function (attemptDetails) {
                var result = '';
                var status = attemptDetails['status'];
                if (status != 200) {
                    result = ERROR_MESSAGE;
                } else {
                    var data = attemptDetails['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetFastestAttemptsComplete");
            } else {
                var quiz = Platform.makeHttpGetCall(url);
                return onGetFastestAttemptsComplete($.parseJSON(quiz));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }catch (e) {
        console.log("function : getFastestAttempts"
                + "\n message:" + e.message);
    }
}
MobileQuizService.prototype.getTodaysActiveQuizzes = function (page, limit, callback) {
    try {
        var quizjson = {};
        if(typeof page != "undefined"){
            page = "&page=" + page;
        }
        else{
            page = "";
        }
        if(typeof limit != "undefined"){
            limit = "&limit=" + limit;
        }
        else{
            limit = "";
        }

        var url = OBJECT_SERVER_DOMAIN + '/api/todaystest' + '?auth=' + AUTH_ENABLED + "&uid=" + OBJECT_USER_ID  + page + limit;

        onAsyncGetTodaysActiveQuizzesComplete = function (quiz) {
            var result = '';
            var status = quiz['status'];
            if (status == 200) {
                quizjson["quiz"] = $.parseJSON(quiz["data"]);
                result = quizjson;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onAsyncGetTodaysActiveQuizzesComplete");
        } else {
            var quiz = Platform.makeHttpGetCall(url);
            return onAsyncGetActiveQuizzesComplete($.parseJSON(quiz));
        }
    } catch (e) {
        var error = "onAsyncGetTodaysActiveQuizzesComplete"
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getTestCollectionById =  function(test_collection_id, callback){
    var url = OBJECT_SERVER_DOMAIN + '/api/gettest_collectionbyid?nid=' + test_collection_id + '&auth=' + AUTH_ENABLED;
    try{
        if (Platform.getNetworkState() == true) {
            onGetTestCollectionById = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200)
                {
                    var data = events['data'];
                    data =  $.parseJSON(data);
                    result = data;
                }
                else{
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "get", "false", "onGetTestCollectionById");
            } else {
                var user = Platform.makeHttpGetCall(url);
                return onGetTestCollectionById($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/onGetTestCollectionById '
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getScoreCardInfo = function (quizId, subjectId, chapterId, callback) {
    var subjectParam = "", chapterParam = "";
    if (subjectId) {
        subjectParam = "&subject_id=" + subjectId;
    }
    if (chapterId) {
        chapterParam = "&chapter_id=" + chapterId;
    }
    var url = OBJECT_SERVER_DOMAIN + '/api/get/test_collection/scorecard?quiznid=' + quizId + subjectParam + chapterParam;
    try {
        onGetScoreCardInfoComplete = function (response) {
            var result = '';
            var status = response['status'];
            if (status == 200) {
                result = $.parseJSON(response["data"]);
            } else if(status == 201){
                result = response;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onGetScoreCardInfoComplete");
        } else {
            var response = Platform.makeHttpGetCall(url);
            return onGetScoreCardInfoComplete($.parseJSON(response));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getPerformanceAnalysisInfo = function (quizId, subjectId, chapterId, callback) {
    var subjectParam = "", chapterParam = "";
    if (subjectId) {
        subjectParam = "&subject_id=" + subjectId;
    }
    if (chapterId) {
        chapterParam = "&chapter_id=" + chapterId;
    }
    var url = OBJECT_SERVER_DOMAIN + '/api/get/test_collection/comparisonchart?quiznid=' + quizId + subjectParam + chapterParam;
    try {
        onGetPerformanceAnalysisInfoComplete = function (response) {
            var result = '';
            var status = response['status'];
            if (status == 200) {
                result = $.parseJSON(response["data"]);
            } else if(status == 201){
                result = response;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onGetPerformanceAnalysisInfoComplete");
        } else {
            var response = Platform.makeHttpGetCall(url);
            return onGetPerformanceAnalysisInfoComplete($.parseJSON(response));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getDifficultyAnalysisInfo = function (quizId, subjectId, chapterId, callback) {
    var subjectParam = "", chapterParam = "";
    if (subjectId) {
        subjectParam = "&subject_id=" + subjectId;
    }
    if (chapterId) {
        chapterParam = "&chapter_id=" + chapterId;
    }
    var url = OBJECT_SERVER_DOMAIN + '/api/get/test_collection/levelwisereport?quiznid=' + quizId + subjectParam + chapterParam;
    try {
        onGetDifficultyAnalysisInfoComplete = function (response) {
            var result = '';
            var status = response['status'];
            if (status == 200) {
                result = $.parseJSON(response["data"]);
            } else if(status == 201){
                result = response;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onGetDifficultyAnalysisInfoComplete");
        } else {
            var response = Platform.makeHttpGetCall(url);
            return onGetDifficultyAnalysisInfoComplete($.parseJSON(response));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getChaptersForQuestions = function (quizId, callback) {
    var url = OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_COURSE +'sfrom' + OBJECT_QUIZ + 'question?nid=' + quizId;
    try {
        onGetChaptersForQuestionsComplete = function (response) {
            var result = '';
            var status = response['status'];
            if (status == 200) {
                result = $.parseJSON(response["data"]);
            } else if(status == 201){
                result = response;
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onGetChaptersForQuestionsComplete");
        } else {
            var response = Platform.makeHttpGetCall(url);
            return onGetChaptersForQuestionsComplete($.parseJSON(response));
        }
    } catch (e) {
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};


MobileQuizService.prototype.uploadQuizLog = function (nid, result_id, log, logType, callback) {
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/logs/uploadquizlog.php?user_id=' + OBJECT_USER_ID + '&quiz_id=' + nid + '&result_id=' + result_id + "&type=" + logType;
            var postdata = log;
            onUploadQuizLogComplete = function (response) {
                SUBMITTED_RESULT_ID = "";
                var status = response['status'];
                var result = "";
                if (status != 200) {
                    result = ERROR_MESSAGE;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            };
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                Platform.makeAjaxPostAsyncCall(url, postdata, "onUploadQuizLogComplete");
            } else {
                var result = Platform.makeHttpPostCall(url, postdata);
                return onUploadQuizLogComplete($.parseJSON(result));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    } catch (e) {
        console.log("function : uploadQuizLog"
            + "\n message:" + e.message);
    }
};

MobileQuizService.prototype.getConsentInteractiveSnippet = function (callback) {
    try{
        var url = OBJECT_SERVER_DOMAIN + '/api/get/statichtml?title=' + CONSENT_SNIPPET_TITLE;
        onGetConsentInteractiveSnippet = function (response) {
            var result = '';
            var status = response['status'];
            if (status == 200) {
                result = $.parseJSON(response["data"]);
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onGetConsentInteractiveSnippet");
        } else {
            var response = Platform.makeHttpGetCall(url);
            return onGetConsentInteractiveSnippet($.parseJSON(response));
        }
    }catch(e){
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
};

MobileQuizService.prototype.getUserQuizConsent = function (quiz_id, callback) {
    try{
        var url = OBJECT_SERVER_DOMAIN + '/api/get/userquizconsent?uid=' + OBJECT_USER_ID + '&quiz_nid=' + quiz_id;
        onGetUserQuizConsent = function (response) {
            var result = '';
            var status = response['status'];
            if (status == 200) {
                if(response["data"] == ""){
                    result = response["data"];
                }else{
                    result = $.parseJSON(response["data"]);
                }
            } else if (status == 401 || status == 403) {
                result = status;
            } else {
                result = ERROR_MESSAGE;
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onGetUserQuizConsent");
        } else {
            var response = Platform.makeHttpGetCall(url);
            return onGetUserQuizConsent($.parseJSON(response));
        }
    }catch(e){
        var error = "url :" + url
                + "\n message :" + e.message;
        console.log(error);
    }
}

MobileQuizService.prototype.setUserQuizConsent = function (consent_data, callback) {
    try {
        if (Platform.getNetworkState() == true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/set/userquizconsent';
            var postdata = JSON.stringify(consent_data);
            onSetUserQuizConsent = function (response) {
                var status = response['status'];
                console.log(response);
                if (status == 200) {
                    if (typeof callback != 'undefined' && typeof callback == 'function') {
                        return callback(response);
                    } else {
                        return response;
                    }
                }else{
                    return ERROR_MESSAGE;
                }
            };
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                Platform.makeAjaxPostAsyncCall(url, postdata, "onSetUserQuizConsent");
            }else{
                var result = Platform.makeHttpPostCall(url, postdata);
                return onSetUserQuizConsent($.parseJSON(result));
            }
        }
    }catch(e){
        console.log("function : onSetUserQuizConsent"
            + "\n message:" + e.message);
    }
}

MobileQuizService.prototype.getTestRemainingDuration = function (result_id) {
    var url = OBJECT_SERVER_DOMAIN + '/api/get/testremainingduration' + '?result_id=' + result_id;
    try {
        onGetTestRemainingDuration = function (data) {
            var result = '';
            var status = data['status'];
            if (status == 200) {
                var remainingDuration = $.parseJSON(data["data"]);
                result = remainingDuration.remaining_duration;
            } else if (status == 401 ) {
                result = "";
            } else if(status == 403){
                return onAccessDenied();
            } else {
                result = t("ERROR_MESSAGE",true);
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(result);
            } else {
                return result;
            }
        }
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            makeAsyncTaskCall("", '', url, "get", "false", "onGetTestRemainingDuration");
        } else {
            var data = Platform.makeHttpGetCall(url);
            return onGetTestRemainingDuration($.parseJSON(data));
        }
    } catch (e) {
        var error = "url :" + url
            + "\n message :" + e.message;
        console.log(error);
    }
};
function TocService() {
    this.utils = CONTROLLER.getService("Utils");
}
;

TocService.prototype.getTocSdCardInfo = function(cid, productToc) {

    if (productToc) {
        var toc = productToc;
    } else {
        var toc = ""
        if(typeof PLATFORM_VERSION != "undefined" && PLATFORM_VERSION == 4.0){
            toc = Platform.getDataFromExternalFileSystem("product.toc");
        }
        else{
            toc = Platform.getDataFromFileSystem("product.toc");
        }
    }
    if(toc == ""){
        return "";
    }
    var sdcard='';
    try {
        toc = $.parseJSON(toc);
        $.each(toc, function(ckeys, group_values) {
            if (group_values instanceof Array || typeof group_values == "object") {
            $.each(group_values, function(keys, values) {
                if (values instanceof Array) {
                    $.each(values, function(key, value) {
                        if (value.nid == cid) {
                            sdcard = value.sdcard;
                            return true;
                        }
                    });
                }
            });
        }
        });

        if (sdcard) {
            return sdcard;
        } else {
            return false;
        }
    }
    catch (e)
    {
        var error = "method : getTocSdCardInfo"
                + "\n response_data :" + toc
                + "\n message :" + e.message;
        console.log(error);
    };

};

TocService.prototype.getChaptersOfSdCard = function() {
    try {
        if(typeof PLATFORM_VERSION != "undefined" && PLATFORM_VERSION == 4.0){
            var chaptersofsdcard = Platform.getDataFromExternalFileSystem("content.toc");
        }
        else{
            chaptersofsdcard = Platform.getDataFromFileSystem("content.toc");
        }
        if(chaptersofsdcard != ""){
            chaptersofsdcard = $.parseJSON(chaptersofsdcard);
        }
        else{
            chaptersofsdcard = "";
        }
        return chaptersofsdcard;
    }
    catch (e) {
        console.log("function : getChaptersOfSdCard"
                + " message" + e.message);
    }
};


function MobileCourseService() {}
;

MobileCourseService.prototype.getChaptersForCourse = function (courseid, groupid)
{
    try {

        if (typeof groupid != "undefined" && groupid != "") {
            var filter = '[{"object_type":"group","object_id":"' + groupid + '","relation_object_type":"' + OBJECT_CHAPTER + '"}]';
            var chapters = Platform.getRelatedObjectsFilter(OBJECT_COURSE, courseid, OBJECT_CHAPTER,filter);
            chapters = $.parseJSON(chapters);
            return chapters;
        }
        else
        {
            var chapters = Platform.getRelatedObjects(OBJECT_COURSE, courseid, OBJECT_CHAPTER);
            chapters = $.parseJSON(chapters);
            return chapters;
        }
    } catch (e) {
        console.log("function : getChaptersForCourse"
                + "\n message:" + e.message);
    }
};

MobileCourseService.prototype.getCourseDetails = function(courseid, groupid){
    try{
        if (COURSE_DETAILS.hasOwnProperty(courseid) == false) {
            var groupFilter = "";
            if (typeof groupid != undefined) {
                groupFilter = '&groupid=' + groupid;
            }
            var courses = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get'+OBJECT_COURSE+'details?uid=' + OBJECT_USER_ID + '&nid=' + courseid + groupFilter);
            try {
                courses = $.parseJSON(courses);
                var status = courses['status'];
                if (status == 200)
                {
                    var data = $.parseJSON(courses['data']);
                    COURSE_DETAILS[courseid] = data;
                    return data;
                }
                else{
                    return "error";
                }
            }
            catch (e)
            {
                var error = "function - getCourseDetails"
                + "\n error_code :" + status
                + "\n response_data :" + data
                + "\n message :" + e.message;
                console.log(error);
            }
        }
        else{
            return COURSE_DETAILS[courseid];
        }
    }
    catch(e){
        console.log("function : downloadAllCategoriesAsync"
            + " message" + e.message);
    }
};

MobileCourseService.prototype.getVideosWatchedByCourse = function (catid) {
    try {
        if(VIDEOS_WATCHED_CATEGORY.hasOwnProperty(catid)){
            return VIDEOS_WATCHED_CATEGORY[catid];
        }
        var url = OBJECT_SERVER_DOMAIN + '/api/getapi/getwatched'+OBJECT_VIDEO+'sfor'+OBJECT_COURSE+'?uid=' + OBJECT_USER_ID;
        var cp = Platform.makeHttpGetCall(url);
        try {
            cp = $.parseJSON(cp);
            var status = cp['status'];
            if (status == 200)
            {
                var data = $.parseJSON(cp['data']);
                VIDEOS_WATCHED_COURSE.push(data);
                return data;
            } else {
                return "error";
            }
        } catch (e)
        {
            var error = "url :" + url
                    + "\n error_code :" + status
                    + "\n response_data :" + data
                    + "\n message :" + e.message;
            console.log(error);
        }

    } catch (e) {
        console.log("function - getVideosWatchedByCourse message - " + e.message);
    }
};

MobileCourseService.prototype.getCoursesForGroup = function (groupid) {
    try {
        if(COURSES_FOR_GROUP.hasOwnProperty(groupid)){
            return COURSES_FOR_GROUP[groupid];
        }
        var group = $.parseJSON(Platform.getObjectProperty(OBJECT_GROUP, groupid, "content"));
        var url = OBJECT_SERVER_DOMAIN + '/api/getall'+OBJECT_COURSE+'s?groups='+group.title+'&uid=' + OBJECT_USER_ID;
        var cp = Platform.makeHttpGetCall(url);
        try {
            cp = $.parseJSON(cp);
            var status = cp['status'];
            if (status == 200)
            {
                var data = $.parseJSON(cp['data']);
                COURSES_FOR_GROUP[groupid]= data;
                return data;
            } else {
                return "error";
            }
        } catch (e)
        {
            var error = "url :" + url
                    + "\n error_code :" + status
                    + "\n response_data :" + data
                    + "\n message :" + e.message;
            console.log(error);
        }

    } catch (e) {
        console.log("function - getCoursesForGroup message - " + e.message);
    }
};

MobileCourseService.prototype.getCourseProgress = function (courseids, callback) {
    try {
        var coursesToBeChecked = [];
        for (var i = 0; i < courseids.length; i++) {
            if (typeof COURSE_PROGRESS[courseids[i]] == "undefined") {
                coursesToBeChecked.push(courseids[i]);
            }
        }
        if (coursesToBeChecked.length > 0) {
            var url = OBJECT_SERVER_DOMAIN + '/api/getuser' + OBJECT_COURSE + 'progress?uid=' + OBJECT_USER_ID + '&courses=' + coursesToBeChecked.join(", ");
            Platform.makePostAsyncCall(url, function (cp) {
                try {
                    var status = cp['status'];
                    if (status == 200)
                    {
                        var data = $.parseJSON(cp['data']);
                        $.extend(COURSE_PROGRESS, data);
                        callback(COURSE_PROGRESS);
                    } else {
                        callback("error");
                    }
                } catch (e)
                {
                    var error = "url :" + url
                            + "\n error_code :" + status
                            + "\n response_data :" + data
                            + "\n message :" + e.message;
                    console.log(error);
                }
            });
        } else {
            callback(COURSE_PROGRESS);
        }
    } catch (e) {
        console.log("function - getCourseProgress message - " + e.message);
    }
};


MobileCourseService.prototype.getTitle = function (catid, courseid, groupid) {
    var course = this.getOffsetForCourse(catid, courseid, groupid);
    if (typeof COURSE_INDEX == "undefined"  || COURSE_INDEX == "") {
        return course.offset + ". " + course.title;
    } else {
        return COURSE_INDEX + ". " + course.title;
    }
}
MobileCourseService.prototype.getCourseTitle = function (param) {
    try {
        if (typeof param == "object") {
            var courseid = param.courseid;
        } else {
            var courseid = param;
        }
	    var catobj = CONTROLLER.getVariable("activecatobj");
	    if(typeof COURSE_INDEX_MAP!= 'undefined' && typeof COURSE_INDEX_MAP[courseid]!= 'undefined'){
	        if(typeof catobj != "undefined" && (typeof catobj.hide_label != "undefined" && catobj.hide_label == 1)){
	             return COURSE_INDEX_MAP[courseid]['title'];
	        }else if (typeof COURSE_INDEX == "undefined" || COURSE_INDEX == "") {
	            return COURSE_INDEX_MAP[courseid]['offset'] + ". " + COURSE_INDEX_MAP[courseid]['title'];
	        }else {
	            return COURSE_INDEX + ". " + COURSE_INDEX_MAP[courseid]['title'];
	        }
	    }else{
	        var course_title = Platform.getObjectProperty(OBJECT_COURSE, courseid, 'content');
	        course_title = $.parseJSON(course_title);
	        return course_title.title;
	    }
	} catch (e) {
        var error = "getCourseTitle"
        + "\n message :" + e.message;
        console.log(error);
    }
}
MobileCourseService.prototype.getAllAssignment = function (courseid,status,callback) {
    // if(typeof status == "undefined" || status == ""){
    //     status = 0;
    // } To list all submitted and not submitted status should be empty string
    var url = OBJECT_SERVER_DOMAIN + '/api/getalluser'+OBJECT_ASSIGNMENT + '?uid=' + OBJECT_USER_ID + '&course_id=' + courseid + "&status=" + status ;
    try {
        if (Platform.getNetworkState() == true) {
            onGetAllAssignment = function(assignment){
                var result = '';
                var status = assignment['status'];
                if (status == 200)
                {
                    var data = assignment['data'];
                    data =  $.parseJSON(data);
                    result = data;
                }
                else{
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "get", "false", "onGetAllAssignment");
            } else {
                var user = Platform.makeHttpGetCall(url);
                return onGetAllAssignment($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getalluser'+OBJECT_ASSIGNMENT+'?uid=' + OBJECT_USER_ID
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }

}
MobileCourseService.prototype.getAssignmentAttempts = function ( courseid) {
    var assignment = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getalluser'+OBJECT_ASSIGNMENT+'?uid=' + OBJECT_USER_ID +'&assignment_nid=' + courseid);
        try {
            assignment = $.parseJSON(assignment);
            var status = assignment['status'];
            if (status == 200)
            {
                var data = $.parseJSON(assignment['data']);
                return data;
            }
            else{
                return "error";
            }
        }
        catch (e)
        {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getalluserassignment' +  '?uid=' + OBJECT_USER_ID
            + "\n error_code :" + status
            + "\n response_data :" + data
            + "\n message :" + e.message;
            console.log(error);
        }
}
MobileCourseService.prototype.getCompletedVideosOfCourseCount = function(groupid, courseid, filetype, contenttype) {
    if (typeof GET_VVH_BY_USER_GROUPS == "undefined") {
        groupid = "";
    }
    var filter = '[{"object_type":' + OBJECT_COURSE + ',"object_id":' + courseid + '},{"object_type":' + OBJECT_CHAPTER + '},{"object_type":' + OBJECT_VIDEO + '}]';
    if ((filetype) && (contenttype)) {
        var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
    } else {
        var ignore_params = "";
    }
    var count = Platform.getVideoViewHistoryCompletedCountByType(OBJECT_USER_ID, groupid, courseid, "course",ignore_params);
    return count;
}

MobileCourseService.prototype.getResourcesCount = function(catid,courseid,groupid,filetype,contenttype){
    try{
        var filter = '[{"object_type":"'+OBJECT_COURSE+'","object_id":"'+courseid+'"},{"object_type":"'+OBJECT_CHAPTER+'"},{"object_type":"'+OBJECT_VIDEO+'"}]';
        if ((filetype) && (contenttype)) {
            var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
        } else {
            var ignore_params = "";
        }
        var resourcescount = Platform.getRelationObjectCount(groupid,OBJECT_CATEGORY,catid,filter,ignore_params);
        return resourcescount;
    }
    catch(e){

    }
}
MobileCourseService.prototype.getCoursesCount = function(catid,groups){
    try{
        var filter = '[{"object_type":'+OBJECT_COURSE+'}]';
        var ignore_params = "";
        var coursescount = Platform.getRelationObjectCount(groups,OBJECT_CATEGORY,catid,filter,ignore_params);
        return coursescount;
    }
    catch(e){

    }
}
MobileCourseService.prototype.getEbookCount = function (catid, courseid, groupid) {
    try {
        var filter = '[{"object_type":"' + OBJECT_COURSE + '","object_id":"' + courseid + '"},{"object_type":"' + OBJECT_BOOK + '"}]';
        var resourcescount = Platform.getRelationObjectCount(groupid, OBJECT_CATEGORY, catid, filter);
        return resourcescount;
    }
    catch (e) {
        console.log("function : getEbookCount"
                + "\n message:" + e.message);
    }
}
MobileCourseService.prototype.getQuizzesCount = function (catid, courseid, groupid) {
    try {
        var filter = '[{"object_type":"' + OBJECT_COURSE + '","object_id":"' + courseid + '"},{"object_type":"' + OBJECT_QUIZ + '"}]';
        var resourcescount = Platform.getRelationObjectCount(groupid, OBJECT_CATEGORY, catid, filter);
        return resourcescount;
    }
    catch (e) {
         console.log("function : getQuizzesCount"
                + "\n message:" + e.message);
    }
}

MobileCourseService.prototype.getResourcesCountByType = function(courseid,groupid,filetype,contenttype){
    try{
        var filter = '[{"object_type":'+OBJECT_CHAPTER+'},{"object_type":'+OBJECT_VIDEO+'}]';
        if ((filetype) && (contenttype)) {
            var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
        } else {
            var ignore_params = "";
        }
        if (typeof GET_VVH_BY_USER_GROUPS == "undefined") {
                groupid = "";
        }
        var resourcescount = Platform.getRelationObjectCount(groupid, OBJECT_COURSE,courseid,filter,ignore_params);
        return resourcescount;
    }
    catch(e){

    }
}


MobileCourseService.prototype.getOffsetForCourse = function (catid, courseid, groupid) {
	if(EXEC_MODE == 'online'){
		if(COURSE_INDEX_MAP.hasOwnProperty(courseid)){
	        return COURSE_INDEX_MAP[courseid];
	    }
	    var categoryService = CONTROLLER.getService("Category");
	    var courses = categoryService.getCoursesForCategory(catid, groupid);
	    courses[OBJECT_COURSE] = sortByRank(courses[OBJECT_COURSE]);
	    COURSE_INDEX_MAP = {};
	    for (var i = 0; i < courses[OBJECT_COURSE].length; i++) {
	        COURSE_INDEX_MAP[courses[OBJECT_COURSE][i].nid] = {"offset":i+1,"title":courses[OBJECT_COURSE][i].title};
	    }
	    return COURSE_INDEX_MAP[courseid];
	} else {
		try{
	        var filter = '[{"object_type":"group","object_id":' + groupid + ',"relation_object_type":' + OBJECT_COURSE + ',"relation_object_id":' + courseid + '}]';
	        var course = Platform.getRelatedObjectByOffset(OBJECT_CATEGORY, catid, OBJECT_COURSE, courseid, filter);
	        course = $.parseJSON(course);
	        return course;
	    }
	    catch(e){
	        console.log("MobileCourseService - getOffsetForCourse : " + e.message);
	        return "";
	    }
	}
}


MobileCourseService.prototype.getEbooksForCourse = function (courseid) {
    if(COURSE_DETAILS.hasOwnProperty(courseid)){
        var ebooks = [];
        ebooks[OBJECT_BOOK] = COURSE_DETAILS[courseid]["ebook"];
        return ebooks;
    }
    var ebooks = Platform.getRelatedObjects(OBJECT_COURSE, courseid, OBJECT_BOOK);
    ebooks = $.parseJSON(ebooks);
    return ebooks;
}

MobileCourseService.prototype.getNotesForCourse = function (courseid)
{
    try {
        if(EXEC_MODE == 'online'){
            var notes = this.getNotesofUserByCourse(courseid);
            notes['chapterToRender'] = notes[OBJECT_CHAPTER]
            return notes;
        } else if(typeof NOTES_OLD != "undefined" && NOTES_OLD == true){
            var notes = Platform.getRelatedObjects(OBJECT_COURSE, courseid, OBJECT_NOTES);
            notes = $.parseJSON(notes);
            return notes;
        }
        var notes_list = [];
        var new_notes_list = [];
        var new_resoursce_list = [];
        var resouse_list;
        var i = 0;
        var new_notes_text_list = [];
        var course_notes = [];
        var chapterService = CONTROLLER.getService("Chapter");
        var courseService = CONTROLLER.getService("Course");
        var notesTemp = "";
        var groupid = CONTROLLER.getVariable("activegroupid");
        var catid = CONTROLLER.getVariable("activecatid");
        var courseid = CONTROLLER.getVariable("activecourseid");
        var chapter = courseService.getChaptersForCourse(courseid, groupid);
        course_notes[OBJECT_NOTES] = [];
        for (j in chapter[OBJECT_CHAPTER]) {
            if (typeof CHECK_CONTENT_BELONGS_TO_USERGROUPS != "undefined" && CHECK_CONTENT_BELONGS_TO_USERGROUPS == "true") {
                videos = chapterService.getResourcesForChapterByGroups(chapter[OBJECT_CHAPTER][j].nid);
            } else {
               videos = chapterService.getResourcesForChapter(chapter[OBJECT_CHAPTER][j].nid);
            }
            for (k in videos[OBJECT_VIDEO]) {
                notesTemp = Platform.getAllNotesByUserID(OBJECT_USER_ID, videos[OBJECT_VIDEO][k].nid);
                try {
                    notesTemp = $.parseJSON(notesTemp);
                    if (!$.isEmptyObject(notesTemp[OBJECT_NOTES])) {
                        for (m in notesTemp[OBJECT_NOTES]) {
                            try {
                                notesTemp[OBJECT_NOTES][m].chapterid = chapter[OBJECT_CHAPTER][j].nid;
                                notesTemp[OBJECT_NOTES][m].courseid = courseid;
                                notesTemp[OBJECT_NOTES][m].catid = catid;
                                notesTemp[OBJECT_NOTES][m].groupid = groupid;
                                notesTemp[OBJECT_NOTES][m].originalDuration = notesTemp[OBJECT_NOTES][m].duration;
                                notesTemp[OBJECT_NOTES][m].note = notesTemp[OBJECT_NOTES][m].note.replace(/^\n+|\n+$/g, '').replace(/^\t+|\t+$/g, '').replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
                                notesTemp[OBJECT_NOTES][m].duration = getVideoDurationFromSeconds(parseInt(notesTemp[OBJECT_NOTES][m].duration, 10)) + "m";
                                course_notes[OBJECT_NOTES].push(notesTemp[OBJECT_NOTES][m]);
                            } catch (e) {
                                console.log("notes loop " + e.message);
                            }

                        }
                    }
                } catch (e) {
                    console.log("get notes " + e.message);
                }
            }
        }

        if (course_notes[OBJECT_NOTES].length > 0) {
            notes_list = course_notes;

            new_notes_list[OBJECT_CHAPTER] = [];
            // Initializing new_notes_list array to store notes
            for (i = 0; i < chapter[CLIENT_OBJECT_CHAPTER].length; i++) {
                new_notes_list[OBJECT_CHAPTER][i] = {};
                new_notes_list[OBJECT_CHAPTER][i].title = chapter[OBJECT_CHAPTER][i].title;
                new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO] = [];
                var chapterid = chapter[OBJECT_CHAPTER][i].nid;
                  if (typeof CHECK_CONTENT_BELONGS_TO_USERGROUPS != "undefined" && CHECK_CONTENT_BELONGS_TO_USERGROUPS == "true") {
                     var resources = chapterService.getResourcesForChapterByGroups(chapterid);
                  } else {
                     var resources = chapterService.getResourcesForChapter(chapterid); // take all videos of each chapter
                  }
                for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
                    new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k] = {};
                    new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k].title = resources[OBJECT_VIDEO][k].title;
                    new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES] = [];
                }
            }

            //comparing all the chapters with the notes_list chapters
            for (i = 0; i < chapter[CLIENT_OBJECT_CHAPTER].length; i++) {
                for (var j = 0; j < course_notes[OBJECT_NOTES].length; j++) {
                    if (chapter[OBJECT_CHAPTER][i].nid == course_notes[OBJECT_NOTES][j].chapterid) {
                        var chapterid = chapter[OBJECT_CHAPTER][i].nid;
                        if (typeof CHECK_CONTENT_BELONGS_TO_USERGROUPS != "undefined" && CHECK_CONTENT_BELONGS_TO_USERGROUPS == "true") {
                            var resources = chapterService.getResourcesForChapterByGroups(chapterid);
                        } else {
                            var resources = chapterService.getResourcesForChapter(chapterid);
                        }
                        //getting all resorces of chapter and adding the notes to array if it contains
                        for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
                            if (resources[OBJECT_VIDEO][k].nid == course_notes[OBJECT_NOTES][j].resourceid) {
                                new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].push(course_notes[OBJECT_NOTES][j]);
                            }
                        }
                    }
                }
            }

            for (i = chapter[CLIENT_OBJECT_CHAPTER].length - 1; i >= 0; i--) {
                notescount = 0;
                for (var k = new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO].length - 1; k >= 0; k--) {
                    notescount = new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO].length;
                    count = new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].length;
                    // removing resourses which does not have notes
                    if (new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].length == 0) {
                        new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k] = "";
                        new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO].splice(k, 1);
                        notescount = notescount - 1;
                    }
                }
                // removing chapters which does not have resourse with notes
                if (notescount == 0) {
                    new_notes_list[OBJECT_CHAPTER][i] = "";
                    new_notes_list[OBJECT_CHAPTER].splice(i, 1);
                }
            }
        }

        new_notes_list['chapterToRender'] = new_notes_list[OBJECT_CHAPTER];

        return new_notes_list;
    } catch (e) {
        console.log("function : getNotesForCourse"
                + "\n message:" + e.message);
    }
};
MobileCourseService.prototype.getDownloadTime = function(){
    var downloadTime = "";
    if(typeof LAST_DOWNLOAD_ADDED_TIME == 'undefined' || LAST_DOWNLOAD_ADDED_TIME == 0 || LAST_DOWNLOAD_ADDED_TIME < new Date().getTime()) {
        downloadTime = 0;
        LAST_DOWNLOAD_ADDED_TIME = new Date().getTime();
    }
    else{
        downloadTime = (LAST_DOWNLOAD_ADDED_TIME + 500) - new Date().getTime();
        LAST_DOWNLOAD_ADDED_TIME = LAST_DOWNLOAD_ADDED_TIME + 500;
    }
    return downloadTime;
}
MobileCourseService.prototype.downloadAllResourcesOfCourse = function(courseid)
{
    var resources = "";
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var quizService = CONTROLLER.getService("Quiz");
    var chapters = this.getChaptersForCourse(courseid);
    var resourceStatus = "";
    var THIS = this;
    if(CONTROLLER.getVariable("catid_addedfor_download")){
        var catid = CONTROLLER.getVariable("catid_addedfor_download");
    }else{
        var catid = CONTROLLER.getVariable("activecatid");
    }
    this.downloadContentsOfCourse(chapters,courseid,catid);

    this.downloadQuizOfCourse(courseid,catid);

    this.downloadSupportDocsofCourse(chapters,courseid,catid);

    CONTROLLER.setVariable("courseleveldownload","true");
}

MobileCourseService.prototype.downloadContentsOfCourse = function(chapters,courseid, catid)
{
    var resourceStatus = "";
    var resources = {};resources[OBJECT_VIDEO] = [];
    var THIS = this;
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var quizService = CONTROLLER.getService("Quiz");
    var download_action = CONTROLLER.getVariable("download_action");
    for (var j = 0; j < chapters[OBJECT_CHAPTER].length; j++) {
        var contents = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][j].nid, OBJECT_VIDEO))
        for(var i=0; i<contents[OBJECT_VIDEO].length; i++){
            if ((contents[OBJECT_VIDEO][i].type != "HTML" && contents[OBJECT_VIDEO][i].source != "Link" && contents[OBJECT_VIDEO][i].filetype != "jpg") || (contents[OBJECT_VIDEO][i].type == "Assessment" && (contents[OBJECT_VIDEO][i].source == "Link" || contents[OBJECT_VIDEO][i].source == "Upload"))) {
                resources[OBJECT_VIDEO].push(contents[OBJECT_VIDEO][i]);
            }
        }
    }
    for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
        (function(k) {
            var downloadTime = THIS.getDownloadTime();
            setTimeout(function() {
                try{
                    if (resources[OBJECT_VIDEO][k]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                        var quiz = quizService.getQuiz(resources[OBJECT_VIDEO][k].url);
                        if (typeof quiz != "undefined") {
                            if (download_action == "download") {
                                var status = contentUtils.checkResourceDownloadStatus(resources[OBJECT_VIDEO][k].nid, "content", courseid, resources[OBJECT_VIDEO][k].youtube_id);
                                if (status != 2) {
                                    quiz['contentid'] = resources[OBJECT_VIDEO][k]["nid"];
                                    contentUtils.fileDownload(quiz, "", "", "", courseid, OBJECT_QUIZ, catid);
                                }
                            } else {
                                quiz['contentid'] = resources[OBJECT_VIDEO][k]["nid"];
                                contentUtils.fileDownload(quiz, "", "", "", courseid, OBJECT_QUIZ, catid);
                            }
                        }
                    }
                    else {
                        if (download_action == "download") {
                            var status = contentUtils.checkResourceDownloadStatus(resources[OBJECT_VIDEO][k].nid, "content", courseid, resources[OBJECT_VIDEO][k].youtube_id);
                            if (status != 2) {
                                contentUtils.fileDownload(resources[OBJECT_VIDEO][k], RESOURCE_FORMAT, "", "", courseid, OBJECT_VIDEO, catid);
                            }
                        } else {
                            contentUtils.fileDownload(resources[OBJECT_VIDEO][k], RESOURCE_FORMAT, "", "", courseid, OBJECT_VIDEO, catid);
                        }
                    }
                }
                catch(e){
                    console.log(e.message + " resource = " + JSON.stringify(resources[OBJECT_VIDEO]) + " index = " + k);
                }
            },downloadTime);
        }(k));
    }
}
MobileCourseService.prototype.downloadQuizOfCourse = function(courseid, catid){
    var THIS = this;
    var all_quizzes = this.getQuizForCourse(courseid);
    var contentUtils = CONTROLLER.getService("ContentUtils");
    if (!$.isEmptyObject(all_quizzes[OBJECT_QUIZ])) {
        for (var i in all_quizzes[OBJECT_QUIZ]) {
            var downloadTime = THIS.getDownloadTime();
            (function(i) {
                setTimeout(function() {
                    var status = contentUtils.checkResourceDownloadStatus(all_quizzes[OBJECT_QUIZ][i]['nid'], OBJECT_QUIZ, courseid, all_quizzes[OBJECT_QUIZ][i]['changed']);
                    if (status != 2) {
                        contentUtils.fileDownload(all_quizzes[OBJECT_QUIZ][i], "", "", "", courseid, OBJECT_QUIZ, catid);
                    }
                }, downloadTime);
            }(i));
        }
    }
}
MobileCourseService.prototype.downloadSupportDocsofCourse = function(chapters,courseid, catid)
{
    var THIS = this;
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var downloadTime = "";
    var supportdocs = {};
    supportdocs[OBJECT_SUPPORT_DOCS] = [];
    for (var j = 0; j < chapters[OBJECT_CHAPTER].length; j++) {
        var docs = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][j].nid, OBJECT_SUPPORT_DOCS));
        for(var i=0; i<docs[OBJECT_SUPPORT_DOCS].length; i++){
            supportdocs[OBJECT_SUPPORT_DOCS].push(docs[OBJECT_SUPPORT_DOCS][i]);
        }
    }
    if (!$.isEmptyObject(supportdocs[OBJECT_SUPPORT_DOCS])) {
        for (var i in supportdocs[OBJECT_SUPPORT_DOCS]) {
            (function(i) {
                downloadTime = THIS.getDownloadTime();
                setTimeout(function() {
                    var status = contentUtils.checkResourceDownloadStatus(supportdocs[OBJECT_SUPPORT_DOCS][i]['nid'], OBJECT_SUPPORT_DOCS, courseid);
                    if (status != 2) {
                        if (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] != RESOURCE_TYPE_LINK || (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] == RESOURCE_TYPE_LINK && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['type'] == RESOURCE_TYPE_HTML && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['url']).indexOf(OBJECT_SERVER_DOMAIN) >= 0))))) {
                            contentUtils.fileDownload(supportdocs[OBJECT_SUPPORT_DOCS][i], DOCUMENT_FORMAT, "", "", courseid, OBJECT_SUPPORT_DOCS, catid);
                        }
                    }
                }, downloadTime);
            }(i));
        }
    }
}
MobileCourseService.prototype.getCourseById = function (courseid)
{
    try {
        var course = Platform.getObjectProperty(OBJECT_COURSE, courseid, "content");
        course = $.parseJSON(course);
        return course;
    } catch (e) {
        console.log("function : getCourseById"
                + "\n message:" + e.message);
    }

};

MobileCourseService.prototype.getQuizForCourse = function (courseid)
{
    try {
        if (EXEC_MODE == "online") {
            if(COURSE_DETAILS.hasOwnProperty(courseid)){
                var quiz = [];
                quiz[OBJECT_QUIZ] = COURSE_DETAILS[courseid]["quiz"];
                return quiz;
            }
		} else {
			var quiz = Platform.getRelatedObjects(OBJECT_COURSE, courseid, OBJECT_QUIZ);
	        chapters = $.parseJSON(quiz);
	        return chapters;
		}
    } catch (e) {
        console.log("function : getQuizForCourse"
                + "\n message:" + e.message);
    }
};

MobileCourseService.prototype.downloadAllBooksOfCourse = function(courseid)
{
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var books = this.getEbooksForCourse(courseid);
    var resourceStatus = "";
    if(CONTROLLER.getVariable("catid_addedfor_download")){
        var catid = CONTROLLER.getVariable("catid_addedfor_download");
    }else{
        var catid = CONTROLLER.getVariable("activecatid");
    }
    for(i in books[OBJECT_BOOK]){
        (function (i) {
            var downloadTime = THIS.getDownloadTime();
            setTimeout(function () {
                try {
                    resourceStatus = contentUtils.checkResourceDownloadStatus(books[OBJECT_BOOK][i]["nid"], OBJECT_BOOK, courseid);
                    if (resourceStatus == 0) {
                        contentUtils.fileDownload(books[OBJECT_BOOK][i], RESOURCE_FORMAT, "", "", courseid, OBJECT_BOOK, catid);
                    }
                } catch (e) {
                    console.log(e.message + " book = " + JSON.stringify(books[OBJECT_BOOK]) + " index = " + i);
                }
            }, downloadTime);
        }(i));
    }
}

MobileCourseService.prototype.pauseAllDownloadsOfCourse = function(courseid)
{
    var resources = "";
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var quizService = CONTROLLER.getService("Quiz");
    var chapters = this.getChaptersForCourse(courseid);
    var resourceStatus = "";
    var all_quizzes = this.getQuizForCourse(courseid);
    var THIS = this;
    if (!$.isEmptyObject(all_quizzes)) {
        for (var i in all_quizzes[OBJECT_QUIZ]) {
            (function(i) {
                var downloadTime = THIS.getDownloadTime();
                setTimeout(function() {
                    try{
                        var status = contentUtils.checkResourceDownloadStatus(all_quizzes[OBJECT_QUIZ][i]['nid'], OBJECT_QUIZ, courseid, all_quizzes[OBJECT_QUIZ][i]['changed']);
                        if (status == 2) {
                            Platform.pauseDownload(all_quizzes[OBJECT_QUIZ][i]['nid']);
                        }
                    } catch (e) {
                        console.log(e.message + " quiz = " + JSON.stringify(all_quizzes[OBJECT_QUIZ]) + " index = " + i);
                    }
                }, downloadTime);
            }(i));
        }
    }
    for (i in chapters[OBJECT_CHAPTER]) {
        resources = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][i].nid, OBJECT_VIDEO));
        var supportdocs = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][i].nid, OBJECT_SUPPORT_DOCS));
        if (!$.isEmptyObject(supportdocs[OBJECT_SUPPORT_DOCS])) {
            for (var i in supportdocs[OBJECT_SUPPORT_DOCS]) {
                (function (i) {
                    var downloadTime = THIS.getDownloadTime();
                    setTimeout(function () {
                        try {
                            var status = contentUtils.checkResourceDownloadStatus(supportdocs[OBJECT_SUPPORT_DOCS][i]['nid'], OBJECT_SUPPORT_DOCS, courseid);
                            if (status == 2) {
                                if (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] != RESOURCE_TYPE_LINK || (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] == RESOURCE_TYPE_LINK && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['type'] == RESOURCE_TYPE_HTML && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['url']).indexOf(OBJECT_SERVER_DOMAIN) >= 0))))) {
                                    Platform.pauseDownload(supportdocs[OBJECT_SUPPORT_DOCS][i]['nid']);
                                }
                            }
                        } catch (e) {
                            console.log(e.message + " support docs = " + JSON.stringify(supportdocs[OBJECT_SUPPORT_DOCS]) + " index = " + i);
                        }
                    }, downloadTime);
                }(i));
            }
        }
        for (j in resources[OBJECT_VIDEO]) {
            (function (j) {
                var downloadTime = THIS.getDownloadTime();
                setTimeout(function () {
                    try {
                        var content = Platform.getObjectProperty(OBJECT_VIDEO, resources[OBJECT_VIDEO][j]["nid"], "content");
                        content = $.parseJSON(content);
                        var quiz = quizService.getQuiz(content.url);
                        if (resources[OBJECT_VIDEO][j]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                            if (typeof quiz != "undefined") {
                                resourceStatus = contentUtils.checkResourceDownloadStatus(quiz["nid"], OBJECT_QUIZ, courseid, quiz['changed']);
                            }
                            else{
                                return;
                            }
                        } else {
                            resourceStatus = contentUtils.checkResourceDownloadStatus(resources[OBJECT_VIDEO][j]["nid"], OBJECT_VIDEO, courseid);
                        }
                        if (resourceStatus == 2) {
                            if (resources[OBJECT_VIDEO][j]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                                if (typeof quiz != "undefined") {
                                    Platform.pauseDownload(quiz["nid"]);
                                }
                            } else {
                                if (resources[OBJECT_VIDEO][j]['source'] != RESOURCE_TYPE_LINK || (resources[OBJECT_VIDEO][j]['source'] == RESOURCE_TYPE_LINK && ((resources[OBJECT_VIDEO][j]['type'] == RESOURCE_TYPE_HTML && ((resources[OBJECT_VIDEO][j]['url']).indexOf(OBJECT_SERVER_DOMAIN) >= 0))))) {
                                    Platform.pauseDownload(resources[OBJECT_VIDEO][j].nid);
                                }
                            }
                        }
                    } catch (e) {
                        console.log(e.message + " resource = " + JSON.stringify(resources[OBJECT_VIDEO]) + " index = " + j);
                    }
                }, downloadTime);
            }(j));
        }
    }
    var books = this.getEbooksForCourse(courseid);
    for(i in books[OBJECT_BOOK]){
        (function (i) {
            var downloadTime = THIS.getDownloadTime();
            setTimeout(function () {
                try {
                    resourceStatus = contentUtils.checkResourceDownloadStatus(books[OBJECT_BOOK][i]["nid"], OBJECT_BOOK, courseid);
                    if (resourceStatus == 2) {
                        Platform.pauseDownload(books[OBJECT_BOOK][i].nid);
                    }
                } catch (e) {
                    console.log(e.message + " books = " + JSON.stringify(books[OBJECT_BOOK]) + " index = " + j);
                }
            }, downloadTime);
        }(i));
    }
}

MobileCourseService.prototype.deleteAllDownloadsOfCourse = function(courseid)
{
    var resources = "";
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var quizService = CONTROLLER.getService("Quiz");
    var chapters = this.getChaptersForCourse(courseid);
    var resourceStatus = "";
    var all_quizzes = this.getQuizForCourse(courseid);
    var THIS = this;
    if (!$.isEmptyObject(all_quizzes[OBJECT_QUIZ])) {
        for (var i in all_quizzes[OBJECT_QUIZ]) {
            (function (i) {
                var downloadTime = THIS.getDownloadTime();
                setTimeout(function () {
                    try {
                        var status = contentUtils.checkResourceDownloadStatus(all_quizzes[OBJECT_QUIZ][i]['nid'], OBJECT_QUIZ, courseid, all_quizzes[OBJECT_QUIZ][i]['changed']);
                        if (status != 0) {
                            Platform.deleteFileFromInternalMemory(all_quizzes[OBJECT_QUIZ][i]['nid']);
                        }
                    } catch (e) {
                        console.log(e.message + " quiz = " + JSON.stringify(all_quizzes[OBJECT_QUIZ]) + " index = " + j);
                    }
                }, downloadTime);
            }(i));
        }
    }
    for(i in chapters[OBJECT_CHAPTER]){
        resources = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][i].nid, OBJECT_VIDEO));
        var supportdocs = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][i].nid, OBJECT_SUPPORT_DOCS));
        if (!$.isEmptyObject(supportdocs)) {
            for (var i in supportdocs[OBJECT_SUPPORT_DOCS]) {
                (function (i) {
                    var downloadTime = THIS.getDownloadTime();
                    setTimeout(function () {
                        try {
                            var status = contentUtils.checkResourceDownloadStatus(supportdocs[OBJECT_SUPPORT_DOCS][i]['nid'], OBJECT_SUPPORT_DOCS, courseid);
                            if (status != 0) {
                                if (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] != RESOURCE_TYPE_LINK || (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] == RESOURCE_TYPE_LINK && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['type'] == RESOURCE_TYPE_HTML && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['url']).indexOf(OBJECT_SERVER_DOMAIN) >= 0))))) {
                                    Platform.deleteFileFromInternalMemory(supportdocs[OBJECT_SUPPORT_DOCS][i]['nid']);
                                }
                            }
                        } catch (e) {
                            console.log(e.message + " support docs = " + JSON.stringify(supportdocs[OBJECT_SUPPORT_DOCS]) + " index = " + i);
                        }
                    }, downloadTime);
                }(i));
            }
        }
        for(j in resources[OBJECT_VIDEO]){
            (function (j) {
                var downloadTime = THIS.getDownloadTime();
                setTimeout(function () {
                    try {
                        var content = Platform.getObjectProperty(OBJECT_VIDEO, resources[OBJECT_VIDEO][j]["nid"], "content");
                        content = $.parseJSON(content);
                        var quiz = quizService.getQuiz(content.url);
                        if (resources[OBJECT_VIDEO][j]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                            if (typeof quiz != "undefined") {
                                resourceStatus = contentUtils.checkResourceDownloadStatus(quiz["nid"], OBJECT_QUIZ, courseid, quiz['changed']);
                            }
                            else{
//                                continue;
                                return;
                            }
                        } else {
                            resourceStatus = contentUtils.checkResourceDownloadStatus(resources[OBJECT_VIDEO][j]["nid"], OBJECT_VIDEO, courseid);
                        }
                        if (resourceStatus != 0) {
                            if (resources[OBJECT_VIDEO][j]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                                if (typeof quiz != "undefined") {
                                    Platform.deleteFileFromInternalMemory(quiz["nid"]);
                                }
                            } else {
                                if (resources[OBJECT_VIDEO][j]['source'] != RESOURCE_TYPE_LINK || (resources[OBJECT_VIDEO][j]['source'] == RESOURCE_TYPE_LINK && ((resources[OBJECT_VIDEO][j]['type'] == RESOURCE_TYPE_HTML && ((resources[OBJECT_VIDEO][j]['url']).indexOf(OBJECT_SERVER_DOMAIN) >= 0))))) {
                                    Platform.deleteFileFromInternalMemory(resources[OBJECT_VIDEO][j].nid);
                                }
                            }
                        }
                    } catch (e) {
                        console.log(e.message + " resource = " + JSON.stringify(resources[OBJECT_VIDEO]) + " index = " + j);
                    }
                }, downloadTime);
            }(j));
        }
    }
    var books = this.getEbooksForCourse(courseid);
    for(i in books[OBJECT_BOOK]){
        (function (i) {
            var downloadTime = THIS.getDownloadTime();
            setTimeout(function () {
                try {
                    resourceStatus = contentUtils.checkResourceDownloadStatus(books[OBJECT_BOOK][i]["nid"], OBJECT_BOOK, courseid);
                    if (resourceStatus != 0) {
                        Platform.deleteFileFromInternalMemory(books[OBJECT_BOOK][i].nid);
                    }
                } catch (e) {
                    console.log(e.message + " books = " + JSON.stringify(books[OBJECT_BOOK]) + " index = " + j);
                }
            }, downloadTime);
        }(i));
    }
}

MobileCourseService.prototype.resumeAllDownloadsOfCourse = function(courseid)
{
    var resources = "";
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var quizService = CONTROLLER.getService("Quiz");
    var chapters = this.getChaptersForCourse(courseid);
    var resourceStatus = "";
    var all_quizzes = this.getQuizForCourse(courseid);
    var THIS = this;
    if (!$.isEmptyObject(all_quizzes)) {
        for (var i in all_quizzes[OBJECT_QUIZ]) {
            (function (i) {
                var downloadTime = THIS.getDownloadTime();
                setTimeout(function () {
                    try {
                        var status = contentUtils.checkResourceDownloadStatus(all_quizzes[OBJECT_QUIZ][i]['nid'], OBJECT_QUIZ, courseid, all_quizzes[OBJECT_QUIZ][i]['changed']);
                        if (status != 0) {
                            Platform.resumeDownload(all_quizzes[OBJECT_QUIZ][i]['nid']);
                        }
                    } catch (e) {
                        console.log(e.message + " quiz = " + JSON.stringify(all_quizzes[OBJECT_QUIZ]) + " index = " + i);
                    }
                }, downloadTime);
            }(i));
        }
    }
    for(i in chapters[OBJECT_CHAPTER]){
        resources = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][i].nid, OBJECT_VIDEO));
        var supportdocs = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][i].nid, OBJECT_SUPPORT_DOCS));
        if (!$.isEmptyObject(supportdocs)) {
            for (var i in supportdocs[OBJECT_SUPPORT_DOCS]) {
                (function (i) {
                    var downloadTime = THIS.getDownloadTime();
                    setTimeout(function () {
                        try {
                            var status = contentUtils.checkResourceDownloadStatus(supportdocs[OBJECT_SUPPORT_DOCS][i]['nid'], OBJECT_SUPPORT_DOCS, courseid);
                            if (status != 0) {
                                if (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] != RESOURCE_TYPE_LINK || (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] == RESOURCE_TYPE_LINK && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['type'] == RESOURCE_TYPE_HTML && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['url']).indexOf(OBJECT_SERVER_DOMAIN) >= 0))))) {
                                    Platform.resumeDownload(supportdocs[OBJECT_SUPPORT_DOCS][i]['nid']);
                                }
                            }
                        } catch (e) {
                            console.log(e.message + " support docs = " + JSON.stringify(supportdocs[OBJECT_SUPPORT_DOCS]) + " index = " + i);
                        }
                    }, downloadTime);
                }(i));
            }
        }
        for(j in resources[OBJECT_VIDEO]){
            (function (j) {
                var downloadTime = THIS.getDownloadTime();
                setTimeout(function () {
                    try {
                        var content = Platform.getObjectProperty(OBJECT_VIDEO, resources[OBJECT_VIDEO][j]["nid"], "content");
                        content = $.parseJSON(content);
                        var quiz = quizService.getQuiz(content.url);
                        if (resources[OBJECT_VIDEO][j]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                            if (typeof quiz != "undefined") {
                                resourceStatus = contentUtils.checkResourceDownloadStatus(quiz["nid"], OBJECT_QUIZ, courseid, quiz['changed']);
                            }
                            else{
//                                continue;
                                return;
                            }
                        } else {
                            resourceStatus = contentUtils.checkResourceDownloadStatus(resources[OBJECT_VIDEO][j]["nid"], OBJECT_VIDEO, courseid);
                        }
                        if (resourceStatus == 2) {
                            if (resources[OBJECT_VIDEO][j]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                                if (typeof quiz != "undefined") {
                                    Platform.resumeDownload(quiz["nid"]);
                                }
                            } else {
                                Platform.resumeDownload(resources[OBJECT_VIDEO][j].nid);
                            }
                        }
                    } catch (e) {
                        console.log(e.message + " resource = " + JSON.stringify(resources[OBJECT_VIDEO]) + " index = " + j);
                    }
                }, downloadTime);
            }(j));
        }
    }
    var books = this.getEbooksForCourse(courseid);
    for(i in books[OBJECT_BOOK]){
        (function (i) {
            var downloadTime = THIS.getDownloadTime();
            setTimeout(function () {
                try {
                    resourceStatus = contentUtils.checkResourceDownloadStatus(books[OBJECT_BOOK][i]["nid"], OBJECT_BOOK, courseid);
                    if (resourceStatus == 2) {
                        Platform.resumeDownload(books[OBJECT_BOOK][i].nid);
                    }
                } catch (e) {
                    console.log(e.message + " books = " + JSON.stringify(books[OBJECT_BOOK]) + " index = " + j);
                }
            }, downloadTime);
        }(i));
    }
}

MobileCourseService.prototype.getBatchScheduleOfCourse = function(groupid,courseid)
{
    var batchScheduleOfCourse = [];
    batchScheduleOfCourse[OBJECT_COURSE] = {};

    try {
        if(typeof OBJECT_BATCH_SCHEDULE != 'undefined' && OBJECT_BATCH_SCHEDULE != ''){
            var batchSchedule = $.parseJSON(Platform.getRelatedObjects(OBJECT_GROUP, groupid, OBJECT_BATCH_SCHEDULE));
            if(batchSchedule != null && !$.isEmptyObject(batchSchedule[OBJECT_BATCH_SCHEDULE]))
                batchSchedule = batchSchedule[OBJECT_BATCH_SCHEDULE][0];

            if (typeof batchSchedule != 'undefined' && !$.isEmptyObject(batchSchedule[OBJECT_COURSE])) {
                for (var i in batchSchedule[OBJECT_COURSE]) {
                    if(batchSchedule[OBJECT_COURSE][i].nid == courseid) {
                        batchScheduleOfCourse[OBJECT_COURSE] = batchSchedule[OBJECT_COURSE][i];
                        break;
                    }
                }
            }
        }
        return batchScheduleOfCourse;
    } catch (e) {
        console.log("function : getBatchScheduleOfCourse"
                + "\n message:" + e.message);
        return batchScheduleOfCourse;
    }
}

MobileCourseService.prototype.getNotesofUserByCourse = function(courseid)
{
    try {

        if(typeof NOTES_OF_COURSE != "undefined" && NOTES_OF_COURSE.length > 0){
            for (var i = 0; i < NOTES_OF_COURSE.length; i++) {
                if(typeof NOTES_OF_COURSE[i].courseid != "undefined" && NOTES_OF_COURSE[i].courseid == courseid)
                    return NOTES_OF_COURSE[i].notes_data;
            }
        }

        var courseService = CONTROLLER.getService("Course");
        var i = 0;
        var courseid = CONTROLLER.getVariable("activecourseid");
        var catid = CONTROLLER.getVariable("activecatid");
        var groupid = CONTROLLER.getVariable("activegroupid");
        var courseNotes = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getnotesofuserby' + OBJECT_COURSE + '?uid=' + OBJECT_USER_ID + '&nid=' + courseid);
        courseNotes = $.parseJSON(courseNotes);
        var notes = {};
        var status = courseNotes['status'];
        if (status == 200) {
            var data = courseNotes['data'];
            notes[OBJECT_NOTES] = $.parseJSON(data);
            var chapter = courseService.getChaptersForCourse(courseid, groupid);
            for (var i = 0; i < notes[OBJECT_NOTES].length; i++) {
                var chapterid = "";
                notes[OBJECT_NOTES][i]['catid'] = catid;
                notes[OBJECT_NOTES][i]['groupid'] = groupid;
                notes[OBJECT_NOTES][i]['courseid'] = courseid;
                notes[OBJECT_NOTES][i].originalDuration = notes[OBJECT_NOTES][i].duration;
                notes[OBJECT_NOTES][i].note = notes[OBJECT_NOTES][i].note.replace(/^\n+|\n+$/g, '').replace(/^\t+|\t+$/g, '').replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
                notes[OBJECT_NOTES][i].duration = getVideoDurationFromSeconds(parseInt(notes[OBJECT_NOTES][i].duration, 10)) + "m";

                var content = Platform.getObjectProperty(OBJECT_VIDEO, notes[OBJECT_NOTES][i]['resourceid'], "content");
                content = $.parseJSON(content);
                if(content[OBJECT_CHAPTER].length > 1){
                    for (var j in content[OBJECT_CHAPTER]) {
                        for (var k in chapter[OBJECT_CHAPTER]) {
                            if(content[OBJECT_CHAPTER][j] == chapter[OBJECT_CHAPTER][k]['nid'])
                                chapterid = content[OBJECT_CHAPTER][j];
                        }
                    }
                }else{
                    chapterid = content[OBJECT_CHAPTER][0];
                }
                notes[OBJECT_NOTES][i]['chapterid'] = chapterid;
            }
            var new_notes_list = this.getNotesofCourseWithTopicDetails(notes, chapter);

            var notesCourse ={};
            notesCourse.courseid = courseid;
            notesCourse.notes_data = new_notes_list;
            NOTES_OF_COURSE.push(notesCourse);

            return new_notes_list;
        } else {
            return false;
        }
    } catch (e) {
        console.log("function : getCourseById"
                + "\n message:" + e.message);
    }

};

MobileCourseService.prototype.getNotesofCourseWithTopicDetails = function(notes, chapter)
{
    try {
        var new_notes_list = [];
        var notes_list = [];
        var chapterService = CONTROLLER.getService("Chapter");
         if (notes[OBJECT_NOTES].length > 0) {
            notes_list = notes;

            new_notes_list[OBJECT_CHAPTER] = [];
            // Initializing new_notes_list array to store notes
            for (var i = 0; i < chapter[CLIENT_OBJECT_CHAPTER].length; i++) {
                new_notes_list[OBJECT_CHAPTER][i] = {};
                new_notes_list[OBJECT_CHAPTER][i].title = chapter[OBJECT_CHAPTER][i].title;
                new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO] = [];
                var chapterid = chapter[OBJECT_CHAPTER][i].nid;
                var resources = chapterService.getResourcesForChapter(chapterid, CONTROLLER.getVariable("activecourseid"))// take all videos of each chapter
                for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
                    new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k] = {};
                    new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k].title = resources[OBJECT_VIDEO][k].title;
                    new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES] = [];
                }
            }

            //comparing all the chapters with the notes_list chapters
            for (var i = 0; i < chapter[CLIENT_OBJECT_CHAPTER].length; i++) {
                for (var j = 0; j < notes[OBJECT_NOTES].length; j++) {
                    if (chapter[OBJECT_CHAPTER][i].nid == notes[OBJECT_NOTES][j].chapterid) {
                        var chapterid = chapter[OBJECT_CHAPTER][i].nid;
                        var resources = chapterService.getResourcesForChapter(chapterid, CONTROLLER.getVariable("activecourseid"));
                        //getting all resorces of chapter and adding the notes to array if it contains
                        for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
                            if (resources[OBJECT_VIDEO][k].nid == notes[OBJECT_NOTES][j].resourceid) {
                                new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].push(notes[OBJECT_NOTES][j]);
                            }
                        }
                    }
                }
            }
            for (i = chapter[CLIENT_OBJECT_CHAPTER].length - 1; i >= 0; i--) {
                notescount = 0;
                for (var k = new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO].length - 1; k >= 0; k--) {
                    notescount = new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO].length;
                    count = new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].length;
                    // removing resourses which does not have notes
                    if (new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].length == 0) {
                        new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO][k] = "";
                        new_notes_list[OBJECT_CHAPTER][i][OBJECT_VIDEO].splice(k, 1);
                        notescount = notescount - 1;
                    }
                }
                // removing chapters which does not have resourse with notes
                if (notescount == 0) {
                    new_notes_list[OBJECT_CHAPTER][i] = "";
                    new_notes_list[OBJECT_CHAPTER].splice(i, 1);
                }
            }
            }
             return new_notes_list;
    } catch (e) {
        console.log("function : getCourseById"
                + "\n message:" + e.message);
    }

};

function MobileChapterService(){};

MobileChapterService.prototype.downloadSingleChapter = function(chapterid,changed){
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'fromid?nid=' + chapterid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&changed=' + changed + "&version=" + API_VERSION, OBJECT_CHAPTER+"-"+chapterid);
    }
    catch(e){
        console.log("function : downloadSingleChapter"
            + " message" + e.message);
    }
};

MobileChapterService.prototype.downloadChaptersForCourseAsync = function(courseid){
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'sfor' + OBJECT_COURSE + '?nid=' + courseid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET, OBJECT_CHAPTER+"-"+courseid);
    }
    catch(e){
        console.log("function : downloadChaptersForCourseAsync"
            + " message" + e.message);
    }
};

MobileChapterService.prototype.getResourcesForChapter = function(chapterid, param)
{
    /*
*    array of resource_ids = getRelationsOfType(OBJECT_CHAPTER,chapterid,OBJECT_VIDEO);
*    getObjects(OBJECT_CHAPTER,array of resource ids,"content");
*
*/
    //api/getresourcesforchapter
    try{
        if(EXEC_MODE == "mobile" || EXEC_MODE == "online"){
			var courseid = param;
			if(EXEC_MODE == "online"){
				if(typeof courseid != "undefined" && COURSE_DETAILS.hasOwnProperty(courseid)){
	                var chapter = getJSONByKey(COURSE_DETAILS[courseid][OBJECT_CHAPTER],"nid",chapterid);
	                var videos = [];
	                videos[OBJECT_VIDEO] = chapter[0][OBJECT_VIDEO];
	                return videos;
	            }
	            var resources = Platform.getRelatedObjects(OBJECT_CHAPTER, chapterid, OBJECT_VIDEO);
			} else {
				var group = param; 
				if (group) {
	                var filter = '[{"object_type":"group","object_id":"' + group + '" ,"relation_object_type":"' + OBJECT_VIDEO + '"}]';
	                var resources = Platform.getRelatedObjectsFilter(OBJECT_CHAPTER, chapterid, OBJECT_VIDEO, filter);
	            } else {
	                var resources = Platform.getRelatedObjects(OBJECT_CHAPTER, chapterid, OBJECT_VIDEO);
	            }
			}
            
            resources = $.parseJSON(resources);
            return resources;
        }
        else{
            var strResourceData = {};
            strResourceData[OBJECT_VIDEO] = [  {
                        "nid":"230196",
                        "title":"krishi Hatti krishi yojane hagu bittane Cotton-BK.pdf",
                        "description":"test data",
                        "rank":"1",
                        "expiry":"",
                        "price":"Free",
                        "source":"Upload",
                        "type":"Document",
                        "viewcount":null,
                        "duration":null,
                        "external_id":"256",
                        "external_name":"krishi Hatti krishi yojane hagu bittane Cotton-BK.pdf",
                        "filetype":"pdf"
                    },{
                        "nid":"230198",
                        "title":"krishi Hatti krishi yojane hagu bittane Cotton-BS.pdf",
                        "description":"test data",
                        "rank":"2",
                        "expiry":"",
                        "price":"Free",
                        "source":"Upload",
                        "type":"Document",
                        "viewcount":"1",
                        "duration":"test data",
                        "external_id":"257",
                        "external_name":"krishi Hatti krishi yojane hagu bittane Cotton-BS.pdf",
                        "filetype":"pdf"
                    },{
                        "nid":"231758",
                        "title":"krishi Hatti krishi yojane hagu bittane Besaya saamagrigalu.mp3",
                        "description":"test data",
                        "rank":"3",
                        "expiry":"",
                        "price":"Free",
                        "source":"Upload",
                        "type":"Video",
                        "viewcount":"test data",
                        "duration":"141",
                        "external_id":"1021",
                        "external_name":"krishi Hatti krishi yojane hagu bittane Besaya saamagrigalu.mp3",
                        "format-type":"240",
                        "stream-type":"rtmp,rtmpe,http,rtsp",
                        "filetype":"mp3"
                    },{
                        "nid":"231760",
                        "title":"krishi",
                        "description":"test data",
                        "rank":"4",
                        "expiry":"",
                        "price":"Free",
                        "source":"Upload",
                        "type":"Video",
                        "viewcount":null,
                        "duration":"145",
                        "external_id":"1022",
                        "external_name":"krishi Hatti krishi yojane hagu bittane Bittane.mp3",
                        "format-type":"240",
                        "stream-type":"rtmp,rtmpe,http,rtsp",
                        "filetype":"mp4"
                    },{
                        "nid":"231762",
                        "title":"krishi",
                        "description":"test data",
                        "rank":"5",
                        "expiry":"",
                        "price":"Free",
                        "source":"Upload",
                        "type":"Video",
                        "viewcount":null,
                        "duration":"95",
                        "external_id":"1023",
                        "external_name":"krishi Hatti krishi yojane hagu bittane Bittane2.mp3",
                        "format-type":"240",
                        "stream-type":"rtmp,rtmpe,http,rtsp",
                        "filetype":"mp4"
                    }];

            return strResourceData;
        }
    }
    catch(e){
        console.log( "function : getResourcesForChapter"
            + "\n message:" + e.message);
    }
};

MobileChapterService.prototype.getResourcesCount = function(catid,courseid,groupid,chapterid){
    try{
        var filter = '[{"object_type":"'+OBJECT_COURSE+'","object_id":"'+courseid+'"},{"object_type":"'+OBJECT_CHAPTER+'","object_id":"'+chapterid+'"},{"object_type":"'+OBJECT_VIDEO+'"}]';
        var resourcescount = Platform.getRelationObjectCount(groupid,OBJECT_CATEGORY,catid,filter);
        return resourcescount;
    }
    catch(e){
        
    }
}

MobileChapterService.prototype.getCompletedVideosOfChapterCount = function (groupid,chapterid) {
    groupid = "";
    var count = Platform.getVideoViewHistoryCompletedCountByType(OBJECT_USER_ID,groupid,chapterid,"chapter");
    return count;
}

MobileChapterService.prototype.getSupportDocsForChapter = function(chapterid)
{
    var resources = Platform.getRelatedObjects(OBJECT_CHAPTER, chapterid, OBJECT_SUPPORT_DOCS);
    resources = $.parseJSON(resources);
    return resources;
}


MobileChapterService.prototype.getChapterById = function(chapterid)
{
    try{
        var chapter = Platform.getObjectProperty(OBJECT_CHAPTER, chapterid, "content");
        chapter = $.parseJSON(chapter);
        return chapter;
    }
    catch(e){
        console.log( "function : getChapterById"
            + "\n message:" + e.message);
    }
};

function downloadModifiedChapter(pendingobjectdata){
    //download pending chapter data
    if((pendingobjectdata.operation == 'modified' || pendingobjectdata.operation == 'created') && pendingobjectdata.offline != '0'){
        DataManager.downloadSingleChapter(pendingobjectdata.nid,pendingobjectdata.changed);
    } else if(pendingobjectdata.offline == '0' || pendingobjectdata.operation == 'delete'){
        DataManager.removeObject(OBJECT_CHAPTER,pendingobjectdata.nid);
        Platform.deleteVideoViewHistoryByType(pendingobjectdata.nid,"chapter");
    }
}


MobileChapterService.prototype.getResourcesForChapterByGroups = function(chapterid) {
    try {
        var chapter_resources = [];
        var content_groups_array = this.getContentGroupsForVideViedwedHistory();
        var content_groups = content_groups_array.length ? ('"' + content_groups_array.join('","') + '"') : "";
        var content_groups = CONTROLLER.getVariable("contentGroups");
        for (var i = 0; i < content_groups.length; i++) {
            var resources = this.getResourcesForChapter(chapterid, content_groups[i].nid);
            for (var j = 0; j < resources[OBJECT_VIDEO].length; j++) {
                chapter_resources.push(resources[OBJECT_VIDEO][j]);
            }
        }
        resources[OBJECT_VIDEO] = chapter_resources;
        return resources;

    } catch (e) {
        Client.closeProgressBar();
        console.log("function :- getResourcesForChapterByGroups, message :- " + e.message);
    }
}

MobileChapterService.prototype.getContentGroupsForVideViedwedHistory = function() {
    var contentGroups = CONTROLLER.getVariable("contentGroups");
    var user_content_groups = [];
    for (var i = 0; i < contentGroups.length; i++) {
        user_content_groups.push(contentGroups[i].nid)
    }
    return user_content_groups;
}
function MobileCategoryService() {};

//Sync methods
MobileCategoryService.prototype.downloadSingleCategory = function(catid,changed){
    
    //api to download single category asynchronously
    
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CATEGORY + 'fromid?nid=' + catid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&changed=' + changed + "&version=" + API_VERSION, OBJECT_CATEGORY+"-"+catid);
    }
    catch(e){
        console.log("function : downloadSingleCategory"
            + " message" + e.message);
    }
};

MobileCategoryService.prototype.downloadAllCategoriesAsync = function(){
    try{
        var url = OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET;
        Platform.downloadForm(url, OBJECT_CATEGORY);
    }
    catch(e){
        console.log("function : downloadAllCategoriesAsync"
            + " message" + e.message);
    }
};

MobileCategoryService.prototype.downloadPendingCategories = function(pendingobjectdata, pendingdata){
    try{
        for(var i=0;i<pendingobjectdata.length;i++){
            try{
                var category = DataManager.getObjectProperty(OBJECT_CATEGORY,pendingobjectdata[i].nid,"content");
                if(!$.isEmptyObject(category)){
                    if(category.thumbnail_id != pendingobjectdata[i].thumbnail_id){
                        DataManager.deleteMetaImage(OBJECT_CATEGORY, category.nid);
                    }
                }
                if(!$.isEmptyObject(pendingobjectdata[i])){
                    Platform.removeObject(OBJECT_CATEGORY,pendingobjectdata[i].nid);
                    Platform.saveObject(OBJECT_CATEGORY, pendingobjectdata[i].nid, "content", JSON.stringify(pendingobjectdata[i]));
                    if(typeof OBJECT_GROUP != "undefined" && OBJECT_GROUP != ""){
                        addManyToOneRelation(OBJECT_GROUP, pendingobjectdata[i][OBJECT_GROUP], OBJECT_CATEGORY , pendingobjectdata[i].nid);
                    }
                    DataManager.downloadImage(pendingobjectdata[i].nid, pendingobjectdata[i].thumbnail_filename, OBJECT_PRESET, FILE_DOWNLOAD_ASYNC);
                }
            }
            catch(e){
                var error = "FOR LOOP : method - downloadPendingCategories"
                + "\n data = " + JSON.stringify(pendingobjectdata[i])
                + "\n message:" + e.message
                console.log(error);
            }
                
        }
        Platform.removePendingEvent(pendingdata["id"]);
    }
    catch(e){
        console.log( "function : downloadPendingCategories"
            + "\n message:" + e.message);
    }
}

//get methods
MobileCategoryService.prototype.getAllCategories = function(useAudiPrepApiVersion)
{
    //api to get all categories from database
    
    try{
        var categories = Platform.getObjectsOfType(OBJECT_CATEGORY, useAudiPrepApiVersion);
        return $.parseJSON(categories);
    }
    catch(e){
        console.log( "function : getAllCategories"
            + "\n message:" + e.message);
    }

};

MobileCategoryService.prototype.getCategoriesForGroup = function(groupid)
{
    if(CATEGORIES_FOR_GROUPS[groupid]){
        return $.extend(true,{},CATEGORIES_FOR_GROUPS[groupid]);
    }
    if(EXEC_MODE == "test"){
        if(typeof BUILD_VERSION != "undefined" && BUILD_VERSION != ""){
            var version = '&version=' + BUILD_VERSION;
        }
        else{
            version = '';
        }
        if(typeof limit == "undefined" && typeof page == "undefined"){
            var limit = "";
            var page = "";
        }
        var theUrl = OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?groups=Class 1&uid=' + OBJECT_USER_ID  + version + '&limit=' + limit + '&page=' + page;
        var response = httpGet(theUrl);
        if(response == ''){
            var data = '';
        }else{
            if(OBJECT_CLIENT_NAME == "ekisaan" || OBJECT_CLIENT_NAME == "pearson"){
                response = '{"'+OBJECT_CATEGORY+'":'+response+'}';
            }
            data = $.parseJSON(response);
        }
        return data;
    }
    else{
        var categories = Platform.getRelatedObjects(OBJECT_GROUP, groupid, OBJECT_CATEGORY);
        categories = $.parseJSON(categories);
        categories[OBJECT_CATEGORY].forEach(function (category, i) {
            if (getJSONByKey(CATEGORY_DETAILS, "nid", category["nid"]) == false) {
                CATEGORY_DETAILS.push(category);
            }
        });
        CATEGORIES_FOR_GROUPS[groupid] = $.extend(true,{},categories);
        return categories;
    }
};

MobileCategoryService.prototype.getCategoryFromid = function(catid)
{
    if(getJSONByKey(CATEGORY_DETAILS,"nid",catid) != false){
        return getJSONByKey(CATEGORY_DETAILS,"nid",catid);
    }
    else{
        var category = Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content");
        return $.parseJSON(category);
    }
};

MobileCategoryService.prototype.getCoursesForCategory = function(catid,groupid)
{
    /*
*    array of course_ids = getRelationsOfType(OBJECT_CATEGORY,catid,OBJECT_COURSE);
*    getObjects(OBJECT_COURSE,array of course_ids,"content");
*
*/
    //api/getcoursesforcategory
    try{
        if (typeof groupid != "undefined" && groupid != "") {
            var filter = '[{"object_type":"group","object_id":' + groupid + ',"relation_object_type":"' + OBJECT_COURSE + '"}]';
            var courses = Platform.getRelatedObjectsFilter(OBJECT_CATEGORY, catid, OBJECT_COURSE, filter);
        }
        else
        {
            var courses = Platform.getRelatedObjects(OBJECT_CATEGORY, catid, OBJECT_COURSE)
        }
        courses = $.parseJSON(courses);
        return courses;
    }
    catch(e){

    }
};

MobileCategoryService.prototype.getNotesForCategory = function(catid,groupid)
{
    try {
        if(EXEC_MODE == 'online'){
            var notes = this.getNotesofUserByCategory(catid, groupid);
            return notes;
        } else if(typeof NOTES_OLD != "undefined" && NOTES_OLD == true){
            if (typeof groupid != "undefined" && groupid != "") {
                var filter = '[{"object_type":"group","object_id":' + groupid + ',"relation_object_type":' + OBJECT_NOTES + '}]';
                var notes = Platform.getRelatedObjectsFilter(OBJECT_CATEGORY, catid, OBJECT_NOTES, filter);
                notes = $.parseJSON(notes);
                return notes;
            }
            else
            {
                var courses = Platform.getRelatedObjects(OBJECT_CATEGORY, catid, OBJECT_COURSE)
                courses = $.parseJSON(courses);
                return courses;
            }
        } else {
        var courseService = CONTROLLER.getService("Course");
        var chapterService = CONTROLLER.getService("Chapter");
            if (typeof catid != 'undefined' && catid != '' && ( (typeof GROUP_SUBSCRIPTION != "undefined" && GROUP_SUBSCRIPTION != "true" ) || typeof GROUP_SUBSCRIPTION == "undefined")) {
                var courses = this.getCoursesForCategory(catid, groupid);
            } else {
                var group_service =  CONTROLLER.getService("Group");
                var groups = OBJECT_USER_DATA.groups;
                var courses = group_service.getCoursesForUserGroups(groups);
            }
        var chapters = "";
        var notesTemp = "";
        var videos = "";
        var category_notes = [];
        var new_notes_list = [];
	        new_notes_list[OBJECT_COURSE] = [];
        	new_notes_list['courseToRender'] = [];
	        category_notes[OBJECT_NOTES] = [];
	        var groupid = CONTROLLER.getVariable("activegroupid");
            if (EXEC_MODE != "online") {
                for (a in courses[OBJECT_COURSE]) {
                    chapters = courseService.getChaptersForCourse(courses[OBJECT_COURSE][a].nid, groupid);
                    for (j in chapters[OBJECT_CHAPTER]) {
	                    if (typeof CHECK_CONTENT_BELONGS_TO_USERGROUPS != "undefined" && CHECK_CONTENT_BELONGS_TO_USERGROUPS == "true") {
	                        var videos = chapterService.getResourcesForChapterByGroups(chapters[OBJECT_CHAPTER][j].nid);
	                    } else {
	                        videos = chapterService.getResourcesForChapter(chapters[OBJECT_CHAPTER][j].nid);
	                    }
	                	for (k in videos[OBJECT_VIDEO]) {
	                    notesTemp = Platform.getAllNotesByUserID(OBJECT_USER_ID, videos[OBJECT_VIDEO][k].nid);
	                    try {
	                        notesTemp = $.parseJSON(notesTemp);
	                        if (!$.isEmptyObject(notesTemp[OBJECT_NOTES])) {
	                            for (m in notesTemp[OBJECT_NOTES]) {
	                                try {
	                                    notesTemp[OBJECT_NOTES][m].chapterid = chapters[OBJECT_CHAPTER][j].nid;
	                                    notesTemp[OBJECT_NOTES][m].courseid = courses[OBJECT_COURSE][a].nid;
	                                        if (typeof catid != 'undefined' && catid != '') {
	                                            notesTemp[OBJECT_NOTES][m].catid = catid;
	                                        } else {
	                                            notesTemp[OBJECT_NOTES][m].catid = courses[OBJECT_COURSE][a][OBJECT_CATEGORY][0];
	                                        }
	                                    notesTemp[OBJECT_NOTES][m].groupid = groupid;
	                                    notesTemp[OBJECT_NOTES][m].note = notesTemp[OBJECT_NOTES][m].note.replace(/^\s+|\s+$/g, '').replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
	                                    notesTemp[OBJECT_NOTES][m].originalDuration = notesTemp[OBJECT_NOTES][m].duration;
	                                    notesTemp[OBJECT_NOTES][m].duration = getVideoDurationFromSeconds(parseInt(notesTemp[OBJECT_NOTES][m].duration, 10)) + "m";
	                                    category_notes[OBJECT_NOTES].push(notesTemp[OBJECT_NOTES][m]);
	                                } catch (e) {
	                                    console.log("notes loop " + e.message);
	                                }
	                            }
	                        }
	                    } catch (e) {
	                        console.log("get notes " + e.message);
	                    }
	                }
	            }
	        }
        } else {
            category_notes = this.getNotesofUserByCategory(catid);
            for (var i = 0; i < category_notes[OBJECT_NOTES].length; i++) {
                category_notes[OBJECT_NOTES][i]['catid'] = catid;
                category_notes[OBJECT_NOTES][i]['groupid'] = groupid;
                category_notes[OBJECT_NOTES][i].originalDuration = category_notes[OBJECT_NOTES][i].duration;
                category_notes[OBJECT_NOTES][i].note = category_notes[OBJECT_NOTES][i].note.replace(/^\n+|\n+$/g, '').replace(/^\t+|\t+$/g, '').replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
                category_notes[OBJECT_NOTES][i].duration = getVideoDurationFromSeconds(parseInt(category_notes[OBJECT_NOTES][i].duration, 10)) + "m";
                var content = Platform.getObjectProperty(OBJECT_VIDEO, category_notes[OBJECT_NOTES][i]['resourceid'], "content");
                content = $.parseJSON(content);
                var chapterid = content[OBJECT_CHAPTER][0];
                category_notes[OBJECT_NOTES][i]['chapterid'] = chapterid;
                var chaptercontent = Platform.getObjectProperty(OBJECT_CHAPTER, chapterid, "content");
                chaptercontent = $.parseJSON(chaptercontent);
                category_notes[OBJECT_NOTES][i]['courseid'] = chaptercontent[OBJECT_COURSE][0];
            }
        }
        if(category_notes[OBJECT_NOTES].length > 0){
            for (l = 0; l < courses[OBJECT_COURSE].length; l++) {
                new_notes_list[OBJECT_COURSE][l] = [];
                new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER] = [];
                new_notes_list['courseToRender'][l] = new_notes_list[OBJECT_COURSE][l];
                new_notes_list['courseToRender'][l]['chapterToRender'] = new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER];
            var chapters = courseService.getChaptersForCourse(courses[OBJECT_COURSE][l].nid);
            for (i = 0; i < chapters[CLIENT_OBJECT_CHAPTER].length; i++) {
                new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i] = {};
                new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i].title = chapters[OBJECT_CHAPTER][i].title;
                new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO] = [];
                var chapterid = chapters[OBJECT_CHAPTER][i].nid;
                 if (typeof CHECK_CONTENT_BELONGS_TO_USERGROUPS != "undefined" && CHECK_CONTENT_BELONGS_TO_USERGROUPS == "true") {
                       var resources = chapterService.getResourcesForChapterByGroups(chapterid);
                    } else {
                       var resources = chapterService.getResourcesForChapter(chapterid);
                    }
                for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
                    new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k] = {};
                    new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k].title = resources[OBJECT_VIDEO][k].title;
                    new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES] = [];
                }
            }

            for (i = 0; i < chapters[CLIENT_OBJECT_CHAPTER].length; i++) {
                for (var j = 0; j < category_notes[OBJECT_NOTES].length; j++) {
                    if (chapters[OBJECT_CHAPTER][i].nid == category_notes[OBJECT_NOTES][j].chapterid) {
                        var chapterid = chapters[OBJECT_CHAPTER][i].nid;
                         if (typeof CHECK_CONTENT_BELONGS_TO_USERGROUPS != "undefined" && CHECK_CONTENT_BELONGS_TO_USERGROUPS == "true") {
                            resources = chapterService.getResourcesForChapterByGroups(chapterid);
                         } else {
                            resources = chapterService.getResourcesForChapter(chapterid);
                         }
                        for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
                            if (resources[OBJECT_VIDEO][k].nid == category_notes[OBJECT_NOTES][j].resourceid) {
                                new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].push(category_notes[OBJECT_NOTES][j]);
                            }
                        }
                    }
                }
            }
        }
        for (l = courses[OBJECT_COURSE].length - 1; l >= 0; l--) {
            topicsnotescount = 0;
                var chapters = courseService.getChaptersForCourse(courses[OBJECT_COURSE][l].nid, groupid);
                for (i = chapters[CLIENT_OBJECT_CHAPTER].length - 1; i >= 0; i--) {
                      notescount = 0;
                    for (var k = new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO].length - 1; k >= 0; k--) {
                        notescount = new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO].length;
                        count = new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].length;
                        if (new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].length == 0) {
                            new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k] = "";
                            new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO].splice(k, 1);
                            notescount = notescount - 1;
                        }
                    }
                    topicsnotescount = topicsnotescount + notescount;
                    if (notescount == 0) {
                        new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i] = "";
                        new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER].splice(i, 1);
                    }
                }
                if (topicsnotescount == 0) {
                    new_notes_list[OBJECT_COURSE][l] = "";
                    new_notes_list[OBJECT_COURSE].splice(l, 1);
                }
            }

        }
        

        return new_notes_list;
        }
        
    } catch (e) {
        console.log("function :- getNotesByCategory message :- " + e.message);
    }
};

MobileCategoryService.prototype.getResourcesCount = function(catid,courseid,groupid,filetype,contenttype){
    try{
        if(courseid){
             var filter = '[{"object_type":"'+OBJECT_COURSE+'","object_id":"'+courseid+'"},{"object_type":"'+OBJECT_CHAPTER+'"},{"object_type":"'+OBJECT_VIDEO+'"}]';
        }else{
             var filter = '[{"object_type":"'+OBJECT_COURSE+'"},{"object_type":"'+OBJECT_CHAPTER+'"},{"object_type":"'+OBJECT_VIDEO+'"}]';
        }
        if ((filetype) && (contenttype)) {
            var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
        } else {
            var ignore_params = "";
        }
        var resourcescount = Platform.getRelationObjectCount(groupid,OBJECT_CATEGORY,catid,filter,ignore_params);
        return resourcescount;
    }
    catch(e){
        
    }
}
MobileCategoryService.prototype.getVideosCount = function (catid, courseid, groupid) {
    try {
        var filter = '[{"object_type":"' + OBJECT_COURSE + '"},{"object_type":"' + OBJECT_CHAPTER + '"},{"object_type":"' + OBJECT_VIDEO + '"}]';
        var resourcescount = Platform.getRelationObjectCount(groupid, OBJECT_CATEGORY, catid, filter);
        return resourcescount;
    }
    catch (e) {
        console.log("function : getVideosCount"
                + "\n message:" + e.message);
    }
}
MobileCategoryService.prototype.getEbookCount = function (catid, courseid, groupid) {
    try {
        var filter = '[{"object_type":"' + OBJECT_COURSE + '"},{"object_type":"' + OBJECT_BOOK + '"}]';
        var resourcescount = Platform.getRelationObjectCount(groupid, OBJECT_CATEGORY, catid, filter);
        return resourcescount;
    }
    catch (e) {
        console.log("function : getEbookCount"
                + "\n message:" + e.message);
    }
}
MobileCategoryService.prototype.getQuizzesCount = function (catid, courseid, groupid) {
    try {
        var filter = '[{"object_type":"' + OBJECT_COURSE + '"},{"object_type":"' + OBJECT_QUIZ + '"}]';
        var resourcescount = Platform.getRelationObjectCount(groupid, OBJECT_CATEGORY, catid, filter);
        return resourcescount;
    }
    catch (e) {
          console.log("function : getQuizzesCount"
                + "\n message:" + e.message);
    }
}
MobileCategoryService.prototype.getSupportDocsCount = function(catid, courseid, groupid, filetype, contenttype) {
    try {
            if (typeof GET_VVH_BY_USER_GROUPS == "undefined") {
                groupid = "";
            }
            if ((filetype) && (contenttype)) {
              var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
            } else {
              var ignore_params = "";
            }
            var filter = '[{"object_type":' + OBJECT_COURSE + ',"object_id":' + courseid + '},{"object_type":' + OBJECT_CHAPTER + '},{"object_type":' + OBJECT_SUPPORT_DOCS + '}]';
			if(EXEC_MODE == 'online'){
				var supportdocscount = Platform.getRelationObjectCount(groupid,OBJECT_CATEGORY,catid,filter,courseid,OBJECT_SUPPORT_DOCS);
			} else {
				var supportdocscount = Platform.getRelationObjectCount(groupid, OBJECT_CATEGORY, catid, filter, ignore_params);
			}
            return supportdocscount;
    }
    catch (e) {

    }
}
MobileCategoryService.prototype.getSupportdocsCount = function(catid,courseid,groupid,filetype,contenttype){
    try{
        if(courseid){
             var filter = '[{"object_type":"'+OBJECT_COURSE+'","object_id":"'+courseid+'"},{"object_type":"'+OBJECT_CHAPTER+'"},{"object_type":"'+OBJECT_SUPPORT_DOCS+'"}]';
        }else{
             var filter = '[{"object_type":"'+OBJECT_COURSE+'"},{"object_type":"'+OBJECT_CHAPTER+'"},{"object_type":"'+OBJECT_SUPPORT_DOCS+'"}]';
        }
        if ((filetype) && (contenttype)) {
            var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
        } else {
            var ignore_params = "";
        }
        var resourcescount = Platform.getRelationObjectCount(groupid,OBJECT_CATEGORY,catid,filter,ignore_params);
        return resourcescount;
    }
    catch(e){
        console.log(e.message);
    }
}
MobileCategoryService.prototype.getEbooksCount = function(catid,courseid,groupid,filetype,contenttype){
    try{
        if(courseid){
             var filter = '[{"object_type":"'+OBJECT_COURSE+'","object_id":"'+courseid+'"},{"object_type":"'+OBJECT_BOOK+'"}]';
        }else{
             var filter = '[{"object_type":"'+OBJECT_COURSE+'"},{"object_type":"'+OBJECT_BOOK+'"}]';
        }
        if ((filetype) && (contenttype)) {
            var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
        } else {
            var ignore_params = "";
        }
        var resourcescount = Platform.getRelationObjectCount(groupid,OBJECT_CATEGORY,catid,filter,ignore_params);
        return resourcescount;
    }
    catch(e){
        console.log(e.message);
    }
}
MobileCategoryService.prototype.downloadModifiedCategory = function(pendingobjectdata){
    //download modified category data
    if((pendingobjectdata.operation == 'modified' || pendingobjectdata.operation == 'created') ){
        DataManager.downloadSingleCategory(pendingobjectdata.nid,pendingobjectdata.changed);
    } else if(pendingobjectdata.operation == 'delete'){
        DataManager.removeObject(OBJECT_CATEGORY,pendingobjectdata.nid);
        Platform.deleteVideoViewHistoryByType(pendingobjectdata.nid,"subject");
    }
}

MobileCategoryService.prototype.getTitle = function (param) {
    if (typeof param == "object") {
        var catid = param.catid;
    } else {
        var catid = param;
    }
	if(EXEC_MODE == 'online'){
		var category = this.getCategoryFromid(catid);
    	return category[0].title;
	} else {
		var category = Platform.getObjectProperty(CLIENT_OBJECT_CATEGORY, catid, "content");
	    category = $.parseJSON(category);
	    return category.title;
	}
}

MobileCategoryService.prototype.getCompletedVideosOfSubjectCount = function (groupid, catid, filetype, contenttype) {
    if ((filetype) && (contenttype)) {
        var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
    } else {
        var ignore_params = "";
    }
    var count = Platform.getVideoViewHistoryCompletedCountByType(OBJECT_USER_ID,groupid,catid,'subject',ignore_params);
    return count;
}

MobileCategoryService.prototype.downloadAllResourcesOfCategory = function(catid)
{
    var resources = "";
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var quizService = CONTROLLER.getService("Quiz");
    var chapters = this.getChaptersForCourse(courseid);
    var resourceStatus = "";
    CONTROLLER.setVariable("courseleveldownload","true");
    var all_quizzes = this.getQuizForCourse(courseid);
    if (!$.isEmptyObject(all_quizzes[OBJECT_QUIZ])) {
        for (var i in all_quizzes[OBJECT_QUIZ]) {
            var status = contentUtils.checkResourceDownloadStatus(all_quizzes[OBJECT_QUIZ][i]['nid'], OBJECT_QUIZ, courseid, all_quizzes[OBJECT_QUIZ][i]['changed']);
            if (status == 0) {
                contentUtils.fileDownload(all_quizzes[OBJECT_QUIZ][i], "", "", all_quizzes[OBJECT_QUIZ][i]['title'] + " downloaded successfully", courseid, OBJECT_QUIZ, CONTROLLER.getVariable("activecatid"));
            }
        }
    }
    for(i in chapters[OBJECT_CHAPTER]){
        resources = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][i].nid, OBJECT_VIDEO));
        var supportdocs = $.parseJSON(Platform.getRelatedObjects(OBJECT_CHAPTER, chapters[OBJECT_CHAPTER][i].nid, OBJECT_SUPPORT_DOCS));
        if (!$.isEmptyObject(supportdocs)) {
            for (var i in supportdocs[OBJECT_SUPPORT_DOCS]) {
                var status = contentUtils.checkResourceDownloadStatus(supportdocs[OBJECT_SUPPORT_DOCS][i]['nid'], OBJECT_SUPPORT_DOCS, courseid);
                if (status == 0) {
                    if (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] != RESOURCE_TYPE_LINK || (supportdocs[OBJECT_SUPPORT_DOCS][i]['source'] == RESOURCE_TYPE_LINK && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['type'] == RESOURCE_TYPE_HTML && ((supportdocs[OBJECT_SUPPORT_DOCS][i]['url']).indexOf(OBJECT_SERVER_DOMAIN) >= 0))))) {
                        contentUtils.fileDownload(supportdocs[OBJECT_SUPPORT_DOCS][i], DOCUMENT_FORMAT, "", supportdocs[OBJECT_SUPPORT_DOCS][i]['title'] + " downloaded successfully", courseid, OBJECT_SUPPORT_DOCS, CONTROLLER.getVariable("activecatid"));
                    }
                }
            }
        }
        for (j in resources[OBJECT_VIDEO]) {
            var content = Platform.getObjectProperty(OBJECT_VIDEO, resources[OBJECT_VIDEO][j]["nid"], "content");
            content = $.parseJSON(content);
            if (resources[OBJECT_VIDEO][j]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                var quiz = quizService.getQuiz(content.url);
                if (typeof quiz != "undefined" ) {
                    resourceStatus = contentUtils.checkResourceDownloadStatus(quiz["nid"], OBJECT_QUIZ, courseid, quiz['changed']);
                }else{
                    continue;
                }
            }else{
                resourceStatus = contentUtils.checkResourceDownloadStatus(resources[OBJECT_VIDEO][j]["nid"], OBJECT_VIDEO, courseid);
            }
            if(resourceStatus == 0){
                if (resources[OBJECT_VIDEO][j]["type"] == RESOURCE_TYPE_ASSESSMENT) {
                if(typeof quiz != "undefined" ){
                        quiz['contentid'] = resources[OBJECT_VIDEO][j]["nid"];
                        contentUtils.fileDownload(quiz, "", "", resources[OBJECT_VIDEO][j]['title'] + " downloaded successfully", courseid, OBJECT_QUIZ,CONTROLLER.getVariable("activecatid"));
                }
            }else{
                if (resources[OBJECT_VIDEO][j]['source'] != RESOURCE_TYPE_LINK || (resources[OBJECT_VIDEO][j]['source'] == RESOURCE_TYPE_LINK && ((resources[OBJECT_VIDEO][j]['type'] == RESOURCE_TYPE_HTML && ((resources[OBJECT_VIDEO][j]['url']).indexOf(OBJECT_SERVER_DOMAIN) >= 0))))) {

                    contentUtils.fileDownload(resources[OBJECT_VIDEO][j], RESOURCE_FORMAT, "", resources[OBJECT_VIDEO][j].title + " downloaded successfully", courseid, OBJECT_VIDEO,CONTROLLER.getVariable("activecatid"));
                }
        }
            }
        }
    }
    CONTROLLER.setVariable("courseleveldownload","");
}
MobileCategoryService.prototype.getAllCategoriesOnline = function (groupid)
{
//    for Aakash live user getAllCategoriesOnline and save in database
    var contentUtilsService = CONTROLLER.getService("ContentUtils");
    if (typeof groupid != "undefined" && groupid != "") {
        var group = Platform.getObjectProperty("group", groupid, "content");
        group = $.parseJSON(group);
        var grouptitle = group.title;
    }
    else if (typeof CLIENT_GROUP != "undefined")
    {
        grouptitle = CLIENT_GROUP;
    }
    else {
        grouptitle = "";
    }
    if (Platform.getNetworkState() == true) {

        var ts = new Date().getTime();


        //Make syncronous call to download the category meta data
        var url = OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle) + '&t=' + ts;
        var categories = Platform.makeHttpGetCall(url);
        try
        {
            if (!$.isEmptyObject(categories))
            {
                categories = $.parseJSON(categories);
                var status = categories['status'];
                if (status == 103) {
                    categories = this.retry(url);
                }
                if (status == 200 || categories != false)
                {
                    var data = $.parseJSON(categories['data']);
                    //download each cateogry
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].nid != null) {
                            if (typeof LIMIT_BOOTSTRAP != "undefined" && LIMIT_BOOTSTRAP) {
                                delete data[i].description;
                                delete data[i].price;
                                delete data[i].external_id;
                            }
                            Platform.saveObject(OBJECT_CATEGORY, data[i].nid, "content", JSON.stringify(data[i]));
                            if (typeof groupid != "undefined" && groupid != "") {
                                Platform.addRelation("group", groupid, OBJECT_CATEGORY, data[i].nid, "");
                            }
                        }
                    }
                }
                else
                {
                    console.log("error status" + status + ", getAllCategoriesOnline ");
                    this.setBootstrapStatus("failed", group, status);
                }
            } else {
                console.log("Empty JSON data" + " getAllCategoriesOnline ");
                status = "Empty JSON data url :- " + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle) + '&t=' + ts;
                this.setBootstrapStatus("failed", group, status);
            }
        }
        catch (e)
        {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle)
                    + "\n error_code :" + status
                    + "\n response_data :" + data
                    + "\n message :" + e.message;
            +"\n getAllCategories for Aakash live ";
            console.log(error);
            this.setBootstrapStatus("failed", group, status);
        }
        ;
    }
    else
    {
        this.setBootstrapStatus("failed", group, status);
    }
};
MobileCategoryService.prototype.retry = function (url) {
    try {
        var data = "";
        var THIS = this;
        console.log("retrying for url - " + url);
        for (var i = 0; i <= 100; i++) {
            sleep(5000);
            data = Platform.makeHttpGetCall(url);
            if (!$.isEmptyObject(data))
            {
                data = $.parseJSON(data);
                var status = data['status'];
            }
            console.log(status);
            if (status == 200) {
                return data;
            }
            if (i == 100) {
                if (confirm('Unable to continue after multiple attempts to connect, do you want to retry again?') == true) {
                    i = 0;
                }
            }
        }
        return false;
    }
    catch (e) {
        console.log("retry failed for url - " + url);
    }
}

MobileCategoryService.prototype.addCategoriestoUserGroups = function(groupid) {
    var contentUtilsService = CONTROLLER.getService("ContentUtils");
    if (typeof groupid != "undefined" && groupid != "") {
        var group = Platform.getObjectProperty("group", groupid, "content");
        group = $.parseJSON(group);
        var grouptitle = group.title;
    }
    else if (typeof CLIENT_GROUP != "undefined")
    {
        grouptitle = CLIENT_GROUP;
    }
    else {
        grouptitle = "";
    }
    if (Platform.getNetworkState() == true) {

        var ts = new Date().getTime();
        //Make syncronous call to download the category meta data
        var url = OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle) + '&t=' + ts;
        var categories = Platform.makeHttpGetCall(url);
        try
        {
            if (!$.isEmptyObject(categories))
            {
                categories = $.parseJSON(categories);
                var status = categories['status'];
                if (status == 103) {
                    categories = this.retry(url);
                }
                if (status == 200 || categories != false)
                {
                    var data = $.parseJSON(categories['data']);
                    //download each cateogry
                    for (var i = 0; i < data.length; i++) {
                        if (typeof groupid != "undefined" && groupid != "") {
                            ADD_RELATION_ARRAY.push({"objecttype":"group","objectid":groupid,"relobjecttype":OBJECT_CATEGORY,"relobjectid":data[i].nid});
                        }
                    }
                }
                else
                {
                    console.log("error status" + status);
                }
            } else {
                console.log("Empty JSON data");
                status = "Empty JSON data url :- " + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle) + '&t=' + ts;
            }
        }
        catch (e)
        {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&groups=' + encodeURIComponent(grouptitle)
                    + "\n error_code :" + status
                    + "\n response_data :" + data
                    + "\n message :" + e.message;
            console.log(error);
        }
        ;
    }
};

MobileCategoryService.prototype.getCompletedVideosOfCategoryCount = function (groupid,categoryid,filetype,contenttype) {
    if ((filetype) && (contenttype)) {
        var ignore_params = '[{"filetype": "' + filetype + '", "type": "' + contenttype + '"}]';
    } else {
        var ignore_params = "";
    }
    var count = Platform.getVideoViewHistoryCompletedCountByType(OBJECT_USER_ID,groupid,categoryid,"subject",ignore_params);
    return count;
}
MobileCategoryService.prototype.getSearchedChapters = function (groupid,categoryid,searchtext) {
    var filter = '[{"object_type":"group","object_id":' + groupid + ',"relation_object_type":' + OBJECT_CATEGORY+ ',"relation_object_id":'+ categoryid+'}]';
    var chapters = Platform.searchObject(searchtext,OBJECT_CHAPTER,100,0,filter);
    chapters = $.parseJSON(chapters);
    return chapters;
}
MobileCategoryService.prototype.getTagsForCategory = function(catid,callback){
    try{
        var url = OBJECT_SERVER_DOMAIN + "/api/download/filesbytag?cat_id=" + catid + "&uid=" + OBJECT_USER_ID;
        if (Platform.getNetworkState() === true) {
            onGetTagsForCategoryComplete = function (tags_data) {
                var result = '';
                var status = tags_data['status'];
                if (status != 200) {
                    result = RESOURCE_ERROR;
                }else if (status == 403) {
                    result=onAccessDenied();
                }
                else {
                    var data = tags_data['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetTagsForCategoryComplete");
            } else {
                var tags_data = Platform.makeHttpGetCall(url);
                return onGetTagsForCategoryComplete($.parseJSON(tags_data));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }catch(e){
        Client.closeProgressBar();
        e.console.log(e.message);
    }
}
MobileCategoryService.prototype.getCategoryTagContents = function(catid, tagid, page, limit, callback){
    try{

        var url = OBJECT_SERVER_DOMAIN + "/api/download/filesbytag?cat_id=" + catid +"&tag_id="+ tagid + "&page="+ page +"&limit="+ limit +"&uid=" + OBJECT_USER_ID;
        if (Platform.getNetworkState() === true) {
            onGetCategoryTagContentsComplete = function (content_data) {
                var result = '';
                var status = content_data['status'];
                if (status != 200) {
                    result = RESOURCE_ERROR;
                }else if (status == 403) {
                    result=onAccessDenied();
                }
                else {
                    var data = content_data['data'];
                    data = $.parseJSON(data);
                    result = data;
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", '', url, "get", "false", "onGetCategoryTagContentsComplete");
            } else {
                var content_data = Platform.makeHttpGetCall(url);
                return onGetCategoryTagContentsComplete($.parseJSON(content_data));
            }
        } else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }catch(e){
        Client.closeProgressBar();
        e.console.log(e.message);
    }
}
MobileCategoryService.prototype.downloadSingleContent = function(resourceid){
    try{
        if (Platform.getNetworkState() === true) {
            var url = OBJECT_SERVER_DOMAIN + '/api/' + OBJECT_VIDEO + '/downloadurl?nid=' + resourceid + '&uid=' + OBJECT_USER_ID;
            var download_content = Platform.makeHttpGetCall(url);
            download_content = $.parseJSON(download_content);
            if (download_content.status == 200) {
                var download_content_data = JSON.parse(download_content.data);
                var download_path = download_content_data.url;
                return download_path;
            }else if (download_content.status == 403) {
                onAccessDenied();
                Client.closeProgressBar();
                return false;
            }else{
                Client.closeProgressBar();
                alert(RESOURCE_ERROR);
                return false;
            }

        } else {
            Client.closeProgressBar();
            alert(INTERNET_ERROR);
            return false;
        }
    }catch(e){
        Client.closeProgressBar();
        e.console.log(e.message);
    }
}
MobileCategoryService.prototype.getNotesofUserByCategory = function (catid, groupid)
{
    try {
        if(typeof NOTES_OF_CATEGORY != "undefined" && NOTES_OF_CATEGORY.length > 0){
            for (var i = 0; i < NOTES_OF_CATEGORY.length; i++) {
                if(typeof NOTES_OF_CATEGORY[i].catid != "undefined" && NOTES_OF_CATEGORY[i].catid == catid && NOTES_OF_CATEGORY[i].groupid == groupid)
                    return NOTES_OF_CATEGORY[i].notes_data;
            }
        }

        var courseService = CONTROLLER.getService("Course");
        var category = Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content");
        category = $.parseJSON(category);
        var i = 0;
        var courseid = CONTROLLER.getVariable("activecourseid");
        var catid = CONTROLLER.getVariable("activecatid");
        var groupid = CONTROLLER.getVariable("activegroupid");
        var categoryNotes = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getnotesofuserby' + OBJECT_CATEGORIES + '?uid=' + OBJECT_USER_ID + '&nid=' + category.tid);
        categoryNotes = $.parseJSON(categoryNotes);

        var notes = {};
        var status = categoryNotes['status'];
        if (status == 200) {
           var data = categoryNotes['data'];
           notes[OBJECT_NOTES] = $.parseJSON(data);
            for (var i = 0; i < notes[OBJECT_NOTES].length; i++) {
                notes[OBJECT_NOTES][i]['catid'] = catid;
                notes[OBJECT_NOTES][i]['groupid'] = groupid;
                notes[OBJECT_NOTES][i].originalDuration = notes[OBJECT_NOTES][i].duration;
                notes[OBJECT_NOTES][i].note = notes[OBJECT_NOTES][i].note.replace(/^\n+|\n+$/g, '').replace(/^\t+|\t+$/g, '').replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
                notes[OBJECT_NOTES][i].duration = getVideoDurationFromSeconds(parseInt(notes[OBJECT_NOTES][i].duration, 10)) + "m";

                var content = Platform.getObjectProperty(OBJECT_VIDEO, notes[OBJECT_NOTES][i]['resourceid'], "content");
                content = $.parseJSON(content);
                var chapterid = content[OBJECT_CHAPTER][0];
                notes[OBJECT_NOTES][i]['chapterid'] = chapterid;
            }

            var new_notes_list = this.getNotesofCategoryWithTopicDetails(notes, catid);

            var notesCategory ={};
            notesCategory.catid = catid;
            notesCategory.groupid = groupid;
            notesCategory.notes_data = new_notes_list;
            NOTES_OF_CATEGORY.push(notesCategory);

            return new_notes_list;
        } else {
            return false;
        }
    } catch (e) {
        console.log("function : getCourseById"
                + "\n message:" + e.message);
    }
};

MobileCategoryService.prototype.getNotesofCategoryWithTopicDetails = function(category_notes, catid)
{
    try {
        var courseService = CONTROLLER.getService("Course");
        var chapterService = CONTROLLER.getService("Chapter");
        var groupid = CONTROLLER.getVariable("activegroupid");
        var user_groups = OBJECT_USER_GROUPS;
        if (typeof catid != 'undefined' && catid != '' && ((typeof GROUP_SUBSCRIPTION != "undefined" && GROUP_SUBSCRIPTION != "true") || typeof GROUP_SUBSCRIPTION == "undefined")) {
            if(UNIQUE_GROUPS == "true"){
                var allcourses = []; allcourses[OBJECT_COURSE] = []; var allcourses;
                var courses = []; courses[OBJECT_COURSE] = [];
                for(var k = 0; k < user_groups.length; k++){
                     var allcourses = this.getCoursesForCategory(catid, user_groups[k]);
                     for(var l = 0; l < allcourses[OBJECT_COURSE].length; l++){
                         if(jsonArrayHasId(courses[OBJECT_COURSE], allcourses[OBJECT_COURSE][l].nid) == false){
                             courses[OBJECT_COURSE].push(allcourses[OBJECT_COURSE][l]);
                         }
                     }
                }

            }
            else{
                var courses = this.getCoursesForCategory(catid, groupid);
            }
        } else {
            var group_service = CONTROLLER.getService("Group");
            var groups = OBJECT_USER_DATA.groups;
            var courses = group_service.getCoursesForUserGroups(groups);
        }
        var chapters = "";
        var notesTemp = "";
        var videos = "";
        var new_notes_list = [];
        new_notes_list[OBJECT_COURSE] = [];

        if (category_notes[OBJECT_NOTES].length > 0) {
            for (var l = 0; l < courses[OBJECT_COURSE].length; l++) {
                var courseid = courses[OBJECT_COURSE][l].nid;
                new_notes_list[OBJECT_COURSE][l] = [];
                new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER] = [];
                var chapters = courseService.getChaptersForCourse(courses[OBJECT_COURSE][l].nid, groupid);
                for (var i = 0; i < chapters[CLIENT_OBJECT_CHAPTER].length; i++) {
                    new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i] = {};
                    new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i].title = chapters[OBJECT_CHAPTER][i].title;
                    new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO] = [];
                    var chapterid = chapters[OBJECT_CHAPTER][i].nid;
                    var resources = chapterService.getResourcesForChapter(chapterid, courses[OBJECT_COURSE][l].nid);
                    for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
                        new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k] = {};
                        new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k].title = resources[OBJECT_VIDEO][k].title;
                        new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES] = [];
                    }
                }

                for (var i = 0; i < chapters[CLIENT_OBJECT_CHAPTER].length; i++) {
                    for (var j = 0; j < category_notes[OBJECT_NOTES].length; j++) {
                        if (chapters[OBJECT_CHAPTER][i].nid == category_notes[OBJECT_NOTES][j].chapterid) {
                            var chapterid = chapters[OBJECT_CHAPTER][i].nid;
                            var resources = chapterService.getResourcesForChapter(chapterid, courses[OBJECT_COURSE][l].nid);
                            for (var k = 0; k < resources[OBJECT_VIDEO].length; k++) {
                                if (resources[OBJECT_VIDEO][k].nid == category_notes[OBJECT_NOTES][j].resourceid) {
                                    category_notes[OBJECT_NOTES][j].courseid = courseid;
                                    new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].push(category_notes[OBJECT_NOTES][j]);
                                }
                            }
                        }
                    }
                }
            }
            for (var l = courses[OBJECT_COURSE].length - 1; l >= 0; l--) {
                topicsnotescount = 0;
                var chapters = courseService.getChaptersForCourse(courses[OBJECT_COURSE][l].nid, groupid);
                for (var i = chapters[CLIENT_OBJECT_CHAPTER].length - 1; i >= 0; i--) {
                    notescount = 0;
                    for (var k = new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO].length - 1; k >= 0; k--) {
                        notescount = new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO].length;
                        count = new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].length;
                        if (new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k][OBJECT_NOTES].length == 0) {
                            new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO][k] = "";
                            new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i][OBJECT_VIDEO].splice(k, 1);
                            notescount = notescount - 1;
                        }
                    }
                    topicsnotescount = topicsnotescount + notescount;
                    if (notescount == 0) {
                        new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER][i] = "";
                        new_notes_list[OBJECT_COURSE][l][OBJECT_CHAPTER].splice(i, 1);
                    }
                }
                if (topicsnotescount == 0) {
                    new_notes_list[OBJECT_COURSE][l] = "";
                    new_notes_list[OBJECT_COURSE].splice(l, 1);
                }
            }
        }
        return new_notes_list;
    } catch (e) {
        console.log("function : getCourseById"
                + "\n message:" + e.message);
    }
}

function MobileBooksService(){};

//sync method

MobileBooksService.prototype.downloadSingleChapter = function(chapterid,changed){
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'fromid?nid=' + chapterid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET + '&changed=' + changed + "&version=" + API_VERSION, OBJECT_CHAPTER+"-"+chapterid);
    }
    catch(e){
        console.log("function : downloadSingleChapter"
            + " message" + e.message);
    }
};

MobileBooksService.prototype.downloadChaptersForCourseAsync = function(courseid){
    try{
        Platform.downloadForm(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_CHAPTER + 'sfor' + OBJECT_COURSE + '?nid=' + courseid + '&uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET, OBJECT_CHAPTER+"-"+courseid);
    }
    catch(e){
        console.log("function : downloadChaptersForCourseAsync"
            + " message" + e.message);
    }
};

MobileBooksService.prototype.getBooksForCourse = function(courseid)
{
    /*
*    array of chapter_ids = getRelationsOfType(OBJECT_COURSE,courseid,OBJECT_CHAPTER);
*    getObjects(OBJECT_CHAPTER,array of chapter_ids,"content");
*
*/
    //api/getchaptersforcourse
    try{
        var books = Platform.getRelatedObjects(OBJECT_COURSE, courseid, OBJECT_BOOK);
        if(books != ""){
            books = $.parseJSON(books);
        }
        return books;
    }
    catch(e){
        console.log( "function : getBooksForCourse"
            + "\n message:" + e.message);
    }
};

function downloadModifiedChapter(pendingobjectdata){
    //download pending chapter data
    if((pendingobjectdata.operation == 'modified' || pendingobjectdata.operation == 'created') && pendingobjectdata.offline != '0'){
        DataManager.downloadSingleChapter(pendingobjectdata.nid,pendingobjectdata.changed);
    } else if(pendingobjectdata.offline == '0' || pendingobjectdata.operation == 'delete'){
        DataManager.removeObject(OBJECT_CHAPTER,pendingobjectdata.nid);
        Platform.deleteVideoViewHistoryByType(pendingobjectdata.nid,"chapter");
    }
}

function MobileGroupService(){

}

MobileGroupService.prototype.getAllGroups = function()
{
    try{
        var groups = Platform.getObjectsOfType("group");
        if(groups != ""){
            return $.parseJSON(groups);
        }
        else
        {
            return "";
        }
    }
    catch(e){
        console.log( "function : getAllGroups"
            + "\n message:" + e.message);
    }

};
MobileGroupService.prototype.getAllGroupsOnline = function () {
//for Aakash live user getgroups online
    if (typeof OBJECT_USER_GROUPS_JSON != "undefined" && OBJECT_USER_GROUPS_JSON.length > 0) {
        return OBJECT_USER_GROUPS_JSON;
    }
    var groups = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_GROUPS + '?uid=' + OBJECT_USER_ID + '&preset=' + OBJECT_PRESET);
    try {
        groups = $.parseJSON(groups);
        var status = groups['status'];
        if (status == 200)
        {
            var data = $.parseJSON(groups['data']);
            OBJECT_USER_GROUPS_JSON = data;
            return data;
        }
    }
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_GROUPS + '?uid=' + OBJECT_USER_ID
                + "\n error_code :" + status
                + "\n response_data :" + data
                + "\n message :" + e.message;
        +"\n function:" + 'getAllGroupsOnline';
        console.log(error);
    }
    ;
};
MobileGroupService.prototype.getGroupIdByName = function(group){
    var groups = this.getAllGroups();
    if(groups != ""){
        for(var i= 0;i<groups.group.length;i++){
            if(groups.group[i].title == group){
                var groupid = groups.group[i].nid;
            }
        }
    }
    else{
        groupid = '';
    }

    return groupid;
}
MobileGroupService.prototype.getCoursesForUserGroups = function (groups)
{
    try {
        var coursesForGroup=[];
        coursesForGroup[OBJECT_COURSE]=[];
        for(var i=0; i<groups.length;i++){
            var courses = Platform.getRelatedObjects(OBJECT_GROUP, groups[i], OBJECT_COURSE);
            courses = $.parseJSON(courses);
            for(var j=0; j<courses[OBJECT_COURSE].length;j++){
                if(jsonArrayHasId(coursesForGroup[OBJECT_COURSE], courses[OBJECT_COURSE][j].nid) == false){
                        coursesForGroup[OBJECT_COURSE].push(courses[OBJECT_COURSE][j])
                     }

            }

        }
        return coursesForGroup;
    } catch (e) {
        console.log("function : getChaptersForCourse"
                + "\n message:" + e.message);
    }
};

MobileGroupService.prototype.getGroupFromId = function (groupId) {
    var groups = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_GROUP + 'fromid?group=' + groupId + '&uid='+ OBJECT_USER_ID +'&auth=' + AUTH_ENABLED);
    try {
        groups = $.parseJSON(groups);
        var status = groups['status'];
        if (status == 200) {
            var data = $.parseJSON(groups['data']);
            return data;
        } else {
            return false;
        }
    }
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/get' + OBJECT_GROUP + 'fromid?group=' + groupId + '&uid='+ OBJECT_USER_ID +'&auth=' + AUTH_ENABLED
                + "\n error_code :" + status
                + "\n response_data :" + data
                + "\n message :" + e.message;
        +"\n function:" + 'getAllGroupsOnline';
        console.log(error);
    }
    ;
};


function BookmarksService() {

};


BookmarksService.prototype.getAllBookmarks =  function(params,callback){
    var url = OBJECT_SERVER_DOMAIN + '/api/getuserbookmarks?uid=' + params.uid + '&auth=' + AUTH_ENABLED;
    try{
        if (Platform.getNetworkState() == true) {

            if(params.filter == 'subject'){
                var json = "{";
                var temp = "";
                temp = ' "uid" : "' + params.uid + '",';
                temp = temp + ' "group" : "' + params.group_id + '",';
                temp = temp + ' "category_id" : "' + params.tid + '",';
                temp = temp + ' "sort" : "' + params.sort + '"';
                json = json + temp + "}";
            }
            else if(params.filter == 'course'){
                var json = "{";
                var temp = "";
                temp = ' "uid" : "' + params.uid + '",';
                temp = temp + ' "group" : "' + params.group_id + '",';
                temp = temp + ' "category_id" : "' + params.tid + '",';
                temp = temp + ' "course_id" : "' + params.course_id + '",';
                temp = temp + ' "sort" : "' + params.sort + '"';
                json = json + temp + "}";
            }
            else if(params.filter == 'timings'){
                var json = "{";
                var temp = "";
                temp = ' "uid" : "' + params.uid + '",';
                temp = temp + ' "group" : "' + params.group_id + '",';
                temp = temp + ' "category_id" : "' + params.tid + '",';
                temp = temp + ' "course_id" : "' + params.course_id + '",';
                temp = temp + ' "sort" : "' + params.sort + '"';
                json = json + temp + "}";
            }
            else if(params.filter == 'get_bookmarks'){
                var json = "{";
                var temp = "";
                temp = ' "uid" : "' + params.uid + '",';
                temp = temp + ' "group" : "' + params.group_id + '",';
                temp = temp + ' "category_id" : "' + params.category_id + '",';
                temp = temp + ' "course_id" : "' + params.course_id + '"';
                json = json + temp + "}";
            }
            else{
                var json = "{";
                var temp = "";
                temp = ' "uid" : "' + params.uid + '",';
                temp = temp + ' "group" : "' + params.group_id + '",';
                temp = temp + ' "sort" : "' + params.sort + '"';
                json = json + temp + "}";
            }
            var postdata = encodeURIComponent(json);
            onGetAllBookmarks = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200)
                {
                    var data = events['data'];
                    data =  $.parseJSON(data);
                    result = data;
                }
                else{
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onGetAllBookmarks");
            } else {
                var user = Platform.makeHttpPostCall(url, postdata);
                return onGetAllBookmarks($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }
   
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/onGetAllBookmarks '
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}   


BookmarksService.prototype.updateBookmark =  function(params,callback){
    var url = OBJECT_SERVER_DOMAIN + '/api/update/userbookmarks?' + '&auth=' + AUTH_ENABLED;
    try{
        if (Platform.getNetworkState() == true) {

            if(params.action == 'add'){
                var json = "{";
                var temp = "";
                temp = ' "uid" : "' + OBJECT_USER_ID + '",';
                temp = temp + ' "nid" : "' + params.nid + '",';
                temp = temp + ' "global_pin" : "' + params.global_pin + '",';
                temp = temp + ' "group" : "' + params.group_id + '",';
                temp = temp + ' "category_id" : "' + params.category_id + '",';
                temp = temp + ' "course_id" : "' + params.course_id + '",';
                temp = temp + ' "chapter_id" : "' + params.chapter_id + '",';
                temp = temp + ' "duration" : "' + params.duration + '",';
                temp = temp + ' "title" : "' + params.title + '",';
                temp = temp + ' "op" : "' + params.action + '"';
                json = json + temp + "}";
            }
            else if(params.action == 'delete'){
                var json = "{";
                var temp = "";
                temp = ' "uid" : "' + OBJECT_USER_ID + '",';
                temp = temp + ' "nid" : "' + params.nid + '",';
                temp = temp + ' "group" : "' + params.group_id + '",';
                temp = temp + ' "favourite_id" : "' + params.bookmark_id + '",';
                temp = temp + ' "op" : "' + params.action + '"';
                json = json + temp + "}";
            }
            else if(params.action == 'update'){
                var json = "{";
                var temp = "";
                temp = ' "uid" : "' + OBJECT_USER_ID + '",';
                temp = temp + ' "title" : "' + params.title + '",';
                temp = temp + ' "favourite_id" : "' + params.bookmark_id + '",';
                temp = temp + ' "op" : "' + params.action + '"';
                json = json + temp + "}";
            }
            
            
            var postdata = encodeURIComponent(json);
            onUpdateBookmark = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200)
                {
                    var data = 'success';
                    result = data;
                }
                else{
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onUpdateBookmark");
            } else {
                var user = Platform.makeHttpPostCall(url, postdata);
                return onUpdateBookmark($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }
   
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/onUpdateBookmark '
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}   


function BuyNowService(){};

BuyNowService.prototype.getCartContents = function(callback)
{
    try{
        if(Platform.getNetworkState() == true){
                var url = OBJECT_SERVER_DOMAIN + '/api/user/cart/contents';
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    onAsyncGetCartContents = function (contents)
                    {
                        var status = contents['status'];
                        if (status == '200') {
                            var data = '{"contents":' + contents['data'].trim() + '}';
                            contents = $.parseJSON(data);
                            return callback(contents);
                        }
                        else if (status == 403) {
                            return callback(status);
                        }
                        else {
                            return callback(ERROR_MESSAGE);
                        }
                    }
                    makeAsyncTaskCall("", "", url, "post", "false", "onAsyncGetCartContents");
                }                             
        }
        else{
            return INTERNET_ERROR;
        }
        }
    catch(e){
        console.log( "function : getCartContents"
            + "\n message:" + e.message);
    }
};
BuyNowService.prototype.SaveLead = function(userjson,callback) {
    try {
        if (Platform.getNetworkState() == true) {
         var url = SAVE_LEADS_API;
              $.ajax({
                type: "post",
                        url: url,
                        data: userjson,
                        contentType: "text/plain",
                        crossDomain:true,
                        async: true,
                        success: function (data) {
                        console.log(data);
                        },
                error: function (xhr, status, error) {
                    status = xhr.status;
                    result = '{"status":' + status + '}';
                    console.log(result);
                }
                });           
    }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : SaveLead"
                + "\n message:" + e.message);

    }
};
BuyNowService.prototype.Zoho = function(userjson,callback) {
    try {
        if (Platform.getNetworkState() == true) {
         var url = OBJECT_SERVER_DOMAIN + '/api/zoho';
         var postdata = encodeURIComponent(userjson);
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                onAsyncZohoCallComplete = function (user)
                {
                    var status = user['status'];
                    if (status == '200') {
                        return callback('true');
                    }
                    else {
                        return callback('');
                    }
                }
                makeAsyncTaskCall("", postdata, url, "post", "false", "onAsyncZohoCallComplete");
            }            
        }
        else {
            return INTERNET_ERROR;
        }
    }
    catch (e) {
        console.log("function : callZoho"
                + "\n message:" + e.message);

    }
};
BuyNowService.prototype.getFullCourses = function (groups,callback) {
    try {
        var url = OBJECT_SERVER_DOMAIN + '/api/get/product/course?groups='+groups+'&uid='+OBJECT_USER_ID;
        var data = "";
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            onAsyncGetFullCourses = function (contents)
            {
                var status = contents['status'];
                if (status == '200') {
                    if(contents['data'] == ""){
                        var data = '{"contents":""}';
                    }
                    else{
                        var data = '{"contents":' + contents['data'] + '}';
                    }
                    
                    contents = $.parseJSON(data);
                    return callback(contents);
                }
                else {
                    return callback('');
                }
            }
            makeAsyncTaskCall("", "", url, "post", "false", "onAsyncGetFullCourses");
        }
    }
    catch (e) {
        console.log("getFullCourses" + e.message);
    }
};
BuyNowService.prototype.getCrashCourses = function (groups,callback) {
    try {
        var url = OBJECT_SERVER_DOMAIN + '/api/get/product/crashcourse?groups='+groups+'&uid='+OBJECT_USER_ID;
        if (typeof callback != 'undefined' && typeof callback == 'function') {
            onAsyncGetCrashCourses = function (contents)
            {
                var status = contents['status'];
                var data ='';
                if (status == '200') {
                    if( contents['data'].trim() != ''){
                     data = '{"contents":' + contents['data'].trim() + '}';   
                    }
                    contents = $.parseJSON(data);
                    return callback(contents);
                }
                else {
                    return callback('');
                }
            }
            makeAsyncTaskCall("", "", url, "post", "false", "onAsyncGetCrashCourses");
        }
    }
    catch (e) {
        console.log("getCrashCourses" + e.message);
    }
};
BuyNowService.prototype.getTestseries = function (groups) {
    try {
        var contents = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get/product/testseries?groups='+groups+'&uid='+OBJECT_USER_ID);
        contents = $.parseJSON(contents);
        var status = contents['status'];
        if (status == 200)
        {
            var data = '{"contents":' + contents['data'].trim() + '}';
            contents = $.parseJSON(data);
        }
        else {
            contents = "";
        }
        return contents;
    }
    catch (e) {
        console.log("getTestseries" + e.message);
    }
};
BuyNowService.prototype.getTestcollection = function (groups) {
    try {
        var contents = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get/product/testcollection?groups='+groups+'&uid='+OBJECT_USER_ID);
        contents = $.parseJSON(contents);
        var status = contents['status'];
        if (status == 200)
        {
            var data = '{"contents":' + contents['data'].trim() + '}';
            contents = $.parseJSON(data);
        }
        else {
            contents = "";
        }
        return contents;
    }
    catch (e) {
        console.log("getTestseries" + e.message);
    }
};
BuyNowService.prototype.getChapters = function (groups,category) {
    try {
        var contents = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get/product/chapters?groups='+groups+'&tid='+category+'&uid='+OBJECT_USER_ID);
        contents = $.parseJSON(contents);
        var status = contents['status'];
        if (status == 200)
        {
            var data = '{"contents":' + contents['data'].trim() + '}';
            contents = $.parseJSON(data);
        }
        else {
            contents = "";
        }
        return contents;
    }
    catch (e) {
        console.log("getChapters" + e.message);
    }
};
BuyNowService.prototype.getModules= function (groups,category) {
    try {
        var contents = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/get/product/module?groups='+groups+'&category='+category+'&uid='+OBJECT_USER_ID);
        contents = $.parseJSON(contents);
        var status = contents['status'];
        if (status == 200)
        {
            var data = '{"contents":' + contents['data'].trim() + '}';
            contents = $.parseJSON(data);
        }
        else {
            contents = "";
        }
        return contents;
    }
    catch (e) {
        console.log("getChapters" + e.message);
    }
};
BuyNowService.prototype.addToCart = function (json) {
    try {
        json = JSON.stringify(json);
        var url = OBJECT_SERVER_DOMAIN + '/api/addtocart?auth='+AUTH_ENABLED+'&uid='+OBJECT_USER_ID;
        var postdata = encodeURIComponent(json);
        var contents = Platform.makeHttpPostCall(url, postdata);
        contents = $.parseJSON(contents);
        var status = contents['status'];
        if (status == 200)
        {
            contents = status;
        }
        else {
            contents = "";
        }
        return contents;
    }
    catch (e) {
        console.log("addToCart" + e.message);
    }
};


BuyNowService.prototype.removeCartContents = function (json) {
    try {
         var url = OBJECT_SERVER_DOMAIN + '/api/removecartitems?auth='+AUTH_ENABLED+'&uid='+OBJECT_USER_ID;
        var postdata = encodeURIComponent(json);
        var contents = Platform.makeHttpPostCall(url, postdata);
        contents = $.parseJSON(contents);
        var status = contents['status'];
        if (status == 200)
        {
            return 'true';
        }
        else {
             return 'false';
        }
    }
    catch (e) {
        console.log("getCartContents" + e.message);
    }
};
BuyNowService.prototype.applyCouponCode = function (json) {
    try {
        var url = OBJECT_SERVER_DOMAIN + '/api/coupon/apply?auth='+AUTH_ENABLED+'&uid='+OBJECT_USER_ID;
        var postdata = encodeURIComponent(json);
        var contents = Platform.makeHttpPostCall(url, postdata);
        contents = $.parseJSON(contents);
        var status = contents['status'];
        if (status == 200)
        {
            var data = '{"contents":' + contents['data'].trim() + '}';
            contents = $.parseJSON(data);
            contents = contents['contents'];
        }
        else {
              contents='';
        }
         return contents;
    }
    catch (e) {
        console.log("getCartContents" + e.message);
    }
};
BuyNowService.prototype.getCartItems = function()
{
    try{
        if(Platform.getNetworkState() == true){
            try {
                var contents = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getcartitems?auth='+AUTH_ENABLED+'&uid=' + OBJECT_USER_ID);
                contents = $.parseJSON(contents);
                var status = contents['status'];
                if (status == 200)
                {
                    var data = '{"contents":' + contents['data'].trim() + '}';
                    contents = $.parseJSON(data);
                }
                else {
                    contents = "";
                }
                return contents;
            }
            catch (e) {
                console.log("getCartContents"+e.message);
            }
        }
        else{
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : getCartContents"
            + "\n message:" + e.message);
    }
};
BuyNowService.prototype.cartItemCheckOut = function()
{
    try{
        if(Platform.getNetworkState() == true){
            try {
                var contents = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/cartitemcheckout?auth='+AUTH_ENABLED+'&uid=' + OBJECT_USER_ID);
                contents = $.parseJSON(contents);
                var status = contents['status'];
                if (status == 200)
                {
                    var data = '{"contents":' + contents['data'].trim() + '}';
                    contents = status;
                }
                else {
                    contents = "";
                }
                return contents;
            }
            catch (e) {
                console.log("getUserPofileInfo"+e.message);
            }
        }
        else{
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : getUserPofileInfo"
            + "\n message:" + e.message);
    }
};

BuyNowService.prototype.saveBillingInfo = function(userjson)
{
    try{
        if(Platform.getNetworkState() == true){
            try {
                if (Platform.getNetworkState() == true) {
                    var url = OBJECT_SERVER_DOMAIN + '/api/cartitembilling&uid='+ OBJECT_USER_ID;
                    var postdata = encodeURIComponent(userjson);
                    var contents = Platform.makeHttpPostCall(url, postdata);
                    contents = $.parseJSON(contents);
                    var status = contents['status'];
                    Client.closeProgressBar();
                    if (status == 200) {
                        var data = '{"contents":' + contents['data'].trim() + '}';
                        contents = $.parseJSON(data);
                    }
                    else{
                        contents =status;
                    }
                    return contents;
                }
                else {
                    return INTERNET_ERROR;
                }
            }
            catch (e) {
                console.log("getUserPofileInfo"+e.message);
            }
        }
        else{
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : getUserPofileInfo"
            + "\n message:" + e.message);
    }
};


BuyNowService.prototype.saveEnrollmentInfo = function(userjson)
{
    try{
        if(Platform.getNetworkState() == true){
            try {
                if (Platform.getNetworkState() == true) {
                    var url = OBJECT_SERVER_DOMAIN + '/api/userenrollment?uid=' + OBJECT_USER_ID;
                    var postdata = encodeURIComponent(userjson);
                    var contents = Platform.makeHttpPostCall(url, postdata);
                    contents = $.parseJSON(contents);
                    var status = contents['status'];
                    Client.closeProgressBar();
                    if (status == 200) {
                         contents =status;
                    }
                    else{
                        contents =status;
                    }
                    return contents;
                }
                else {
                    return INTERNET_ERROR;
                }
            }
            catch (e) {
                console.log("getUserPofileInfo"+e.message);
            }
        }
        else{
            return INTERNET_ERROR;
        }
    }
    catch(e){
        console.log( "function : getUserPofileInfo"
            + "\n message:" + e.message);
    }
};

BuyNowService.prototype.getAllcategoriesForUserGroups = function(groups){    
        try {
        var contents = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getall' + OBJECT_CATEGORIES + '&uid=' + OBJECT_USER_ID + '&groups=' + groups);
        contents = $.parseJSON(contents);
        var status = contents['status'];
        if (status == 200)
        {
            var data = '{"contents":' + contents['data'].trim() + '}';
            contents = $.parseJSON(data);
        }
        else {
            contents = "";
        }
        return contents;
    }
    catch (e) {
        console.log("getChapters" + e.message);
    }
};

BuyNowService.prototype.getproductcountforsubjects = function(subject_tids,groups,type){    
        try {
        var contents = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + '/api/getpublishedproductsfor' + OBJECT_CATEGORIES + '/count?tids=' + subject_tids + '&groupid=' + groups + '&type='+type + "&auth=" + AUTH_ENABLED);
        contents = $.parseJSON(contents);
        var status = contents['status'];
        if (status == 200)
        {
            var data = '{"contents":' + contents['data'].trim() + '}';
            contents = $.parseJSON(data);
        }
        else {
            contents = "";
        }
        return contents;
    }
    catch (e) {
        console.log("getChapters" + e.message);
    }
};
function CalenderService() {

};


CalenderService.prototype.getAllCalenderEvents =  function(startDate, endDate, callback){
    var groupID= CONTROLLER.getVariable('activegroupid');
    var url = OBJECT_SERVER_DOMAIN + '/api/getcalendarevents?type=quiz&groupid=' + groupID + '&start=' + startDate + '&end=' + endDate + '&auth=' + AUTH_ENABLED;
    try{
        if (Platform.getNetworkState() == true) {
            onGetAllCalenderEvents = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200)
                {
                    var data = events['data'];
                    data =  $.parseJSON(data);
                    result = data;
                }
                else{
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "get", "false", "onGetAllCalenderEvents");
            } else {
                var user = Platform.makeHttpGetCall(url);
                return onGetAllCalenderEvents($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }
   
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/getcalendarevents '+ start + '&end=' + end
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}   


function CoachmarkService() {

};


CoachmarkService.prototype.setCoachmark =  function(params,callback){
    var url = OBJECT_SERVER_DOMAIN + '/api/set/coachmark?' + '&auth=' + AUTH_ENABLED;
    try{
        if (Platform.getNetworkState() == true) {
            
            var json = "{";
            var temp = "";
            temp = ' "uid" : "' + params.uid + '",';
            temp = temp + ' "screen_type" : "' + params.screen_type + '",';
            temp = temp + ' "time" : "' + params.time + '",';
            temp = temp + ' "status" : "' + params.status + '"';
            json = json + temp + "}";
            
            var postdata = encodeURIComponent(json);
            onSetCoachmark = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200)
                {
                    var data = 'success';
                    result = data;
                }
                else{
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", postdata, url, "post", "false", "onSetCoachmark");
            } else {
                var user = Platform.makeHttpPostCall(url, postdata);
                return onSetCoachmark($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }
   
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/onSetCoachmark '
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}   


CoachmarkService.prototype.getAllCoachmarks =  function(callback){
    
    var url = OBJECT_SERVER_DOMAIN + '/api/get/coachmark?uid=' + OBJECT_USER_ID + '&auth=' + AUTH_ENABLED;
    try{
        if (Platform.getNetworkState() == true) {
            onGetAllCoachmarks = function(events){
                var result = '';
                var status = events['status'];
                if (status == 200)
                {
                    var data = events['data'];
                    data =  $.parseJSON(data);
                    result = data;
                }
                else{
                    result = "error";
                }
                if (typeof callback != 'undefined' && typeof callback == 'function') {
                    return callback(result);
                } else {
                    return result;
                }
            }
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                makeAsyncTaskCall("", "", url, "get", "false", "onGetAllCoachmarks");
            } else {
                var user = Platform.makeHttpGetCall(url);
                return onGetAllCoachmarks($.parseJSON(user));
            }
        }
        else {
            if (typeof callback != 'undefined' && typeof callback == 'function') {
                return callback(INTERNET_ERROR);
            } else {
                return INTERNET_ERROR;
            }
        }
    }
   
    catch (e)
    {
        var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/onGetAllBookmarks '
        + "\n error_code :" + status
        + "\n response_data :" + data
        + "\n message :" + e.message;
        console.log(error);
    }
}   